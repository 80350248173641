import React, { Fragment } from "react";

import { MjmlColumn, MjmlSection, MjmlTable, MjmlText } from "../../mjml-react";

import useVKLData from "../../hooks/useVKLData";
import useSelection from "../../hooks/useSelection";

export default function LosList() {
  const { vkl, kw, year, startDate, endDate, lose, zubehoer } = useVKLData();

  const { template } = useSelection();

  return (
    <Fragment>
      {template === "SaleOverview" ? (
        <MjmlSection padding="20px 10px 0 10px" backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlTable
              padding="20px"
              paddingTop="0"
              paddingBottom="30px"
              fontSize="14px"
              color="#000000"
            >
              <tr
                style={{
                  color: "#00334c",
                  borderBottom: "2px solid #00334c",
                }}
              >
                <th style={{ textAlign: "left" }}>Los</th>
                <th
                  style={{
                    textAlign: "left",
                    color: "#82C539",
                  }}
                >
                  Verkaufspreis
                </th>
              </tr>
              {(lose || []).map((los, index) => (
                <tr
                  key={index}
                  style={
                    index + 1 < lose.length
                      ? {}
                      : { borderBottom: "1px solid #00334c" }
                  }
                >
                  <td style={{ fontWeight: 500 }}>
                    <a
                      href={los.url}
                      style={{
                        color: "#00334c",
                        textDecoration: "underline",
                        textDecorationColor: "#bdc3c7",
                      }}
                      target="_blank"
                    >
                      Los {los.number} VKL{vkl} - {los.amount} {los.manufactor}{" "}
                      {los.type}
                    </a>
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      color: "#82C539",
                    }}
                  >
                    {los.soldAmount} €
                  </td>
                </tr>
              ))}
            </MjmlTable>
          </MjmlColumn>
        </MjmlSection>
      ) : (
        <MjmlSection padding="20px 10px 0 10px" backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlTable
              padding="20px"
              paddingTop="0"
              paddingBottom="30px"
              fontSize="14px"
              color="#000000"
            >
              <tr
                style={{
                  color: "#00334c",
                  borderBottom: "2px solid #00334c",
                }}
              >
                <th style={{ textAlign: "left" }}>Los</th>
                <th style={{ textAlign: "left", padding: "10px" }}>Start</th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "10px",
                    color: "#00b8f1",
                  }}
                >
                  &#9654; &nbsp; Ende
                </th>
              </tr>
              {(lose || []).map((los, index) => (
                <tr
                  key={index}
                  style={
                    index + 1 < lose.length
                      ? {}
                      : { borderBottom: "1px solid #00334c" }
                  }
                >
                  <td style={{ fontWeight: 500 }}>
                    <a
                      href={los.url}
                      style={{
                        color: "#00334c",
                        textDecoration: "underline",
                        textDecorationColor: "#bdc3c7",
                      }}
                      target="_blank"
                    >
                      Los {los.number} VKL{vkl} - {los.amount} {los.manufactor}{" "}
                      {los.type}
                    </a>
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {startDate}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      color: "#00b8f1",
                    }}
                  >
                    {los.endTime} Uhr <br />
                    <strong>{endDate}</strong>
                  </td>
                </tr>
              ))}
            </MjmlTable>
          </MjmlColumn>
        </MjmlSection>
      )}

      {zubehoer && (
        <MjmlSection padding="20px 10px 0 10px" backgroundColor="#FFFFFF">
          {template === "SaleOverview" ? (
            <MjmlColumn>
              <MjmlText
                align="left"
                paddingTop="0px"
                fontSize="16px"
                padding="20px"
                paddingBottom="20px"
              >
                Zudem laufen folgende LGI Auktionen:
              </MjmlText>
              <MjmlTable
                padding="20px"
                paddingTop="0"
                paddingBottom="30px"
                fontSize="14px"
                color="#000000"
              >
                <tr
                  style={{
                    color: "#00334c",
                    borderBottom: "2px solid #00334c",
                  }}
                >
                  <th style={{ textAlign: "left" }}>Los</th>
                  <th
                    style={{
                      textAlign: "left",
                      color: "#82C539",
                    }}
                  >
                    Verkaufspreis
                  </th>
                </tr>

                {zubehoer.map((los, index) => (
                  <tr
                    key={index}
                    style={
                      index + 1 < lose.length
                        ? {}
                        : { borderBottom: "1px solid #00334c" }
                    }
                  >
                    <td style={{ color: "#004a7f", fontWeight: 500 }}>
                      <a
                        href={los.url}
                        style={{ color: "#00334c", textDecoration: "none" }}
                        target="_blank"
                      >
                        Zubeh&ouml;r Los {los.number} KW{kw} {year} -
                        {los.amount} {los.manufactor} {los.type}
                      </a>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        color: "#82C539",
                      }}
                    >
                      {los.soldAmount} €
                    </td>
                  </tr>
                ))}
              </MjmlTable>
            </MjmlColumn>
          ) : (
            <MjmlColumn>
              <MjmlText
                align="left"
                paddingTop="0px"
                fontSize="16px"
                padding="20px"
                paddingBottom="20px"
              >
                Zudem laufen folgende LGI Auktionen:
              </MjmlText>
              <MjmlTable
                padding="20px"
                paddingTop="0"
                paddingBottom="30px"
                fontSize="14px"
                color="#000000"
              >
                <tr
                  style={{
                    color: "#00334c",
                    borderBottom: "2px solid #00334c",
                  }}
                >
                  <th style={{ textAlign: "left" }}>Los</th>
                  <th style={{ textAlign: "left", padding: "10px" }}>Start</th>
                  <th
                    style={{
                      textAlign: "left",
                      padding: "10px",
                      color: "#00b8f1",
                    }}
                  >
                    &#9654; &nbsp; Ende
                  </th>
                </tr>

                {zubehoer.map((los, index) => (
                  <tr
                    key={index}
                    style={
                      index + 1 < lose.length
                        ? {}
                        : { borderBottom: "1px solid #00334c" }
                    }
                  >
                    <td style={{ color: "#004a7f", fontWeight: 500 }}>
                      <a
                        href={los.url}
                        style={{ color: "#00334c", textDecoration: "none" }}
                        target="_blank"
                      >
                        Zubeh&ouml;r Los {los.number} KW{kw} {year} -
                        {los.amount} {los.manufactor} {los.type}
                      </a>
                    </td>
                    <td style={{ padding: "10px", textAlign: "center" }}>
                      {startDate}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        color: "#00b8f1",
                      }}
                    >
                      {los.endTime} Uhr <br />
                      <strong>{endDate}</strong>
                    </td>
                  </tr>
                ))}
              </MjmlTable>
            </MjmlColumn>
          )}
        </MjmlSection>
      )}
    </Fragment>
  );
}
