import gql from "graphql-tag";
import { makeAnOfferEntryFragment } from "../fragments/makeAnOfferEntry";
import { accountLabelFragment } from "../../../account-manager/graphql/fragments/accountLabel";

export default gql`
query makeAnOfferEntriesQuery($shopIds: [ID], $filters: MakeAnOfferFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [OffersSortBy]) {
    makeAnOfferEntries(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
        nodes {
          ...MakeAnOfferEntry
          account {
            ...AccountLabel
          }
        }
        totalCount
        __typename
  }
}
${makeAnOfferEntryFragment}
${accountLabelFragment}
`;
