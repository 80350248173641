import gql from "graphql-tag";
import { productSpecFragment } from "../fragments/productSpec";

export default gql`
  query productSpecsQuery($shopIds: [ID], $filters: ProductSpecFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ProductSpecSortBy]) {
    productSpecs(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...ProductSpec
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${productSpecFragment}
`;
