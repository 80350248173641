import React from "react";
import PropTypes from "prop-types";
import { registerComponent } from "../../../reaction-components";

const Currency = ({ amount, priceRange, editable }) => (
  <span itemProp="price">{amount || priceRange}</span>
);

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  priceRange: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

registerComponent("Currency", Currency);

export default Currency;
