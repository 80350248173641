import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  Link,
  ListItemText,
  Menu,
  FormControlLabel,
  Checkbox,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import ChevronDownIcon from "mdi-material-ui/ChevronDown";
import Button from "@reactioncommerce/catalyst/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingRight: theme.spacing(1.5)
  },
  formControlLabel: {
    width: "100%"
  }
}));

/**
 * @name FieldsMenu
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
const FieldsMenu = React.forwardRef((props, ref) => {
  const {
    children,
    onSelect,
    allColumns,
    toggleHideAllColumns
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = ref || React.useRef(null);

  /**
   * Handle menu close
   * @param {SyntheticEvent} event Event object
   * @returns {undefined}
   */
  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        aria-controls="action-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        ref={anchorRef}
      >
        {children}
        <Box display="flex" paddingLeft={1}>
          <ChevronDownIcon />
        </Box>
      </Button>
      <Menu
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorRef.current}
        id="action-menu"
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="default-label" disabled>
          <Box maxWidth={320} whiteSpace="normal">
            <ListItemText
              primary={children}
            />
          </Box>
        </MenuItem>
        {allColumns.map((column, index) => {
          const {
            Header,
            id
          } = column;

          let label = Header;

          if (id === "selection") {
            label = "Auswahl-Checkbox";
          }

          if (id === "expander") {
            label = "Detailansicht";
          }

          if (id === "primaryImage") {
            label = "Produktbild";
          }

          return (
            <MenuItem
              key={index}
            >
              <FormControlLabel
                classes={{
                  root: classes.formControlLabel
                }}
                control={<Checkbox />}
                label={label}
                {...column.getToggleHiddenProps()}
              />
            </MenuItem>
          );
        })}
        <ListItem key="clear-button">
          <Link
            component="button"
            variant="body2"
            onClick={() => toggleHideAllColumns(false)}
          >
            Alle entfernen
          </Link>
        </ListItem>
      </Menu>
    </Fragment>
  );
});

FieldsMenu.displayName = "FieldsMenu";

FieldsMenu.defaultProps = {
  color: "primary",
  variant: "outlined"
};

FieldsMenu.propTypes = {
  /**
   * The content of the Button
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * Options: `default` | `inherit` | `primary` | `secondary` | `error`
   */
  color: PropTypes.string,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool, // eslint-disable-line
  /**
   * If `true`, the CircularProgress will be displayed and the button will be disabled.
   */
  isWaiting: PropTypes.bool,
  /**
   * Called when an option is selected. Can be use simultaneously with option onClick callbacks.
   */
  onSelect: PropTypes.func,
  /**
   * Menu options
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Change the cancel button label in the confirm dialog
     */
    cancelActionText: PropTypes.string,
    /**
     * Change the label of the confirmation button in the confirm dialog
     */
    confirmActionText: PropTypes.string,
    /**
     * If supplied, the option will show a confirm dialog this message when selected.
     */
    confirmMessage: PropTypes.string,
    /**
     * If supplied, the option will show a confirm dialog this title when selected
     */
    confirmTitle: PropTypes.string,
    /**
     * Secondary option label
     */
    details: PropTypes.string,
    /**
     * Option label
     */
    label: PropTypes.string.isRequired,
    /**
     * If supplied, this function will be called in addition to onSelect
     */
    onClick: PropTypes.func
  }))
};

export default FieldsMenu;
