import gql from "graphql-tag";

export const productLineFragment = gql`
    fragment ProductLine on ProductLine {
        _id
        name
        purchaseDiscount
        salesDiscount
    }
`;
