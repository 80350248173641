import React, {Fragment, useState} from "react";
import _ from "lodash";
import { Form, muiOptions, useReactoForm } from "reacto-form";
import { useQuery, useMutation } from "@apollo/react-hooks";
import SimpleSchema from "simpl-schema";
import countryOptions from "../../../../utils/CountryOptions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import userShippingAddress from "../graphql/queries/userShippingAddress";
import DeleteIcon from "mdi-material-ui/Delete";
import updateShippingMutation from "../graphql/mutations/updateShipping";
import createShippingMutation from "../graphql/mutations/createShipping";
import deleteShippingMutation from "../graphql/mutations/deleteShipping";
import ReactPhoneInput from "react-phone-input-material-ui";

import {
    styled,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    makeStyles,
    TextField,
    Typography, Dialog, DialogContent, DialogActions
} from "@material-ui/core";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const useStyles = makeStyles((theme) => ({
    submitButton: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.colors.white
    },
}));

function AccountEditShippingForm(props) {
    const { match } = props;
    const classes = useStyles();
    const accountId = (match && match.params.accountId) || null;
    const addressId = (match && match.params.addressId) || null;

    const [ phoneCode, setPhoneCode ] = useState(null);
    const [ showMsg, setShowMsg ] = useState(false);
    const [ returnToList, setReturnToList ] = useState(false);
    const [ dialogMsg, setDialogMsg ] = useState("");
    const [ isDeleteConfirmation, setIsDeleteConfirmation ] = useState(false);

    const [ deleteShipping ] = useMutation(deleteShippingMutation);
    const [ updateShipping ] = useMutation(updateShippingMutation);
    const [ createShipping ] = useMutation(createShippingMutation);

    const { data } = useQuery(userShippingAddress, {
        variables: {
            accountId, addressId
        }
    });

    const returnToAddressSelect = () => {
        props.history.push("/account-manager/shipping/" + accountId);
    }

    const returnToAccountsTable = () => {
        props.history.push("/account-manager");
    }

    const showDeleteWarning = () => {
        setShowMsg(true);
        setDialogMsg("Are you sure you want to delete this address?");
        setIsDeleteConfirmation(true);
    }

    const handleModalClose = () => {
        setShowMsg(false);
        setIsDeleteConfirmation(false);

        if (returnToList) {
            returnToAddressSelect();
        }
    }

    const handleModalOpen = () => {
        setShowMsg(true);
    }

    const deleteAddress = async () => {
        setShowMsg(false);
        setIsDeleteConfirmation(false);

        try {
            await deleteShipping({
                variables: {
                    input: {
                        accountId: accountId,
                        addressId: addressId
                    }
                }
            });

            setDialogMsg("Success: Shipping Address deleted");
            setReturnToList(true);
            handleModalOpen();
        } catch (error) {
            setDialogMsg("Error: unable to delete Shipping Address");
            setReturnToList(false);
        }
    }

    const getSchema = () => {
        let schema = {
            fullName: {
                type: String,
                min: 1
            },
            address1: {
                type: String,
                min: 1
            },
            address2: {
                type: String,
                optional: true
            },
            phone: {
                type: String,
                min: 8
            },
            city: {
                type: String,
                min: 1
            },
            region: {
                type: String
            },
            postal: {
                type: String,
                min: 1
            },
            country: {
                type: String,
                min: 1
            },
            "__typename": {
                type: String,
                min: 1
            },
            "_id": {
                type: String,
                min: 1
            }
        }

        if (addressId) {
            Object.entries(schema).forEach(([key]) => {
               schema[key].optional = true;
            });
        }

        return schema;
    }

    const formSchema = new SimpleSchema(getSchema());

    const validator = formSchema.getFormValidator();

    const { getErrors, hasErrors, getInputProps, submitForm, formData, isDirty } = useReactoForm({
        async onSubmit(formData) {
            let is_ok = false;
            try {
                if (addressId != null) {
                    const updatedAddress = _.omit(formData, ["_id", "__typename"]);

                    await updateShipping({
                        variables: {
                            input: {
                                accountId: accountId,
                                addressId: addressId,
                                updates: {
                                    ...updatedAddress,
                                    isCommercial: false
                                }
                            }
                        }
                    });
                    setDialogMsg("Success: Shipping Address updated");
                    is_ok = true;
                } else {
                    await createShipping({
                        variables: {
                            input: {
                                accountId: accountId,
                                address: {
                                    ...formData,
                                    isCommercial: false
                                }
                            }
                        }
                    });
                    setDialogMsg("Success: Shipping Address created");
                }

                setReturnToList(true);
            } catch (error) {
                setDialogMsg("Error: unable to update Address Book");
                setReturnToList(false);
            }

            handleModalOpen();
            return { ok: is_ok };
        },
        validator,
        value: data?.shippingAddress
    });

    let shippingAddress = {};

    if (addressId !== null) {
        shippingAddress = data && data.shippingAddress;
    }

    if (shippingAddress) {
        return (
            <div>
                <BootstrapDialog
                    open={showMsg}
                    onClose={handleModalClose}
                    aria-labelledby={"modal-modal-title"}
                    aria-describedby={"modal-modal-description"}
                >
                    <DialogContent>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {dialogMsg}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {isDeleteConfirmation ? (
                            <React.Fragment>
                                <Button onClick={handleModalClose} variant={"outlined"}>
                                    No
                                </Button>
                                <Button onClick={deleteAddress} variant={"contained"}>
                                    Yes
                                </Button>
                            </React.Fragment>
                        ) : (
                            <Button onClick={handleModalClose} variant={"contained"}>
                                Close
                            </Button>
                        )}
                    </DialogActions>
                </BootstrapDialog>

                <Card>
                    {addressId !== null ? (
                        <CardHeader
                            title={"Edit Shipping Address"}
                            titleTypographyProps={{variant: 'h1'}}
                            action={
                                <Button
                                    id={'deleteBtn'}
                                    onClick={showDeleteWarning}
                                    // variant={"outlined"}
                                    // display={"flex"}
                                    startIcon={<DeleteIcon/>}
                                    color={"error"}
                                    m={1}
                                >
                                    Delete Address
                                </Button>
                            }
                        />
                    ) : (
                        <CardHeader
                            title={"Create Shipping Address"}
                            titleTypographyProps={{variant: 'h1'}}
                        />
                    )}
                    <CardContent>
                        <Form>
                            <Grid container spacing={2} style={({padding: '0px 25px 25px 25px'})}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("fullName", muiOptions)}
                                        error={hasErrors(["fullName"])}
                                        helperText={
                                            getErrors(["fullName"]).length
                                                ? getErrors(['fullName']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        id={"fullName"}
                                        label={'Full Name'}
                                        fullWidth
                                        value={((formData && formData.fullName) || (formData.fullName === "")) ?
                                            formData.fullName : shippingAddress.fullName}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <ReactPhoneInput
                                        {...getInputProps("phone", muiOptions)}
                                        value={((formData && formData.phone) || (formData.phone === "")) ?
                                            formData.phone : shippingAddress.phone}
                                        onChange={(phoneNum) => {
                                        }}
                                        component={TextField}
                                        country={phoneCode || "de"}
                                        isValid={!hasErrors(["invoiceAddress.phone"])}
                                        placeholder={" "}
                                        id={`phone`}
                                        autoComplete="phone"
                                        label={'Phone'}
                                        fullWidth
                                    />
                                    {hasErrors(["phone"]) &&
                                        (getErrors(["phone"]).length
                                            ? getErrors(["phone"]).map((error, index) => (
                                                <Fragment key={index}>
                                                    <div style={{ color: "red" }}>{error.message}</div>
                                                </Fragment>
                                            ))
                                            : "")
                                    }
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("address1", muiOptions)}
                                        error={hasErrors(["address1"])}
                                        helperText={
                                            getErrors(["address1"]).length
                                                ? getErrors(['address1']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        label={'Address 1'}
                                        fullWidth
                                        value={((formData && formData.address1) || (formData.address1 === "")) ?
                                            formData.address1 : shippingAddress.address1}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("address2", muiOptions)}
                                        label={'Address 2'}
                                        fullWidth
                                        value={((formData && formData.address2) || (formData.address2 === "")) ?
                                            formData.address2 : shippingAddress.address2}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("city", muiOptions)}
                                        error={hasErrors(["city"])}
                                        helperText={
                                            getErrors(["city"]).length
                                                ? getErrors(['city']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        label={'City'}
                                        fullWidth
                                        value={((formData && formData.city) || (formData.city === "")) ?
                                            formData.city : shippingAddress.city}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("region", muiOptions)}
                                        error={hasErrors(["region"])}
                                        helperText={
                                            getErrors(["region"]).length
                                                ? getErrors(['region']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        label={'Region'}
                                        fullWidth
                                        value={((formData && formData.region) || (formData.region === "")) ?
                                            formData.region : shippingAddress.region}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        {...getInputProps("postal", muiOptions)}
                                        error={hasErrors(["postal"])}
                                        helperText={
                                            getErrors(["postal"]).length
                                                ? getErrors(['postal']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        label={'Zip Code'}
                                        fullWidth
                                        value={((formData && formData.postal) || (formData.postal === "")) ?
                                            formData.postal : shippingAddress.postal}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        {...getInputProps("country", muiOptions)}
                                        id={"invoice-country"}
                                        options={countryOptions}
                                        getOptionLabel={(option) => {
                                            if (option && option.value) return option.value;
                                            return option || "";
                                        }}
                                        renderOption={(option) => option.label}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={hasErrors(["country"])}
                                                helperText={
                                                    getErrors(["country"]).length
                                                        ? getErrors(['country']).map((error, index) => (
                                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                                        )) : ""
                                                }
                                                label={'Country'}
                                                fullWidth
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                        getOptionSelected={(option, value) => {
                                            return option.value === value;
                                        }}
                                        value={((formData && formData.country) || (formData.country === "")) ?
                                            formData.country : shippingAddress.country}
                                        onChange={(event, value) => {
                                            if (value && value.value) {
                                                setPhoneCode(value.value.toLowerCase());
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Form>

                        <Grid
                            container
                            justify={"flex-end"}
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    id={'cancelBtn'}
                                    onClick={returnToAccountsTable}
                                    variant={"outlined"}
                                    display={"flex"}
                                    m={1}
                                >
                                    Back to Accounts
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    id={'cancelBtn'}
                                    onClick={returnToAddressSelect}
                                    variant={"outlined"}
                                    display={"flex"}
                                    m={1}
                                >
                                    Back to Addresses
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    id={'submitBtn'}
                                    onClick={submitForm}
                                    variant={"contained"}
                                    className={classes.submitButton}
                                    disabled={!isDirty}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    } else {
        return (<div>loading</div>);
    }
}

export default (AccountEditShippingForm);
