import React from "react";
import PropTypes from "prop-types";

/**
 * @name PrimaryImageCell
 * @param {Object} primaryImage A primaryImage object
 * @return {React.Component} A date component
 */
export default function PrimaryImageCell({ primaryImage }) {
  if (!primaryImage) {
    return null;
  }

  const { URLs } = primaryImage;

  if (!URLs) {
    return null;
  }

  const { thumbnail } = URLs;

  return <img alt="" src={thumbnail} height={45} />;
}

PrimaryImageCell.propTypes = {
  primaryImage: PropTypes.object,
};
