import React, { Component } from "react";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
import Button from "@reactioncommerce/components/Button/v1";
import debounce from "lodash/debounce";

const styles = (theme) => ({
  dialogPaper: {
    overflow: "visible"
  }
});

class QuerySelectReturnIdDialog extends React.Component {
    state = {

    }

    handleSelectedSpecToAddChange = (selectedOption) => {
      this.setState({
        specificationIdToAdd: selectedOption.value
      });
    }

    handleSelectedSpecInputChange = debounce((value) => {
      this.setState({
        searchField: value
      });
    }, 1000)

    handleAddField = () => {
      const { specificationIdToAdd } = this.state;
      const { onSubmit, mutation } = this.props;

      if (mutation) {
        onSubmit(specificationIdToAdd, mutation);
      } else {
        onSubmit(specificationIdToAdd);
      }
    }

    render() {
      const { searchField } = this.state;
      const { shopId, classes: { dialogPaper }, isOpen, query, onClose, queryData, title } = this.props;

      return (
        <Query query={query} variables={{ shopId, filters: { searchField } }} fetchPolicy="network-only">
          {({ data }) => {
            const fields = data && data[queryData] || {};
            const { nodes: queriedById = [] } = fields;
            return (
              <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{ className: dialogPaper }}
              >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className={dialogPaper}>
                  <Select
                    // value={selectedOption}
                    onChange={this.handleSelectedSpecToAddChange}
                    options={queriedById.map((spec) => ({ value: spec._id, label: spec.name }))}
                    onInputChange={this.handleSelectedSpecInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                Abbrechen
                  </Button>
                  <Button onClick={this.handleAddField} color="primary">
                Speichern
                  </Button>
                </DialogActions>
              </Dialog>
            );
          }}
        </Query>
      );
    }
}

export default withStyles(styles)(QuerySelectReturnIdDialog);
