import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import MessageBox from "./MessageBox";

const styles = {
  rceContainerMlist: {
    position: "relative",
    display: "flex",
    margin: "auto"
  },
  rceMlist: {
    display: "block",
    overflow: "auto",
    position: "relative",
    flex: 1
  },
  rceMlistDownButton: {
    "position": "absolute",
    "right": "10px",
    "bottom": "15px",
    "width": "40px",
    "height": "40px",
    "background": "#fff",
    "boxShadow":
      "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.1)",
    "borderRadius": "100%",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "color": "#333",
    "cursor": "pointer",
    "transition": "200ms",
    "&:hover": {
      opacity: 0.7
    }
  },
  rceMlistDownButtonBadge: {
    position: "absolute",
    right: "-5px",
    top: "-5px",
    background: "red",
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    fontSize: "12px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "700"
  }
};

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollBottom: 0,
      downButton: false
    };

    this.loadRef = this.loadRef.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  checkScroll() {
    const e = this.mlistRef;
    if (!e) return;

    if (
      this.props.toBottomHeight === "100%" ||
      this.state.scrollBottom < this.props.toBottomHeight
    ) {
      // scroll to bottom
      e.scrollTop = e.scrollHeight;
    } else if (this.props.lockable === true) {
      e.scrollTop = e.scrollHeight - e.offsetHeight - this.state.scrollBottom;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.mlistRef) return;
    if (nextProps.dataSource.length !== this.props.dataSource.length) {
      this.setState(
        {
          scrollBottom: this.getBottom(this.mlistRef)
        },
        this.checkScroll.bind(this)
      );
    }
  }

  getBottom(e) {
    return e.scrollHeight - e.scrollTop - e.offsetHeight;
  }

  onOpen(item, i, e) {
    if (this.props.onOpen instanceof Function) this.props.onOpen(item, i, e);
  }

  onDownload(item, i, e) {
    if (this.props.onDownload instanceof Function) { this.props.onDownload(item, i, e); }
  }

  onPhotoError(item, i, e) {
    if (this.props.onPhotoError instanceof Function) { this.props.onPhotoError(item, i, e); }
  }

  onClick(item, i, e) {
    if (this.props.onClick instanceof Function) this.props.onClick(item, i, e);
  }

  onTitleClick(item, i, e) {
    if (this.props.onTitleClick instanceof Function) { this.props.onTitleClick(item, i, e); }
  }

  onForwardClick(item, i, e) {
    if (this.props.onForwardClick instanceof Function) { this.props.onForwardClick(item, i, e); }
  }

  onContextMenu(item, i, e) {
    if (this.props.onContextMenu instanceof Function) { this.props.onContextMenu(item, i, e); }
  }

  onMessageFocused(item, i, e) {
    if (this.props.onMessageFocused instanceof Function) { this.props.onMessageFocused(item, i, e); }
  }

  loadRef(ref) {
    this.mlistRef = ref;
    if (this.props.cmpRef instanceof Function) this.props.cmpRef(ref);
  }

  onScroll(e) {
    const bottom = this.getBottom(e.target);
    this.state.scrollBottom = bottom;
    if (
      this.props.toBottomHeight === "100%" ||
      bottom > this.props.toBottomHeight
    ) {
      if (this.state.downButton !== true) {
        this.state.downButton = true;
        this.setState({
          downButton: true,
          scrollBottom: bottom
        });
      }
    } else if (this.state.downButton !== false) {
      this.state.downButton = false;
      this.setState({
        downButton: false,
        scrollBottom: bottom
      });
    }

    if (this.props.onScroll instanceof Function) {
      this.props.onScroll(e);
    }
  }

  toBottom(e) {
    if (!this.mlistRef) return;
    this.mlistRef.scrollTop = this.mlistRef.scrollHeight;
    if (this.props.onDownButtonClick instanceof Function) {
      this.props.onDownButtonClick(e);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames([
          classes.rceContainerMlist,
          this.props.className
        ])}
      >
        <div
          ref={this.loadRef}
          onScroll={this.onScroll}
          className={classes.rceMlist}
        >
          {this.props.dataSource.map((x, i) => (
            <MessageBox
              key={i}
              {...x}
              isAdminView
              onOpen={this.props.onOpen && ((e) => this.onOpen(x, i, e))}
              onPhotoError={
                this.props.onPhotoError && ((e) => this.onPhotoError(x, i, e))
              }
              onDownload={
                this.props.onDownload && ((e) => this.onDownload(x, i, e))
              }
              onTitleClick={
                this.props.onTitleClick && ((e) => this.onTitleClick(x, i, e))
              }
              onForwardClick={
                this.props.onForwardClick && ((e) => this.onForwardClick(x, i, e))
              }
              onClick={this.props.onClick && ((e) => this.onClick(x, i, e))}
              onContextMenu={
                this.props.onContextMenu && ((e) => this.onContextMenu(x, i, e))
              }
              onMessageFocused={
                this.props.onMessageFocused &&
                    ((e) => this.onMessageFocused(x, i, e))
              }
            />
          ))}
        </div>
      </div>
    );
  }
}

MessageList.defaultProps = {
  onClick: null,
  onTitleClick: null,
  onForwardClick: null,
  onDownButtonClick: null,
  onOpen: null,
  onPhotoError: null,
  onDownload: null,
  dataSource: [],
  lockable: false,
  toBottomHeight: 300,
  downButton: true,
  downButtonBadge: null
};

export default withStyles(styles)(MessageList);
