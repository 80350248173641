import gql from "graphql-tag";
import { productClassFragment } from "../fragments/productClass";

export default gql`
  query productClassesQuery($shopIds: [ID], $filters: ProductClassFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ProductClassSortBy]) {
    productClasses(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...ProductClass
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${productClassFragment}
`;
