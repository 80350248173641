import React from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Components } from "../../../../reaction-components";
import { useMutation } from "@apollo/react-hooks";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ProductMediaItem from "./ProductMediaItem";
import { useSnackbar } from "notistack";
import alert from "../../../../utils/alert";
import encodeOpaqueId from "../../../../utils/encodeOpaqueId";
import config from "../../../../config";

const { REACT_APP_PUBLIC_FILES_BASE_URL } = config;

const archiveMediaRecordMutation = gql`
  mutation ArchiveMediaRecord($input: ArchiveMediaRecordInput!) {
    archiveMediaRecord(input: $input) {
      mediaRecord {
        _id
      }
    }
  }
`;

const updateMediaRecordPriorityMutation = gql`
mutation UpdateMediaRecordPriorityMutation($input: UpdateMediaRecordPriorityInput!) {
  updateMediaRecordPriority(input: $input) {
    mediaRecord {
      _id
    }
  }
}
`;

/**
 * ProductMediaGallery
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function ProductMediaGallery(props) {
  const {
    editable,
    media,
    productId,
    shopId,
    variantId,
    refetchProduct
  } = props;

  const [archiveMediaRecord] = useMutation(archiveMediaRecordMutation, { ignoreResults: true });
  const [updateMediaRecordPriority] = useMutation(updateMediaRecordPriorityMutation, { ignoreResults: true });

  const { t } = useTranslation("ns1");
  const { enqueueSnackbar } = useSnackbar();

  const handleMediaAdded = async () => {
    // Wait 3 Seconds to allow the API to wait for the Admin
    await new Promise(resolve => setTimeout(resolve, 5000));
    refetchProduct();
  };

  const handleRemoveMedia = (mediaToRemove) => {
    const imageUrl = `${REACT_APP_PUBLIC_FILES_BASE_URL}${mediaToRemove.URLs.medium}`;
    const opaqueMediaId = encodeOpaqueId("reaction/mediaRecord", mediaToRemove._id);

    alert({
      title: "Remove Media?",
      icon: "warning",
      showCancelButton: true,
      imageUrl,
      imageHeight: 150
    }, async (isConfirm) => {
      if (isConfirm) {
        await archiveMediaRecord({
          variables: {
            input: {
              mediaRecordId: opaqueMediaId,
              shopId
            }
          },
          onError(error) {
            console.error(error);
            enqueueSnackbar("Unable to remove media", { variant: "error" });
          }
        });

        refetchProduct();
      }
    });
  };

  const handleSetMediaPriority = async (mediaRecord, priority) => {
    const opaqueMediaId = encodeOpaqueId("reaction/mediaRecord", mediaRecord._id);

    await updateMediaRecordPriority({
      variables: {
        input: {
          mediaRecordId: opaqueMediaId,
          priority,
          shopId
        }
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Unable to update media priority", { variant: "error" });
      }
    });

    refetchProduct();
  };


  let count = (Array.isArray(media) && media.length) || 0;
  const hasMedia = count > 0;

  const getFileMetadata = () => {
    count += 1;
    return {
      productId,
      variantId,
      priority: count
    };
  };

  const onUploadError = (error) => {
    // Alerts.toast(error.reason || error.message, "error");
  };

  return (
    <div className="rui media-gallery">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("admin.productTable.header.order")}</TableCell>
            <TableCell>{"Media"}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!!hasMedia && (
            (media || []).map((mediaItem) => (
              <ProductMediaItem
                editable={editable}
                key={mediaItem._id}
                onRemoveMedia={handleRemoveMedia}
                onSetMediaPriority={handleSetMediaPriority}
                size="small"
                source={mediaItem}
              />
            ))
          )}
          {editable &&
            <TableRow>
              <TableCell colSpan={3}>
                <Components.MediaUploader
                  canUploadMultiple
                  metadata={getFileMetadata}
                  onError={onUploadError}
                  shopId={shopId}
                  onMediaAdded={handleMediaAdded}
                />
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
}

ProductMediaGallery.propTypes = {
  editable: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  media: PropTypes.arrayOf(PropTypes.object),
  onSetMediaPriority: PropTypes.func,
  productId: PropTypes.string,
  shopId: PropTypes.string,
  variantId: PropTypes.string
};

export default ProductMediaGallery;
