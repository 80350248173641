import React, { useState, useMemo } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import config from "../../../../config";

const { REACT_APP_PUBLIC_PRICELIST_BASE_URL } = config;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  newButton: {
    margin: theme.spacing(0, 1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const vendors = ["hp", "sharp", "lg", "epson", "kyocera", "samsung"];

function ProductExport() {
  const classes = useStyles();
  const { t } = useTranslation("ns1");
  const { accessToken } = useAuth();

  const [state, setState] = useState(
    vendors.reduce((result, current) => ({ ...result, [current]: false }), {})
  );

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const selectedVendors = useMemo(
    () =>
      Object.entries(state).reduce((result, current) => {
        if (current[1]) {
          return [...result, current[0]];
        }
        return result;
      }, []),
    [state]
  );

  return (
    <Card className={classes.card}>
      <CardHeader title={t("admin.dashboard.productExportTitle")} />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Hersteller auswählen</FormLabel>
              <FormGroup>
                {vendors.map((vendor) => (
                  <FormControlLabel
                    key={vendor}
                    control={
                      <Checkbox
                        checked={state[vendor]}
                        onChange={handleChange}
                        name={vendor}
                      />
                    }
                    label={vendor.toUpperCase()}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <a
              href={`${REACT_APP_PUBLIC_PRICELIST_BASE_URL}/assets/itscope?vendors=${encodeURIComponent(
                selectedVendors
              )}&token=${accessToken}&type=xlsx`}
              target="_blank"
              download
            >
              <Button
                color="primary"
                variant="contained"
                disabled={selectedVendors.length < 1}
              >
                Download Excel-Liste
              </Button>
            </a>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProductExport;
