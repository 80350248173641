import React from "react";
import PropTypes from "prop-types";

const decodeOpaqueId = (opaqueId) => {
  if (opaqueId === undefined || opaqueId === null) return null;
  const unencoded = Buffer.from(opaqueId, "base64").toString("utf8");
  const [namespace, id] = unencoded.split(":");
  return { namespace, id };
};

/**
 * @name IdCell
 * @param {Object} row A react-table row object
 * @param {Object} history Router history API
 * @return {React.Component} A date component
 */
function IdCell({ value }) {
  const { id: decodedId } = decodeOpaqueId(value);

  return decodedId;
}

IdCell.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  value: PropTypes.string.isRequired
};

export default IdCell;
