import React from "react";
import AccountIcon from "mdi-material-ui/AccountMultiple";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import AccountTable from "./components/AccountTable";
import AccountEditForm from "./pages/AccountEditFormContainer";
import AccountEditShippingForm from "./components/AccountEditShippingForm";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 20,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: AccountTable,
  path: "/account-manager",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <AccountIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.accountAdminLabel"
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/account-manager/:mode/:accountId",
  mainComponent: AccountEditForm
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/account-manager/shipping/:accountId/edit/:addressId",
  mainComponent: AccountEditShippingForm
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/account-manager/shipping/:accountId/create",
  mainComponent: AccountEditShippingForm
});
