import React from "react";

import { MjmlColumn, MjmlSection, MjmlText } from "../../mjml-react";

export default function FooterCaption() {
  return (
    <MjmlSection padding="0">
      <MjmlColumn>
        <MjmlText
          paddingBottom="30px"
          paddingTop="10px"
          paddingLeft="20px"
          paddingRight="20px"
          fontSize="12px"
          lineHeight="18px"
          color="#959698"
        >
          <a
            href="https://mall.remarket-now.com/Account/UserProfile#/Account/CommunicationSettings"
            style={{
              color: "#00b8f1",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
            target="_blank"
          >
            Klicken Sie bitte hier
          </a>
          &nbsp; um sich von unseren Email-Benachrichtigungen abzumelden.
        </MjmlText>
      </MjmlColumn>
    </MjmlSection>
  );
}
