import React, {
  Children,
  useContext,
  useCallback,
  useState,
  useRef,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "mdi-material-ui/Close";
import NewTabIcon from "mdi-material-ui/ArrowTopRight";
import { UIContext } from "../../../../context/UIContext";

const useStyles = makeStyles((theme) => ({
  action: {
    marginLeft: theme.spacing(),
  },
  content: {
    paddingTop: theme.spacing(),
    height: "100%",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  paperAnchorRight: {
    width: ({ detailDrawerWidth }) => detailDrawerWidth,
  },
  dragger: {
    width: "5px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 5000,
    //backgroundColor: "#f4f7f9",
  },
  resizing: {
    display: "none",
  },
}));

const minDrawerWidth = 50;
const maxDrawerWidth = 1000;

/**
 * Detail drawer used for displaying supplementary info and actions for a view.
 * @param {Object} props Component props
 * @returns {React.Component} Sidebar component
 */
function PreviewDrawer({ anchor, children, previewUrl }) {
  const {
    isMobile,
    onCloseDetailDrawer,
    isDetailDrawerOpen,
    detailDrawerWidth: outerDetailDrawerWidth,
    onChangeDetailDrawerWidth,
  } = useContext(UIContext);

  const [detailDrawerWidth, setDetailDrawerWidth] = useState(
    outerDetailDrawerWidth
  );

  const [isResizing, setIsResizing] = useState(false);

  const detailDrawerWidthRef = useRef(detailDrawerWidth);

  const classes = useStyles({ detailDrawerWidth });

  const handleMouseMove = useCallback((event) => {
    const newWidth =
      document.body.offsetWidth - event.clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDetailDrawerWidth(newWidth);
      detailDrawerWidthRef.current = newWidth;
    }
  }, []);

  const handleMouseDown = useCallback(() => {
    setIsResizing(true);
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  }, [document]);

  const handleMouseUp = () => {
    setIsResizing(false);
    onChangeDetailDrawerWidth(detailDrawerWidthRef.current);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  return (
    <Drawer
      classes={{ paperAnchorRight: classes.paperAnchorRight }}
      elevation={2}
      variant={isMobile ? "temporary" : "persistent"}
      anchor={anchor}
      open={isDetailDrawerOpen}
      onClose={onCloseDetailDrawer}
      ModalProps={{
        keepMounted: false, // Better open performance on mobile.
      }}
    >
      <div
        onMouseDown={(event) => handleMouseDown(event)}
        className={classes.dragger}
      />
      <AppBar color="default" elevation={0} position="sticky">
        <Toolbar className={classes.toolbar}>
          <Button
            href={previewUrl}
            endIcon={<NewTabIcon />}
            target="_blank"
            rel="noopener noreferrer"
          >
            In neuem Tab öffnen
          </Button>
          <IconButton onClick={onCloseDetailDrawer} size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {Children.map(children, (child) => (
        <div className={isResizing ? classes.resizing : classes.content}>
          {child}
        </div>
      ))}
    </Drawer>
  );
}

PreviewDrawer.propTypes = {
  anchor: PropTypes.oneOf(["left", "right", "top", "bottom"]),
  children: PropTypes.node,
  classes: PropTypes.object,
  title: PropTypes.string,
};

PreviewDrawer.defaultProps = {
  anchor: "right",
};

export default PreviewDrawer;
