import gql from "graphql-tag";
import { productSpecFragment } from "../fragments/productSpec";

export default gql`
  query productSpecQuery($productSpecId: ID!) {
    productSpec(_id: $productSpecId, shouldIncludeInvisible: true) {
      ...ProductSpec
    }
  }
  ${productSpecFragment}
`;
