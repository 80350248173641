import React, { createContext } from "react";

export type ExcelLink = {
  name: string;
  file: string;
};

export interface VKLData {
  [key: string]: any;
  excelLinks?: ExcelLink[];
}

export type Props = {
  vklData: VKLData;
  children: React.ReactNode;
};

type IContext = VKLData;

export const VKLContext = createContext<IContext>({});

export const VKLProvider = ({ vklData, children }: Props) => (
  <VKLContext.Provider value={vklData}>{children}</VKLContext.Provider>
);
