import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { applyTheme, getRequiredValidator } from "@reactioncommerce/components/utils";
import { Mutation, Query } from "react-apollo";
import { orderBy, uniqueId } from "lodash";
import styled from "styled-components";
import { Form } from "reacto-form";
import TextField from "@material-ui/core/TextField";
import Button from "@reactioncommerce/components/Button/v1";
import Checkbox from "@reactioncommerce/components/Checkbox/v1";
import ErrorsBlock from "@reactioncommerce/components/ErrorsBlock/v1";
import Field from "@reactioncommerce/components/Field/v1";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "mdi-material-ui/CloseCircle";
import SpecsIcon from "mdi-material-ui/FormatListBulleted";
import TextIcon from "mdi-material-ui/Textbox";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MUICardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "mdi-material-ui/Plus";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { withTranslation } from "react-i18next";
import productSpecGroupsQuery from "../../graphql/queries/productSpecGroups";
import productSpecsQuery from "../../../product-specifications/graphql/queries/productSpecs";
import addProductSpecGroupMutation from "../../graphql/mutations/addProductSpecGroup";
import updateProductSpecGroupMutation from "../../graphql/mutations/updateProductSpecGroup";
import removeProductSpecGroupMutation from "../../graphql/mutations/removeProductSpecGroup";
import TagToolbar from "../../../shared/components/table/TagToolbar";
import QuerySelectReturnIdDialog from "../../../shared/components/Dialogs/QuerySelectReturnIdDialog";

const Title = styled.h3`
  margin-bottom: 16px;
`;

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const PaddedField = styled(Field)`
  margin-bottom: 30px;
`;

const ContentGroup = styled.div`
  margin-bottom: 30px;
`;

const HeroEditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const HeroUploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const styles = (theme) => ({
  specContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  specElement: {
    cursor: "grabbing",
    fontSize: "1em",
    margin: "10px 5px"
  },
  addIconButton: {
    padding: "12px 14px"
  },
  menuItem: {},
  menuItemIcon: {},
  menuItemPrimary: {},
  addTextField: {},
  dialogPaper: {
    overflow: "visible"
  }
});

class ProductSpecGroupForm extends Component {
  static propTypes = {
    isLoadingShopId: PropTypes.bool,
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    productSpec: PropTypes.object,
    shopId: PropTypes.string
  }

  static defaultProps = {
    onCancel() {},
    onCreate() {},
    onUpdate() {},
    productSpec: {
      isVisible: true
    }
  }

  state = {
    uploadPreview: null,
    anchorEl: null,
    specifications: [],
    addTextDialogOpen: false,
    addSpecificationDialogOpen: false
  }

  formValue = null;

  uniqueInstanceIdentifier = uniqueId("URLRedirectEditForm");

  checkForSpecifications() {
    const { productSpec } = this.props;
    const { specifications } = productSpec;
    const { specifications: localSpecifications } = this.state;
    if (!localSpecifications.length && specifications) {
      this.setState({
        specifications
      });
    }
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.checkForSpecifications();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productSpec !== this.props.productSpec) {
      console.log("componentDidUpdate");
      this.checkForSpecifications();
    }
  }

  async handleSubmit(data, mutation) {
    const { shopId } = this.props;
    const { specifications } = this.state;
    const isNew = !data._id;

    const refetchQueries = [{
      query: productSpecGroupsQuery,
      variables: {
        shopId
      }
    }];


    /*
    const SpecificationOrTextValidator = ProductSpecGroupsSchema.getFormValidator();
    const specificationsInput = specifications.map((specification) => {
      return SpecificationOrTextValidator(SpecificationOrTextSchema.clean(specification));
    });
    */

    const specificationsInput = specifications && specifications.map(({ __typename, ...item }) => item) || [];

    const input = {
      id: data._id,
      name: data.name,
      specifications: specificationsInput,
      isVisible: data.isVisible || false,
      shopId,
      heroMediaUrl: data.heroMediaUrl
    };

    /* needsFix
    if (!isNew) {
      // On update, refetch featured products
      refetchQueries.push({
        query: tagProductsQuery,
        variables: {
          shopId,
          tagId: data._id
        }
      });
    }
    */

    const result = await mutation({
      refetchQueries,
      variables: {
        input
      }
    });

    console.log("result", result);

    if (result.data.addProductSpecGroup) {
      this.props.onCreate(result.data.addProductSpecGroup.productSpecGroup);
    } else {
      this.props.onUpdate(result.data.updateProductSpecGroup.productSpecGroup);
    }

    return result;
  }

  handleAddClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAddClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemove(id, mutation) {
    /*
    Alerts.alert({
      title: t("admin.routing.form.deleteConfirm"),
      type: "warning",
      showCancelButton: true
    }, async (isConfirm) => {
      if (isConfirm) {
        const { shopId } = this.props;

        await mutation({
          variables: {
            input: {
              id,
              shopId
            }
          }
        });
      }
    });
    */
  }

  reset() {
    this.formValue = null;
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  handleSubmitForm = () => {
    this.form.submit();
  }

  handleFormChange = (value) => {
    this.formValue = value;
  }

  handleAddText = () => {
    this.handleAddClose();
    this.setState({ addTextDialogOpen: true });
  };

  handleAddSpec = () => {
    this.handleAddClose();
    this.setState({ addSpecificationDialogOpen: true });
  }

  handleAddSpecificationDialogSubmit = (selectedId) => {
    const newElement = {
      _id: selectedId,
      position: 0,
      type: "specification"
    };

    const { specifications: oldSpecifications } = this.state;
    const specifications = [...oldSpecifications, newElement].map((element, index) => {
      const newElement = { ...element, position: index };
      return newElement;
    });

    this.setState({
      specifications
    }, () => {
      this.handleAddSpecificationDialogClose();
    });
  };

  handleAddSpecificationDialogClose = () => {
    this.setState({ addSpecificationDialogOpen: false });
  };

  handleAddTextDialogClose = () => {
    this.setState({ addTextDialogOpen: false, tempAddTextValue: null });
  };

  handleAddTextboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleAddTextField = () => {
    const { tempAddTextValue } = this.state;

    const newElement = {
      content: tempAddTextValue,
      position: 0,
      type: "text"
    };

    const { specifications: oldSpecifications = [] } = this.state;
    const specifications = [...oldSpecifications, newElement].map((element, index) => {
      const newElement = { ...element, position: index };
      return newElement;
    });

    this.setState({
      specifications
    }, () => {
      this.handleAddTextDialogClose();
    });
  };

  renderAddTextDialog() {
    const { addTextDialogOpen, tempAddTextValue } = this.state;
    return (
      <Dialog
        open={addTextDialogOpen}
        onClose={this.handleAddTextDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Neuer Text-Platzhalter</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            autoComplete="off"
            margin="dense"
            id="text"
            label="text"
            type="text"
            value={tempAddTextValue}
            onChange={this.handleAddTextboxChange("tempAddTextValue")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAddTextDialogClose} color="primary">
              Abbrechen
          </Button>
          <Button onClick={this.handleAddTextField} color="primary">
              Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  get productSpecData() {
    const { productSpec } = this.props;

    if (productSpec) {
      const metafields = {};

      if (Array.isArray(productSpec.metafields)) {
        productSpec.metafields.forEach((field) => {
          metafields[field.key] = field.value;
        });
      }

      return {
        ...productSpec,
        ...metafields
      };
    }

    return {};
  }

  arrayMove(arr, old_index, new_index) {
    console.log("old array", arr);
    const array = arr.slice();
    while (old_index < 0) {
      old_index += array.length;
    }
    while (new_index < 0) {
      new_index += array.length;
    }
    if (new_index >= array.length) {
      let k = new_index - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);

    const sortedArray = array.map((element, index) => {
      const newElement = { ...element, position: index };
      return newElement;
    });

    console.log("new array", sortedArray);
    return sortedArray;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ specifications }) => ({
      specifications: this.arrayMove(specifications, oldIndex, newIndex)
    }));
    console.log(oldIndex);
    console.log(newIndex);
  };

  handleDeleteSpecFromGroup = (data) => () => {
    console.log("chip data", data);
    this.setState(({ specifications }) => ({
      specifications: specifications.filter((specification) => specification.position !== data.position)
    }));
  };

  render() {
    const productSpec = this.productSpecData;
    const { /*shopId,*/ classes: { specElement, specContainer, menuItem, menuItemIcon, menuItemPrimary, addIconButton}, t } = this.props;
    const { anchorEl, addSpecificationDialogOpen } = this.state;

    const shopId = "cmVhY3Rpb24vc2hvcDpKOEJocTN1VHRkZ3daeDNyeg==";

    const nameInputId = `name_${this.uniqueInstanceIdentifier}`;
    const unitInputId = `unit_${this.uniqueInstanceIdentifier}`;
    const isVisibleInputId = `isVisible_${this.uniqueInstanceIdentifier}`;

    let title = t("admin.productSpecGroups.form.formTitleNew");
    let mutation = addProductSpecGroupMutation;

    if (productSpec._id) {
      title = t("admin.productSpecGroups.form.formTitleUpdate");
      mutation = updateProductSpecGroupMutation;
    }

    const { specifications } = this.state;

    const SortableItem = SortableElement(({ value }) => {
      const { type, name, content, unit } = value;

      const color = type === "specification" && "secondary" || "primary";
      const label = type === "specification" && `${name}${unit && ` (${unit})` || ""}` || value.content;

      return (
        <Chip
          key={value.id}
          label={label}
          color={color}
          variant="outlined"
          onDelete={this.handleDeleteSpecFromGroup(value)}
          deleteIcon={<DeleteIcon/>}
          className={specElement}
        />
      );
    });

    const SortableList = SortableContainer(({ items }) => (
      <div className={specContainer}>
        {Array.isArray(items) && items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    ));

    const specificationIds = specifications && specifications.map((specification) => specification._id) || [];

    return (
      <Fragment>
        {this.renderAddTextDialog()}

        <QuerySelectReturnIdDialog
          isOpen={addSpecificationDialogOpen}
          onSubmit={this.handleAddSpecificationDialogSubmit}
          onClose={this.handleAddSpecificationDialogClose}
          query={productSpecsQuery}
          queryData="productSpecs"
          title="Spezifikation hinzufügen"
          shopId={shopId}
        />

        <Query query={productSpecsQuery} variables={{ shopId, filters: { specificationIds } }} fetchPolicy="network-only">
          {({ data }) => {
            const productSpecs = data && data.productSpecs || {};
            const { nodes: queriedSpecsById = [] } = productSpecs;
            console.log("productSpecs", queriedSpecsById);

            const finalItems = specifications && specifications.map((specification) => {
              if (specification && specification.type === "specification") {
                const matchedSpec = queriedSpecsById.find((queriedSpecsById) => queriedSpecsById._id === specification._id);
                if (matchedSpec) {
                  console.log("matched a spec");
                  const { name, unit } = matchedSpec;
                  const newSpec = { ...specification, name, unit };
                  return newSpec;
                }
              }
              return specification;
            }).sort((a, b) => a.position - b.position);

            console.log("finalItems", finalItems);

            /*
        const mergedItems = queriedSpecsById.slice().sort(function(a, b){
          return specifications.indexOf(a._id) - specifications.indexOf(b._id);
        })
        */

            return (
              <Mutation mutation={mutation}>
                {(mutationFunc) => (
                  <Fragment>
                    <Mutation mutation={removeProductSpecGroupMutation}>
                      {(removeMutationFunc) => (
                        <TagToolbar
                          onDelete={() => { this.handleRemove(productSpec._id, removeMutationFunc); }}
                          onCancel={this.handleCancel}
                          onSave={this.handleSave}
                        />
                      )}
                    </Mutation>
                    <Title>{title}</Title>
                    <Form
                      ref={(formRef) => { this.form = formRef; }}
                      onChange={this.handleFormChange}
                      onSubmit={(data) => this.handleSubmit(data, mutationFunc)}
                      validator={getRequiredValidator("name")}
                      value={productSpec}
                    >
                      <ContentGroup>
                        <PaddedField
                          name="name"
                          label={t("admin.productSpecs.form.name")}
                          labelFor={nameInputId}
                          isRequired
                        >
                          <TextInput id={nameInputId} name="name" placeholder={t("admin.productSpecs.form.specNamePlaceholder")} />
                          <ErrorsBlock names={["name"]} />
                        </PaddedField>
                      </ContentGroup>

                      <Card>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item md={12}>
                              { queriedSpecsById &&
                        <PaddedField
                          helpText={t("admin.productSpecGroups.form.specificationsHelpText")}
                          name="specifications"
                          label={t("admin.productSpecGroups.form.specifications")}
                          labelFor={unitInputId}
                          isRequired
                        >
                          <div className={specContainer}>
                            <IconButton
                              aria-label="More"
                              aria-haspopup="true"
                              onClick={this.handleAddClick}
                              className={addIconButton}
                            >
                              <AddIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={this.handleAddClose}
                            >
                              <MenuItem className={menuItem} onClick={this.handleAddSpec}>
                                <ListItemIcon className={menuItemIcon}>
                                  <SpecsIcon />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: menuItemPrimary }} inset primary="Spezifikation" />
                              </MenuItem>
                              <MenuItem className={menuItem} onClick={this.handleAddText}>
                                <ListItemIcon className={menuItemIcon}>
                                  <TextIcon />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: menuItemPrimary }} inset primary="Text-Platzhalter" />
                              </MenuItem>
                            </Menu>
                            <SortableList /* items={productSpecs.nodes}*/
                              items={finalItems}
                              onSortEnd={this.onSortEnd}
                              distance={5}
                              axis="x"
                            />
                          </div>
                          <ErrorsBlock names={["unit"]} />
                        </PaddedField>
                              }

                              <PaddedField
                                name="isVisible"
                                labelFor={isVisibleInputId}
                              >
                                <Checkbox
                                  id={isVisibleInputId}
                                  name="isVisible"
                                  label={t("admin.productSpecGroups.form.isVisible")}
                                />
                              </PaddedField>
                            </Grid>
                          </Grid>

                          <CardActions disableSpacing>
                            <Button actionType="secondary" onClick={this.handleSubmitForm}>
                              {t("admin.tags.form.save")}
                            </Button>
                          </CardActions>
                        </CardContent>
                      </Card>
                    </Form>
                  </Fragment>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

export default withTranslation()(withStyles(styles, { name: "ProductSpecGroupForm" })(ProductSpecGroupForm));
