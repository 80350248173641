import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { applyTheme, getRequiredValidator } from "@reactioncommerce/components/utils";
import { Mutation } from "react-apollo";
import { orderBy, uniqueId } from "lodash";
import styled from "styled-components";
import { Form } from "reacto-form";
import Button from "@reactioncommerce/components/Button/v1";
import Checkbox from "@reactioncommerce/components/Checkbox/v1";
import ErrorsBlock from "@reactioncommerce/components/ErrorsBlock/v1";
import Field from "@reactioncommerce/components/Field/v1";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import MUICardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import productSpecsQuery from "../../graphql/queries/productSpecs";
import addProductSpecMutation from "../../graphql/mutations/addProductSpec";
import updateProductSpecMutation from "../../graphql/mutations/updateProductSpec";
import removeProductSpecMutation from "../../graphql/mutations/removeProductSpec";
import TagToolbar from "../../../shared/components/table/TagToolbar";

const Title = styled.h3`
  margin-bottom: 16px;
`;

const CardActions = styled(MUICardActions)`
  justify-content: flex-end;
  padding-right: 0 !important;
`;

const PaddedField = styled(Field)`
  margin-bottom: 30px;
`;

const ContentGroup = styled.div`
  margin-bottom: 30px;
`;

const HeroEditButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const HeroUploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

class ProductSpecForm extends Component {
  static propTypes = {
    isLoadingShopId: PropTypes.bool,
    onCancel: PropTypes.func,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    productSpec: PropTypes.object,
    shopId: PropTypes.string.isRequired
  }

  static defaultProps = {
    onCancel() {},
    onCreate() {},
    onUpdate() {},
    productSpec: {
      isVisible: true
    }
  }

  state = {
    uploadPreview: null
  }

  formValue = null;

  uniqueInstanceIdentifier = uniqueId("URLRedirectEditForm");

  async handleSubmit(data, mutation) {
    const { shopId } = this.props;
    const isNew = !data._id;

    const refetchQueries = [{
      query: productSpecsQuery,
      variables: {
        shopId
      }
    }];

    const input = {
      id: data._id,
      name: data.name,
      unit: data.unit,
      isVisible: data.isVisible || false,
      shopId,
      heroMediaUrl: data.heroMediaUrl
    };

    /* needsFix
    if (!isNew) {
      // On update, refetch featured products
      refetchQueries.push({
        query: tagProductsQuery,
        variables: {
          shopId,
          tagId: data._id
        }
      });
    }
    */

    console.log("Before mutation");
    console.log("refetchQueries", refetchQueries);

    const result = await mutation({
      refetchQueries,
      variables: {
        input
      }
    });

    console.log("result", result);

    if (result.data.addProductSpec) {
      this.props.onCreate(result.data.addProductSpec.productSpec);
    } else {
      this.props.onUpdate(result.data.updateProductSpec.productSpec);
    }

    return result;
  }

  handleRemove(id, mutation) {
    /*
    Alerts.alert({
      title: t("admin.routing.form.deleteConfirm"),
      type: "warning",
      showCancelButton: true
    }, async (isConfirm) => {
      if (isConfirm) {
        const { shopId } = this.props;

        await mutation({
          variables: {
            input: {
              id,
              shopId
            }
          }
        });
        this.props.onCancel();
      }
    });
    */
  }

  reset() {
    this.formValue = null;
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  handleSubmitForm = () => {
    this.form.submit();
  }

  handleFormChange = (value) => {
    this.formValue = value;
  }

  get productSpecData() {
    const { productSpec } = this.props;

    if (productSpec) {
      const metafields = {};

      if (Array.isArray(productSpec.metafields)) {
        productSpec.metafields.forEach((field) => {
          metafields[field.key] = field.value;
        });
      }

      return {
        ...productSpec,
        ...metafields
      };
    }

    return {};
  }

  render() {
    const productSpec = this.productSpecData;
    const { t } = this.props;
    const nameInputId = `name_${this.uniqueInstanceIdentifier}`;
    const unitInputId = `unit_${this.uniqueInstanceIdentifier}`;
    const isVisibleInputId = `isVisible_${this.uniqueInstanceIdentifier}`;

    let title = t("admin.productSpecs.form.formTitleNew");
    let mutation = addProductSpecMutation;

    if (productSpec._id) {
      title = t("admin.productSpecs.form.formTitleUpdate");
      mutation = updateProductSpecMutation;
    }

    return (
      <Mutation mutation={mutation}>
        {(mutationFunc) => (
          <Fragment>
            <Mutation mutation={removeProductSpecMutation}>
              {(removeMutationFunc) => (
                <TagToolbar
                  canBeDeleted
                  onDelete={() => { this.handleRemove(productSpec._id, removeMutationFunc); }}
                  onCancel={this.handleCancel}
                  // onSave={this.handleSave}
                  onSave={this.handleSubmitForm}
                />
              )}
            </Mutation>
            <Title>{title}</Title>
            <Form
              ref={(formRef) => { this.form = formRef; }}
              onChange={this.handleFormChange}
              onSubmit={(data) => this.handleSubmit(data, mutationFunc)}
              validator={getRequiredValidator("name")}
              value={productSpec}
            >
              <ContentGroup>
                <PaddedField
                  name="name"
                  label={t("admin.productSpecs.form.name")}
                  labelFor={nameInputId}
                  isRequired
                >
                  <TextInput id={nameInputId} name="name" placeholder={t("admin.productSpecs.form.specNamePlaceholder")} />
                  <ErrorsBlock names={["name"]} />
                </PaddedField>
              </ContentGroup>

              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <PaddedField
                        helpText={t("admin.productSpecs.form.unitHelpText")}
                        name="unit"
                        label={t("admin.productSpecs.form.unit")}
                        labelFor={unitInputId}
                        isRequired
                      >
                        <TextInput id={unitInputId} name="unit" placeholder={t("admin.productSpecs.form.unitPlaceholder")} />
                        <ErrorsBlock names={["unit"]} />
                      </PaddedField>

                      <PaddedField
                        name="isVisible"
                        labelFor={isVisibleInputId}
                      >
                        <Checkbox
                          id={isVisibleInputId}
                          name="isVisible"
                          label={t("admin.productSpecs.form.isVisible")}
                        />
                      </PaddedField>
                    </Grid>
                  </Grid>

                  <CardActions disableSpacing>
                    <Button actionType="secondary" onClick={this.handleSubmitForm}>
                      {t("admin.tags.form.save")}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Form>
          </Fragment>
        )}
      </Mutation>
    );
  }
}

export default withTranslation()(ProductSpecForm);
