import gql from "graphql-tag";
import { makeAnOfferEntryFragment } from "../fragments/makeAnOfferEntry";

export default gql`
mutation acceptOffer($input: UpdateOfferInput!) {
  acceptOffer(input: $input) {
    makeAnOfferEntry {
        ...MakeAnOfferEntry
      }
    }
}
${makeAnOfferEntryFragment}
`;
