import React, { Fragment } from "react";

import { MjmlColumn, MjmlSection, MjmlText } from "../../mjml-react";

import useVKLData from "../../hooks/useVKLData";
import useSelection from "../../hooks/useSelection";

import { B4BTemplate } from "../../context/SelectionContext";

function NewsletterTypeGretting() {
  const { endDate, endDateWeekday, endTime } = useVKLData();
  const { template } = useSelection();

  if (template === B4BTemplate.SaleOverview) {
    return (
      <MjmlText
        align="center"
        paddingTop="10px"
        paddingBottom="5px"
        fontWeight="400"
        fontSize="13px"
        padding="20px"
        color="#00334C"
      >
        die Auktionen von gebrauchten EDV-Geräten aus dem Hause Daimler
        bei&nbsp;
        <a
          href="https://remarket-now.com"
          style={{ color: "#00b8f1", textDecoration: "underline" }}
          target="_blank"
        >
          REMARKET-NOW
        </a>
        &nbsp;sind soeben beendet, dies sind die Ergebnisse:
      </MjmlText>
    );
  }

  if (template === B4BTemplate.Invitation) {
    return (
      <MjmlText
        align="center"
        paddingTop="10px"
        paddingBottom="5px"
        fontWeight="400"
        fontSize="13px"
        padding="20px"
        color="#00334C"
      >
        wir laden Sie ein zur Auktion von gebrauchten EDV-Geräten aus dem Hause
        Daimler bei&nbsp;
        <a
          href="https://remarket-now.com"
          style={{ color: "#00b8f1", textDecoration: "underline" }}
          target="_blank"
        >
          REMARKET-NOW
        </a>
        .
      </MjmlText>
    );
  }

  return (
    <Fragment>
      <MjmlText
        align="left"
        paddingTop="10px"
        paddingBottom="5px"
        fontWeight="400"
        fontSize="13px"
        padding="20px"
        color="#00344c"
      >
        die Auktionen von gebrauchten EDV-Geräten aus dem Hause Daimler
        bei&nbsp;
        <a
          href="https://remarket-now.com"
          style={{ color: "#00b8f1", textDecoration: "underline" }}
          target="_blank"
        >
          REMARKET-NOW
        </a>
      </MjmlText>
      {template === B4BTemplate.Reminder1 && (
        <MjmlText
          align="left"
          fontWeight="500"
          paddingTop="0px"
          paddingBottom="5px"
          fontSize="13px"
          padding="20px"
          color="#00344c"
        >
          laufen gerade und enden am {endDateWeekday}, den {endDate} um{" "}
          {endTime} Uhr.
        </MjmlText>
      )}
      {template === B4BTemplate.Reminder2 && (
        <MjmlText
          align="left"
          fontWeight="500"
          paddingTop="0px"
          paddingBottom="5px"
          fontSize="13px"
          padding="20px"
          color="#00344c"
        >
          laufen gerade und enden&nbsp;
          <span style={{ color: "#ff0000" }}>heute</span>, am&nbsp;
          {endDateWeekday}, den {endDate} um&nbsp;
          <span style={{ color: "#ff0000" }}>{endTime} Uhr</span>.
        </MjmlText>
      )}
      {template === B4BTemplate.Reminder3 && (
        <MjmlText
          align="left"
          fontWeight="500"
          paddingTop="0px"
          paddingBottom="5px"
          fontSize="13px"
          padding="20px"
          color="#00344c"
        >
          laufen gerade und enden&nbsp;
          <span style={{ color: "#ff0000" }}>in einer Stunde!</span>
        </MjmlText>
      )}
    </Fragment>
  );
}

export default function Intro() {
  return (
    <Fragment>
      <MjmlSection padding="20px 10px 0 10px" backgroundColor="#FFFFFF">
        <MjmlColumn>
          <MjmlText
            align="center"
            padding="20px 20px 10px 20px"
            fontSize="25px"
            fontWeight="bold"
            lineHeight="40px"
            color="#00344c"
          >
            Sehr geehrte Damen und Herren,
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection padding="10px 10px 0px 10px" backgroundColor="#FFFFFF">
        <MjmlColumn>
          <NewsletterTypeGretting />
        </MjmlColumn>
      </MjmlSection>
    </Fragment>
  );
}
