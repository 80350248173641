import gql from "graphql-tag";

export default gql`
query productsCustomQuery($shopIds: [ID], $filters: ProductFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ProductsSortBy]) {
  productsCustom(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
    nodes {
      _id
      productType
      type
      description
      salesDescription
      productDescription
      localization
      productType
      createdAt
      isVisible
      metafields {
        key
        value
      }
      productNumber
      pageTitle
      priceRange {
        range
        min
        max
      }
      listPrice
      title
      primaryImage {
      	URLs {
          thumbnail
        }
      }
      updatedAt
      publishedAt
      publishedProductHash
      currentProductHash
      limesId
      consumptionValues {
        name
        description
        type
        stringValue
        intValue
        floatValue
      }
      vendor
      cdsType
    }
    totalCount
    __typename
  }
}
`;
