import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

/**
 * @name DateCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function DateCell({ value }) {
  // Determine what date or time to display.
  const now = moment();
  const orderCreatedAt = moment(value);
  const duration = moment.duration(now.diff(orderCreatedAt));
  const durationHours = duration.asHours();

  let dateTimeFormat = "DD.MM [um] HH:mm";
  // Show year for orders placed outside the current year.
  if (orderCreatedAt.year() !== now.year()) {
    dateTimeFormat = "D.M.YYYY [um] HH:mm";
  }

  // Render order date by default
  let dateOrTime = moment(orderCreatedAt).format(dateTimeFormat);
  if (durationHours < 1) {
    dateOrTime = `Vor ${Math.round(duration.asMinutes())} Minuten`;
  }

  if (durationHours > 1 && durationHours < 8) {
    dateOrTime = `Vor ${Math.round(durationHours)} Stunden`;
  }

  return (
    <Fragment>
      {dateOrTime}
    </Fragment>
  );
}

DateCell.propTypes = {
  value: PropTypes.string
};
