import gql from "graphql-tag";

export const productSpecGroupFragment = gql`
    fragment ProductSpecGroup on ProductSpecGroup {
        _id
        name
        specifications {
            ... on Specification {
            _id
            type
            position
            }
            ... on SpecificationText {
            _id
            content
            type
            position
            }
        }
        isVisible
    }
`;
