const handlers = {
  inline: boolToString,
  "full-width": boolToString,
  width: numberToPx,
  height: numberToPx,
  "border-radius": numberToPx,
  "border-width": numberToPx,
  "background-size": numberToPx,
  padding: numberToPx,
  "padding-top": numberToPx,
  "padding-right": numberToPx,
  "padding-bottom": numberToPx,
  "padding-left": numberToPx,
  "font-size": numberToPx,
  "letter-spacing": numberToPx,
  "line-height": numberToPx,
  "icon-padding": numberToPx,
  "text-padding": numberToPx,
};

type MJMlProp = keyof typeof handlers;

type MJMLProps = {
  [key: string]: any;
};

export function handleMjmlProps(props: MJMLProps) {
  return Object.keys(props).reduce((acc, curr) => {
    const mjmlProp = kebabCase(curr);

    return {
      ...acc,
      [mjmlProp]: handleMjmlProp(mjmlProp as MJMlProp, props[curr]),
    };
  }, {});
}

function handleMjmlProp(name: MJMlProp, value: any) {
  if (typeof value === "undefined" || value === null) {
    return undefined;
  }
  const handler =
    handlers[name as MJMlProp] || ((name: string, value: any) => value);
  return handler(name, value);
}

function kebabCase(string: string) {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

function boolToString(name: MJMlProp, value: any) {
  return value ? name : undefined;
}

function numberToPx(name: MJMlProp, value: any) {
  if (typeof value === "number") {
    return `${value}px`;
  }
  return value;
}
