import gql from "graphql-tag";

export default gql`
  query product($productId: ID!, $shopId: ID!) {
    product(productId: $productId, shopId: $shopId) {
      _id
      productNumber
      originalVendor
      productDescription
      salesDescription
      limesPurchaseDiscount
      limesSalesDiscount
      priceGroup
      productLine
      pricingProductLine
      currentProductHash
      description
      isDeleted
      isVisible
      media {
        _id
        URLs {
          small
          medium
          large
          original
          thumbnail
        }
      }
      metaDescription
      metafields {
        key
        value
      }
      originCountry
      pageTitle
      productType
      publishedAt
      publishedProductHash
      shop {
        _id
      }
      slug
      socialMetadata {
        message
        service
      }
      supportedFulfillmentTypes
      tagIds
      tags {
        nodes {
          _id
          name
        }
      }
      title
      updatedAt
      vendor
      blockReservation
      publicPricing
      variants {
        _id
        serialNumber
        cdsType

        listPrice
        inventoryValue
        listPurchasePrice
        purchasePrice
        purchasePriceReal

        localization
        consumptionValues {
          name
          description
          type
          stringValue
          intValue
          floatValue
        }
        invoiceNumber
        limesCreatedAt
        missingPartsStatus
        opticalStatusDescription
        processStatus
        sellStatus
        showOnPricelist
        status
        storage
        storagePermission
        technicalStatus
        technicalStatusDescription
        warehouseLocation
        productDescription
        salesDescription
        storageBin
        isMakeAnOffer

        attributeLabel
        barcode
        compareAtPrice
        height
        index
        isDeleted
        isVisible
        length
        media {
          _id
          URLs {
            small
            medium
            large
            original
            thumbnail
          }
        }
        metafields {
          key
          value
        }
        minOrderQuantity
        optionTitle
        originCountry
        price
        shop {
          _id
        }
        sku
        title
        updatedAt
        weight
        width
        isTaxable
        taxCode
        options {
          _id
          attributeLabel
          barcode
          compareAtPrice
          height
          index
          isDeleted
          isVisible
          length
          media {
            _id
            URLs {
              small
              medium
              large
              original
              thumbnail
            }
          }
          metafields {
            key
            value
          }
          minOrderQuantity
          optionTitle
          originCountry
          price
          shop {
            _id
          }
          sku
          title
          updatedAt
          weight
          width
          isTaxable
          taxCode
        }
      }
    }
  }
`;
