import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function arrayMove(arr, old_index, new_index) {
  const array = arr.slice();
  while (old_index < 0) {
    old_index += array.length;
  }
  while (new_index < 0) {
    new_index += array.length;
  }
  if (new_index >= array.length) {
    let k = new_index - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(new_index, 0, array.splice(old_index, 1)[0]);

  const sortedArray = array.map((element, index) => {
    const newElement = { ...element, position: index };
    return newElement;
  });

  return sortedArray;
}

export default function withProductClassEdit(Comp) {
  function WithProductClassEdit(props) {
    const { productClass: productClassFromQuery } = props;
    const [productClass, setProductClass] = useState(productClassFromQuery);
    const { relatedSpecifications = [], relatedSpecificationsPreview = [] } = productClass || {};

    useEffect(() => {
      setProductClass(productClassFromQuery);
    }, [productClassFromQuery]);

    const onRelatedSpecificationsSortEnd = ({ oldIndex, newIndex }) => {
      setProductClass({
        ...productClass,
        relatedSpecifications: arrayMove(relatedSpecifications, oldIndex, newIndex)
      });
    };

    const onRelatedSpecificationsPreviewSortEnd = ({ oldIndex, newIndex }) => {
      setProductClass({
        ...productClass,
        relatedSpecificationsPreview: arrayMove(relatedSpecificationsPreview, oldIndex, newIndex)
      });
    };

    const handleAddSpecification = (_id) => {
      setProductClass({
        ...productClass,
        relatedSpecifications: [
          ...relatedSpecifications,
          {
            _id,
            position: relatedSpecifications.length,
            type: "specification"
          }
        ]
      });
    };

    const handleAddSpecificationGroup = (_id) => {
      setProductClass({
        ...productClass,
        relatedSpecifications: [
          ...relatedSpecifications,
          {
            _id,
            position: relatedSpecifications.length,
            type: "specificationGroup"
          }
        ]
      });
    };

    const handleAddSpecificationPreview = (_id) => {
      setProductClass({
        ...productClass,
        relatedSpecificationsPreview: [
          ...relatedSpecificationsPreview,
          {
            _id,
            position: relatedSpecificationsPreview.length,
            type: "specification"
          }
        ]
      });
    };

    const handleAddSpecificationGroupPreview = (_id) => {
      setProductClass({
        ...productClass,
        relatedSpecificationsPreview: [
          ...relatedSpecificationsPreview,
          {
            _id,
            position: relatedSpecificationsPreview.length,
            type: "specificationGroup"
          }
        ]
      });
    };

    const handleDeleteSpecification = (position, _id) => {
      setProductClass({
        ...productClass,
        relatedSpecifications: productClass.relatedSpecifications.filter((specification) => !(specification.position === position && specification._id === _id))
      });
    };

    const handleDeleteSpecificationPreview = (position, _id) => {
      setProductClass({
        ...productClass,
        relatedSpecificationsPreview: productClass.relatedSpecificationsPreview.filter((specification) => !(specification.position === position && specification._id === _id))
      });
    };

    return (
      <Comp
        {...props}
        productClass={productClass}
        onRelatedSpecificationsSortEnd={onRelatedSpecificationsSortEnd}
        onRelatedSpecificationsPreviewSortEnd={onRelatedSpecificationsPreviewSortEnd}
        handleAddSpecification={handleAddSpecification}
        handleAddSpecificationGroup={handleAddSpecificationGroup}
        handleAddSpecificationPreview={handleAddSpecificationPreview}
        handleAddSpecificationGroupPreview={handleAddSpecificationGroupPreview}
        handleDeleteSpecification={handleDeleteSpecification}
        handleDeleteSpecificationPreview={handleDeleteSpecificationPreview}
      />
    );
  }

  WithProductClassEdit.propTypes = {
    product: PropTypes.object
  };

  return WithProductClassEdit;
}
