import gql from "graphql-tag";
import { productSpecFragment } from "../fragments/productSpec";

export default gql`
  mutation removeProductSpecMutation($input: RemoveProductSpecInput!) {
    removeProductSpec(input: $input) {
      productSpec {
        ...ProductSpec
      }
    }
  }
  ${productSpecFragment}
`;
