import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
    chip: {
        marginLeft: theme.spacing(2)
    },
    avatar: {
        marginRight: theme.spacing()
    },
    avatarContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    link: {
        color: theme.palette.primary.main,
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));

const stringToHslColor = (str) => {
    const saturation = 70;
    const lightness = 50;
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return `hsl(${h}, ${saturation}%, ${lightness}%)`;
};

/**
 * @name AvatarCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
function AvatarCell({ account, showLink = true }) {
    const classes = useStyles();

    const { name, firstName, lastName, primaryEmailAddress, invoiceAddress = {} } = account;

    const lastNameSplitted = name && name.split(" ").slice(-1).join(" ");

    const viewerFirstNameForAvatar = invoiceAddress && invoiceAddress.firstName || firstName || name || primaryEmailAddress;
    const viewerFirstName = invoiceAddress && invoiceAddress.firstName || firstName || name;
    const viewerLastName = invoiceAddress && invoiceAddress.lastName || lastName || lastNameSplitted || "";

    const firstInitial = viewerFirstNameForAvatar.charAt().toUpperCase();
    const lastInitial = viewerLastName.charAt().toUpperCase();

    const viewerFullName = `${viewerFirstName || " - "}${(viewerLastName) ? ` ${viewerLastName}` : ""}`;
    const viewerInitials = `${firstInitial}${lastInitial}`;

    const urlParams = useMemo(() => queryString.stringify({ searchTerm: primaryEmailAddress }), [primaryEmailAddress]);

    if (!showLink) {
        return (
            <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar} style={{ backgroundColor: stringToHslColor(viewerFirstName && viewerFullName || primaryEmailAddress) }}>{viewerInitials}</Avatar>
                {viewerFullName}
            </div>
        );
    }

    return (
        <Link to={`/account-manager?${urlParams}`} className={classes.link}>
            <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar} style={{ backgroundColor: stringToHslColor(viewerFirstName && viewerFullName || primaryEmailAddress) }}>{viewerInitials}</Avatar>
                {viewerFullName}
            </div>
        </Link>
    );
}

AvatarCell.propTypes = {
    account: PropTypes.object.isRequired
};

export default AvatarCell;
