import gql from "graphql-tag";
import { productClassFragment } from "../fragments/productClass";

export default gql`
  query productClassQuery($productClassId: ID!) {
    productClass(_id: $productClassId, shouldIncludeInvisible: true) {
      ...ProductClass
    }
  }
  ${productClassFragment}
`;
