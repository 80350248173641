import React, { Suspense, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Link } from "react-router-dom";
import { AuthenticationProvider, oidcLog } from "@axa-fr/react-oidc-context";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ApolloProvider } from "react-apollo";
import { ComponentsProvider } from "@reactioncommerce/components-context";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";
import initApollo from "./graphql/helpers/initApollo";
import muiTheme from "./theme/customMuiTheme";
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from "notistack";
import appComponents from "./appComponents";
import theme from "./theme";
import App from "./layouts/App";
import RouterContext from "./context/RouterContext";
import snackbarPosition from "./utils/getSnackbarPosition";
import "./routes";
import "./appComponents";
import "./registeredPlugins";
import "./styles.css";
import "./hco_fonts.css";
import "./setUpFileRecords";
import * as serviceWorker from './serviceWorker';
import config from "./config";
import { loadRegisteredBlocks, loadRegisteredComponents } from "./reaction-components";

loadRegisteredBlocks();
loadRegisteredComponents();

const { REACT_APP_OAUTH2_CLIENT_ID, REACT_APP_OAUTH2_PUBLIC_URL, REACT_APP_ROOT_URL } = config;

const makeAbsolute = (relativeUrl) => {
    const url = new URL(relativeUrl, REACT_APP_ROOT_URL); /*// eslint-disable-line node/no-unsupported-features/node-builtins*/
    return url.href;
};

function NotAuthenticated() {
    return (
      <Fragment>
        <Redirect to="/" />
        <Typography>Not Authenticated, redirecting...</Typography>
        <Typography>Click <Link to="/">here</Link> if you are not automatically redirected.</Typography>
      </Fragment>
    )
}

// https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#application-startup-indexjs
/* eslint-disable camelcase */
const oidcConfiguration = {
    client_id: REACT_APP_OAUTH2_CLIENT_ID,
    redirect_uri: makeAbsolute("/authentication/callback"),
    response_type: "code",
    post_logout_redirect_uri: REACT_APP_ROOT_URL,
    scope: "openid",
    authority: REACT_APP_OAUTH2_PUBLIC_URL,
    silent_redirect_uri: makeAbsolute("/authentication/silent_callback"),
    automaticSilentRenew: true,
    loadUserInfo: true,
    triggerAuthFlow: true
  };
  /* eslint-enable camelcase */
  
  const oidcProps = {
    configuration: oidcConfiguration,
    // NONE, ERROR, WARN, INFO, DEBUG
    // Change to DEBUG temporarily if you're debugging an issue with login/logout/auth
    loggerLevel: oidcLog.NONE,
    // These are components for which the @axa-fr/react-oidc-context package shows
    // default text if we don't override these. We don't really need them since in
    // our situation they're only shown for a second.
    authenticating: () => null,
    callbackComponentOverride: () => null,
    notAuthenticated: () => <NotAuthenticated />,
    notAuthorized: () => null,
    sessionLostComponent: () => null
};

const apolloClient = initApollo();  

ReactDOM.render(<ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AuthenticationProvider {...oidcProps}>
        <ComponentsProvider value={appComponents}>
            <ThemeProvider theme={theme}>
                <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <SnackbarProvider anchorOrigin={snackbarPosition} maxSnack={3}>
                    <Suspense fallback={<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><CircularProgress /></div>}>
                        <I18nextProvider i18n={i18n}>  
                            <Route>
                                {(routeProps) => (
                                <RouterContext.Provider value={routeProps}>
                                    <App />
                                </RouterContext.Provider>
                                )}
                            </Route>
                        </I18nextProvider>
                    </Suspense>
                </SnackbarProvider>
                </MuiThemeProvider>
            </ThemeProvider>
        </ComponentsProvider>
      </AuthenticationProvider>
    </BrowserRouter>
</ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
