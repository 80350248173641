import React, { useMemo, useEffect, useCallback, useState } from "react";
import {
    Grid,
    Box,
    Checkbox,
    Card,
    CardHeader,
    CardContent,
    Button,
    IconButton,
    makeStyles,
    Menu,
    MenuItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AccountEdit from "mdi-material-ui/AccountEdit";


function AccountEditMenu({ row, history }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const { t } = useTranslation("ns1");


    const openEditor = (mode) => {
        history.push(`/account-manager/` + mode + `/${row.values._id}`);
    }

    const handleClick = (event) => {
        setOpenMenu(true);
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (event) => {
        event.stopPropagation();
        setOpenMenu(false);
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                id={"editButton"}
                onClick={handleClick}
                aria-controls={openMenu ? 'editMenu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup={"true"}
            >
                <AccountEdit/>
            </Button>
            <Menu
                id={"editMenu"}
                open={openMenu}
                onClick={handleClick}
                onClose={handleClose}
                anchorEl={anchorEl}
                MenuListProps={{
                    'aria-labelledby': 'editButton',
                }}
            >
                <MenuItem onClick={(event) => {
                    handleClose(event);
                    openEditor('invoice');
                }}>Edit Invoice Address/E-mail Address</MenuItem>
                <MenuItem onClick={(event) => {
                    handleClose(event);
                    openEditor('shipping');
                }}>Edit Shipping Address</MenuItem>
            </Menu>
        </div>
    );
}

export default withRouter(AccountEditMenu);
