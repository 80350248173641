import React from "react";
import { registerBlock } from "../../../../reaction-components";

// import withProductLines from "../hocs/withProductLines";
// import withProductClasses from "../hocs/withProductClasses";

// import ProductSpecForm from "./ProductSpecForm";
// import ProductPricingForm from "./ProductPricingForm";
// import ProductDetailForm from "./ProductDetailForm";
import ProductHeader from "./ProductHeader";
import ProductDetailForm from "./ProductDetailForm";

import VariantHeader from "./VariantHeader";
import VariantDetailForm from "./VariantDetailForm";
import VariantList from "./VariantList";
import VariantPricesForm from "./VariantPricesForm";


// ProductDetail Header without duplicate / archive
// ProductDetail Block: Header
registerBlock({
  region: "ProductDetailHeader",
  name: "ProductHeader",
  // eslint-disable-next-line react/display-name
  component: (props) => <ProductHeader shouldDisplayStatus={true} {...props} />,
  priority: 10
});

// ProductDetail Block Region: Main
registerBlock({
  region: "ProductDetailMain",
  name: "ProductHeader",
  component: ProductHeader,
  priority: 10
});

registerBlock({
  region: "ProductDetailMain",
  name: "ProductDetailForm",
  component: ProductDetailForm,
  priority: 20
});


// VariantDetail: Main Region
registerBlock({
  region: "VariantDetailMain",
  name: "VariantHeader",
  component: VariantHeader,
  priority: 10
});

registerBlock({
  region: "VariantDetailMain",
  name: "VariantDetailForm",
  component: VariantDetailForm,
  priority: 20
});

registerBlock({
  region: "VariantDetailMain",
  name: "VariantPricesForm",
  component: VariantPricesForm,
  priority: 30
});

/*
registerBlock({
  region: "ProductDetailMain",
  name: "ProductSpecForm",
  component: ProductSpecForm,
  hocs: [
    withProductForm,
    withOpaqueShopId
  ],
  priority: 40
});

registerBlock({
  region: "ProductDetailMain",
  name: "ProductPricingForm",
  component: ProductPricingForm,
  hocs: [
    withProductForm,
    withOpaqueShopId,
    withProductLines
  ],
  priority: 50
});
*/

registerBlock({
  region: "ProductDetailSidebar",
  name: "VariantList",
  component: VariantList,
  priority: 10
});

registerBlock({
  region: "VariantDetailSidebar",
  name: "VariantList",
  component: VariantList,
  priority: 10
});

// Empty Components to remove blocks

// Product
registerBlock({
  region: "ProductDetailMain",
  name: "ProductMetadataForm",
  component: () => null,
  priority: 50
});

registerBlock({
  region: "ProductDetailMain",
  name: "ProductSocialForm",
  component: () => null,
  priority: 30
});

// Variant
registerBlock({
  region: "VariantDetailMain",
  name: "VariantInventoryForm",
  component: () => null,
  priority: 30
});

registerBlock({
  region: "VariantDetailMain",
  name: "VariantTaxForm",
  component: () => null,
  priority: 30
});

registerBlock({
  region: "VariantDetailMain",
  name: "OptionTable",
  component: () => null,
  priority: 40
});