import React, { Fragment } from "react";
import useVKLData from "../../hooks/useVKLData";
import useSelection from "../../hooks/useSelection";

import {
  Mjml,
  MjmlAccordion,
  MjmlAccordionElement,
  MjmlAccordionText,
  MjmlAccordionTitle,
  MjmlAll,
  MjmlAttributes,
  MjmlBody,
  MjmlColumn,
  MjmlDivider,
  MjmlFont,
  MjmlHead,
  MjmlSection,
  MjmlStyle,
  MjmlTable,
  MjmlText,
  MjmlTitle,
} from "../../mjml-react";

export default function LosPage() {
  const title = "Los";

  const { vkl, lose, zubehoer } = useVKLData();

  const {
    selectedLot: { index, isAccessory },
  } = useSelection();

  const selectedLot = isAccessory ? zubehoer?.[index] : lose?.[index];

  const hasPictures = selectedLot?.products?.some(
    (product) => product.imageUrls?.length > 0
  );

  const {
    vendor,
    number,
    amount,
    manufactor,
    notes,
    countedAsPalettes,
    sumCalculated,
    customCalculation,
    products = [],
    hasAttachment,
    type,
    type_short,
    manufactor_short,
    palette_amount,
  } = selectedLot || {};

  return (
    <Mjml>
      <MjmlHead>
        <MjmlTitle>{title}</MjmlTitle>
        <MjmlFont
          name="Open Sans"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400"
        ></MjmlFont>
        <MjmlAttributes>
          <MjmlAll fontFamily="Open Sans, Arial, sans-serif"></MjmlAll>
          <MjmlText
            fontWeight="400"
            fontSize="16px"
            color="#000000"
            lineHeight="24px"
          ></MjmlText>
          <MjmlSection padding="0px"></MjmlSection>
          <MjmlAccordion border="1px solid #ecedee" />
          <MjmlAccordionElement
            iconWrappedUrl="https://mall.remarket-now.com/backoffice2/api/cms/cds/arrow-down-solid.png"
            iconUnwrappedUrl="https://mall.remarket-now.com/backoffice2/api/cms/cds/arrow-up-solid.png"
            iconHeight="20px"
            iconWidth="20px"
          />
          <MjmlAccordionText
            backgroundColor="#fafafa"
            padding="15px"
            color="#505050"
          />
        </MjmlAttributes>
        <MjmlStyle>
          {`table {
            margin-bottom: 0 !important;
          }`}
        </MjmlStyle>
      </MjmlHead>
      <MjmlBody backgroundColor="#FFFFFF">
        <MjmlSection paddingTop="20px" backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlText fontWeight="250" fontSize="20px" color="#004081">
              {vendor} Los {number} VKL{vkl} - {amount} {manufactor} {type}
            </MjmlText>
            <MjmlText fontWeight="250" fontSize="20px" color="#004081">
              Gebrauchte EDV Geräte
            </MjmlText>
          </MjmlColumn>
        </MjmlSection>
        <MjmlSection backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlText
              align="left"
              paddingTop="10px"
              paddingBottom="5px"
              fontWeight="500"
              fontSize="13px"
              padding="25px"
            >
              Das Los besteht aus:
            </MjmlText>
            {notes && (
              <MjmlText
                align="left"
                paddingTop="10px"
                paddingBottom="5px"
                fontSize="13px"
                fontWeight="bold"
                padding="25px"
              >
                {notes}
              </MjmlText>
            )}
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection backgroundDolor="#FFFFFF">
          <MjmlColumn>
            <MjmlTable
              padding="20px"
              paddingTop="10px"
              paddingBottom="30px"
              fontSize="14px"
              color="#000000"
            >
              <tr
                style={{
                  border: "1px solid #ecedee",
                  backgroundColor: "#004A7F",
                  color: "#FFFFFF",
                }}
              >
                {hasPictures && countedAsPalettes && (
                  <th style={{ padding: "10px", width: "100px" }}>Foto</th>
                )}

                <th style={{ padding: "10px", textAlign: "left" }}>
                  {countedAsPalettes ? "Paletten" : "Kategorie"}
                </th>
                <th style={{ padding: "10px", width: "80px" }}>
                  {sumCalculated ? "Stückzahl nach Berechnung" : "Stückzahl"}

                  {customCalculation && `(${customCalculation})`}
                </th>
              </tr>

              {countedAsPalettes ? (
                <Fragment>
                  {products.map((product, indexOuter) => (
                    <tr
                      key={`product-${indexOuter}`}
                      style={{ border: "1px solid #ecedee" }}
                    >
                      {hasPictures && (
                        <td
                          style={{
                            padding: "10px",
                            color: "#004a7f",
                            fontWeight: "bold",
                          }}
                        >
                          {(product?.imageUrls ?? []).map((imageUrl, index) => (
                            <a
                              target="_blank"
                              href={imageUrl}
                              key={`${index}-${imageUrl}`}
                            >
                              <img src={imageUrl} width="100px" />
                            </a>
                          ))}
                        </td>
                      )}

                      <td
                        style={{
                          padding: "10px",
                          color: "#004A7F",
                          fontWeight: "bold",
                        }}
                      >
                        {product.name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        {product.amount}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {products.map((product, indexOuter) => (
                    <Fragment key={`product-${indexOuter}`}>
                      <tr style={{ border: "1px solid #ecedee" }}>
                        <td
                          style={{
                            padding: "10px",
                            color: "#004A7F",
                            fontWeight: "bold",
                          }}
                        >
                          {product.type}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            color: "#000000",
                            textAlign: "center",
                          }}
                        ></td>
                      </tr>

                      {product?.vendors?.map((vendor, indexInner) => (
                        <Fragment key={`vendor-${indexInner}`}>
                          <tr style={{ border: "1px solid #ecedee" }}>
                            <td
                              style={{
                                padding: "10px",
                                paddingLeft: "35px",
                                color: "#004A7F",
                                fontWeight: "bold",
                              }}
                            >
                              {vendor.name}
                            </td>
                            <td
                              style={{ padding: "10px", textAlign: "center" }}
                            ></td>
                          </tr>
                          {vendor?.categories?.map(
                            (category, categoryIndex) => (
                              <tr
                                key={`category-${categoryIndex}`}
                                style={{ border: "1px solid #ecedee" }}
                              >
                                <td
                                  style={{
                                    padding: "10px",
                                    paddingLeft: "75px",
                                    color: "#004A7F",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {category.name}
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    color: "#000000",
                                    textAlign: "center",
                                  }}
                                >
                                  {category.amount}
                                </td>
                              </tr>
                            )
                          )}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              )}

              <tr
                style={{
                  border: "1px solid #ecedee",
                  backgroundColor: "#ecedee",
                  color: "#004A7F",
                }}
              >
                {hasPictures && countedAsPalettes && (
                  <td style={{ padding: "10px", fontWeight: "bold" }}></td>
                )}
                <td
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {sumCalculated ? "Summe nach Berechnung" : "Summe"}
                </td>
                <td
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {amount}
                </td>
              </tr>
            </MjmlTable>
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlText
              align="left"
              paddingTop="0px"
              fontWeight="500"
              fontSize="13px"
              padding="20px"
              paddingBottom="0"
            >
              {sumCalculated && (
                <Fragment>
                  <strong>
                    Der Verkauf erfolgt nach Angabe des Palettengewichts.
                  </strong>
                  <br />
                  <strong>Die angegebene Stückzahl ist ein Schätzwert</strong>
                  {customCalculation && (
                    <strong>
                      {" "}
                      nach der Kalkulationsbasis {customCalculation}
                    </strong>
                  )}
                  . <br />
                </Fragment>
              )}
              Die Geräte wurden von LGI kontrolliert und in Qualitätsstufen
              eingeordnet. <br />
              Alle Daten wurden durch ein zertifiziertes Verfahren von den
              Datenträgern gelöscht. <br />
              Der Verkauf erfolgt unter Ausschluss jeglicher Gewährleistung per
              Vorauskasse. <br />
            </MjmlText>

            {hasAttachment && (
              <Fragment>
                <MjmlText
                  align="left"
                  paddingTop="0px"
                  fontWeight="500"
                  fontSize="13px"
                  padding="20px"
                  paddingBottom="0"
                >
                  Details zu den Losen und den angebotenen Produkten entnehmen
                  Sie bitte der folgenden Datei:
                </MjmlText>
                <MjmlText
                  align="center"
                  paddingTop="20px"
                  paddingBottom="10px"
                  fontWeight="bold"
                  fontSize="16px"
                  padding="20px"
                  paddingBottom="0"
                  color="#004081"
                >
                  <a
                    href={`https://mall.remarket-now.com/backoffice2/api/cms/cds/Los_${number}_${amount}_${type_short}_${manufactor_short}_VKL${vkl}.xlsx`}
                    style={{ color: "#004081", textDecoration: "none" }}
                    target="_blank"
                  >
                    Los {number} - {amount} {manufactor} {type} VKL{vkl}
                  </a>
                </MjmlText>
              </Fragment>
            )}
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection padding="10px 0" backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlDivider
              width="30px"
              borderWidth="3px"
              borderColor="#9B9B9B"
            ></MjmlDivider>
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection backgroundColor="#FFFFFF">
          <MjmlColumn>
            <MjmlAccordion padding="20px">
              <MjmlAccordionElement>
                <MjmlAccordionTitle>
                  Wie funktionieren die Auktionen?
                </MjmlAccordionTitle>
                <MjmlAccordionText>
                  <span style={{ lineHeight: "20px" }}>
                    In dieser "ersten" Auktion können Sie nur einmalig ein Gebot
                    abgeben, daher platzieren Sie hier bitte Ihr maximales
                    Angebot. <br />
                    Nach Ende der Auktion, werden die ersten 3 höchstbietenden
                    zu einer weiteren Auktion per E-Mail eingeladen.
                    <br />
                    Die "zweite" Auktion ist eine reguläre Auktion, wo Sie
                    mehrere Angebote abgeben können und sich gegenseitig
                    überbieten können. <br />
                    Die Laufzeit der Auktion ist auf 10 Minuten begrenzt und
                    startet sofort nach Ende der "ersten" Auktion. <br />
                    Werden keine weitere Gebote in der 2. Auktionsrunde
                    abgegeben, ist automatisch der höchstbietende aus der ersten
                    Runde der Gewinner der Auktion.
                  </span>
                </MjmlAccordionText>
              </MjmlAccordionElement>

              <MjmlAccordionElement>
                <MjmlAccordionTitle>
                  Wichtige Informationen zur Gebotsabgabe
                </MjmlAccordionTitle>
                <MjmlAccordionText>
                  <span style={{ lineHeight: "20px" }}>
                    Mit der Abgabe eines Gebotes erklären Sie sich bereit, im
                    Falle des Gewinns die Exceldatei mit ihren Einzelpreisen für
                    die Weiterleitung an Daimler zu übermitteln. <br />
                    <strong>
                      Verkauf / Versteigerung erfolgt ausschließlich unter
                      Ausschluss der Gewährleistung. <br />
                      <strong>
                        Bei Geräten ohne verbauten Datenspeicher garantieren wir
                        nicht, dass den Produkten entsprechende Rahmen oder
                        Halterungen beiliegen.
                      </strong>{" "}
                      <br />
                      Die original Datenblätter bzw. Gebrauchsanweisungen finden
                      Sie zum Download auf den Herstellerseiten.
                    </strong>
                  </span>
                </MjmlAccordionText>
              </MjmlAccordionElement>

              <MjmlAccordionElement>
                <MjmlAccordionTitle>Lieferbedingungen</MjmlAccordionTitle>
                <MjmlAccordionText>
                  <span style={{ lineHeight: "20px" }}>
                    Die Lieferung erfolgt ab Werk LGI Herrenberg-Gültstein auf{" "}
                    <strong>{palette_amount}</strong> Paletten.
                    <br />
                    <strong>
                      Pro gestellter Palette berechnen wir eine Gebühr von
                      6,00€. <br />
                    </strong>
                    Selbstabholung ist auf Anfrage möglich.
                  </span>
                </MjmlAccordionText>
              </MjmlAccordionElement>
            </MjmlAccordion>
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection
          paddingBottom="20px"
          paddingTop="20px"
          backgroundColor="#FFFFFF"
        >
          <MjmlColumn>
            <MjmlText
              align="left"
              paddingTop="0px"
              fontWeight="500"
              fontSize="13px"
              padding="20px"
              paddingBottom="20px"
            >
              Viel Erfolg!
            </MjmlText>
            <MjmlText
              align="left"
              color="#004081"
              paddingTop="0px"
              fontWeight="600"
              fontSize="13px"
              padding="20px"
              paddingBottom="0"
            >
              Ihr REMARKET NOW Sales-Team
            </MjmlText>
          </MjmlColumn>
        </MjmlSection>
      </MjmlBody>
    </Mjml>
  );
}
