import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CircleIcon from "mdi-material-ui/CheckboxBlankCircle";

const useStyles = makeStyles((theme) => ({
  isVisible: {
    color: theme.palette.colors.forestGreen300,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  },
  isChanged: {
    color: theme.palette.colors.yellow300,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  },
  isHidden: {
    color: theme.palette.colors.black40,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  }
}));

/**
 * @name PublishedYesNoCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function PublishedYesNoCell({ row }) {
  const classes = useStyles();

  if (!row.original.product.publishedProductHash || row.original.product.publishedProductHash === null) {
    return (
      <div style={{ display: "flex" }}>
        <CircleIcon className={classes.isHidden}/>
        <span>Nein</span>
      </div>
    );
  }

  if (row.original.product.publishedProductHash === row.original.product.currentProductHash) {
    return (
      <div style={{ display: "flex" }}>
        <CircleIcon className={classes.isVisible}/>
        <span>Ja</span>
      </div>
    );
  }

  if (row.original.product.publishedProductHash) {
    return (
      <div style={{ display: "flex" }}>
        <CircleIcon className={classes.isChanged}/>
        <span>Geändert</span>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <CircleIcon className={classes.isHidden}/>
      <span>Nein</span>
    </div>
  );
}

PublishedYesNoCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      currentProductHash: PropTypes.string,
      publishedProductHash: PropTypes.string
    })
  })
};
