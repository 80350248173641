import React, { Fragment, useRef, useMemo, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import AddIcon from "mdi-material-ui/Plus";
import DeleteIcon from "mdi-material-ui/Delete";
import DownloadIcon from "mdi-material-ui/Download";
import ChevronRightIcon from "mdi-material-ui/ChevronRight";
import ChevronDownIcon from "mdi-material-ui/ChevronDown";
import TreeItem from "@material-ui/lab/TreeItem";
import i18next from "i18next";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import { parse, format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import { saveAs } from "file-saver";

import getNewsletterUploadfile from "../utils/getNewsletterUploadfile";

import b4bLosListingQuery from "../graphql/queries/b4bLosListing";
import createB4BLosListingMutation from "../graphql/mutations/createB4BLosListing";
import updateB4BLosListingMutation from "../graphql/mutations/updateB4BLosListing";

import DetailToolbar from "./DetailToolbar";

import omitDeep from "../../shared/useDataTable-custom/omitDeep";

import { VKLProvider } from "../context/VKLContext";
import { SelectionProvider } from "../context/SelectionContext";
import { HTMLResultProvider } from "../context/HTMLResultContext";

import TemplateSelection from "../components/TemplateSelection";
import useSelection from "../hooks/useSelection";
import { B4BTemplate, TemplateType } from "../context/SelectionContext";

import useGetHTMLResult from "../hooks/useGetHTMLResult";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: 216,
    flexGrow: 1,
    maxWidth: 400,
  },
  card: {
    minWidth: 275,
    margin: 8,
  },
  numberInput: {
    width: 90,
    margin: 8,
  },
  normalInput: {
    margin: 8,
  },
  priceInput: {
    width: 100,
    margin: 8,
  },
  paddedText: {
    padding: theme.spacing(2),
  },
  drawer: {
    width: 625,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 625,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  paperRoot: {
    // flexGrow: 1,
  },
  tabSmall: {
    minWidth: 85,
  },
  previewContainer: {
    padding: theme.spacing(),
  },
  centerGridItem: {
    display: "flex",
    alignItems: "center",
  },
}));

function LosCard({
  los,
  losIndex,
  updateLos,
  vkl,
  deleteLosByIndex,
  isAccesory,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);

  let lastProductTypeRef = useRef(null);
  let lastProductVendorRef = useRef(null);
  let lastProductCategoryRef = useRef(null);

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const {
    number,
    hasAttachment,
    url,
    vendor,
    manufactor,
    manufactor_short,
    type,
    type_short,
    type_short_category,
    amount,
    products,
    soldAmount,
    minimumAmount,
    endTime,
    notes,
    sumCalculated = false,
    customCalculation = "",
    countedAsPalettes = false,
    palette_amount,
  } = los;

  useEffect(() => {
    if (type) {
      if (type === "Desktop" && type_short_category !== "AFRD")
        return updateLos({ ...los, type_short_category: "AFRD" }, losIndex);
      if (type === "Laptop" && type_short_category !== "AFRN")
        return updateLos({ ...los, type_short_category: "AFRN" }, losIndex);
      if (type === "Notebook" && type_short_category !== "AFRN")
        return updateLos({ ...los, type_short_category: "AFRN" }, losIndex);
      if (type === "Monitor" && type_short_category !== "AFRM")
        return updateLos({ ...los, type_short_category: "AFRM" }, losIndex);
      if (type === "Workstation" && type_short_category !== "AFRD")
        return updateLos({ ...los, type_short_category: "AFRD" }, losIndex);
      if (type === "MIX" && type_short_category !== "AFRZ")
        return updateLos({ ...los, type_short_category: "AFRZ" }, losIndex);
    }
  }, [type, type_short_category]);

  const addProductType = () => {
    updateLos(
      {
        ...los,
        products: [
          ...products,
          {
            type: "",
            vendors: [],
          },
        ],
      },
      losIndex
    );
    setTimeout(() => {
      lastProductTypeRef.current.focus();
    }, 100);
  };

  const addProductAccesoryType = () => {
    updateLos(
      {
        ...los,
        products: [
          ...products,
          {
            name: "",
            amount: 0,
          },
        ],
      },
      losIndex
    );
    setTimeout(() => {
      lastProductTypeRef.current.focus();
    }, 100);
  };

  const deleteLos = () => {
    deleteLosByIndex(losIndex);
  };

  const onChangeProductAccesoryName = (productTypeIndex, name) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            name,
          },
        }),
      },
      losIndex
    );
  };

  const onChangeProductAccesoryAmount = (productTypeIndex, amount) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            amount,
          },
        }),
      },
      losIndex
    );
  };

  const onChangeProductTypeName = (productTypeIndex, type) => {
    console.log("productTypeIndex", productTypeIndex);
    console.log("type", type);
    console.log("los", los);

    const newLos = {
      ...los,
      products: Object.assign([], products, {
        [productTypeIndex]: {
          ...products[productTypeIndex],
          type,
        },
      }),
    };

    console.log("newLos", newLos);

    updateLos(newLos, losIndex);
    /*
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            type
          }
        })
      },
      losIndex
    );
      */
  };

  const onRemoveProductTypeName = (productTypeIndex) => {
    updateLos(
      {
        ...los,
        products: products.filter(
          (product, index) => index !== productTypeIndex
        ),
      },
      losIndex
    );
  };

  const addProductVendor = (productTypeIndex) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: [
              ...products[productTypeIndex].vendors,
              {
                name: "",
                categories: [],
              },
            ],
          },
        }),
      },
      losIndex
    );
    setTimeout(() => {
      lastProductVendorRef.current.focus();
    }, 100);
  };

  const onChangeProductVendor = (productTypeIndex, vendorIndex, name) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: Object.assign([], products[productTypeIndex].vendors, {
              [vendorIndex]: {
                ...products[productTypeIndex].vendors[vendorIndex],
                name,
              },
            }),
          },
        }),
      },
      losIndex
    );
  };

  const onRemoveProductVendor = (productTypeIndex, vendorIndex) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: products[productTypeIndex].vendors.filter(
              (vendor, index) => index !== vendorIndex
            ),
          },
        }),
      },
      losIndex
    );
  };

  const addProductCategory = (productTypeIndex, vendorIndex) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: Object.assign([], products[productTypeIndex].vendors, {
              [vendorIndex]: {
                ...products[productTypeIndex].vendors[vendorIndex],
                categories: [
                  ...products[productTypeIndex].vendors[vendorIndex].categories,
                  {
                    name: "",
                    amount: 0,
                  },
                ],
              },
            }),
          },
        }),
      },
      losIndex
    );
    setTimeout(() => {
      lastProductCategoryRef.current.focus();
    }, 100);
  };

  const onChangeProductCategoryName = (
    productTypeIndex,
    vendorIndex,
    categoryIndex,
    name
  ) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: Object.assign([], products[productTypeIndex].vendors, {
              [vendorIndex]: {
                ...products[productTypeIndex].vendors[vendorIndex],
                categories: Object.assign(
                  [],
                  products[productTypeIndex].vendors[vendorIndex].categories,
                  {
                    [categoryIndex]: {
                      ...products[productTypeIndex].vendors[vendorIndex]
                        .categories[categoryIndex],
                      name,
                    },
                  }
                ),
              },
            }),
          },
        }),
      },
      losIndex
    );
  };

  const onChangeProductCategoryAmount = (
    productTypeIndex,
    vendorIndex,
    categoryIndex,
    amount
  ) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: Object.assign([], products[productTypeIndex].vendors, {
              [vendorIndex]: {
                ...products[productTypeIndex].vendors[vendorIndex],
                categories: Object.assign(
                  [],
                  products[productTypeIndex].vendors[vendorIndex].categories,
                  {
                    [categoryIndex]: {
                      ...products[productTypeIndex].vendors[vendorIndex]
                        .categories[categoryIndex],
                      amount,
                    },
                  }
                ),
              },
            }),
          },
        }),
      },
      losIndex
    );
  };

  const onRemoveProductCategory = (
    productTypeIndex,
    vendorIndex,
    categoryIndex
  ) => {
    updateLos(
      {
        ...los,
        products: Object.assign([], products, {
          [productTypeIndex]: {
            ...products[productTypeIndex],
            vendors: Object.assign([], products[productTypeIndex].vendors, {
              [vendorIndex]: {
                ...products[productTypeIndex].vendors[vendorIndex],
                categories: products[productTypeIndex].vendors[
                  vendorIndex
                ].categories.filter(
                  (category, index) => index !== categoryIndex
                ),
              },
            }),
          },
        }),
      },
      losIndex
    );
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item>
            <TextField
              className={classes.numberInput}
              id="number"
              type="number"
              label="Losnummer"
              variant="outlined"
              placeholder="1"
              InputLabelProps={{ shrink: true }}
              value={number}
              onChange={(event) => {
                updateLos(
                  { ...los, number: Number(event.target.value) },
                  losIndex
                );
              }}
            />
            <TextField
              className={classes.normalInput}
              id="vendor"
              label="Herkunft"
              variant="outlined"
              placeholder="Daimler"
              InputLabelProps={{ shrink: true }}
              value={vendor}
              onChange={(event) => {
                updateLos({ ...los, vendor: event.target.value }, losIndex);
              }}
            />
            <TextField
              className={classes.numberInput}
              id="amount"
              label="Anzahl"
              type="number"
              variant="outlined"
              placeholder="0"
              InputLabelProps={{ shrink: true }}
              value={amount}
              onChange={(event) => {
                updateLos({ ...los, amount: event.target.value }, losIndex);
              }}
            />
            <TextField
              className={classes.numberInput}
              id="palette_amount"
              label="Anzahl Paletten"
              type="number"
              variant="outlined"
              placeholder="0"
              InputLabelProps={{ shrink: true }}
              value={palette_amount}
              onChange={(event) => {
                updateLos(
                  { ...los, palette_amount: Number(event.target.value) },
                  losIndex
                );
              }}
            />
            <TextField
              className={classes.normalInput}
              id="manufactor"
              label="Hersteller"
              variant="outlined"
              placeholder="HP"
              InputLabelProps={{ shrink: true }}
              value={manufactor}
              onChange={(event) => {
                updateLos({ ...los, manufactor: event.target.value }, losIndex);
              }}
            />
            <TextField
              className={classes.numberInput}
              id="manufactor_short"
              label="Hersteller Kürzel"
              variant="outlined"
              placeholder="HP"
              InputLabelProps={{ shrink: true }}
              value={manufactor_short}
              onChange={(event) => {
                updateLos(
                  { ...los, manufactor_short: event.target.value },
                  losIndex
                );
              }}
            />
            <TextField
              className={classes.normalInput}
              id="type"
              label="Typ"
              variant="outlined"
              placeholder="Desktop"
              InputLabelProps={{ shrink: true }}
              value={type}
              onChange={(event) => {
                updateLos({ ...los, type: event.target.value }, losIndex);
              }}
            />
            <TextField
              className={classes.numberInput}
              id="type_short"
              label="Typ Kürzel"
              variant="outlined"
              placeholder="DES"
              InputLabelProps={{ shrink: true }}
              value={type_short}
              onChange={(event) => {
                updateLos({ ...los, type_short: event.target.value }, losIndex);
              }}
            />
            <TextField
              className={classes.normalInput}
              disabled={isAccesory}
              id="type_short_category"
              label="B4B-Mall Kategorie"
              variant="outlined"
              placeholder="z.b. AFRN"
              InputLabelProps={{ shrink: true }}
              value={isAccesory ? "AFRZ" : type_short_category}
              onChange={(event) => {
                updateLos(
                  { ...los, type_short_category: event.target.value },
                  losIndex
                );
              }}
            />
            <TextField
              className={classes.priceInput}
              id="minimumAmount"
              label="Mindestpreis"
              variant="outlined"
              placeholder="10.000"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              value={minimumAmount}
              onChange={(event) => {
                updateLos(
                  { ...los, minimumAmount: event.target.value },
                  losIndex
                );
              }}
            />
            <TextField
              className={classes.priceInput}
              id="soldAmount"
              label="Verkaufspreis"
              variant="outlined"
              placeholder="10.000"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              value={soldAmount}
              onChange={(event) => {
                updateLos({ ...los, soldAmount: event.target.value }, losIndex);
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <TimePicker
                className={classes.normalInput}
                showTodayButton
                ampm={false}
                todayLabel="now"
                value={
                  endTime
                    ? parse(endTime, "HH:mm", new Date(), {
                        locale: deLocale,
                      })
                    : new Date()
                }
                minutesStep={1}
                label="Endzeit"
                inputVariant="outlined"
                onChange={(date) => {
                  const formattedDate = format(date, "HH:mm", {
                    locale: deLocale,
                  });
                  updateLos({ ...los, endTime: formattedDate }, losIndex);
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start">
          <Grid item md={3}>
            <FormControlLabel
              className={classes.normalInput}
              control={
                <Switch
                  checked={hasAttachment}
                  value="hasAttachment"
                  onChange={(event) => {
                    updateLos(
                      { ...los, hasAttachment: event.target.checked },
                      losIndex
                    );
                  }}
                />
              }
              label="Hat Dateianhang"
            />
          </Grid>
          <Grid item md={6} className={classes.centerGridItem}>
            {hasAttachment && (
              <Typography>
                Exceldatei für dieses Los heißt: Los_{number}_{amount}_
                {type_short}_{manufactor_short}_VKL{vkl}.xlsx
              </Typography>
            )}
          </Grid>
          <Grid item md={12}>
            <TextField
              className={classes.normalInput}
              id="url"
              fullWidth
              label="Los-Url"
              variant="outlined"
              placeholder="https://www.domain.com?listingId=2000"
              InputLabelProps={{ shrink: true }}
              value={url}
              onChange={(event) => {
                updateLos({ ...los, url: event.target.value }, losIndex);
              }}
            />
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              className={classes.normalInput}
              control={
                <Switch
                  checked={sumCalculated}
                  value="sumCalculated"
                  onChange={(event) => {
                    updateLos(
                      { ...los, sumCalculated: event.target.checked },
                      losIndex
                    );
                  }}
                />
              }
              label="Summe kalkuliert(Bei Zubehör)"
            />
          </Grid>
          <Grid item md={3}>
            <FormControlLabel
              className={classes.normalInput}
              control={
                <Switch
                  checked={countedAsPalettes}
                  value="countedAsPalettes"
                  onChange={(event) => {
                    updateLos(
                      { ...los, countedAsPalettes: event.target.checked },
                      losIndex
                    );
                  }}
                />
              }
              label="Stückzahl anhand Paletten (Bei Zubehör)"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              className={classes.normalInput}
              id="customCalculation"
              fullWidth
              label="Kalkulation"
              variant="outlined"
              placeholder="900g/Tastatur"
              InputLabelProps={{ shrink: true }}
              value={customCalculation}
              onChange={(event) => {
                updateLos(
                  { ...los, customCalculation: event.target.value },
                  losIndex
                );
              }}
            />
          </Grid>
          <Grid item md={12} sm={9}>
            <TextField
              className={classes.normalInput}
              id="notes"
              fullWidth
              multiline
              rows="4"
              placeholder="..."
              label="Notiz / Hinweis"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={notes}
              onChange={(event) => {
                updateLos({ ...los, notes: event.target.value }, losIndex);
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="column" align="start">
          <Grid item>
            <Typography>Produkte:</Typography>
          </Grid>
          <Grid item>
            {isAccesory ? (
              <Fragment>
                {products &&
                  products.map((product, productIndex) => {
                    const { name, amount } = product;
                    return (
                      <Grid container>
                        <Grid item sm={4}>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Name"
                            value={name || ""}
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={(event) =>
                              onChangeProductAccesoryName(
                                productIndex,
                                event.target.value
                              )
                            }
                            inputRef={lastProductCategoryRef}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Anzahl"
                            value={amount}
                            onKeyDown={(e) => e.stopPropagation()}
                            onChange={(event) =>
                              onChangeProductAccesoryAmount(
                                productIndex,
                                Number(event.target.value)
                              )
                            }
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            onClick={() => {
                              onRemoveProductTypeName(productIndex);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                <Button
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={addProductAccesoryType}
                >
                  Palette
                </Button>
              </Fragment>
            ) : (
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<ChevronDownIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleChange}
              >
                <Fragment>
                  {products &&
                    products.map((product, productIndex) => {
                      const { type, vendors } = product;
                      return (
                        <TreeItem
                          nodeId={`${losIndex}-${productIndex}`}
                          key={`${losIndex}-${productIndex}`}
                          label={
                            <Fragment>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Produkttyp"
                                value={type || ""}
                                onKeyDown={(e) => e.stopPropagation()}
                                onChange={(event) =>
                                  onChangeProductTypeName(
                                    productIndex,
                                    event.target.value
                                  )
                                }
                                inputRef={lastProductTypeRef}
                              />
                              <IconButton
                                aria-label="delete"
                                className={classes.margin}
                                onClick={() => {
                                  onRemoveProductTypeName(productIndex);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Fragment>
                          }
                        >
                          <Fragment>
                            {vendors &&
                              vendors.map((vendor, vendorIndex) => {
                                const { name, categories } = vendor;
                                return (
                                  <TreeItem
                                    nodeId={`${losIndex}-${productIndex}-${vendorIndex}`}
                                    key={`${losIndex}-${productIndex}-${vendorIndex}`}
                                    label={
                                      <Fragment>
                                        <TextField
                                          InputLabelProps={{ shrink: true }}
                                          label="Hersteller"
                                          value={name || ""}
                                          onKeyDown={(e) => e.stopPropagation()}
                                          onChange={(event) =>
                                            onChangeProductVendor(
                                              productIndex,
                                              vendorIndex,
                                              event.target.value
                                            )
                                          }
                                          inputRef={lastProductVendorRef}
                                        />
                                        <IconButton
                                          aria-label="delete"
                                          className={classes.margin}
                                          onClick={() => {
                                            onRemoveProductVendor(
                                              productIndex,
                                              vendorIndex
                                            );
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Fragment>
                                    }
                                  >
                                    <Fragment>
                                      {categories &&
                                        categories.map(
                                          (category, categoryIndex) => {
                                            const { name, amount } = category;
                                            return (
                                              <TreeItem
                                                nodeId={`${losIndex}-${productIndex}-${vendorIndex}+${categoryIndex}`}
                                                key={`${losIndex}-${productIndex}-${vendorIndex}+${categoryIndex}`}
                                                label={
                                                  <Grid container>
                                                    <Grid item sm={4}>
                                                      <TextField
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        label="Kategorie"
                                                        value={name || ""}
                                                        onKeyDown={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                        onChange={(event) =>
                                                          onChangeProductCategoryName(
                                                            productIndex,
                                                            vendorIndex,
                                                            categoryIndex,
                                                            event.target.value
                                                          )
                                                        }
                                                        inputRef={
                                                          lastProductCategoryRef
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                      <TextField
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        label="Anzahl"
                                                        value={amount}
                                                        onKeyDown={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                        onChange={(event) =>
                                                          onChangeProductCategoryAmount(
                                                            productIndex,
                                                            vendorIndex,
                                                            categoryIndex,
                                                            Number(
                                                              event.target.value
                                                            )
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                      <IconButton
                                                        aria-label="delete"
                                                        className={
                                                          classes.margin
                                                        }
                                                        onClick={() => {
                                                          onRemoveProductCategory(
                                                            productIndex,
                                                            vendorIndex,
                                                            categoryIndex
                                                          );
                                                        }}
                                                      >
                                                        <DeleteIcon fontSize="small" />
                                                      </IconButton>
                                                    </Grid>
                                                  </Grid>
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      <Button
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={() =>
                                          addProductCategory(
                                            productIndex,
                                            vendorIndex
                                          )
                                        }
                                      >
                                        Kategorie
                                      </Button>
                                    </Fragment>
                                  </TreeItem>
                                );
                              })}
                            <Button
                              color="primary"
                              size="small"
                              startIcon={<AddIcon />}
                              onClick={() => addProductVendor(productIndex)}
                            >
                              Hersteller
                            </Button>
                          </Fragment>
                        </TreeItem>
                      );
                    })}
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={addProductType}
                  >
                    Produkttyp
                  </Button>
                </Fragment>
              </TreeView>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button size="small" startIcon={<DeleteIcon />} onClick={deleteLos}>
          Los löschen
        </Button>
      </CardActions>
    </Card>
  );
}

function LosDetailView({ match, history, vklData, setVklData }) {
  const classes = useStyles();
  const [previewOpen, setPreviewOpen] = useState(false);

  const {
    templateType,
    setTemplateType,
    template,
    setTemplate,
    selectedLot,
    setSelectedLot,
  } = useSelection();

  const { getResultHtml } = useGetHTMLResult();

  const previewIndex = useMemo(() => {
    if (templateType === TemplateType.LosPage) {
      if (selectedLot.isAccessory) {
        return 2;
      }

      return 1;
    }

    return 0;
  }, [selectedLot, template, templateType]);

  const previewNewsletterIndex = useMemo(() => {
    if (template === B4BTemplate.Invitation) return 0;
    if (template === B4BTemplate.Reminder1) return 1;
    if (template === B4BTemplate.Reminder2) return 2;
    if (template === B4BTemplate.Reminder3) return 3;
    if (template === B4BTemplate.SaleOverview) return 4;

    return 0;
  }, [template]);

  const { previewLosIndex, previewZubehoerIndex } = useMemo(
    () => ({
      previewLosIndex: selectedLot.index,
      previewZubehoerIndex: selectedLot.index,
    }),
    [selectedLot]
  );

  const { enqueueSnackbar } = useSnackbar();
  const [currentShopId] = useCurrentShopId();
  const shopId = currentShopId;

  const [createB4BLosListing, { loading: createB4BLosListingLoading }] =
    useMutation(createB4BLosListingMutation, {
      onCompleted() {
        enqueueSnackbar(i18next.t("changesSaved"), { variant: "success" });
      },
      onError() {
        enqueueSnackbar(i18next.t("changesNotSaved"), { variant: "warning" });
      },
    });
  const [updateB4BLosListing, { loading: updateB4BLosListingLoading }] =
    useMutation(updateB4BLosListingMutation, {
      onCompleted() {
        enqueueSnackbar(i18next.t("changesSaved"), { variant: "success" });
      },
      onError() {
        enqueueSnackbar(i18next.t("changesNotSaved"), { variant: "warning" });
      },
    });

  const b4bLosListingId = match && match.params._id;

  const { data: b4bLosListingData } = useQuery(b4bLosListingQuery, {
    variables: {
      id: b4bLosListingId,
      shopId,
    },
    skip: !b4bLosListingId || !shopId,
    errorPolicy: "all",
  });

  useEffect(() => {
    if (b4bLosListingData?.b4bLosListing) {
      setVklData(b4bLosListingData.b4bLosListing);
    }
  }, [setVklData, b4bLosListingData]);

  const {
    vkl,
    kw,
    year,
    startDate,
    endDate = "01. Januar 2020",
    endDateWeekday = "Donnerstag",
    endTime = "14:30",
    excelLink,
  } = vklData;

  const { lose = [], zubehoer = [], ...restData } = vklData;

  useEffect(() => {
    if (lose.length && vkl) {
      setVklData({
        ...vklData,
        excelLink: `Zusammenfassung_Los_1-${lose.length}_VKL${vkl}.xlsx`,
      });
    }
  }, [lose.length, vkl]);

  const parsedStartDate = startDate
    ? parse(startDate, "dd. LLLL yyyy", new Date(), {
        locale: deLocale,
      })
    : new Date();

  const parsedEndDate = endTime
    ? parse(`${endDate} ${endTime}`, "dd. LLLL yyyy HH:mm", new Date(), {
        locale: deLocale,
      })
    : parse(endDate, "dd. LLLL yyyy", new Date(), {
        locale: deLocale,
      });

  const updateLos = (losData, index) => {
    setVklData({
      ...vklData,
      lose: Object.assign([], lose, { [index]: losData }),
    });
  };

  const updateAccesory = (losData, index) => {
    setVklData({
      ...vklData,
      zubehoer: Object.assign([], zubehoer, { [index]: losData }),
    });
  };

  const deleteLosByIndex = (deleteIndex) => {
    setVklData({
      ...vklData,
      lose: lose.filter((los, index) => index !== deleteIndex),
    });
  };

  const deleteAccesoryByIndex = (deleteIndex) => {
    setVklData({
      ...vklData,
      zubehoer: zubehoer.filter((los, index) => index !== deleteIndex),
    });
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return null;
    }

    const reader = new FileReader();

    reader.onload = function (event) {
      // The file's text will be printed here
      const textResult = event.target.result;
      const jsonResult = JSON.parse(textResult);
      setVklData(jsonResult);
    };

    reader.readAsText(file);
  };

  const onLosSave = async (index) => {
    const templateData = await getResultHtml({
      selectedLot: {
        index: index,
        isAccessory: false,
      },
      templateType: TemplateType.LosPage,
    });

    const file = new File([templateData], `Los ${index + 1}.html`, {
      type: "text/html;charset=utf-8",
    });
    saveAs(file);
  };

  const onLosClipboardSave = async (index) => {
    const templateData = await getResultHtml({
      selectedLot: {
        index: index,
        isAccessory: false,
      },
      templateType: TemplateType.LosPage,
    });

    navigator.clipboard.writeText(templateData).then(
      function () {
        enqueueSnackbar(`Los ${index + 1} in Zwischenablage kopiert`, {
          variant: "success",
        });
      },
      function () {
        enqueueSnackbar(
          "Kopieren in Zwischenablage nicht unterstützt, bitte Download verwenden",
          { variant: "error" }
        );
      }
    );
  };

  const onLosAccesorySave = async (index) => {
    const templateData = await getResultHtml({
      selectedLot: {
        index: index,
        isAccessory: true,
      },
      templateType: TemplateType.LosPage,
    });

    const file = new File([templateData], `Zubehör Los ${index + 1}.html`, {
      type: "text/html;charset=utf-8",
    });
    saveAs(file);
  };

  const onLosAccesoryClipboardSave = async (index) => {
    const templateData = await getResultHtml({
      selectedLot: {
        index: index,
        isAccessory: true,
      },
      templateType: TemplateType.LosPage,
    });

    navigator.clipboard.writeText(templateData).then(
      function () {
        enqueueSnackbar(`Zubehör Los ${index + 1} in Zwischenablage kopiert`, {
          variant: "success",
        });
      },
      function () {
        enqueueSnackbar(
          "Kopieren in Zwischenablage nicht unterstützt, bitte Download verwenden",
          { variant: "error" }
        );
      }
    );
  };

  const onNewsletterSave = async (template, filename) => {
    const templateData = await getResultHtml({
      templateType: TemplateType.RemarketNowNewsletter,
      template,
    });

    const file = new File([templateData], filename, {
      type: "text/html;charset=utf-8",
    });
    saveAs(file);
  };

  const onNewsletterClipboardSave = async (template, filename) => {
    const templateData = await getResultHtml({
      templateType: TemplateType.RemarketNowNewsletter,
      template,
    });

    navigator.clipboard.writeText(templateData).then(
      function () {
        enqueueSnackbar(`${filename} in Zwischenablage kopiert`, {
          variant: "success",
        });
      },
      function () {
        enqueueSnackbar(
          "Kopieren in Zwischenablage nicht unterstützt, bitte Download verwenden",
          { variant: "error" }
        );
      }
    );
  };

  const onUploadfileSave = () => {
    const templateData = getNewsletterUploadfile({
      ...vklData,
      startDateValue: format(parsedStartDate, "dd.LL.yyyy"),
      endDateValue: format(parsedEndDate, "dd.LL.yyyy"),
    });
    const file = new File([templateData], `VKL${vkl}.txt`, {
      type: "text/plain;charset=utf-8",
    });
    saveAs(file);
  };

  const addLos = () => {
    setVklData({
      ...vklData,
      lose: [
        ...(lose || []),
        {
          number: lose ? lose.length + 1 : 1,
          vendor: "Daimler",
          manufactor: "",
          manufactor_short: "",
          type: "",
          type_short: "",
          type_short_category: "",
          amount: "0",
          palette_amount: 0,
          soldAmount: "",
          minimumAmount: "1",
          endTime: "14:30",
          url: "",
          hasAttachment: true,
          products: [],
        },
      ],
    });
  };

  const addAccesory = () => {
    setVklData({
      ...vklData,
      zubehoer: [
        ...(zubehoer || []),
        {
          number: zubehoer ? zubehoer.length + 1 : 1,
          vendor: "Zubehör",
          manufactor: "",
          manufactor_short: "",
          type: "",
          type_short: "",
          amount: "0",
          palette_amount: 0,
          soldAmount: "",
          minimumAmount: "1",
          endTime: "14:30",
          sumCalculated: true,
          customCalculation: "",
          countedAsPalettes: true,
          url: "",
          hasAttachment: false,
          products: [],
        },
      ],
    });
  };

  return (
    <div>
      <DetailToolbar
        title={
          b4bLosListingId
            ? "B4B-Mall Listing berarbeiten"
            : "B4B-Mall Listing erstellen"
        }
        onDelete={() => {
          console.log("clicked remove");
        }}
        onCancel={() => history.push("/b4b-listings")}
        onSave={async () => {
          if (b4bLosListingId) {
            const { createdAt, updatedAt, isDeleted, ...updateData } = vklData;
            await updateB4BLosListing({
              variables: {
                input: {
                  ...omitDeep(updateData, "__typename"),
                },
              },
            });
          } else {
            const { data } = await createB4BLosListing({
              variables: {
                input: {
                  ...omitDeep(vklData, "__typename"),
                },
              },
            });
            history.push(
              `/b4b-listings/${data.createB4BLosListing.b4bLosListing._id}`
            );
          }
        }}
      />
      <Grid
        container
        direction="row"
        align="center"
        justify="flex-end"
        className={classes.previewContainer}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="label"
            onClick={() => setPreviewOpen(!previewOpen)}
          >
            Vorschau
          </Button>
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item>
              <TextField
                className={classes.numberInput}
                id="vkl"
                type="number"
                label="VKL Nummer"
                variant="outlined"
                placeholder="100"
                InputLabelProps={{ shrink: true }}
                value={vkl}
                onChange={(event) =>
                  setVklData({ ...vklData, vkl: Number(event.target.value) })
                }
              />
              <TextField
                className={classes.numberInput}
                id="kw"
                type="number"
                label="Kalenderwoche"
                variant="outlined"
                placeholder="10"
                InputLabelProps={{ shrink: true }}
                value={kw}
                onChange={(event) =>
                  setVklData({ ...vklData, kw: Number(event.target.value) })
                }
              />
              <TextField
                className={classes.numberInput}
                id="year"
                type="number"
                label="Jahr"
                placeholder="2020"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={year}
                onChange={(event) =>
                  setVklData({ ...vklData, year: Number(event.target.value) })
                }
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <DatePicker
                  className={classes.normalInput}
                  autoOk
                  ampm={false}
                  format="dd. MMMM yyyy"
                  value={parsedStartDate}
                  onChange={(date) => {
                    const formattedDate = format(date, "dd. LLLL yyyy", {
                      locale: deLocale,
                    });
                    setVklData({ ...vklData, startDate: formattedDate });
                  }}
                  label="Startzeit"
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <DateTimePicker
                  className={classes.normalInput}
                  autoOk
                  ampm={false}
                  value={parsedEndDate}
                  onChange={(date) => {
                    const formattedDate = format(date, "dd. LLLL yyyy", {
                      locale: deLocale,
                    });
                    const formattedHour = format(date, "HH:mm", {
                      locale: deLocale,
                    });

                    const formattedDay = format(date, "EEEE", {
                      locale: deLocale,
                    });

                    setVklData({
                      ...vklData,
                      endDate: formattedDate,
                      endTime: formattedHour,
                      endDateWeekday: formattedDay,
                    });
                  }}
                  label="Endzeit"
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <TextField
                className={classes.normalInput}
                id="excelLink"
                label="Excel-Übersicht Dateiname"
                fullWidth
                variant="outlined"
                placeholder="Zusammenfassung_Los_1-8_VKLXYZ.xlsx"
                InputLabelProps={{ shrink: true }}
                value={excelLink}
                onChange={(event) => {
                  setVklData({ ...vklData, excelLink: event.target.value });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container className={classes.paddedText}>
        <Grid item>
          <Typography>Lose:</Typography>
        </Grid>
      </Grid>
      {lose &&
        lose.map((los, index) => (
          <LosCard
            key={index}
            los={los}
            losIndex={index}
            updateLos={updateLos}
            vkl={vkl}
            deleteLosByIndex={deleteLosByIndex}
          />
        ))}
      <Grid container className={classes.paddedText}>
        <Grid item>
          <Button
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={addLos}
          >
            Los
          </Button>
        </Grid>
      </Grid>

      <Grid container className={classes.paddedText}>
        <Grid item>
          <Typography>Zubehör:</Typography>
        </Grid>
      </Grid>
      {zubehoer &&
        zubehoer.map((zubehoer, index) => (
          <LosCard
            key={index}
            los={zubehoer}
            losIndex={index}
            updateLos={updateAccesory}
            vkl={vkl}
            deleteLosByIndex={deleteAccesoryByIndex}
            isAccesory
          />
        ))}
      <Grid container className={classes.paddedText}>
        <Grid item>
          <Button
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={addAccesory}
          >
            Zubehör
          </Button>
        </Grid>
      </Grid>

      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="row" className={classes.paddedText}>
            <Grid item sm={12}>
              <Typography align="left">VKL Upload-File:</Typography>
              <Grid container>
                <Grid item>
                  <Button
                    color="secondary"
                    component="label"
                    onClick={() => onUploadfileSave()}
                  >
                    VKL{vkl}.txt
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {lose && (
              <Grid item sm={12}>
                <Typography align="left">
                  Folgende Lose stehen zum Download bereit:
                </Typography>
                <Grid container>
                  {lose.map((los, index) => (
                    <Grid item key={index}>
                      <Button
                        color="secondary"
                        component="label"
                        endIcon={
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              onLosSave(index);
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        }
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onLosClipboardSave(index);
                        }}
                      >
                        Los {index + 1}.html
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {zubehoer && (
              <Grid item sm={12}>
                <Typography align="left">
                  Folgende Zubehör-Lose stehen zum Download bereit:
                </Typography>
                <Grid container>
                  {zubehoer.map((los, index) => (
                    <Grid item key={index}>
                      <Button
                        color="secondary"
                        component="label"
                        endIcon={
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              onLosAccesorySave(index);
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        }
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onLosAccesoryClipboardSave(index);
                        }}
                      >
                        Zubehör Los {index + 1}.html
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid item sm={12}>
              <Typography align="left">
                Folgende Newsletter stehen zum Download bereit:
              </Typography>
              <Grid container>
                <Grid item>
                  <Typography align="left">
                    Betreff: remarket-now.com: Einladung - Daimler Used Assets -
                    Daimler Invitation 1 VKL{vkl} KW{kw} - {year}
                  </Typography>
                  <Button
                    color="secondary"
                    component="label"
                    endIcon={
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onNewsletterSave(
                            B4BTemplate.Invitation,
                            "Invitation.html"
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onNewsletterClipboardSave(
                        B4BTemplate.Invitation,
                        "Invitation.html"
                      );
                    }}
                  >
                    Invitation.html
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Typography align="left">
                    Betreff: remarket-now.com - Auktionen laufen gerade und
                    enden am {endDateWeekday}, den {endDate} ab {endTime}!
                  </Typography>
                  <Button
                    color="secondary"
                    component="label"
                    endIcon={
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onNewsletterSave(
                            B4BTemplate.Reminder1,
                            "Reminder1.html"
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onNewsletterClipboardSave(
                        B4BTemplate.Reminder1,
                        "Reminder1.html"
                      );
                    }}
                  >
                    Reminder 1.html
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Typography align="left">
                    Betreff: remarket-now.com - Auktionen laufen gerade und
                    enden heute ab {endTime}!
                  </Typography>
                  <Button
                    color="secondary"
                    component="label"
                    endIcon={
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onNewsletterSave(
                            B4BTemplate.Reminder2,
                            "Reminder2.html"
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onNewsletterClipboardSave(
                        B4BTemplate.Reminder2,
                        "Reminder2.html"
                      );
                    }}
                  >
                    Reminder 2.html
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Typography align="left">
                    Betreff: remarket-now.com - Auktionen laufen gerade und
                    enden in einer Stunde!
                  </Typography>
                  <Button
                    color="secondary"
                    component="label"
                    endIcon={
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onNewsletterSave(
                            B4BTemplate.Reminder3,
                            "Reminder3.html"
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onNewsletterClipboardSave(
                        B4BTemplate.Reminder3,
                        "Reminder3.html"
                      );
                    }}
                  >
                    Reminder 3.html
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Typography align="left">
                    Betreff: remarket-now.com: Auktionsende Daimler Used Asset
                    VKL
                    {vkl} - Verkaufsübersicht
                  </Typography>
                  <Button
                    color="secondary"
                    component="label"
                    endIcon={
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          onNewsletterSave(
                            B4BTemplate.SaleOverview,
                            "Auktionsende.html"
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onNewsletterClipboardSave(
                        B4BTemplate.SaleOverview,
                        "Auktionsende.html"
                      );
                    }}
                  >
                    Auktionsende.html
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={previewOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setPreviewOpen(false)}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <Paper className={classes.paperRoot}>
          <Tabs
            value={previewIndex}
            onChange={(event, newValue) => {
              if (newValue === 0) {
                setTemplateType(TemplateType.RemarketNowNewsletter);
              } else {
                setTemplateType(TemplateType.LosPage);
                if (newValue === 1) {
                  setSelectedLot({
                    index: 0,
                    isAccessory: false,
                  });
                }
                if (newValue === 2) {
                  setSelectedLot({
                    index: 0,
                    isAccessory: true,
                  });
                }
              }
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
            scrollButtons="auto"
          >
            <Tab className={classes.tabSmall} label="Newsletter" />
            <Tab className={classes.tabSmall} label="Lose" />
            <Tab className={classes.tabSmall} label="Zubehör" />
          </Tabs>
        </Paper>

        {previewIndex === 0 && (
          <Paper className={classes.paperRoot}>
            <Tabs
              value={previewNewsletterIndex}
              onChange={(event, newValue) => {
                if (newValue === 0) setTemplate(B4BTemplate.Invitation);
                if (newValue === 1) setTemplate(B4BTemplate.Reminder1);
                if (newValue === 2) setTemplate(B4BTemplate.Reminder2);
                if (newValue === 3) setTemplate(B4BTemplate.Reminder3);
                if (newValue === 4) setTemplate(B4BTemplate.SaleOverview);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab className={classes.tabSmall} label="Einladung" />
              <Tab className={classes.tabSmall} label="Reminder 1" />
              <Tab className={classes.tabSmall} label="Reminder 2" />
              <Tab className={classes.tabSmall} label="Reminder 3" />
              <Tab className={classes.tabSmall} label="Auktionsende" />
            </Tabs>
          </Paper>
        )}

        {previewIndex === 1 && (
          <Paper className={classes.paperRoot}>
            <Tabs
              value={previewLosIndex}
              onChange={(event, newValue) =>
                setSelectedLot({
                  index: newValue,
                  isAccessory: false,
                })
              }
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {lose &&
                lose.map((los, index) => {
                  return (
                    <Tab
                      key={index}
                      className={classes.tabSmall}
                      label={index + 1}
                    />
                  );
                })}
            </Tabs>
          </Paper>
        )}

        {previewIndex === 2 && (
          <Paper className={classes.paperRoot}>
            <Tabs
              value={previewZubehoerIndex}
              onChange={(event, newValue) =>
                setSelectedLot({
                  index: newValue,
                  isAccessory: true,
                })
              }
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {zubehoer &&
                zubehoer.map((zubehoe, index) => {
                  return (
                    <Tab
                      key={index}
                      className={classes.tabSmall}
                      label={index + 1}
                    />
                  );
                })}
            </Tabs>
          </Paper>
        )}

        <TemplateSelection />
      </Drawer>
    </div>
  );
}

function LosDetailViewWrapper(props) {
  const [vklData, setVklData] = useState({});

  return (
    <VKLProvider vklData={vklData}>
      <SelectionProvider>
        <HTMLResultProvider>
          <LosDetailView {...props} vklData={vklData} setVklData={setVklData} />
        </HTMLResultProvider>
      </SelectionProvider>
    </VKLProvider>
  );
}

export default withRouter(LosDetailViewWrapper);
