import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import SimpleSchema from "simpl-schema";
import muiOptions from "reacto-form/cjs/muiOptions";
import { Button, TextField } from "@reactioncommerce/catalyst";
import useProduct from "../../../included/product-admin/hooks/useProduct";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(4)
  }
}));

const formSchema = new SimpleSchema({
  price: {
    type: Number,
    optional: true
  },
  compareAtPrice: {
    type: Number,
    optional: true
  },
  listPrice: {
    type: Number,
    optional: true
  },
  inventoryValue: {
    type: Number,
    optional: true
  },
  listPurchasePrice: {
    type: Number,
    optional: true
  },
  purchasePrice: {
    type: Number,
    optional: true
  },
  purchasePriceReal: {
    type: Number,
    optional: true
  }
});

const validator = formSchema.getFormValidator();

/**
 * @name VariantPricesForm
 * @param {Object} props Component props
 * @param {Object} ref Forwarded ref
 * @returns {React.Component} Variant form React component
 */
const VariantPricesForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("ns1");

  const {
    currentVariant,
    onUpdateProductVariantPrices,
    product,
    isLoading
  } = useProduct();

  const {
    getFirstErrorMessage,
    getInputProps,
    hasErrors,
    isDirty,
    submitForm
  } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      await onUpdateProductVariantPrices({
        variantId: currentVariant._id,
        variantPrices: formSchema.clean(formData)
      });

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
    value: currentVariant
  });

  const isSaveDisabled = !product || !isDirty || isSubmitting;

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={t("productVariant.prices")} />
      <CardContent>
        { isLoading ?
          Array.apply(null, { length: 3 }).map((element, index) => {
            return (
              <Grid container spacing={1} key={index}>
                <Grid item sm={6}>
                  <Skeleton height={20} width="40%" />
                  <Skeleton height={40} width="80%" />
                  <Skeleton height={20} width="40%" style={{ marginBottom: 20 }} />
                </Grid>
                <Grid item sm={6}>
                  <Skeleton height={20} width="40%" />
                  <Skeleton height={40} width="80%" />
                  <Skeleton height={20} width="40%" style={{ marginBottom: 20 }} />
                </Grid>
              </Grid>
            );
          })
        :
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["price"])}
                fullWidth
                helperText={getFirstErrorMessage(["price"]) || t("admin.helpText.price")}
                label={t("productVariant.price")}
                placeholder="0.00"
                {...getInputProps("price", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["listPrice"])}
                fullWidth
                helperText={getFirstErrorMessage(["listPrice"]) || t("admin.helpText.listPrice")}
                label={t("productVariant.listPrice")}
                placeholder="0.00"
                {...getInputProps("listPrice", muiOptions)}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["inventoryValue"])}
                fullWidth
                helperText={getFirstErrorMessage(["inventoryValue"]) || t("admin.helpText.inventoryValue")}
                label={t("productVariant.inventoryValue")}
                placeholder="0.00"
                {...getInputProps("inventoryValue", muiOptions)}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["listPurchasePrice"])}
                fullWidth
                helperText={getFirstErrorMessage(["listPurchasePrice"]) || t("admin.helpText.listPurchasePrice")}
                label={t("productVariant.listPurchasePrice")}
                placeholder="0.00"
                {...getInputProps("listPurchasePrice", muiOptions)}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["purchasePrice"])}
                fullWidth
                helperText={getFirstErrorMessage(["purchasePrice"]) || t("admin.helpText.purchasePrice")}
                label={t("productVariant.purchasePrice")}
                placeholder="0.00"
                {...getInputProps("purchasePrice", muiOptions)}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["purchasePriceReal"])}
                fullWidth
                helperText={getFirstErrorMessage(["purchasePriceReal"]) || t("admin.helpText.purchasePriceReal")}
                label={t("productVariant.purchasePriceReal")}
                placeholder="0.00"
                {...getInputProps("purchasePriceReal", muiOptions)}
                disabled
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {!isSaveDisabled &&
              <Box paddingRight={2}>
                <Typography>
                  {t("productVariant.pricePublishWarning")}
                </Typography>
              </Box>
            }
            <Button
              color="primary"
              disabled={isSaveDisabled}
              isWaiting={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t("app.saveChanges")}
            </Button>
          </Box>
        </form>
        }
      </CardContent>
    </Card>
  );
});

export default VariantPricesForm;