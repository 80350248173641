import { registerOperatorRoute, operatorRoutes } from "../../../routes";
import OperatorLanding from "./components/OperatorLanding";

// operatorRoutes.splice(operatorRoutes.findIndex((item) => item.path === "/"), 1);

// Default landing page
registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/",
  mainComponent: OperatorLanding
});
