import React from "react";
import { OidcSecure } from "@axa-fr/react-oidc-context";
import { Components } from "../reaction-components";
import useAuth from "../hooks/useAuth";
import Dashboard from "./Dashboard";

/**
 * App component
 * @returns {React.ReactElement} React component
 */
function App() {
  const { logout, viewer } = useAuth();
  // const [isAppLoading] = useIsAppLoading();

  // if (isAppLoading) return <Components.Loading />;

  if (viewer && !viewer.hasDashboardAccess) {
    logout();
  }

  return (
    <OidcSecure>
      <Dashboard logout={logout} viewer={viewer} />
    </OidcSecure>
  );
}

export default App;
