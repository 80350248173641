import gql from "graphql-tag";

export const bundleComponentFragment = gql`
    fragment BundleComponent on BundleComponent {
      _id
      productNumber
      description
      localization
      productType
      serialNumber
      createdAt
      height
      index
      isVisible
      length
      metafields {
        key
        description
        value
      }
      optionTitle
      price
      listPrice
      title
      updatedAt
      weight
      width
      limesId
      consumptionValues {
        name
        description
        type
        stringValue
        intValue
        floatValue
      }
      cdsType
      inventoryValue
      purchasePriceReal
      storageBin
      isCompleted
      specifications {
        specificationId
        value
      }
    }
`;
