import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

/**
 * @name OrderDateCell
 * @param {Object} value A react-table cell value
 * @return {React.Component} A date component
 */
export default function OrderDateCell({ value }) {
  // Determine what date or time to display.
  const now = moment();
  const reservationExpiresAt = moment(value);
  const duration = moment.duration(reservationExpiresAt.diff(now));
  const durationHours = duration.asHours();

  let dateTimeFormat = "DD.MM [um] HH:mm";
  // Show year for orders placed outside the current year.
  if (reservationExpiresAt.year() !== now.year()) {
    dateTimeFormat = "D.M.YYYY [um] HH:mm";
  }

  // Render order date by default
  let dateOptionalTime = moment(reservationExpiresAt).format(dateTimeFormat);

  if (durationHours < 1 && durationHours > -1) {
    const minutes = Math.round(duration.asMinutes());
    if (minutes > 0) {
      dateOptionalTime = `In ${minutes} Minuten`;
    } else {
      dateOptionalTime = `Vor ${minutes} Minuten`;
    }
  }

  if (durationHours > 1 && durationHours < 8 && durationHours < -1 && durationHours > -8) {
    const hours = Math.round(durationHours);
    if (hours > 0) {
      dateOptionalTime = `In ${hours} Stunden`;
    } else {
      dateOptionalTime = `Vor ${hours} Stunden`;
    }
  }

  return (
    <Fragment>
      {dateOptionalTime}
    </Fragment>
  );
}

OrderDateCell.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.string
};
