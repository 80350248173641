import { merge } from "lodash";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { rawMuiTheme } from "@reactioncommerce/catalyst/theme/defaultTheme";

const colors = {
  cdsBlue: {
    main: "#00354D",
    light: "#00B8F1",
    lighter: "",
    accent: "#00B8F1"
  },
  cdsGreen: {
    light: "#75B842",
    main: "#009D3C"
  },
  cdsOrange: {
    light: "#DDA02F",
    main: "#D88C29"
  },
  cdsGrey: {
    light: "#C1C1C1",
    main: "#A1A1A1"
  }
};

// Colors
export const colorPrimaryMain = colors.cdsBlue.main;
export const colorSecondaryMain = colors.cdsBlue.light;

export const rawMuiThemeCustom = {
  palette: {
    ...colors,
    primary: {
      light: colorSecondaryMain,
      main: colorPrimaryMain,
      // dark: colors.coolGrey400
    },
    secondary: {
      // light: colors.coolGrey300,
      main: colorSecondaryMain,
      // dark: colors.coolGrey400
    }
  },
  overrides: {
    MuiCheckbox: {
      root: {
        // color: colors.coolGrey500
      },
      colorSecondary: {
        "&$checked": {
          color: colors.cdsBlue.light
        },
        "&$disabled": {
          // color: colors.coolGrey100
        }
      }
    },
    MuiTableCell: {
      sizeSmall: {
        "padding": "4px 16px 4px 16px",
        "&:last-child": {
          paddingRight: 16
        },
        "&$paddingCheckbox": {
          "padding": "4px",
          "width": "auto",
          "&:last-child": {
            padding: 4,
          },
          '& > *': {
            padding: 9
          }
        }
      }
    },
    MuiDrawer: {
      paperAnchorLeft: {
        border: "none",
        backgroundColor: colors.cdsBlue.light,
        color: "#01b8f2"
      },
    },
    MuiDialog: {
      paper: {
        overflowY: "visible"
      }
    } 
  },
  typography: {
    fontFamily: "\"HCo Gotham\",\"Helvetica Neue\",Helvetica,Arial,sans-serif",
    body1: {
      color: colorPrimaryMain
    }
  }
};

export default createMuiTheme(merge(rawMuiTheme, rawMuiThemeCustom));
