import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Popover from "@material-ui/core/Popover";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";
import { Truck, Info, ShoppingBag, X, Bell, Tag, } from "react-feather";
import Highlighter from "react-highlight-words";
import { formatDistanceToNow, parseISO } from "date-fns";
import { de as deLocale, enUS as enLocale } from "date-fns/locale";
// import useStores from "lib/stores/useStores";
import useNotifications from "./hooks/useNotifications";

const useStyles = makeStyles((theme) => ({
    notificationsDropdown: {
        width: 360,
        overflowY: "scroll",
        maxHeight: "50vh"
    },
    popover: {
        boxShadow: "none",
        border: "1px solid #e6e6e6",
        zIndex: 5001
    },
    badge: {
        width: 20,
        height: 20,
        top: 1,
        left: 4,
        color: "#FFFFFF"
    },
    avatar: {
        background: theme.palette.primary.light
    },
    highlighted: {
        fontWeight: 500,
        color: "inherit",
        backgroundColor: "transparent",
        background: "none"
    },
    secondaryText: {
        fontSize: "0.75rem"
    },
    unreadItem: {
        background: "#73cde826"
    }
}));

function renderTypeIcon(type) {
    if (type === "newOrder") {
      return <ShoppingBag/>;
    }

    if (type === "orderShipped") {
      return <Truck/>;
    }

    if (type === "orderCanceled") {
      return <X/>;
    }

    if (type === "reservationExpiry") {
      return <Tag/>;
    }

    return <Info/>;
}

function NotificationDropdown() {
    const classes = useStyles();
    const [ anchorElementNotifications, setAnchorElementNotifications ] = useState();
    // const { uiStore: { language } } = useStores();
    const language = "de";
    const [notifications, isLoadingNotifications, loadMore] = useNotifications({ skip: false });

    const unreadNotifications = notifications && notifications.filter((notification) => notification.status === "unread");

    const checkScroll = (event) => {
        const { target: { scrollTop, scrollHeight, clientHeight } } = event;
        const percentage = Math.floor((scrollTop * 100) / (scrollHeight - clientHeight))
        if (percentage > 85) {
            loadMore();
        }
    }

    return (
        <Fragment>
            <Popover
            anchorEl={anchorElementNotifications}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            classes={{ paper: classes.popover }}
            open={Boolean(anchorElementNotifications)}
            onClose={() => setAnchorElementNotifications(null)}
            >
            <List dense className={classes.notificationsDropdown} onScroll={checkScroll}>
                { (!notifications || !notifications.length) &&
                    <ListItem>
                        <ListItemText primary="Keine Benachrichtigungen" />
                    </ListItem>
                }
                {(notifications || []).map((notification, index) => {
                const { _id, message, status, type, timeSent, url, highlighted } = notification;

                const timeSentFormatted = formatDistanceToNow(
                    parseISO(timeSent),
                    { locale: language === "en" && enLocale || deLocale }
                );

                return (
                    <div key={`notification-${_id}-${index}`} className={status === "unread" && classes.unreadItem || ""}>
                    <Link to={url}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                {renderTypeIcon(type)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Highlighter
                                highlightClassName={classes.highlighted}
                                searchWords={(highlighted || []).map((word) => word)}
                                autoEscape={true}
                                caseSensitive={false}
                                textToHighlight={message}
                                />
                            } secondary={`${language === "de" && "Vor " || ""}${timeSentFormatted}${language === "en" && " ago" || ""}`} classes={{ secondary: classes.secondaryText }}
                            />
                        </ListItem>
                    </Link>
                    { (index + 1) < notifications.length &&
                    <Divider component="li" />
                    }
                    </div>
                );
                })}
                {   isLoadingNotifications &&
                    [...Array(Math.min(10, Math.max((notifications || []).length, 1)))].map((element, index) => {
                        return (
                            <ListItem key={index}>
                                <ListItemAvatar>
                                    <Skeleton variant="circle" width={40} height={40} />
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Skeleton height={10} width="80%" style={{ marginBottom: 6 }} /> 
                                } secondary={<Skeleton height={10} width="40%" /> }
                                />
                            </ListItem>
                        );
                    })
                }
            </List>
            </Popover>

            <IconButton color="primary" onClick={(event) => setAnchorElementNotifications(event.currentTarget)}>
                {(unreadNotifications && unreadNotifications.length > 0) ?
                <Badge
                    badgeContent={unreadNotifications.length}
                    color="secondary"
                    classes={{ badge: classes.badge }}
                >
                    <Bell />
                </Badge>
                :
                <Bell />
                }
            </IconButton>
        </Fragment>
    );
}

export default NotificationDropdown;