import React, {Component, Fragment, useEffect, useState} from "react";
import AddIcon from "mdi-material-ui/Plus";

import {
    Grid,
    Box,
    Checkbox,
    Card,
    CardHeader,
    CardContent,
    CardActionArea,
    Button,
    IconButton,
    makeStyles,
    TextField,
} from "@material-ui/core";

var cardStyle = {
    width: '65%',
}

function AccountEditShippingMenu(props) {
    const { accountData, onClose, afterSubmit, setReturnToList, setDialogMsg, setShowMsg, history } = props;

    if (!accountData.addressBook) {
        setDialogMsg('No Shipping Addresses found!');
        setShowMsg(true);
        setReturnToList(true);
    }

    const addressBook = accountData.addressBook.nodes;

    const openEditShipping = (addressId) => {
        props.history.push("/account-manager/shipping/" + accountData._id + "/edit/" + addressId);
    }

    const openCreateShipping = (addressId) => {
        props.history.push("/account-manager/shipping/" + accountData._id + "/create");
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Card style={cardStyle}>
                <CardHeader
                    title={"Shipping Addresses"}
                    titleTypographyProps={{variant:'h1'}}
                    action={
                        <Button
                            id={'cancelBtn'}
                            onClick={openCreateShipping}
                            display={"flex"}
                            startIcon={<AddIcon/>}
                            m={1}
                        >
                            Create New Address
                        </Button>
                    }
                />

                <CardContent>
                    <Grid style={({padding: '0px 25px 25px 25px'})}>
                    {addressBook.map((component, index) => (
                        <CardActionArea key={index} onClick={() => {
                            openEditShipping(component._id);
                        }}>
                            <Box m={1} pl={2}>
                                <h3>
                                    {component.address1 && <React.Fragment>{component.address1}, </React.Fragment>}
                                    {component.address2 && <React.Fragment>{component.address2}, </React.Fragment>}
                                    {component.city && <React.Fragment>{component.city}, </React.Fragment>}
                                    {component.region && <React.Fragment>{component.region}, </React.Fragment>}
                                    {component.postal && <React.Fragment>{component.postal}, </React.Fragment>}
                                    {component.country && <React.Fragment>{component.country}</React.Fragment>}
                                </h3>
                            </Box>
                        </CardActionArea>
                    ))}
                    </Grid>

                    <Grid
                        container
                        justify={"flex-end"}
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                id={'cancelBtn'}
                                onClick={onClose}
                                variant={"outlined"}
                                display={"flex"}
                                m={1}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default (AccountEditShippingMenu);
