import gql from "graphql-tag";

export const productSpecFragment = gql`
    fragment ProductSpec on ProductSpec {
        _id
        name
        unit
        isVisible
    }
`;
