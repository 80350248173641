import React, { Fragment } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Grid, Typography, Card, CardContent, IconButton, makeStyles } from "@material-ui/core";
import InboxIcon from "mdi-material-ui/Inbox";
import CubeIcon from "mdi-material-ui/Cube";
import PuzzleIcon from "mdi-material-ui/Puzzle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  gridIcon: {
    width: "3.5em",
    height: "3.5em",
    color: theme.palette.secondary.main
  },
  gridFaIcon: {
    color: theme.palette.secondary.main
  },
  fontAwesomeButton: {
    width: "4em"
  },
  gridFullWidth: {
    width: "100%"
  }
}));

/**
 * OperatorLanding
 * @param {Object} props Component props
 * @returns {Node} React component
 */
function OperatorLanding() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title="CDS Weblounge Admin" />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={5}
      >
        <Grid item xs={12} className={classes.gridFullWidth}>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={5}
              >
                <Grid item md={3}>
                  <Link to="/orders" className={classes.gridItem}>
                    <IconButton>
                      <InboxIcon className={classes.gridIcon} />
                    </IconButton>
                    <Typography align="center" variant="body1">{t("admin.dashboard.ordersLabel")}</Typography>
                  </Link>
                </Grid>
                <Grid item md={3}>
                  <Link to="/products" className={classes.gridItem}>
                    <IconButton>
                      <CubeIcon className={classes.gridIcon} />
                    </IconButton>
                    <Typography align="center" variant="body1">{t("admin.products")}</Typography>
                  </Link>
                </Grid>
                <Grid item md={3}>
                  <Link to="/bundle-components" className={classes.gridItem}>
                    <IconButton>
                      <PuzzleIcon className={classes.gridIcon} />
                    </IconButton>
                    <Typography align="center" variant="body1">{t("admin.dashboard.bundleComponentsLabel")}</Typography>
                  </Link>
                </Grid>
                <Grid item md={3}>
                  <Link to="/make-an-offer" className={classes.gridItem}>
                    <IconButton className={classes.fontAwesomeButton}>
                      <FontAwesomeIcon className={classes.gridFaIcon} icon={faFire} size="3x" />
                    </IconButton>
                    <Typography align="center" variant="body1">{t("admin.dashboard.makeAnOfferLabel")}</Typography>
                  </Link>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default OperatorLanding;
