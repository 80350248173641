import React, { useMemo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import FieldsMenu from "../../shared/useDataTable-custom/FieldsMenu";

import { useSnackbar } from "notistack";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import {
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  makeStyles,
  Toolbar,
  ButtonGroup,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import IdCell from "../../shared/useDataTable-custom/TableCells/IdCell";
import productsWithoutImageQuery from "../graphql/queries/productsWithoutImage";
import { withRouter } from "react-router";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import PlusIcon from "mdi-material-ui/Plus";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";
import FileRecord from "../../../../FileRecord";
import clsx from "clsx";

import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import Tooltip from "@material-ui/core/Tooltip";

import { withStyles } from "@material-ui/core/styles";

import SearchIcon from "mdi-material-ui/Magnify";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "transparent",
    boxShadow: theme.shadows[1],
    maxWidth: 500,
  },
}))(Tooltip);

const createMediaRecordMutation = gql`
  mutation CreateMediaRecord($input: CreateMediaRecordInput!) {
    createMediaRecord(input: $input) {
      mediaRecord {
        _id
      }
    }
  }
`;

const addProductImageFromUrlMutation = gql`
  mutation uploadProductImage($url: String!, $productId: String!) {
    uploadProductImage(url: $url, productId: $productId)
  }
`;

const imageSuggestionsQuery = gql`
  query imageSuggestions($productIds: [ID!]!) {
    imageSuggestions(productIds: $productIds) {
      productId
      images
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  uploadButton: {
    width: 80,
    height: 80,
  },
  uploadButtonDragOver: {
    background: "#d5d5d5",
  },
  uploadOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#80808078",
    borderRadius: theme.shape.borderRadius,
  },
}));

function ToolbarComponent({
  allColumns,
  toggleHideAllColumns,
  labels,
  customButton,
}) {
  const maxFilterButtons = 3;

  const activeFilters = allColumns
    ? allColumns.filter(({ canFilter }) => canFilter)
    : [];

  return (
    <Toolbar>
      <Box paddingRight={2}>{customButton}</Box>

      <Box paddingRight={2}>
        <FieldsMenu
          children="Felder"
          allColumns={allColumns}
          toggleHideAllColumns={toggleHideAllColumns}
        />
      </Box>

      <div style={{ width: "100%" }}></div>
      <Box paddingLeft={2}>
        <ButtonGroup>
          {activeFilters.slice(0, maxFilterButtons).map((column, index) =>
            column.render("Filter", {
              key: index,
              labels: {
                clear: labels.clearFilter,
                clearAll: labels.clearAllFilters,
              },
            })
          )}
        </ButtonGroup>
      </Box>
    </Toolbar>
  );
}

function ImageResult({ name, imageSrc, removeItem, addProductImageFromUrl }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "imageRecommendation",
    item: { name, imageSrc },
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        await addProductImageFromUrl({
          variables: {
            url: item.imageSrc,
            productId: dropResult.productId,
          },
        });

        await removeItem(dropResult.productId);

        if (dropResult.onDragDropDone) {
          dropResult.onDragDropDone(imageSrc);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div ref={drag} style={{ margin: 4, cursor: "grab" }}>
      <CustomTooltip
        enterDelay={0}
        placement="right-start"
        title={<img src={imageSrc} width={400} />}
      >
        <img src={imageSrc} width={60} style={{ maxHeight: 60 }} />
      </CustomTooltip>
    </div>
  );
}

function ImageRecommendationCell({
  imageSuggestion,
  removeItem,
  addProductImageFromUrl,
}) {
  return (
    <div style={{ display: "flex" }}>
      {(imageSuggestion || []).map((imageSuggestion, index) => (
        <ImageResult
          name={`${index}-${imageSuggestion}`}
          removeItem={removeItem}
          addProductImageFromUrl={addProductImageFromUrl}
          imageSrc={imageSuggestion}
        />
      ))}
    </div>
  );
}

function ImageUploadCell({ product, removeItem, createMediaRecord, shopId }) {
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState();

  const [dropped, setDropped] = useState(false);

  const onDragDropDone = (imgSrc) => {
    setUploadFile({ preview: imgSrc });
    setDropped(false);
  };

  const onDrop = async (accepted) => {
    if (accepted.length < 1) return;

    const firstFile = accepted[0];

    setUploadFile({ ...firstFile, preview: URL.createObjectURL(firstFile) });

    const fileRecord = FileRecord.fromFile(firstFile);

    fileRecord.metadata = {
      productId: product._id,
      priority: 0,
    };

    await fileRecord.upload();

    await createMediaRecord({
      variables: {
        input: {
          mediaRecord: fileRecord.document,
          shopId,
        },
      },
    });

    await removeItem(product._id);
    setDropped(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/png, image/jpeg",
    disableClick: true,
    disablePreview: true,
    multiple: false,
    onDrop,
  });

  // We also make this a recommendation drop target
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "imageRecommendation",
    drop: () => {
      setDropped(true);
      return { name: "Upload", productId: product._id, onDragDropDone };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  if (uploadFile || dropped) {
    return (
      <div style={{ position: "relative" }}>
        {uploadFile?.preview ? (
          <img
            src={uploadFile.preview}
            style={{
              width: "80px",
              height: "auto",
            }}
          />
        ) : (
          <div
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        )}
        {dropped && (
          <div className={classes.uploadOverlay}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div ref={drop}>
      <Button
        variant="contained"
        classes={{
          root: clsx(classes.uploadButton, {
            [classes.uploadButtonDragOver]: isActive,
          }),
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <PlusIcon />
      </Button>
    </div>
  );
}

/**
 * @name ProductTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function ProductTable({ history }) {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [totalProducts, setTotalProducts] = useState(0);

  const [searchResultsOpen, setSearchResultsOpen] = useState(false);

  const [searchInProgress, setSearchInProgress] = useState(false);

  const [searchResultImages, setSearchResultImages] = useState([]);

  const productTableState = JSON.parse(
    localStorage.getItem("productImageTable")
  );
  const initialState = {
    pageSize: 20,
    hiddenColumns: ["_id"],
    ...productTableState,
  };

  const { t } = useTranslation("ns1");

  const [shopId] = useCurrentShopId();
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const onProductClick = useCallback(
    async (row) => {
      if (row.values.type === "bundle") {
        history.push(`/products-cto/${row.values._id}`);
      } else {
        history.push(`/products/${row.values._id}`);
      }
    },
    [history]
  );

  const [createMediaRecord] = useMutation(createMediaRecordMutation, {
    ignoreResults: true,
  });

  const [addProductImageFromUrl] = useMutation(addProductImageFromUrlMutation, {
    ignoreResults: true,
  });

  const removeItem = useCallback(
    async (itemId) => {
      setTableData((currentTableData) =>
        // currentTableData.filter((node) => node._id !== itemId)
        currentTableData.reduce((rows, currentElement) => {
          if (currentElement?._id === itemId) {
            return [...rows, { ...currentElement, isDone: true }];
          }

          return [...rows, currentElement];
        }, [])
      );
    },
    [tableData, setTableData]
  );

  // Create and memoize the column data
  const columns = useMemo(
    () => [
      {
        Header: "Bild",
        accessor: "primaryImage",
        disableSorting: true,
        Cell: ({ row }) => (
          <ImageUploadCell
            shopId={shopId}
            product={row.original}
            removeItem={removeItem}
            createMediaRecord={createMediaRecord}
          />
        ),
      },
      ...(searchResultsOpen
        ? [
            {
              Header: "Vorschläge",
              accessor: "imageSuggestion",
              disableSorting: true,
              Cell: ({ cell }) => (
                <ImageRecommendationCell
                  imageSuggestion={cell.value}
                  removeItem={removeItem}
                  addProductImageFromUrl={addProductImageFromUrl}
                />
              ),
            },
          ]
        : []),
      {
        Header: "Titel",
        accessor: "title",
        Cell: ({ row, cell }) => (
          <Link href="#" onClick={() => onProductClick(row)}>
            {cell.value}
          </Link>
        ),
      },
      {
        Header: "Produktnummer",
        accessor: "productNumber",
        Cell: ({ row, cell }) => (
          <Link href="#" onClick={() => onProductClick(row)}>
            {cell.value}
          </Link>
        ),
      },
      {
        Header: "Product ID",
        accessor: "_id",
        Cell: ({ cell }) => <IdCell value={cell.value} />,
      },
      {
        Header: "Hersteller",
        accessor: "vendor",
        Filter: makeDataTableColumnFilter({
          isMulti: true,
          options: [
            { label: t("admin.table.vendor.hp"), value: "hp" },
            { label: t("admin.table.vendor.lg"), value: "lg" },
            { label: t("admin.table.vendor.samsung"), value: "samsung" },
            { label: t("admin.table.vendor.epson"), value: "epson" },
            { label: t("admin.table.vendor.kyocera"), value: "kyocera" },
            { label: t("admin.table.vendor.sharp"), value: "sharp" },
            { label: t("admin.table.vendor.cds"), value: "cds" },
          ],
        }),
      },
    ],
    [t, searchResultsOpen]
  );

  const onFetchData = useCallback(
    async ({ globalFilter, filters, pageIndex, pageSize, sortBy }) => {
      setIsLoading(true);
      if (!shopId) {
        return;
      }

      let filterObject = {};
      filters.forEach((filter) => {
        filterObject[filter.id] = filter.value;
      });

      const { data } = await apolloClient.query({
        query: productsWithoutImageQuery,
        variables: {
          shopIds: [shopId],
          first: pageSize,
          offset: pageIndex * pageSize,
          filters: {
            searchField: globalFilter,
            ...filterObject,
            hasPrimaryImage: false,
          },
          sortBy,
        },
        fetchPolicy: "network-only",
      });

      const totalCount =
        data && data.productsCustom && data.productsCustom.totalCount;
      const nodes = data && data.productsCustom && data.productsCustom.nodes;

      setTableData(nodes);
      setTotalProducts(totalCount);
      setPageCount(Math.ceil(totalCount / pageSize));
      setIsLoading(false);
    },
    [apolloClient, setTableData, setPageCount, shopId]
  );

  const tableDataWithSuggestions = useMemo(
    () =>
      tableData.map((product) => {
        const foundSuggestions = searchResultImages.find(
          (searchResultImage) => searchResultImage.productId === product._id
        );

        if (foundSuggestions && foundSuggestions.images) {
          return {
            ...product,
            imageSuggestion: foundSuggestions.images,
          };
        }

        return product;
      }),
    [tableData, searchResultImages]
  );

  const dataTableProps = useDataTable({
    data: tableDataWithSuggestions,
    columns,
    onFetchData,
    pageCount,
    initialState,
    getRowId: (row) => row._id,
  });

  const {
    state: {
      hiddenColumns,
      sortBy,
      filters,
      pageIndex,
      pageSize,
      globalFilter,
    },
  } = dataTableProps;

  useEffect(() => {
    localStorage.setItem(
      "productImageTable",
      JSON.stringify({ hiddenColumns, pageSize })
    );
  }, [hiddenColumns, pageSize]);

  const handleImageSearch = useCallback(async () => {
    if (searchResultsOpen) {
      setSearchResultImages([]);
      return setSearchResultsOpen(false);
    }

    setSearchInProgress(true);

    const productIds = tableData?.map((product) => product._id);

    const result = await apolloClient.query({
      query: imageSuggestionsQuery,
      variables: {
        productIds,
      },
    });

    const newResults = result?.data?.imageSuggestions ?? [];

    setSearchResultImages(newResults);
    setSearchInProgress(false);
    setSearchResultsOpen(true);
  }, [
    tableData,
    searchResultsOpen,
    setSearchResultImages,
    setSearchResultsOpen,
    setSearchInProgress,
    apolloClient,
  ]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Card className={classes.card}>
            <CardHeader
              title={t("admin.dashboard.productImagesTitle")}
              subheader={t("admin.productTable.bulkActions.totalCount", {
                count: totalProducts,
              })}
            />
            <CardContent>
              <DataTable
                {...dataTableProps}
                isFilterable
                pageSizes={[10, 20, 30, 40, 50, 100, 200]}
                isLoading={isLoading}
                labels={{
                  next: "Nächste",
                  page: "Seite",
                  pageOf: ({ count }) => `von ${count}`,
                  pageSizeSelect: ({ count }) => `${count} Zeilen`,
                  previous: "Vorherige",
                  "filterChipValue.hp": t("admin.table.vendor.hp"),
                  "filterChipValue.lg": t("admin.table.vendor.lg"),
                  "filterChipValue.samsung": t("admin.table.vendor.samsung"),
                  "filterChipValue.epson": t("admin.table.vendor.epson"),
                  "filterChipValue.kyocera": t("admin.table.vendor.kyocera"),
                  "filterChipValue.sharp": t("admin.table.vendor.sharp"),
                  "filterChipValue.cds": t("admin.table.vendor.cds"),
                }}
                ToolbarComponent={(props) => (
                  <ToolbarComponent
                    {...props}
                    customButton={
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleImageSearch}
                        disabled={searchInProgress}
                        startIcon={
                          searchInProgress ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : (
                            <SearchIcon />
                          )
                        }
                      >
                        {searchResultsOpen
                          ? " Bildersuche schließen"
                          : " Bilder suchen"}
                      </Button>
                    }
                  />
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DndProvider>
  );
}

ProductTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(ProductTable);
