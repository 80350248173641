import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import productClassQuery from "../graphql/queries/productClass";

export default function withProductClass(Comp) {
  function WithProductClass(props) {
    const { history, shopId, match } = props;
    const [uploadProgress, setUploadProgress] = useState();


    const productClassId = match && match.params.productClassId;

    const { loading, data, error, refetch } = useQuery(productClassQuery, {
      skip: !productClassId,
      variables: {
        productClassId
      },
      fetchPolicy: "network-only"
    });

    const productClass = data && data.productClass;

    const handleCreate = (tag) => {
      history.push(`/product-classes/${tag._id}`);
    };

    const handleCancel = () => {
      history.push("/product-classes");
    };

    return (
      <Comp
        {...props}
        productClass={productClass}
        onCancel={handleCancel}
        onCreate={handleCreate}
      />
    );
  }

  WithProductClass.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        productClassId: PropTypes.string
      })
    }),
    productClassId: PropTypes.string,
    shopId: PropTypes.string.isRequired
  };

  return WithProductClass;
}
