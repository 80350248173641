import React, { Fragment } from "react";
import PropTypes from "prop-types";
import getProductTypeLabel from "../utils/getProductTypeLabel";

/**
 * @name ProductTypeCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function ProductTypeCell({ row }) {
  const { original: { productType } } = row;

  if (productType) {
    return getProductTypeLabel(productType);
  }

  return null;
}

ProductTypeCell.propTypes = {
  row: PropTypes.object.isRequired
};
