import React from "react";
import PropTypes from "prop-types";
import Barcode from "react-barcode";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  barcodeContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  barcodeText: {
    fontSize: 11,
    color: "#000000",
    fontFamily: "monospace"
  }
});

const validBarcode = (code) =>
  // ASCII value ranges 0-127, 200-211
  /^[\x00-\x7F\xC8-\xD3]+$/.test(code);


/**
 * @name BarcodeCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function BarcodeCell({ value }) {
  const classes = useStyles();

  if (value) {
    if (validBarcode(value)) {
      return (
        <div className={classes.barcodeContainer}>
          <Barcode value={value} displayValue={false} margin={8} width={1}
            height={20} background="transparent"
          />
          <span className={classes.barcodeText}>{value}</span>
        </div>
      );
    }

    return (
      <div className={classes.barcodeContainer}>
        <span className={classes.barcodeText}>{value}</span>
      </div>
    );
  }

  return null;
}

BarcodeCell.propTypes = {
  row: PropTypes.object.isRequired
};
