import React from "react";
import PropTypes from "prop-types";
import { Components } from "../../../../reaction-components";
import formatMoney from "../../../../utils/formatMoney";
import getCurrencyDefinitionByCode from "../../../../utils/getCurrencyDefinitionByCode";
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles
} from "@material-ui/core";
import Barcode from "react-barcode";
import Grid from "@material-ui/core/Grid";
import getProductTypeLabel from "./utils/getProductTypeLabel";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";

const useStyles = makeStyles((theme) => ({
  fulfillmentGroupHeader: {
    marginBottom: theme.spacing(4)
  },
  barcodeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

/**
 * @name BundleComponentForm
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
function BundleComponentForm(props) {
  const classes = useStyles();
  const { bundleComponent } = props;
  const [currentShopId] = useCurrentShopId();
  // const [approveOrderPayments] = useMutation(approveOrderPaymentsMutation);
  // const [captureOrderPayments, { loading: captureOrderPaymentsLoading }] = useMutation(captureOrderPaymentsMutation);

  const currency = getCurrencyDefinitionByCode("EUR");

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item sm={4}>
          <CardHeader title="Bundle Komponenten Details" />
        </Grid>
        <Grid item sm={8}>
          { bundleComponent.productNumber &&
            <div className={classes.barcodeContainer}>
              <Barcode value={bundleComponent.productNumber} displayValue={false} margin={8} width={1}
                height={20} background="transparent"
              />
            </div>
          }
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Components.TextField
              // i18nKeyLabel="productDetailEdit.productNumber"
              // i18nKeyPlaceholder="productDetailEdit.productNumber"
              disabled
              label="Produktnummer"
              name="productNumber"
              // onBlur={this.handleFieldBlur}
              // onChange={this.handleFieldChange}
              // onReturnKeyDown={this.handleFieldBlur}
              placeholder=""
              // ref="productNumberInput"
              value={bundleComponent.productNumber|| ""}
            />
          </Grid>
          <Grid item sm={6}>
            <Components.TextField
              // i18nKeyHelpText="admin.helpText.limesId"
              // i18nKeyLabel="admin.productVariant.limesIdLabel"
              // i18nKeyPlaceholder="admin.productVariant.limesIdPlaceholder"
              disabled
              label="Limes Id"
              name="limesId"
              // onBlur={onVariantFieldBlur}
              // onChange={onVariantFieldChange}
              // onReturnKeyDown={onVariantFieldBlur}
              // validation={validation}
              value={bundleComponent.limesId || ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Components.TextField
              // i18nKeyHelpText="admin.helpText.productType"
              // i18nKeyLabel="admin.productVariant.productTypeLabel"
              // i18nKeyPlaceholder="admin.productVariant.productTypePlaceholder"
              disabled
              label="Produkttyp"
              name="productType"
              // onBlur={onVariantFieldBlur}
              // onChange={onVariantFieldChange}
              // onReturnKeyDown={onVariantFieldBlur}
              // validation={validation}
              value={getProductTypeLabel(bundleComponent.productType)}
            />
          </Grid>
          <Grid item sm={6}>
            <Components.TextField
              // i18nKeyHelpText="admin.helpText.localization"
              // i18nKeyLabel="admin.productVariant.localizationLabel"
              // i18nKeyPlaceholder="admin.productVariant.localizationPlaceholder"
              disabled
              label="Lokalisierung"
              name="localization"
              // onBlur={onVariantFieldBlur}
              // onChange={onVariantFieldChange}
              // onReturnKeyDown={onVariantFieldBlur}
              // validation={validation}
              value={bundleComponent.localization}
            />
          </Grid>
        </Grid>
        <Components.TextField
          // i18nKeyLabel="productDetailEdit.description"
          // i18nKeyPlaceholder="productDetailEdit.description"
          disabled
          label="Beschreibung"
          multiline={true}
          name="description"
          // onBlur={this.handleFieldBlur}
          // onChange={this.handleFieldChange}
          placeholder="Description"
          // ref="descriptionInput"
          value={bundleComponent.description}
        />
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Components.TextField
            // i18nKeyLabel="productDetailEdit.description"
            // i18nKeyPlaceholder="productDetailEdit.description"
              placeholder="0.00"
              format={currency}
              numericType="currency"
              disabled
              label="Listpreis"
              name="listPrice"
              // onBlur={this.handleFieldBlur}
              // onChange={this.handleFieldChange}
              placeholder="listPrice"
              // ref="descriptionInput"
              value={formatMoney(bundleComponent.listPrice, "EUR")}
            />
          </Grid>
          <Grid item sm={6}>
            <Components.TextField
            // i18nKeyHelpText="admin.helpText.localization"
            // i18nKeyLabel="admin.productVariant.localizationLabel"
            // i18nKeyPlaceholder="admin.productVariant.localizationPlaceholder"
              disabled
              label="Lagerplatz"
              name="storageBin"
              // onBlur={onVariantFieldBlur}
              // onChange={onVariantFieldChange}
              // onReturnKeyDown={onVariantFieldBlur}
              // validation={validation}
              value={bundleComponent.storageBin}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Components.TextField
            // i18nKeyLabel="productDetailEdit.description"
            // i18nKeyPlaceholder="productDetailEdit.description"
              placeholder="0.00"
              format={currency}
              numericType="currency"
              disabled
              label="Bestandswert (IV)"
              name="inventoryValue"
              // onBlur={this.handleFieldBlur}
              // onChange={this.handleFieldChange}
              // ref="descriptionInput"
              value={formatMoney(bundleComponent.inventoryValue, "EUR")}
            />
          </Grid>
          <Grid item sm={6}>
            <Components.TextField
            // i18nKeyLabel="productDetailEdit.description"
            // i18nKeyPlaceholder="productDetailEdit.description"
              placeholder="0.00"
              format={currency}
              numericType="currency"
              disabled
              label="Einkaufspreis (EK)"
              name="purchasePriceReal"
              // onBlur={this.handleFieldBlur}
              // onChange={this.handleFieldChange}
              // ref="descriptionInput"
              value={formatMoney(bundleComponent.purchasePriceReal, "EUR")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

BundleComponentForm.propTypes = {
  classes: PropTypes.object
};

export default BundleComponentForm;
