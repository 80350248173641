import gql from "graphql-tag";
import { headerSlideFragment } from "../fragments/headerSlide";

export default gql`
  query headerSlider($input: HeaderSliderInput!) {
    headerSlider(input: $input) {
      ...HeaderSlide
    }
  }
  ${headerSlideFragment}
`;
