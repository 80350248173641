import React, { createContext, useCallback } from "react";
import LosPage from "../templates/LosPage";
import RemarketNowNewsletter from "../templates/RemarketNowNewsletter";

import useSelection from "../hooks/useSelection";

import { TemplateType, LotSelection, B4BTemplate } from "./SelectionContext";

import ReactDOMServer from "react-dom/server";

import useVKLData from "../hooks/useVKLData";

import { VKLProvider } from "./VKLContext";
import { SelectionProvider } from "./SelectionContext";

// import mjml2html from "mjml-browser";

export type Props = {
  children: React.ReactNode;
};

interface IContext {
  getResultHtml: () => Promise<string>;
  getResultMjml: () => string | null;
}

type OptionalOverrides = {
  selectedLot?: LotSelection;
  templateType?: TemplateType;
  template?: B4BTemplate;
};

export const HTMLResultContext = createContext<IContext | undefined>(undefined);

function getTemplate(templateType: TemplateType | undefined) {
  if (templateType === TemplateType.LosPage) return LosPage;
  if (templateType === TemplateType.RemarketNowNewsletter)
    return RemarketNowNewsletter;

  return () => <>null</>;
}

export const HTMLResultProvider = ({ children }: Props) => {
  const selectionStore = useSelection();

  // Pass along
  const vklData = useVKLData();

  const getResultMjml = useCallback(
    (optionalOverrides: OptionalOverrides = {}) => {
      if (typeof window === "undefined") return null;

      const overridenSelectionStore = {
        ...selectionStore,
        ...optionalOverrides,
      };

      const Template = getTemplate(overridenSelectionStore.templateType);

      const staticMjml = ReactDOMServer.renderToStaticMarkup(
        <VKLProvider vklData={vklData}>
          <SelectionProvider value={overridenSelectionStore}>
            <Template />
          </SelectionProvider>
        </VKLProvider>
      );

      return staticMjml;
    },
    [vklData, selectionStore]
  );

  const getResultHtml = useCallback(
    async (optionalOverrides: OptionalOverrides = {}) => {
      if (typeof window === "undefined") return null;

      const mjml2html = (await import("mjml-browser")).default;

      const staticMjml = getResultMjml(optionalOverrides);

      console.log({ mjml: staticMjml });

      const result = mjml2html(staticMjml);

      return result.html;
    },
    [vklData, selectionStore, getResultMjml]
  );

  return (
    <HTMLResultContext.Provider
      value={{
        getResultHtml,
        getResultMjml,
      }}
    >
      {children}
    </HTMLResultContext.Provider>
  );
};
