import React from "react";
import ExcelIcon from "mdi-material-ui/FileExcel";
import { registerOperatorRoute } from "../../../routes";
import ProductExport from "./components/ProductExport";

registerOperatorRoute({
  isNavigationLink: true,
  specialAccessId: "product-export",
  // isNavigationLink: false,
  isSetting: false,
  mainComponent: ProductExport,
  path: "/product-export",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <ExcelIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.productExportLabel",
});
