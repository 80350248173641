import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql`
    mutation updateAccountMutation($input: UpdateAccountInput!) {
        updateAccount(input: $input) {
            account {
                ...Account
            }
        }
    }
    ${accountFragment}
`;
