import gql from "graphql-tag";
import { reservationFragment } from "../fragments/reservation";
import { accountLabelFragment } from "../fragments/accountLabel";

export default gql`
  query reservationsQuery($shopIds: [ID], $filters: ReservationFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ReservationSortBy]) {
    reservations(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...Reservation
        account {
          ...AccountLabel
        }
      }
      totalCount
      __typename
    }
  }
  ${reservationFragment}
  ${accountLabelFragment}
`;
