import gql from "graphql-tag";
import { makeAnOfferEntryFragment } from "../fragments/makeAnOfferEntry";

export default gql`
query makeAnOfferEntryQuery($id: ID!){
    makeAnOfferEntry(id: $id ) {
        ...MakeAnOfferEntry
    }
}
${makeAnOfferEntryFragment}
`;
