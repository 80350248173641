import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql`
  query accountsQuery($shopIds: [ID], $filters: AccountFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [AccountSortBy]) {
    accountsCustom(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...Account
      }
      totalCount
      __typename
    }
  }
  ${accountFragment}
`;
