import gql from "graphql-tag";
import { productSpecGroupFragment } from "../fragments/productSpecGroup";

export default gql`
  mutation updateProductSpecGroupMutation($input: UpdateProductSpecGroupInput!) {
    updateProductSpecGroup(input: $input) {
      productSpecGroup {
        ...ProductSpecGroup
      }
    }
  }
  ${productSpecGroupFragment}
`;
