import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import primaryShopIdQuery from "../../../../graphql/queries/getPrimaryShopId";
import { Checkbox, Card, CardHeader, CardContent, IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "mdi-material-ui/ChevronRight";
import ChevronUpIcon from "mdi-material-ui/ChevronUp";
import { withRouter } from "react-router";
// import { SelectableGroup } from "react-selectable-fast";
import setBundleComponentsCompleteMutation from "../graphql/mutations/setBundleComponentsComplete";
import DateCell from "./TableCells/DateCell";
import BundleSpecificationsCell from "./TableCells/BundleSpecificationsCell";
import ProductTypeCell from "./TableCells/ProductTypeCell";
import BarcodeCell from "./TableCells/BarcodeCell";
import CompletedCell from "./TableCells/CompletedCell";
import bundleComponentsQuery from "../graphql/queries/bundleComponents";
import formatMoney from "../../../../utils/formatMoney";
import SaveFabIcon from "../../shared/useDataTable-custom/SaveFabIcon";


/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible"
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0
  },
  buttonAsc: {
    borderTop: "2px solid"
  },
  buttonDesc: {
    borderBottom: "2px solid"
  },
  expandButton: {
    marginLeft: theme.spacing(1)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: "50%",
    right: "50%"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  selectBox: {
    border: "1px dotted grey"
  }
}));

/*
function ExpandedComponent(props) {
  const { original: { description } } = props;

  return (
    <p>{description}</p>
  );
}
*/

/**
 * @name BundleComponentTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function BundleComponentTable({ history }) {
  const apolloClient = useApolloClient();
  const classes = useStyles();

  const [setBundleComponentsComplete, { loading: setBundleComponentsCompleteLoading }] = useMutation(setBundleComponentsCompleteMutation);
  const [documentEdited, setDocumentEdited] = useState(false);
  const [documentsToUpdate, setDocumentsToUpdate] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("ns1");

  const handleSpecificationEdit = (documentId, newSpecifications) => {
    setDocumentEdited(true);
  };

  // Create and memoize the column data
  const columns = useMemo(() => [
    /*
    {
      // Make an expander cell
      Header: () => null, // No header
      id: "expander",
      cellProps: {
        isClickDisabled: true,
        padding: "none"
      },
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ?
            <IconButton className={classes.expandButton}>
              <ChevronUpIcon/>
            </IconButton>
            :
            <IconButton className={classes.expandButton}>
              <ChevronRightIcon/>
            </IconButton>
          }
        </span>
      ),
      Expanded: (props) => <ExpandedComponent {...props} />
    },
    */
    {
      id: "selection",
      disableSorting: true,
      cellProps: {
        // Disables the cell click if the row is clickable
        // This is important if you have a callback for onRowClick, as the checkbox cell
        // will also trigger the row click.
        // Alternatively you can control the onClick with the following option
        // onClick: (event) => event.stopPropagation(),
        isClickDisabled: true,

        // All other props will be applied to the table cell.
        padding: "none"
      },
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox {...getToggleAllRowsSelectedProps()} />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Cell: ({ row }) => (
        <Checkbox
          {...row.getToggleRowSelectedProps()}
          title={`Toggle row selection for ${row.values.fullName}`}
        />
      )
    },
    {
      Header: "ID",
      accessor: "_id"
    },
    {
      Header: "Produktnummer",
      accessor: "productNumber",
      // Cell: ({ row }) => <BarcodeCell row={row} />
    },
    {
      Header: "Seriennummer",
      accessor: "serialNumber",
      // Cell: ({ row }) => <BarcodeCell row={row} accessor="serialNumber" />
    },
    {
      Header: "Beschreibung",
      accessor: "description"
    },
    {
      Header: "Produkttyp",
      accessor: "productType",
      Cell: ({ row }) => <ProductTypeCell row={row} />
    },
    {
      Header: "Lokalisierung",
      accessor: "localization",
      Cell: ({ cell }) => cell.value || null
    },
    {
      Header: "Listpreis",
      accessor: "listPrice",
      Cell: ({ cell }) => cell.value && formatMoney(cell.value, "EUR") || null
    },
    {
      Header: "Bestandswert (IV)",
      accessor: "inventoryValue",
      Cell: ({ cell }) => cell.value && formatMoney(cell.value, "EUR") || null,
    },
    {
      Header: "Einkaufspreis (EK Real)",
      accessor: "purchasePriceReal",
      Cell: ({ cell }) => cell.value && formatMoney(cell.value, "EUR") || null,
    },
    {
      Header: "Spezifikationen",
      accessor: "specifications",
      Cell: ({ cell }) => <BundleSpecificationsCell specifications={cell.value} onSpecificationEdit={handleSpecificationEdit} />,
      cellProps: {
        isClickDisabled: true,
        padding: "none"
      }
    },
    {
      Header: "Erstelldatum",
      accessor: "createdAt",
      Cell: ({ row }) => <DateCell row={row} />,
    },
    /*
    {
      Header: "Sichtbarkeit",
      accessor: "isVisible",
      Cell: ({ row }) => <VisibilityCell row={row} />
    },
    */
    {
      Header: "Status",
      accessor: "isCompleted",
      Filter: makeDataTableColumnFilter({
        options: [
          { label: t("admin.table.completedStatus.completed"), value: "completed" },
          { label: t("admin.table.completedStatus.uncompleted"), value: "uncompleted" }
        ]
      }),
      Cell: ({ row }) => <CompletedCell row={row} />
    }
  ], [classes.expandButton, t]);

  const onFetchData = useCallback(async ({ globalFilter, filters, pageIndex, pageSize, sortBy }) => {
    setIsLoading(true); 

    const { data: shopData } = await apolloClient.query({
      query: primaryShopIdQuery
    });

    let filterObject = {};
    filters.forEach((filter) => {
      filterObject[filter.id] = filter.value;
    });

    // TODO: Add loading and error handling
    const { data } = await apolloClient.query({
      query: bundleComponentsQuery,
      variables: {
        shopIds: shopData && [shopData.primaryShopId],
        first: pageSize,
        offset: pageIndex * pageSize,
        filters: {
          searchField: globalFilter,
          ...filterObject
        },
        sortBy
      }
    });

    const totalCount = data && data.bundleComponents && data.bundleComponents.totalCount;
    const nodes = data && data.bundleComponents && data.bundleComponents.nodes;

    setTableData(nodes);
    setPageCount(Math.ceil(totalCount / pageSize));
    setIsLoading(false);
  }, [apolloClient, setTableData, setPageCount]);

  // Row click callback
  const onRowClick = useCallback(async ({ row }) => {
    history.push(`/bundle-components/${row.values._id}`);
  }, [history]);

  const onRowSelect = useCallback(async ({ selectedRows: newSelectedRows }) => {
    setSelectedRows(newSelectedRows || []);
  }, [setSelectedRows, tableData]);

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    onRowClick,
    onRowSelect,
    initialState: {hiddenColumns: ["createdAt", "_id", "inventoryValue", "purchasePriceReal"] }
  });

  const options = useMemo(() => [{
    label: "Als Erledigt markieren",
    confirmTitle: "Ausgewählte Bundlekomponenten als Erledigt markieren",
    confirmMessage: `Sind Sie sicher, das ${selectedRows.length} Bundlekomponenten als Erledigt markiert werden sollen,
    ohne weitere Spezifikationen zu erhalten?`,
    isDisabled: selectedRows.length === 0,
    onClick: async () => {
      const { data: shopData } = await apolloClient.query({
        query: primaryShopIdQuery
      });

      const selectedRowIds = (selectedRows || []).map(rowIndex => tableData[rowIndex]._id);

      await setBundleComponentsComplete({
        variables: {
          input: {
            shopId: shopData.primaryShopId,
            bundleComponentIds: selectedRowIds
          }
        }
      });
    }
  }], [apolloClient, selectedRows, tableData, setBundleComponentsComplete]);


  return (
    <Card className={classes.card}>
      <CardHeader title={t("admin.dashboard.bundleComponentsTitle")} />
      <CardContent>
        {/*
        <SelectableGroup
          enableDeselect
          tolerance={0}
          //globalMouse
          allowClickWithoutSelected
          onSelectionClear={data => console.log("onSelectionClear", data)}
          onSelectionFinish={data => console.log("onSelectionFinish", data)}
          ignoreList={[".not-selectable"]}
          selectboxClassName={classes.selectBox}
        >
        */}
        <DataTable
          {...dataTableProps}
          actionMenuProps={{ children: "Aktionen", options }}
          placeholder={"Filter orders"}
          isFilterable
          labels={{
            "filterChipValue.completed": t("admin.table.completedStatus.completed"),
            "filterChipValue.uncompleted": t("admin.table.completedStatus.uncompleted"),
            "allFilters": "Alle Filter",
            "allFiltersDrawerTitle": "Alle Filter",
            "clearAllFilters": "Alle Filter entfernen",
            "clearFilter": "Entfernen",
            "globalFilterPlaceholder": "Filter",
            "next": "Nächste",
            "page": "Seite",
            "pageOf": ({ count }) => `von ${count}`,
            "pageSizeSelect": ({ count }) => `${count} Zeilen`,
            "previous": "Vorherige"
          }}
          isLoading={isLoading}
        />
        {/* </SelectableGroup> */}
        <SaveFabIcon isEnabled={documentEdited} onClick={() => setDocumentEdited(false)} />
      </CardContent>
    </Card>
  );
}

BundleComponentTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(BundleComponentTable);
