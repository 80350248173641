import gql from "graphql-tag";

export const headerSlideFragment = gql`
  fragment HeaderSlide on HeaderSliderInfo {
    _id
    type
    locale
    createdAt
    updatedAt
    slides {
      _id
      imageUrl
      buttonMdStyles
      backgroundColor
      backgroundImage
      buttonText
      buttonLink
      buttonLinkAs
      containerColor
      draftContent
    }
  }
`;
