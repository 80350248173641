import gql from "graphql-tag";

export const makeAnOfferEntryFragment = gql`
  fragment MakeAnOfferEntry on MakeAnOfferEntry {
    _id
    createdAt
    productConfiguration {
      productId
      productVariantId
      __typename
    }
    catalogProduct {
      title
      slug
      productId
      productNumber
      primaryImage {
        URLs {
          large
          medium
          original
          small
          thumbnail
          __typename
        }
        priority
        productId
        variantId
        __typename
      }
      __typename
    }
    chosenVariant {
      serialNumber
      pricing {
        compareAtPrice {
          displayAmount
          __typename
        }
        price
        currency {
          code
          __typename
        }
        displayPrice
        __typename
      }
      primaryImage {
        URLs {
          large
          medium
          original
          small
          thumbnail
          __typename
        }
        priority
        productId
        variantId
        __typename
      }
      variantId
      __typename
    }
    status
    currentPrice
    quantity
    workflow {
      status
      price
      createdAt
      __typename
    }
    __typename
  }
`;
