import { useContext } from "react";
import { HTMLResultContext } from "../context/HTMLResultContext";

export default function useGetHTMLResult() {
  const context = useContext(HTMLResultContext);

  if (context === undefined) {
    throw new Error(
      "useGetHTMLResult must be used within a HTMLResultProvider"
    );
  }

  return context;
}
