import "./blocks";

import React from "react";
import CubeIcon from "mdi-material-ui/Cube";
import { registerOperatorRoute } from "../../../routes";

import ProductsTable from "./components/ProductsTable";
import ProductDetailLayout from "./layouts/ProductDetail";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import ContentViewFullLayout from "../../../layouts/ContentViewFullLayout";

registerOperatorRoute({
  // LayoutComponent: null,
  // MainComponent: ProductDetailLayout,
  layoutComponent: ContentViewFullLayout,
  mainComponent: ProductDetailLayout,
  path: "/products/:handle/:variantId?/:optionId?"
});

/*
registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 20,
  layoutComponent: ContentViewExtraWideLayout,
  path: "/products",
  mainComponent: ProductsTable,
  // eslint-disable-next-line react/display-name, react/no-multi-comp
  SidebarIconComponent: (props) => <CubeIcon {...props} />,
  sidebarI18nLabel: "admin.products"
});
*/
