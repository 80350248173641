import React, { createContext, useCallback } from "react";

export type Props = {
  locale: string;
  translations: { [key: string]: { [key: string]: any } };
  children: React.ReactNode;
};

interface IContext {
  t: (key: string) => string;
  locale: string;
}

export const TranslationContext = createContext<IContext | undefined>(
  undefined
);

export const TranslationProvider = ({
  locale,
  translations,
  children,
}: Props) => {
  const t = useCallback(
    (key: string) => {
      return translations?.[locale]?.[key] ?? key;
    },
    [translations, locale]
  );

  return (
    <TranslationContext.Provider
      value={{
        locale,
        t,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
