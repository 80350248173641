import { useContext } from "react";
import { VKLContext, VKLData } from "../context/VKLContext";

export default function useVKLData() {
  const vklData = useContext(VKLContext);

  // Compatibility
  const { excelLink } = vklData;

  const compatiblityVklData: VKLData = {
    ...vklData,
    vendor: "daimler",
    excelLinks: [
      ...(excelLink
        ? [
            {
              name: `Übersicht Lose 1-${vklData.lose.length} VKL${vklData.vkl}`,
              file: excelLink,
            },
          ]
        : []),
    ],
  };

  return compatiblityVklData;
}
