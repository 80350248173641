import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  makeStyles
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import SimpleSchema from "simpl-schema";
import muiOptions from "reacto-form/cjs/muiOptions";
import muiCheckboxOptions from "reacto-form/cjs/muiCheckboxOptions";
import CountryOptions from "../../../../utils/CountryOptions";
import { Button, TextField } from "@reactioncommerce/catalyst";
import useProduct from "../../../included/product-admin/hooks/useProduct";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(4)
  }
}));

const formSchema = new SimpleSchema({
  serialNumber: {
    type: String,
    optional: true
  },
  cdsType: {
    type: String,
    allowedValues: ["primus", "reveno", "reveno_plus", "outlet", "ultimus"],
    optional: true
  },
  isMakeAnOffer: {
    type: Boolean,
    optional: true
  },
  title: {
    type: String,
    optional: true
  },
  optionTitle: {
    type: String,
    optional: true
  },
  attributeLabel: {
    type: String,
    optional: true
  },
  originCountry: {
    type: String,
    optional: true
  },
  length: {
    type: Number,
    optional: true
  },
  width: {
    type: Number,
    optional: true
  },
  height: {
    type: Number,
    optional: true
  },
  weight: {
    type: Number,
    optional: true
  }
});

const validator = formSchema.getFormValidator();

/**
 * @name VariantDetailForm
 * @param {Object} props Component props
 * @param {Object} ref Forwarded ref
 * @returns {React.Component} Variant form React component
 */
const VariantDetailForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("ns1");

  const {
    onUpdateProductVariant,
    product,
    variant,
    isLoading,
    option
  } = useProduct();

  const editingVariant = option || variant;

  const {
    getFirstErrorMessage,
    getInputProps,
    hasErrors,
    isDirty,
    submitForm
  } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      await onUpdateProductVariant({
        variantId: editingVariant._id,
        variant: formSchema.clean(formData)
      });

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
    value: editingVariant
  });

  const originCountryInputProps = getInputProps("originCountry", muiOptions);
  const cdsTypeInputProps = getInputProps("cdsType", muiOptions);

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={t("admin.productAdmin.details")} />
      <CardContent>
        { isLoading ?
          Array.apply(null, { length: 5 }).map((element, index) => {
            return (
              <div key={index}>
                  <Skeleton height={20} width="40%" />
                  <Skeleton height={40} width="80%" style={{ marginBottom: 20 }} />
              </div>
            );
          })
        :
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <TextField
            className={classes.textField}
            error={hasErrors(["serialNumber"])}
            fullWidth
            // helperText={getFirstErrorMessage(["serialNumber"]) || t("admin.helpText.serialNumber")}
            label={t("productVariant.serialNumberLabel")}
            placeholder={t("admin.productVariant.serialNumberPlaceholder")}
            {...getInputProps("serialNumber", muiOptions)}
            disabled
          />

          <TextField
            className={classes.textField}
            error={hasErrors(["attributeLabel"])}
            fullWidth
            helperText={getFirstErrorMessage(["attributeLabel"]) || t("admin.helpText.attributeLabel")}
            label={t("productVariant.attributeLabelLabel")}
            placeholder={t("productVariant.attributeLabelPlaceholder")}
            {...getInputProps("attributeLabel", muiOptions)}
          />

          {/*
          <TextField
            className={classes.textField}
            error={hasErrors(["optionTitle"])}
            fullWidth
            helperText={getFirstErrorMessage(["optionTitle"]) || t("admin.helpText.optionTitle")}
            label={t("productVariant.optionTitle")}
            placeholder={t("admin.productVariant.optionTitlePlaceholder")}
            {...getInputProps("optionTitle", muiOptions)}
          />
          */}

          <TextField
            className={classes.textField}
            error={hasErrors(["title"])}
            fullWidth
            helperText={getFirstErrorMessage(["title"]) || t("admin.helpText.title")}
            label={t("productVariant.title")}
            placeholder={t("admin.productVariant.titlePlaceholder")}
            {...getInputProps("title", muiOptions)}
          />

          <TextField
            className={classes.textField}
            error={hasErrors(["cdsType"])}
            fullWidth
            helperText={getFirstErrorMessage(["cdsType"])}
            label={t("productDetailEdit.cdsType")}
            onKeyPress={(event) => {
              if (event.key === "Enter") submitForm();
            }}
            select
            {...cdsTypeInputProps}
            value={cdsTypeInputProps.value || ""}
          >
            <MenuItem key="reveno" value="reveno">
                Reveno
            </MenuItem>
            <MenuItem key="primus" value="reveno_plus">
                Reveno+
            </MenuItem>
            <MenuItem key="reveno" value="primus">
                Primus
            </MenuItem>
            <MenuItem key="primus" value="outlet">
                Outlet
            </MenuItem>
            <MenuItem key="primus" value="ultimus">
                Ultimus
            </MenuItem>
          </TextField>

          <TextField
            className={classes.textField}
            error={hasErrors(["originCountry"])}
            fullWidth
            helperText={getFirstErrorMessage(["originCountry"])}
            label={t("productDetailEdit.originCountry")}
            onKeyPress={(event) => {
              if (event.key === "Enter") submitForm();
            }}
            select
            {...originCountryInputProps}
            value={originCountryInputProps.value || ""}
          >
            {CountryOptions.map((countryOption) => (
              <MenuItem key={countryOption.value} value={countryOption.value}>
                {countryOption.label}
              </MenuItem>
            ))}
          </TextField>
          <Box paddingBottom={4}>
            <Divider />
          </Box>
          {/*
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["width"])}
                fullWidth
                helperText={getFirstErrorMessage(["width"])}
                label={t("productVariant.width")}
                placeholder="0"
                {...getInputProps("width", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["length"])}
                fullWidth
                helperText={getFirstErrorMessage(["length"])}
                label={t("productVariant.length")}
                placeholder="0"
                {...getInputProps("length", muiOptions)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["height"])}
                fullWidth
                helperText={getFirstErrorMessage(["height"])}
                label={t("productVariant.height")}
                placeholder="0"
                {...getInputProps("height", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className={classes.textField}
                error={hasErrors(["weight"])}
                fullWidth
                helperText={getFirstErrorMessage(["v"])}
                label={t("productVariant.weight")}
                placeholder="0"
                {...getInputProps("weight", muiOptions)}
              />
            </Grid>
          </Grid>
          */}

        <FormControlLabel
          control={<Checkbox />}
          label="Für Make an Offer verfügbar"
          {...getInputProps("isMakeAnOffer", muiCheckboxOptions)}
        />

          <Box textAlign="right">
            <Button
              color="primary"
              disabled={!product || !isDirty || isSubmitting}
              isWaiting={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t("app.saveChanges")}
            </Button>
          </Box>
        </form>
        }
      </CardContent>
    </Card>
  );
});

export default VariantDetailForm;
