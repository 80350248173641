import gql from "graphql-tag";

export const accountFragment = gql`
  fragment Account on Account {
    _id
    name
    company
    firstName
    lastName
    primaryEmailAddress
    confirmedEmail
    isActivated
    isFamilyAndFriends
    invoicePaymentAllowed
    addressBook {
      nodes {
        _id
        fullName
        address1
        address2
        city
        country
        phone
        postal
        region
      }
    }
    invoiceAddress {
      fullName
      firstName
      lastName
      address1
      address2
      street
      houseNumber
      city
      country
      phone
      postal
      region
    }
    updatedAt
    vatId
    datevId
    createdAt
    currency {
      _id
      code
      decimal
      format
      rate
      scale
      symbol
      thousand
    }
    emailRecords {
      address
      provides
      verified
    }
    language
    metafields {
      key
      value
    }
    name
    note
    preferences
  }
`;
