import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql`
    query accountShippingAddress($accountId: ID!, $addressId:ID!){
        shippingAddress(accountId: $accountId, addressId: $addressId) {
            _id
            fullName
            address1
            address2
            city
            country
            phone
            postal
            region
        }
    }
`;
