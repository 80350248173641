import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql`
    query userAccount($accountId: ID!) {
        account(id: $accountId) {
            ...Account
        }
    }
    ${accountFragment}
`;
