import React, { Fragment } from "react";
import { MjmlText } from "../../mjml-react";

export default function LegalNotesDaimler() {
  return (
    <Fragment>
      <MjmlText
        align="left"
        paddingTop="0px"
        fontWeight="500"
        fontSize="13px"
        padding="20px"
        color="#00334C"
      >
        Mit der Abgabe eines Gebotes erklären Sie sich bereit, im Falle des
        Gewinns das Excel File mit ihren Einzelpreisen für die Weiterleitung an
        Daimler zu übermitteln.
      </MjmlText>

      <MjmlText
        align="left"
        paddingTop="0px"
        fontSize="13px"
        padding="20px"
        paddingBottom="0"
        color="#00334C"
      >
        Alle Produkte wurden von LGI geprüft und für den Verkauf vorbereitet.
        Sie werden auf Paletten ohne Einzelverpackung geliefert. Eine
        Beschreibung des Aufbereitungsprozesses sowie der Verpackungsstandards
        finden Sie in folgender Datei:
      </MjmlText>

      <MjmlText
        align="left"
        paddingTop="0px"
        fontSize="13px"
        padding="20px"
        color="#00B8F1"
      >
        <a
          href="https://mall.remarket-now.com/backoffice2/api/cms/cds/Prozess_LGI_Daimler.pdf"
          style={{ color: "#00b8f1", textDecoration: "underline" }}
          target="_blank"
        >
          Prozess LGI Daimler
        </a>
        |
        <a
          href="https://mall.remarket-now.com/backoffice2/api/cms/cds/LGI%20Kategorien.pdf"
          style={{ color: "#00b8f1", textDecoration: "underline" }}
          target="_blank"
        >
          Kategorieübersicht
        </a>
      </MjmlText>

      <MjmlText
        align="left"
        paddingTop="0px"
        fontSize="13px"
        padding="20px"
        paddingBottom="0"
        color="#00334C"
      >
        <strong>
          Verkauf / Versteigerung erfolgt ausschließlich unter Ausschluss der
          Gewährleistung.
        </strong>
        <br />
        Die original Datenblätter bzw. Gebrauchsanweisungen finden Sie zum
        Download auf den Herstellerseiten.
        <br />
        Die Lieferung erfolgt ab Werk LGI Herrenberg-Gültstein.
        <br />
        Selbstabholung ist auf Anfrage möglich.
      </MjmlText>
    </Fragment>
  );
}
