import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { Query, withApollo } from "react-apollo";
import ProductSpecForm from "../components/table/ProductSpecForm";
import productSpecQuery from "../graphql/queries/productSpec";

class ProductSpecFormPageWithData extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        productSpecId: PropTypes.string
      })
    }),
    productSpecId: PropTypes.string,
    shopId: PropTypes.string.isRequired
  }

  get productSpecId() {
    const { match } = this.props;
    return (match && match.params.productSpecId) || null;
  }

  handleCreate = (tag) => {
    this.props.history.push(`/product-specs/${tag._id}`);
  }

  handleCancel = () => {
    this.props.history.push("/product-specs");
  }

  render() {
    const { client, shopId } = this.props;

    // Id there's a tagId param, then try to find
    // that tag and render the edit form
    if (this.productSpecId) {
      return (
        <Query query={productSpecQuery} variables={{ productSpecId: this.productSpecId }} fetchPolicy="network-only">
          {({ data }) => {
            const productSpec = data && data.productSpec;

            // Render the edit tag form
            return (
              <ProductSpecForm
                client={client}
                shopId={shopId}
                productSpec={productSpec}
                onCancel={this.handleCancel}
              />
            );
          }}
        </Query>
      );
    }

    // Render the create tag form
    return (
      <ProductSpecForm
        client={client}
        shopId={shopId}
        onCancel={this.handleCancel}
        onCreate={this.handleCreate}
      />
    );
  }
}

export default compose(
  withApollo,
  withRouter
)(ProductSpecFormPageWithData);
