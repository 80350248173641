import { useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import useUndo from "../../shared/hooks/useUndo";
import headerSliderQuery from "../graphql/queries/headerSlider";
import publishHeaderSliderMutation from "../graphql/mutations/publishHeaderSlider";
import resetHeaderSliderMutation from "../graphql/mutations/resetHeaderSlider";
import updateHeaderSliderMutation from "../graphql/mutations/updateHeaderSlider";
import useDebouncedCallback from "../../shared/useDataTable-custom/useDebouncedCallback";

function useMainSlider(locale) {
  const history = useHistory();
  const routeParams = useParams();
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const [
    headerSlider,
    {
      set: setTo,
      reset: resetTo,
      undo: undoFunc,
      redo: redoFunc,
      canUndo,
      canRedo,
    },
  ] = useUndo();

  const [
    publishHeaderSlider,
    { loading: publishHeaderSliderLoading },
  ] = useMutation(publishHeaderSliderMutation);
  const [
    resetHeaderSlider,
    { loading: resetHeaderSliderLoading },
  ] = useMutation(resetHeaderSliderMutation);
  const [
    updateHeaderSliderFunc,
    { loading: updateHeaderSliderLoading },
  ] = useMutation(updateHeaderSliderMutation);

  const {
    data: headerSliderQueryResult,
    loading: isHeaderSliderLoading,
    refetch: refetchHeaderSlider,
  } = useQuery(headerSliderQuery, {
    variables: {
      input: {
        locale: locale,
        isPreview: true,
      },
    },
  });

  const updateHeaderSlider = useCallback(async () => {
    await updateHeaderSliderFunc({
      variables: {
        input: {
          _id: headerSlider.present._id,
          slides: headerSlider.present.slides.map((slide) => {
            const { __typename, ...slideData } = slide;
            return slideData;
          }),
        },
      },
    });
    setHasPendingChanges(false);
  }, [updateHeaderSliderFunc, setHasPendingChanges, headerSlider]);

  const [updateHeaderSliderDebounced] = useDebouncedCallback(() => {
    updateHeaderSlider();
  }, 3000);

  const set = useCallback(
    (value) => {
      setHasPendingChanges(true);
      updateHeaderSliderDebounced();
      setTo(value);
    },
    [setTo]
  );

  const undo = useCallback(() => {
    setHasPendingChanges(true);
    updateHeaderSliderDebounced();
    undoFunc();
  }, [undoFunc]);

  const redo = useCallback(() => {
    setHasPendingChanges(true);
    updateHeaderSliderDebounced();
    redoFunc();
  }, [redoFunc]);

  useEffect(() => {
    if (headerSliderQueryResult && headerSliderQueryResult.headerSlider) {
      // If initial, reset to move over blank
      if (!headerSlider.present) {
        resetTo(headerSliderQueryResult.headerSlider);
      } else {
        setTo(headerSliderQueryResult.headerSlider);
      }
    }
  }, [headerSliderQueryResult]);

  const setHeaderField = useCallback(
    (field, arrayIndex, value) => {
      set({
        ...headerSlider.present,
        slides: Object.assign([], headerSlider.present.slides, {
          [arrayIndex]: {
            ...headerSlider.present.slides[arrayIndex],
            [field]: value,
          },
        }),
      });
    },
    [set, headerSlider.present]
  );

  const removeHeaderSlide = useCallback(
    (deleteIndex) => {
      set({
        ...headerSlider.present,
        slides: headerSlider.present.slides.filter(
          (slide, index) => index !== deleteIndex
        ),
      });
    },
    [set, headerSlider.present]
  );

  const addHeaderSlide = useCallback(() => {
    set({
      ...headerSlider.present,
      slides: [...headerSlider.present.slides, {}],
    });
  }, [set, headerSlider.present]);

  const reset = useCallback(() => {
    resetTo(headerSliderQueryResult.headerSlider);
  }, [resetTo, headerSliderQueryResult]);

  return {
    isHeaderSliderLoading,
    headerSlider: headerSlider.present,
    refetchHeaderSlider,
    setHeaderField,
    removeHeaderSlide,
    addHeaderSlide,
    undoState: {
      set,
      reset,
      undo,
      redo,
      canUndo,
      canRedo,
    },
    isSaving:
      publishHeaderSliderLoading |
      resetHeaderSliderLoading |
      updateHeaderSliderLoading,
    hasPendingChanges,
    publishHeaderSlider: async () => {
      await publishHeaderSlider({
        variables: {
          input: {
            _id: headerSlider.present._id,
          },
        },
      });
    },
    resetHeaderSlider: async () => {
      await resetHeaderSlider({
        variables: {
          input: {
            _id: headerSlider.present._id,
          },
        },
      });
      resetTo(headerSliderQueryResult);
    },
    updateHeaderSlider,
  };
}

export default useMainSlider;
