import gql from "graphql-tag";
import { b4bLosListingFragment } from "../fragments/b4bLosListing";

export default gql`
query b4bLosListing($shopId: ID!, $id: ID!) {
  b4bLosListing(shopId: $shopId, id: $id) {
    ...B4BLosListing
  }
}
${b4bLosListingFragment}
`;
