import gql from "graphql-tag";

export const b4bLosListingFragment = gql`
  fragment B4BLosListing on B4BLosListing {
    _id
    vkl
    kw
    year
    startDate
    startDateNumeric
    endDate
    endDateWeekday
    endTime
    excelLink
    createdAt
    updatedAt
    isDeleted
    lose {
      number
      vendor
      manufactor
      manufactor_short
      type
      type_short
      type_short_category
      amount
      palette_amount
      sumCalculated
      customCalculation
      countedAsPalettes
      url
      hasAttachment
      products {
        type
        vendors {
          name
          categories {
            name
            amount
          }
        }
      }
      endTime
      soldAmount
      minimumAmount
      notes
    }
    zubehoer {
      number
      vendor
      manufactor
      manufactor_short
      type
      type_short
      type_short_category
      amount
      palette_amount
      sumCalculated
      customCalculation
      countedAsPalettes
      url
      hasAttachment
      products {
        name
        amount
      }
      endTime
      soldAmount
      minimumAmount
      notes
    }
  }
`;
