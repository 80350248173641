import React from "react";
import { registerOperatorRoute } from "../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import ProductLinesTable from "./components/ProductLinesTable";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  isProductMetadata: true,
  mainComponent: ProductLinesTable,
  path: "/product-lines",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <FontAwesomeIcon icon={faCalculator} {...props} />,
  sidebarI18nLabel: "admin.dashboard.productLinesLabel"
});
