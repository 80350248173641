import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Chip from "@reactioncommerce/catalyst/Chip";
import { Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(2)
  }
}));

/**
 * @name OfferStatusCell
 * @param {Object} row A react-table row object
 * @param {Object} history Router history API
 * @return {React.Component} A date component
 */
export default function OfferStatusCell({ value }) {
  const classes = useStyles();
  const { t } = useTranslation("ns1");

  let chipColor;
  switch (value) {
    case "offerAccepted":
    case "counterOfferAccepted":
      chipColor = "success";
      break;
    case "offer":
    case "counterOffer":
      chipColor = "info";
      break;
    case "offerDeclined":
    case "counterOfferDeclined":
      chipColor = "danger";
      break;
    default:
      chipColor = "info";
      break;
  }

  return (
    <Chip
      className={classes.chip}
      color={chipColor}
      size="small"
      label={t(`admin.table.offerStatus.${value}`)}
    />
  );
}

OfferStatusCell.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  row: PropTypes.object.isRequired
};
