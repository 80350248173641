import gql from "graphql-tag";
import { makeAnOfferEntryFragment } from "../fragments/makeAnOfferEntry";

export default gql`
mutation sendCounterOffer($input: UpdateOfferWithCounterOfferInput!) {
  sendCounterOffer(input: $input) {
    makeAnOfferEntry {
        ...MakeAnOfferEntry
      }
    }
}
${makeAnOfferEntryFragment}
`;
