import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import MessageList from "./MessageList";

// import MakeAnOfferForm from "./MakeAnOfferForm";
// import MakeAnOfferSpecs from "./MakeAnOfferSpecs";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  saveButton: {
    alignSelf: "flex-end"
  }
});

/**
 * @name MakeAnOffer
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
function MakeAnOffer(props) {
  const { classes, makeAnOfferEntry, makeAnOfferEntryMessages, setMakeAnOfferEntry, shopId, saveMakeAnOfferEntry, pendingChanges } = props;
  const { isCompleted = false } = makeAnOfferEntry;
  const { t } = useTranslation("ns1");

  return (
    <div className={classes.container}>
      <Helmet title={`MakeAnOffer Details for MakeAnOffer with id #${makeAnOfferEntry._id}`} />
      {/* <BundleComponentAppBar makeAnOfferEntry={makeAnOfferEntry} /> */}

      {/* <MakeAnOfferForm shopId={shopId} makeAnOfferEntry={makeAnOfferEntry} /> */}
      {/* <MakeAnOfferSpecs shopId={shopId} makeAnOfferEntry={makeAnOfferEntry} setMakeAnOfferEntry={setMakeAnOfferEntry} /> */}
      <MessageList
        isAdminView
        className="message-list"
        lockable={true}
        toBottomHeight={"100%"}
        dataSource={makeAnOfferEntryMessages}
      />
    </div>
  );
}

MakeAnOffer.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.object
};

export default withStyles(styles, { name: "MakeAnOffer" })(MakeAnOffer);
