import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { getRequiredValidator } from "@reactioncommerce/components/utils";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "reacto-form";
import Button from "@reactioncommerce/components/Button/v1";
import Checkbox from "@reactioncommerce/components/Checkbox/v1";
import ErrorsBlock from "@reactioncommerce/components/ErrorsBlock/v1";
import Field from "@reactioncommerce/components/Field/v1";
import TextInput from "@reactioncommerce/components/TextInput/v1";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { makeStyles, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import MUICardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import AddIcon from "mdi-material-ui/Plus";
import DeleteIcon from "mdi-material-ui/CloseCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SpecsIcon from "mdi-material-ui/FormatListBulleted";
import SpecGroupIcon from "mdi-material-ui/ViewList";
import i18next from "i18next";

import productClassesQuery from "../../graphql/queries/productClasses";
import productSpecsQuery from "../../../product-specifications/graphql/queries/productSpecs";
import productSpecGroupsQuery from "../../../product-specification-groups/graphql/queries/productSpecGroups";
import addProductClassMutation from "../../graphql/mutations/addProductClass";
import updateProductClassMutation from "../../graphql/mutations/updateProductClass";
import QuerySelectReturnIdDialog from "../../../shared/components/Dialogs/QuerySelectReturnIdDialog";

import useProductSpecsAndSpecGroups from "../../../shared/hooks/useProductSpecsAndSpecGroups";


const CardActions = withStyles({
  root: {
    justifyContent: "flex-end",
    paddingRight: 0
  }
})(MUICardActions);

const PaddedField = withStyles({
  root: {
    marginBottom: 30
  }
})(Field);

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  contentGroup: {
    marginBottom: 30
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  specContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap"
  },
  specElementGreen: {
    cursor: "grabbing",
    fontSize: "1em",
    margin: "10px 5px",
    borderColor: theme.palette.cdsGreen.main,
    color: theme.palette.cdsGreen.main
  },
  specElement: {
    cursor: "grabbing",
    fontSize: "1em",
    margin: "10px 5px"
  },
  addIconButton: {
    padding: "12px 14px"
  },
  menuItem: {},
  menuItemIcon: {},
  menuItemPrimary: {},
  addTextField: {},
  dialogPaper: {
    overflow: "visible"
  }
}));

function ProductClassForm({
  shopId,
  productClass,
  handleAddSpecification,
  handleAddSpecificationGroup,
  handleAddSpecificationPreview,
  handleAddSpecificationGroupPreview,
  handleDeleteSpecification,
  handleDeleteSpecificationPreview,
  onRelatedSpecificationsSortEnd,
  onRelatedSpecificationsPreviewSortEnd,
  onCreate,
  onUpdate
}) {
  const classes = useStyles();

  const [ currentTab, setCurrentTab ] = useState(0);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ anchorElPreview, setAnchorElPreview ] = useState(null);
  const [ addSpecificationDialogOpen, setAddSpecificationDialogOpen ] = useState(false);
  const [ addSpecificationGroupDialogOpen, setAddSpecificationGroupDialogOpen ] = useState(false);
  const [ addSpecificationDialogPreviewOpen, setAddSpecificationDialogPreviewOpen ] = useState(false);
  const [ addSpecificationGroupDialogPreviewOpen, setAddSpecificationGroupDialogPreviewOpen ] = useState(false);

  let formValue = null;

  const uniqueInstanceIdentifier = uniqueId("URLRedirectEditForm");

  const handleSubmit = async (data, mutation) => {
    const isNew = !data._id;

    const refetchQueries = [{
      query: productClassesQuery,
      variables: {
        shopId
      }
    }];

    const relatedSpecificationsWithType = productClass ? productClass.relatedSpecifications : [];
    const relatedSpecificationsPreviewWithType = productClass ? productClass.relatedSpecificationsPreviewWithType : [];

    const relatedSpecifications = relatedSpecificationsWithType && relatedSpecificationsWithType.map(({ __typename, ...item }) => item) || [];
    const relatedSpecificationsPreview = relatedSpecificationsPreviewWithType && relatedSpecificationsPreviewWithType.map(({ __typename, ...item }) => item) || [];

    const input = {
      id: data._id,
      name: data.name,
      unit: data.unit,
      relatedSpecifications,
      relatedSpecificationsPreview,
      isVisible: data.isVisible || false,
      shopId,
      heroMediaUrl: data.heroMediaUrl
    };

    /* needsFix
    if (!isNew) {
      // On update, refetch featured products
      refetchQueries.push({
        query: tagProductsQuery,
        variables: {
          shopId,
          tagId: data._id
        }
      });
    }
    */

    const result = await mutation({
      refetchQueries,
      variables: {
        input
      }
    });

    if (result.data.addProductClass) {
      onCreate(result.data.addProductClass.productClass);
    } else {
      onUpdate(result.data.updateProductClass.productClass);
    }

    return result;
  }

  const handleFormChange = (value) => {
    formValue = value;
  }

  const handleDeleteSpecFromGroup = (value, scope) => {
    const { _id, position } = value;

    if (scope === "relatedSpecifications") {
      handleDeleteSpecification(position, _id);
    }

    if (scope === "relatedSpecificationsPreview") {
      handleDeleteSpecificationPreview(position, _id);
    }
  };

  const nameInputId = `name_${uniqueInstanceIdentifier}`;
  const isVisibleInputId = `isVisible_${uniqueInstanceIdentifier}`;

  let title = i18next.t("admin.productClasses.form.formTitleNew");
  let mutation = addProductClassMutation;

  if (productClass && productClass._id) {
    title = i18next.t("admin.productClasses.form.formTitleUpdate");
    mutation = updateProductClassMutation;
  }

  const [mutationFunc] = useMutation(mutation);
  const [relatedSpecifications, relatedSpecificationsPreview] = useProductSpecsAndSpecGroups({ shopId, productClass });

  const SortableTableItem = SortableElement(({ value, scope }) => {
    const { type, name, _id, unit, specifications } = value;
    const color = type === "specification" && "secondary" || "primary";
    const label = name;

    return (
      <TableRow key={value.id}>
        <TableCell>
          <Chip
            key={value.id}
            label={label}
            color={color}
            variant="outlined"
            onDelete={() => handleDeleteSpecFromGroup(value, scope)}
            deleteIcon={<DeleteIcon/>}
            className={type === "specificationGroup" && classes.specElementGreen || classes.specElement}
          />
        </TableCell>
        <TableCell>
          { (type === "specification") ?
            unit || "-"
            :
            Array.isArray(specifications) && specifications.map((specification, index) => (
              <Chip
                key={`SpecGroupSpec-item-${index}`}
                label={specification.name || specification.content}
                color={specification.name && "secondary" || "primary"}
                variant="outlined"
                className={classes.specElement}
              />
            ))
          }
        </TableCell>
      </TableRow>
    );
  });

  const SortableTableList = SortableContainer(({ items, itemKey, scope }) => (
    <TableBody>
      {Array.isArray(items) && items.map((value, index) => (
        <SortableTableItem scope={scope} key={`${itemKey}-item-${index}`} index={index} value={value} />
      ))}
    </TableBody>
  ));

  return (
    <Fragment>
      <QuerySelectReturnIdDialog
        isOpen={addSpecificationDialogOpen}
        onSubmit={(selectedId) => {
          handleAddSpecification(selectedId);
          setAddSpecificationDialogOpen(false);
        }}
        onClose={() => setAddSpecificationDialogOpen(false)}
        query={productSpecsQuery}
        queryData="productSpecs"
        title="Spezifikation hinzufügen"
        shopId={shopId}
      />
      <QuerySelectReturnIdDialog
        isOpen={addSpecificationGroupDialogOpen}
        onSubmit={(selectedId) => {
          handleAddSpecificationGroup(selectedId);
          setAddSpecificationGroupDialogOpen(false);
        }}
        onClose={() => setAddSpecificationGroupDialogOpen(false)}
        query={productSpecGroupsQuery}
        queryData="productSpecGroups"
        title="Spezifikationsgruppe hinzufügen"
        shopId={shopId}
      />
      <QuerySelectReturnIdDialog
        isOpen={addSpecificationDialogPreviewOpen}
        onSubmit={(selectedId) => {
          handleAddSpecificationPreview(selectedId);
          setAddSpecificationDialogPreviewOpen(false);
        }}
        onClose={() => setAddSpecificationDialogPreviewOpen(false)}
        query={productSpecsQuery}
        queryData="productSpecs"
        title="Spezifikation hinzufügen"
        shopId={shopId}
      />
      <QuerySelectReturnIdDialog
        isOpen={addSpecificationGroupDialogPreviewOpen}
        onSubmit={(selectedId) => {
          handleAddSpecificationGroupPreview(selectedId);
          setAddSpecificationGroupDialogPreviewOpen(false);
        }}
        onClose={() => setAddSpecificationGroupDialogPreviewOpen(false)}
        query={productSpecGroupsQuery}
        queryData="productSpecGroups"
        title="Spezifikationsgruppe hinzufügen"
        shopId={shopId}
      />

      <Fragment>
        <h3 className={classes.title}>{title}</h3>
          <Form
            // ref={(formRef) => { form = formRef; }}
            onChange={handleFormChange}
            onSubmit={(data) => handleSubmit(data, mutationFunc)}
            validator={getRequiredValidator("name")}
            value={productClass}
          >
            <div className={classes.contentGroup}>
              <PaddedField
                name="name"
                label={i18next.t("admin.productSpecs.form.name")}
                labelFor={nameInputId}
                isRequired
              >
                <TextInput id={nameInputId} name="name" placeholder={i18next.t("admin.productSpecs.form.specNamePlaceholder")} />
                <ErrorsBlock names={["name"]} />
              </PaddedField>
            </div>

            <div className={classes.contentGroup}>
              <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}>
                <Tab label={i18next.t("admin.productSpecs.form.productSpecDetails")} />
                {/* <Tab label={i18next.t("admin.productSpecs.form.productSpecImage")} /> */}
              </Tabs>
              <Divider />
            </div>
                {currentTab === 0 &&
                <Fragment>
                  <Card className={classes.card}>
                    <CardHeader title={i18next.t("admin.productClasses.headers.productSpecs")} />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <IconButton
                            aria-label="More"
                            aria-haspopup="true"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            className={classes.addIconButton}
                          >
                            <AddIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            keepMounted
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setAnchorEl(null);
                                setAddSpecificationDialogOpen(true);
                              }}>
                              <ListItemIcon className={classes.menuItemIcon}>
                                <SpecsIcon />
                              </ListItemIcon>
                              <ListItemText classes={{ primary: classes.menuItemPrimary }} inset primary="Spezifikation" />
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setAnchorEl(null);
                                setAddSpecificationGroupDialogOpen(true);
                              }}>
                              <ListItemIcon className={classes.menuItemIcon}>
                                <SpecGroupIcon />
                              </ListItemIcon>
                              <ListItemText classes={{ primary: classes.menuItemPrimary }} inset primary="Spezifikationsgruppe" />
                            </MenuItem>
                          </Menu>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Details</TableCell>
                              </TableRow>
                            </TableHead>
                            <SortableTableList
                              scope="relatedSpecifications"
                              items={relatedSpecifications && relatedSpecifications.sort((a, b) => a.position - b.position)}
                              itemKey={"relatedSpecificationsTable"}
                              onSortEnd={onRelatedSpecificationsSortEnd}
                              distance={5}
                              axis="y"
                            />
                          </Table>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className={classes.card}>
                    <CardHeader title={i18next.t("admin.productClasses.headers.productSpecsPreview")} />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <IconButton
                            aria-label="More"
                            aria-haspopup="true"
                            onClick={(event) => setAnchorElPreview(event.currentTarget)}
                            className={classes.addIconButton}
                          >
                            <AddIcon />
                          </IconButton>
                          <Menu
                            id="long-menu-preview"
                            anchorEl={anchorElPreview}
                            open={Boolean(anchorElPreview)}
                            onClose={() => setAnchorElPreview(null)}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setAnchorElPreview(null);
                                setAddSpecificationDialogPreviewOpen(true);
                              }}>
                              <ListItemIcon className={classes.menuItemIcon}>
                                <SpecsIcon />
                              </ListItemIcon>
                              <ListItemText classes={{ primary: classes.menuItemPrimary }} inset primary="Spezifikation" />
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => {
                                setAnchorElPreview(null);
                                setAddSpecificationGroupDialogPreviewOpen(true);
                              }}
                            >
                              <ListItemIcon className={classes.menuItemIcon}>
                                <SpecGroupIcon />
                              </ListItemIcon>
                              <ListItemText classes={{ primary: classes.menuItemPrimary }} inset primary="Spezifikationsgruppe" />
                            </MenuItem>
                          </Menu>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Details</TableCell>
                              </TableRow>
                            </TableHead>
                            <SortableTableList
                              scope="relatedSpecificationsPreview"
                              items={relatedSpecificationsPreview && relatedSpecificationsPreview.sort((a, b) => a.position - b.position)}
                              itemKey={"relatedSpecificationsPreviewTable"}
                              onSortEnd={onRelatedSpecificationsPreviewSortEnd}
                              distance={5}
                              axis="y"
                            />
                          </Table>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <PaddedField
                            name="isVisible"
                            labelFor={isVisibleInputId}
                          >
                            <Checkbox
                              id={isVisibleInputId}
                              name="isVisible"
                              label={i18next.t("admin.productClasses.form.isVisible") || ""}
                            />
                          </PaddedField>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Fragment>
                }

                { /*currentTab === 1 &&
                   <Card className={classes.card}>
                     <CardContent>
                       <Grid container spacing={3}>
                         <Grid item md={12}>
                           <Typography variant="h6">{i18next.t("admin.productSpecs.form.productSpecImageTitle")}</Typography>
                           <Typography>{i18next.t("admin.productSpecs.form.productSpecImageHelpText")}</Typography>
                         </Grid>
                       </Grid>
                     </CardContent>
                   </Card>
                */}

                <Card className={classes.card}>
                  <CardContent>
                    <CardActions disableSpacing>
                      <Button actionType="secondary" /*onClick={}*/ >
                        {i18next.t("admin.tags.form.save")}
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>
              </Form>
            </Fragment>
      </Fragment>
    );
}

ProductClassForm.defaultProps = {
  onCancel() {},
  onCreate() {},
  onUpdate() {}
}

export default ProductClassForm;
