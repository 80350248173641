import gql from "graphql-tag";
import { bundleComponentFragment } from "../fragments/bundleComponent";

export default gql`
mutation updateBundleComponentMutation($input: UpdateBundleComponentInput!) {
    updateBundleComponent(input: $input) {
      bundleComponent {
        ...BundleComponent
      }
    }
}
${bundleComponentFragment}
`;
