import gql from "graphql-tag";
import { productSpecFragment } from "../fragments/productSpec";

export default gql`
  mutation updateProductSpecMutation($input: UpdateProductSpecInput!) {
    updateProductSpec(input: $input) {
      productSpec {
        ...ProductSpec
      }
    }
  }
  ${productSpecFragment}
`;
