import React, { useMemo, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import { useSnackbar } from "notistack";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import InlineAlert from "@reactioncommerce/components/InlineAlert/v1";
import { Grid, Box, Checkbox, Card, CardHeader, CardContent, Button, TextField, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "mdi-material-ui/Close";
import { Link } from "@material-ui/core";
import DateCell from "../../shared/useDataTable-custom/TableCells/DateCell";
import IdCell from "../../shared/useDataTable-custom/TableCells/IdCell";
import BarcodeCell from "../../shared/useDataTable-custom/TableCells/BarcodeCell";
import PrimaryImageCell from "./TableCells/PrimaryImageCell";
import YesNoCell from "../../shared/useDataTable-custom/TableCells/YesNoCell";
import PublishedYesNoCell from "./TableCells/PublishedYesNoCell";
import productsQuery from "../graphql/queries/products";
import createProductMutation from "../graphql/mutations/createProduct";
import publishProductsToCatalog from "../graphql/mutations/publishProductsToCatalog";
import archiveProducts from "../graphql/mutations/archiveProducts";
import updateProduct from "../graphql/mutations/updateProduct";
import { withRouter } from "react-router";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import { useDropzone } from "react-dropzone";
import FilterByFileCard from "./FilterByFileCard";
import TagSelector from "./TagSelector";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible"
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0
  },
  buttonAsc: {
    borderTop: "2px solid"
  },
  buttonDesc: {
    borderBottom: "2px solid"
  }
}));

const CSV_FILE_TYPES = [
  "text/csv",
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values"
];

/**
 * @name ProductTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function ProductTable({ history }) {
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [createProduct, { error: createProductError }] = useMutation(createProductMutation);
  const [totalProducts, setTotalProducts] = useState(0);

  // Core Utility Cards
  const [files, setFiles] = useState([]);
  const [isFiltered, setFiltered] = useState(false);
  const [isFilterByFileVisible, setFilterByFileVisible] = useState(false);
  const [filterByProductIds, setFilterByProductIds] = useState(null);

  const [isTagSelectorVisible, setTagSelectorVisible] = useState(false);
  const [noProductsFoundError, setNoProductsFoundError] = useState(false);

  const [fieldToFilter, setFieldToFilter] = useState("internalProductId");

  const productTableState = JSON.parse(localStorage.getItem('productTable'));
  const initialState = { hiddenColumns: ["_id", "productDescription", "description"], ...productTableState }

  const { t } = useTranslation("ns1");

  const [shopId] = useCurrentShopId();
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onPoductClick = useCallback(async (row) => {
    if (row.values.type === "bundle") {
      history.push(`/products-cto/${row.values._id}`);
    } else {
      history.push(`/products/${row.values._id}`);
    }
  }, [history]);

  // Create and memoize the column data
  const columns = useMemo(() => [
    {
      id: "selection",
      disableSorting: true,
      cellProps: {
        // Disables the cell click if the row is clickable
        // This is important if you have a callback for onRowClick, as the checkbox cell
        // will also trigger the row click.
        // Alternatively you can control the onClick with the following option
        // onClick: (event) => event.stopPropagation(),
        isClickDisabled: true,

        // All other props will be applied to the table cell.
        padding: "none"
      },
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox {...getToggleAllRowsSelectedProps()} />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Cell: ({ row }) => (
        <Checkbox
          {...row.getToggleRowSelectedProps()}
          title={`Toggle row selection for ${row.values.fullName}`}
        />
      )
    },
    {
      Header: "Bild",
      accessor: "primaryImage",
      disableSorting: true,
      Cell: ({ row, cell }) => <Link href="#" onClick={() => onPoductClick(row)}><PrimaryImageCell primaryImage={cell.value} /></Link>
    },
    {
      Header: "Titel",
      accessor: "title",
      Cell: ({ row, cell }) => <Link href="#" onClick={() => onPoductClick(row)}>{cell.value}</Link>
    },
    {
      Header: "Produktnummer",
      accessor: "productNumber",
      Cell: ({ row, cell }) => <Link href="#" onClick={() => onPoductClick(row)}>{cell.value}</Link>
      // Cell: ({ cell }) => <BarcodeCell value={cell.value} />
    },
    {
      Header: "Hersteller",
      accessor: "vendor",
      Filter: makeDataTableColumnFilter({
        isMulti: true,
        options: [
          { label: t("admin.table.vendor.hp"), value: "hp" },
          { label: t("admin.table.vendor.lg"), value: "lg" },
          { label: t("admin.table.vendor.samsung"), value: "samsung" },
          { label: t("admin.table.vendor.epson"), value: "epson" },
          { label: t("admin.table.vendor.kyocera"), value: "kyocera" },
          { label: t("admin.table.vendor.sharp"), value: "sharp" },
          { label: t("admin.table.vendor.cds"), value: "cds" }
        ]
      })
    },
    {
      // Header: "Offer ID",
      Header: "Product ID",
      accessor: "_id",
      Cell: ({ cell }) => <IdCell value={cell.value} />
    },
    {
      Header: "Datum",
      accessor: "createdAt",
      Cell: ({ cell }) => <DateCell value={cell.value} />
    },
    /*
    {
      Header: () => <Box textAlign="right">Originalpreis</Box>,
      Cell: ({ cell }) => <Box textAlign="right" style={{textDecoration: "line-through"}}>{cell.value} €</Box>,
      accessor: "currentPrice"
    },
    */
    {
      Header: "Preis",
      id: "priceRange",
      accessor: "priceRange.range"
    },
    {
      // Header: (data) => <Box textAlign="center">Produkttyp</Box>,
      Header: "Produkttyp",
      accessor: "type",
      Filter: makeDataTableColumnFilter({
        isMulti: true,
        options: [
          { label: t("admin.table.productType.simple"), value: "simple" },
          { label: t("admin.table.productType.limesProduct"), value: "limesProduct" },
          { label: t("admin.table.productType.limesDemoProduct"), value: "limesDemoProduct" },
          { label: t("admin.table.productType.bundle"), value: "bundle" }
        ]
      }),
      Cell: ({ cell }) => t(`admin.table.productType.${cell.value}`)
    },
    {
      Header: "Katalogbeschreibung",
      accessor: "description"
    },
    {
      Header: "Vertriebsbeschreibung",
      accessor: "salesDescription"
    },
    {
      Header: "Produktbeschreibung",
      accessor: "productDescription"
    },
    {
      // Header: (data) => <Box textAlign="center">Veröffentlicht</Box>,
      Header: "Veröffentlicht",
      accessor: "publishedProductHash",
      Filter: makeDataTableColumnFilter({
        options: [
          { label: t("admin.table.published"), value: "published" },
          { label: t("admin.table.unpublished"), value: "unpublished" }
        ]
      }),
      Cell: ({ row }) => <PublishedYesNoCell row={row} />
    },
    {
      Header: "Sichtbar",
      accessor: "isVisible",
      Cell: ({ cell }) => <YesNoCell value={cell.value} />
    }
  ], [t]);

  const onFetchData = useCallback(async ({ globalFilter, filters, pageIndex, pageSize, sortBy }) => {
    setIsLoading(true);
    if (!shopId) {
      return;
    }

    let filterObject = {};
    filters.forEach((filter) => {
      filterObject[filter.id] = filter.value;
    });

    let idFilters = {};
    if (fieldToFilter === "internalProductId" && filterByProductIds) {
      idFilters.internalProductIds = filterByProductIds;
    }
    if (fieldToFilter === "productId" && filterByProductIds) {
      idFilters.productIds = filterByProductIds;
    }
    if (fieldToFilter === "productNumber" && filterByProductIds) {
      idFilters.productNumbers = filterByProductIds;
    }
    if (fieldToFilter === "limesId" && filterByProductIds) {
      idFilters.limesIds = filterByProductIds;
    }

    const { data } = await apolloClient.query({
      query: productsQuery,
      variables: {
        shopIds: [shopId],
        first: pageSize,
        offset: pageIndex * pageSize,
        filters: {
          searchField: globalFilter,
          ...filterObject,
          ...idFilters
        },
        sortBy
      },
      fetchPolicy: "network-only"
    });

    // const { productsCustom: { totalCount, nodes } } = data;

    const totalCount = data && data.productsCustom && data.productsCustom.totalCount;
    const nodes = data && data.productsCustom && data.productsCustom.nodes;

    setTableData(nodes);
    setTotalProducts(totalCount);
    setPageCount(Math.ceil(totalCount / pageSize));
    setIsLoading(false);
  }, [apolloClient, setTableData, setPageCount, shopId, filterByProductIds]);

  // Row click callback
  const onRowClick = useCallback(async ({ row }) => {
    // const { id: decodedId } = decodeOpaqueId(row.values._id);

    if (row.values.type === "bundle") {
      history.push(`/products-cto/${row.values._id}`);
    } else {
      history.push(`/products/${row.values._id}`);
    }
  }, [history]);

  const onRowSelect = useCallback(async ({ selectedRows }) => {
    setSelectedRows(selectedRows || []);
  }, []);

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    // onRowClick,
    onRowSelect,
    initialState,
    getRowId: (row) => row._id
  });

  const { state: { hiddenColumns, sortBy, filters, pageIndex, pageSize, globalFilter } } = dataTableProps;

  const refetch = useCallback(
    async () => {
      setIsLoading(true);

      let filterObject = {};
      filters.forEach((filter) => {
        filterObject[filter.id] = filter.value;
      });

      let idFilters = {};
      if (fieldToFilter === "internalProductId" && filterByProductIds) {
        idFilters.internalProductIds = filterByProductIds;
      }
      if (fieldToFilter === "productId" && filterByProductIds) {
        idFilters.productIds = filterByProductIds;
      }
      if (fieldToFilter === "productNumber" && filterByProductIds) {
        idFilters.productNumbers = filterByProductIds;
      }
      if (fieldToFilter === "limesId" && filterByProductIds) {
        idFilters.limesIds = filterByProductIds;
      }

      const { data } = await apolloClient.query({
        query: productsQuery,
        variables: {
          shopIds: [shopId],
          first: pageSize,
          offset: pageIndex * pageSize,
          filters: {
            searchField: globalFilter,
            ...filterObject,
            ...idFilters
          },
          sortBy
        },
        fetchPolicy: "network-only"
      });

      const totalCount = data && data.productsCustom && data.productsCustom.totalCount;
      const nodes = data && data.productsCustom && data.productsCustom.nodes;

      setTableData(nodes);
      setTotalProducts(totalCount);
      setPageCount(Math.ceil(totalCount / pageSize));
      setIsLoading(false);
    },
    [apolloClient, filterByProductIds, globalFilter, sortBy, filters, pageIndex, pageSize, shopId],
  );

  useEffect(() => {
    localStorage.setItem("productTable", JSON.stringify({ hiddenColumns, pageSize }));
  }, [hiddenColumns, pageSize]);

  const options = useMemo(() => [{
    label: "Tags hinzufügen/entfernen",
    isDisabled: selectedRows.length === 0,
    onClick: () => {
      if (isFilterByFileVisible) setFilterByFileVisible(false);
      setTagSelectorVisible(true);
    }
  },
  /*{
    isDisabled: true,
    label: t("admin.createProduct") || "Produkt erstellen",
    onClick: () => {
      const [opaqueShopId] = await getOpaqueIds([{ namespace: "Shop", id: Reaction.getShopId() }]);
      const { data } = await createProduct({ variables: { input: { shopId: opaqueShopId } } });

      if (data) {
        const { createProduct: { product } } = data;
        const { id: decodedId } = decodeOpaqueId(product._id);

        history.push(`/products/${decodedId}`);
      }
    }
  }*/,
  {
    label: "Mit CSV-Datei filtern",
    onClick: () => {
      if (isTagSelectorVisible) setTagSelectorVisible(false);
      setFilterByFileVisible(true);
    }
  }, {
    label: "Veröffentlichen",
    confirmTitle: `${selectedRows.length} Produkte veröffentlichen?`,
    confirmMessage: `Veröffentlichen Sie ausstehende Änderungen an ${selectedRows.length} Produkten in Ihrem Katalog.`,
    isDisabled: selectedRows.length === 0,
    onClick: async () => {
      if (selectedRows.length === 0) return;

      const { data, error } = await apolloClient.mutate({
        mutation: publishProductsToCatalog,
        variables: {
          productIds: selectedRows
        }
      });

      if (error && error.length) {
        enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
        return;
      }

      refetch();
      enqueueSnackbar(
        t("admin.productTable.bulkActions.published", { count: data.publishProductsToCatalog.length }),
        { variant: "success" }
      );
    }
  }, {
    label: "Sichtbar machen",
    confirmTitle: `${selectedRows.length} Produkte sichtbar machen?`,
    confirmMessage: "Zeigen Sie ausgewählte Produkte in Ihrem Katalog. Diese Änderungen müssen veröffentlicht werden, damit sie in Kraft treten können.",
    isDisabled: selectedRows.length === 0,
    onClick: async () => {
      if (selectedRows.length === 0) return;

      const errors = [];
      const successes = [];
      // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
      for (const productId of selectedRows) {
        // eslint-disable-next-line no-await-in-loop
        const { data, error } = await apolloClient.mutate({
          mutation: updateProduct,
          variables: {
            input: {
              product: {
                isVisible: true
              },
              productId,
              shopId
            }
          }
        });

        if (error) errors.push(error);
        if (data) successes.push(data);
      }

      if (errors.length) {
        enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
        return;
      }

      refetch();
      enqueueSnackbar(
        t("admin.productTable.bulkActions.makeVisibleSuccess", { count: successes.length }),
        { variant: "success" }
      );
    }
  }, {
    label: "Verstecken",
    confirmTitle: `${selectedRows.length} Produkte verstecken?`,
    confirmMessage: "Verstecken Sie ausgewählte Produkte in Ihrem Katalog. Diese Änderungen müssen veröffentlicht werden, damit sie in Kraft treten können.",
    isDisabled: selectedRows.length === 0,
    onClick: async () => {
      if (selectedRows.length === 0) return;

      const errors = [];
      const successes = [];
      // TODO: refactor this loop to use a bulk update mutation that needs to be implemented.
      for (const productId of selectedRows) {
        // eslint-disable-next-line no-await-in-loop
        const { data, error } = await apolloClient.mutate({
          mutation: updateProduct,
          variables: {
            input: {
              product: {
                isVisible: false
              },
              productId,
              shopId
            }
          }
        });

        if (error && error.length) errors.push(error);
        if (data) successes.push(data);
      }

      if (errors.length) {
        enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
        return;
      }

      refetch();
      enqueueSnackbar(
        t("admin.productTable.bulkActions.makeHiddenSuccess", { count: successes.length }),
        { variant: "success" }
      );
    }
  },
  /*{
    label: "Duplizieren",
    confirmTitle: `${selectedRows.length} Produkte duplizieren?`,
    confirmMessage: "Duplizieren Sie ausgewählte Produkte und deren Varianten.",
    // isDisabled: selectedRows.length === 0,
    isDisabled: true,
    onClick: () => {
      console.log(`Duplicated ${selectedRows.length} products`);
    }
  },*/
  {
    label: "Archivieren",
    confirmTitle: `${selectedRows.length} Produkte archivieren?`,
    confirmMessage: "Archivierte Produkte werden sowohl aus der Admin- als auch aus der Kundensicht entfernt.",
    isDisabled: selectedRows.length === 0,
    onClick: async () => {
      if (selectedRows.length === 0) return;

      const { data, error } = await apolloClient.mutate({
        mutation: archiveProducts,
        variables: {
          input: {
            productIds: selectedRows,
            shopId
          }
        }
      });

      if (error && error.length) {
        enqueueSnackbar(t("admin.productTable.bulkActions.error", { variant: "error" }));
        return;
      }


      refetch();
      enqueueSnackbar(
        t("admin.productTable.bulkActions.archiveSuccess", { count: data.archiveProducts.products.length }),
        { variant: "success" }
      );
    }
  }], [createProduct, enqueueSnackbar, refetch, selectedRows, shopId, t]);

  const onDrop = (accepted) => {
    if (accepted.length === 0) return;
    setFiles(accepted);
    setNoProductsFoundError(false);
  };

  const importFiles = (newFiles) => {
    let productIds = [];

    newFiles.map((file) => {
      const output = [];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        const parse = require("csv-parse");

        parse(reader.result, {
          trim: true,
          // eslint-disable-next-line camelcase
          skip_empty_lines: true
        })
          .on("readable", function () {
            let record;
            // eslint-disable-next-line no-cond-assign
            while (record = this.read()) {
              output.push(record);
            }
          })
          .on("end", () => {
            output.map((outputarray) => {
              productIds = productIds.concat(outputarray);
              return;
            });
            setFilterByProductIds(productIds);
            setFilterByFileVisible(false);
            setFiltered(true);
          });
      };
      return;
    });
  };

  const handleDelete = (deletedFilename) => {
    const newFiles = files.filter((file) => file.name !== deletedFilename);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      setFiltered(false);
      setFilterByProductIds(null);
    } else if (isFiltered) {
      importFiles(newFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: CSV_FILE_TYPES,
    disableClick: true,
    disablePreview: true,
    multiple: false,
    onDrop
  });

  /*
  // eslint-disable-next-line react/no-multi-comp
  const renderMissedFilterItems = () => {
    if (!filterByProductIds) {
      return null;
    }
    const filterProductIds = filterByProductIds.length;
    if (isFiltered && filteredProductIdsCount < filterProductIds) {
      const missing = filterProductIds - filteredProductIdsCount;
      return (
        <Grid item sm={12} >
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={t("admin.missingFilteredProducts", { count: missing })}
          />
        </Grid>
      );
    }
    return null;
  };
  */

  const onRemoveFilter = useCallback((id, value, multiSelectValue) => {
    if (id === "productIds") {
      setFilterByProductIds(null);
    } else {
      dataTableProps.onRemoveFilter(id, value, multiSelectValue);
    }
  }, [dataTableProps.onRemoveFilter, setFilterByProductIds]);

  return (
    <Grid container spacing={3}>
      {createProductError &&
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={createProductError.message}
          />
        </Grid>
      }
      <FilterByFileCard
        fieldToFilter={fieldToFilter}
        setFieldToFilter={setFieldToFilter}
        isFilterByFileVisible={isFilterByFileVisible}
        files={files}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        importFiles={importFiles}
        handleDelete={handleDelete}
        setFilterByFileVisible={setFilterByFileVisible}
      />
      <TagSelector
        isVisible={isTagSelectorVisible}
        setVisibility={setTagSelectorVisible}
        selectedProductIds={selectedRows}
      />
      {/* renderMissedFilterItems() */}
      <Grid item sm={12}>
        <Card className={classes.card}>
          <CardHeader
            title={t("admin.dashboard.productAdminTitle")}
            subheader={(selectedRows.length > 0) ?
              t("admin.productTable.bulkActions.selectedCount", { count: selectedRows.length })
              :
              t("admin.productTable.bulkActions.totalCount", { count: totalProducts })
            }
          />
          <CardContent>
            <DataTable
              {...dataTableProps}
              onRemoveFilter={onRemoveFilter}
              state={{
                ...dataTableProps.state,
                filters: [
                  ...dataTableProps.state.filters,
                  ...((filterByProductIds && filterByProductIds.length && fieldToFilter === "internalProductId") ? [{ id: "productIds", value: "Interne Produkt-ID via CSV-Datei" }] : []),
                  ...((filterByProductIds && filterByProductIds.length && fieldToFilter === "productId") ? [{ id: "productIds", value: "Produkt-ID via CSV-Datei" }] : []),
                  ...((filterByProductIds && filterByProductIds.length && fieldToFilter === "productNumber") ? [{ id: "productIds", value: "Produktnummer via CSV-Datei" }] : []),
                  ...((filterByProductIds && filterByProductIds.length && fieldToFilter === "limesId") ? [{ id: "productIds", value: "Limes-ID via CSV-Datei" }] : [])
                ]
              }}
              actionMenuProps={{ children: "Aktionen", options }}
              placeholder={"Filter orders"}
              isFilterable
              labels={{
                "filterChipValue.simple": t("admin.table.productType.simple"),
                "filterChipValue.limesProduct": t("admin.table.productType.limesProduct"),
                "filterChipValue.limesDemoProduct": t("admin.table.productType.limesDemoProduct"),
                "filterChipValue.bundle": t("admin.table.productType.bundle"),
                "filterChipValue.published": t("admin.table.published"),
                "filterChipValue.unpublished": t("admin.table.unpublished"),
                "filterChipValue.hp": t("admin.table.vendor.hp"),
                "filterChipValue.lg": t("admin.table.vendor.lg"),
                "filterChipValue.samsung": t("admin.table.vendor.samsung"),
                "filterChipValue.epson": t("admin.table.vendor.epson"),
                "filterChipValue.kyocera": t("admin.table.vendor.kyocera"),
                "filterChipValue.sharp": t("admin.table.vendor.sharp"),
                "filterChipValue.cds": t("admin.table.vendor.cds"),
                "allFilters": "Alle Filter",
                "allFiltersDrawerTitle": "Alle Filter",
                "clearAllFilters": "Alle Filter entfernen",
                "clearFilter": "Entfernen",
                "globalFilterPlaceholder": "Filter",
                "next": "Nächste",
                "page": "Seite",
                "pageOf": ({ count }) => `von ${count}`,
                "pageSizeSelect": ({ count }) => `${count} Zeilen`,
                "previous": "Vorherige"
              }}
              pageSizes={[10, 20, 30, 40, 50, 100, 200]}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

ProductTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(ProductTable);
