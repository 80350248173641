import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import isObject from "lodash/isObject";

/**
 * Show a popup alert
 * @example
 * // Simple
 * Alerts.alert("title", "message", {}, callbackFunction);
 * // - OR, for more control -
 * Alerts.alert({
 *   title: "Title",
 *   text: "Message Text",
 *   type: "success|info|warning|error|"
 * }, callbackFunction);
 *
 * @param  {string|object} titleOrOptions Pass a string or an object containing options
 * @param  {string}   messageOrCallback [description]
 * @param  {string}   options           [description]
 * @param  {function} callback          [description]
 * @returns {string}                     [description]
 */
export default function alert(titleOrOptions, messageOrCallback, options, callback) {
    if (isObject(titleOrOptions)) {
        return swal.fire({
            icon: "info",
            ...titleOrOptions
        }).then((result) => {
            try {
                if (result.value) {
                    messageOrCallback(true, false);
                } else if (result.dismiss) {
                    messageOrCallback(false, result.dismiss);
                }
            } catch (err) {
                if (err === "cancel" || err === "overlay" || err === "timer") {
                    return undefined; // Silence error
                }
                // throw err;
            }
            
            return null;
        });
    }

const title = titleOrOptions;
const message = messageOrCallback;

return swal.fire({
    title,
    text: message,
    icon: "info",
    ...options
    }).then((isConfirm) => {
        if (isConfirm === true && typeof callback === "function") {
        /*// eslint-disable-next-line promise/no-callback-in-promise*/
        callback(isConfirm);
    }
        return null;
    }).catch((err) => {
        if (err === "cancel" || err === "overlay" || err === "timer") {
            return undefined; // Silence error
        }
        // throw err;
    });
}