import gql from "graphql-tag";
import { accountFragment } from "../fragments/account";

export default gql `
    mutation updateAccountAddressBookEntryMutation($input: UpdateAccountAddressBookEntryInput!) {
      updateAccountAddressBookEntry(input: $input) {
        address {
          _id
          address1
          address2
          city
          country
          fullName
          phone
          postal
          region
          isCommercial
        }
      }
    }
`;
