import React from "react";

const Kibana = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 80"
    height="1.5em"
  >
    <polygon fill="currentColor" fillRule="evenodd" points="58 8 8 8 8 65.58 58 8"/>
    <path fill="currentColor" fillRule="evenodd" d="M37,41.31,12.53,69.51,10.37,72H57A50,50,0,0,0,37,41.31Z"/>
  </svg>
);

export default Kibana;
