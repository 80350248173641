import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  IconButton,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  makeStyles
} from "@material-ui/core";
import DeleteIcon from "mdi-material-ui/Delete";
import AddIcon from "mdi-material-ui/Plus";

import productSpecsQuery from "../../product-specifications/graphql/queries/productSpecs";
import QuerySelectReturnIdDialog from "../../shared/components/Dialogs/QuerySelectReturnIdDialog";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";

const useStyles = makeStyles((theme) => ({
  secondCard: {
    margin: theme.spacing(2, 0)
  },
  addIconButton: {
    padding: "12px 14px"
  },
  cellWithoutPadding: {
    "padding": 0,
    "&:last-child": {
      padding: 0
    }
  },
  cellWithMinPadding: {
    padding: "4px 6px"
  }
}));

/**
 * @name BundleComponentForm
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
function BundleComponentForm(props) {
  const classes = useStyles();
  const { bundleComponent, setBundleComponent } = props;
  const { specifications: existingSpecifications } = bundleComponent;
  const specifications = (existingSpecifications && Array.isArray(existingSpecifications) && existingSpecifications) || [];
  const specificationIds = specifications && specifications.map((specification) => specification.specificationId) || [];

  const [specificationDialogOpen, setSpecificationDialogOpen] = useState(false);
  const [currentShopId] = useCurrentShopId();

  const handleAddSpecificationDialogSubmit = (selectedId) => {
    setSpecificationDialogOpen(false);

    setBundleComponent({
      ...bundleComponent,
      specifications: [
        ...specifications,
        { specificationId: selectedId, value: "" }
      ]
    });
  };

  const handleDeleteClick = (selectedId) => {
    setBundleComponent({
      ...bundleComponent,
      specifications: specifications.filter((specification) => specification.specificationId !== selectedId)
    });
  };

  const handleSpecValueUpdate = (selectedId) => (event) => {
    const { value } = event.target;

    setBundleComponent({
      ...bundleComponent,
      specifications: (Array.isArray(specifications) && specifications || []).map((spec) => (
        spec.specificationId === selectedId ?
          { ...spec, value }
          :
          spec
      ))
    });
  };

  const { loading, data, error } = useQuery(productSpecsQuery, {
    onError(fetchError) {
      console.error(fetchError);
    },
    variables: {
      shopId: currentShopId,
      filters: {
        specificationIds
      }
    }
  });

  if (loading) {
    return <CircularProgress />;
  }

  const nodes = data && data.productSpecs && data.productSpecs.nodes;

  return (
    <Fragment>

      <QuerySelectReturnIdDialog
        isOpen={specificationDialogOpen}
        onSubmit={handleAddSpecificationDialogSubmit}
        onClose={() => setSpecificationDialogOpen(false)}
        query={productSpecsQuery}
        queryData="productSpecs"
        title="Spezifikation hinzufügen"
        shopId={currentShopId}
      />

      <Card className={classes.secondCard}>
        <CardHeader title="Spezifikationen"/>
        <CardContent>
          <IconButton
            aria-label="More"
            aria-haspopup="true"
            onClick={() => setSpecificationDialogOpen(true)}
            className={classes.addIconButton}
          >
            <AddIcon />
          </IconButton>
          { (Array.isArray(nodes) && nodes.length > 0) &&
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Details</TableCell>
              <TableCell classes={{ root: classes.cellWithoutPadding }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map((productSpec, index) => {
              const { _id, name, unit } = productSpec;
              const matchedSpecification = specifications && specifications.find((item) => item.specificationId === _id);
              return (
                <TableRow key={`specItem-${_id}-${index}`}>
                  <TableCell classes={{ root: classes.cellWithMinPadding }}>{name}</TableCell>
                  <TableCell classes={{ root: classes.cellWithMinPadding }}>
                    <TextField
                      id="specificationValue"
                      value={matchedSpecification && matchedSpecification.value}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
                      }}
                      inputProps={{
                        style: { textAlign: "center" }
                      }}
                      onChange={handleSpecValueUpdate(_id)}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.cellWithoutPadding }}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDeleteClick(_id)}
                      tabIndex="-1"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
          }
        </CardContent>
      </Card>
    </Fragment>
  );
}

BundleComponentForm.propTypes = {
  classes: PropTypes.object
};

export default BundleComponentForm;
