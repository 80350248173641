import gql from "graphql-tag";
import { b4bLosListingFragment } from "../fragments/b4bLosListing";

export default gql`
mutation updateB4BLosListing($input: UpdateB4BLosListingInput!) {
  updateB4BLosListing(input: $input) {
      b4bLosListing {
        ...B4BLosListing
      }
    }
}
${b4bLosListingFragment}
`;
