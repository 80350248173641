import gql from "graphql-tag";
import { headerSlideFragment } from "../fragments/headerSlide";

export default gql`
  mutation publishHeaderSlider($input: PublishHeaderSliderInput!) {
    publishHeaderSlider(input: $input) {
      ...HeaderSlide
    }
  }
  ${headerSlideFragment}
`;
