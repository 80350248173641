import React, { Fragment } from "react";
import { MjmlText } from "../../mjml-react";

export default function LegalNotesLPR() {
  return (
    <Fragment>
      <MjmlText
        align="left"
        paddingTop="0px"
        fontWeight="500"
        fontSize="13px"
        padding="20px"
        color="#00334C"
      >
        Mit der Abgabe eines Gebotes erklären Sie sich bereit, im Falle des
        Gewinns das Excel File mit ihren Einzelpreisen für die Weiterleitung an
        LPR zu übermitteln.
      </MjmlText>

      <MjmlText
        align="left"
        paddingTop="0px"
        fontSize="13px"
        padding="20px"
        color="#00334C"
      >
        Die Lieferung erfolgt ab Werk.
      </MjmlText>
    </Fragment>
  );
}
