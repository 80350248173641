import React from "react";
import { Slide, Fab, makeStyles } from "@material-ui/core";
import CheckIcon from "mdi-material-ui/Check";

const useStyles = makeStyles((theme) => ({
  fabContainer: {
    width: "100%",
    position: "fixed",
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

/**
 * @name SaveFabIcon
 * @param {Boolean} isEnabled If the Button should show
 * @param {Function} onClick Click handler
 * @returns {React.Component} A React component
 */
export default function SaveFabIcon({ isEnabled, onClick }) {
  const classes = useStyles();

  if (!isEnabled) {
    return null;
  }

  return (
    <div className={classes.fabContainer}>
      <Slide direction="up" in mountOnEnter unmountOnExit>
        <Fab color="primary" variant="extended" aria-label="save" onClick={onClick}>
          <CheckIcon className={classes.extendedIcon}/>
            Speichern
        </Fab>
      </Slide>
    </div>
  );
}
