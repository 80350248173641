import gql from "graphql-tag";
import { b4bLosListingFragment } from "../fragments/b4bLosListing";

export default gql`
mutation createB4BLosListing($input: CreateB4BLosListingInput!) {
  createB4BLosListing(input: $input) {
      b4bLosListing {
        ...B4BLosListing
      }
    }
}
${b4bLosListingFragment}
`;
