import i18next from "i18next";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import i18nextFetch from "i18next-fetch-backend";
import i18nextMultiLoadBackendAdapter from "i18next-multiload-backend-adapter";
import { initReactI18next } from 'react-i18next';
import config from "./config";

const { REACT_APP_PUBLIC_I18N_BASE_URL } = config;

const configuredI18next = i18next
  // https://github.com/i18next/i18next-browser-languageDetector
  // Sets initial language to load based on `lng` query string
  // with various fallbacks.
  .use(i18nextBrowserLanguageDetector)
  // https://github.com/perrin4869/i18next-fetch-backend
  // This uses `fetch` to load resources from the backend based on `backend`
  // config object below.
  .use(i18nextMultiLoadBackendAdapter)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);

/**
 * @summary Async function to initialize i18next after we have a fallback
 *   (shop) language.
 * @return {undefined}
 */
async function initializeI18n() {
    // Reaction does not have a predefined list of namespaces. Any API plugin can
    // add any namespaces. So we must first get the list of namespaces from the API.
    const namespaceResponse = await fetch(`${REACT_APP_PUBLIC_I18N_BASE_URL}/locales/namespaces.json`);
    const allTranslationNamespaces = await namespaceResponse.json();

    try {
        await configuredI18next.init({
            backend: {
              backend: i18nextFetch,
              backendOption: {
                allowMultiLoading: true,
                loadPath: `${REACT_APP_PUBLIC_I18N_BASE_URL}/locales/resources.json?lng={{lng}}&ns={{ns}}`
              }
            },
            debug: false,
            detection: {
              // We primarily set language according to `navigator.language`,
              // which is supported in all modern browsers and can be changed
              // in the browser settings. This is the same list that browsers
              // send in the `Accept-Language` header.
              //
              // For ease of testing translations, we also support `lng`
              // query string to override the browser setting.
              order: ["querystring", "navigator"]
            },
            ns: allTranslationNamespaces,
            defaultNS: "core", // reaction "core" is the default namespace
            fallbackNS: allTranslationNamespaces,
            fallbackLng: "de"
        })
    } catch (error) {
        // We want to log when this happens, but we want to also continue
        // as long as `i18next.language` has been successfully detected
        // so that other language-dependent things work properly.
        console.error(error);
        if (!i18next.language) return;
    }
}

initializeI18n();

export default configuredI18next;