import gql from "graphql-tag";

export const productClassFragment = gql`
  fragment ProductClass on ProductClass {
    _id
    name
    relatedSpecifications {
      _id
      position
      type
    }
    relatedSpecificationsPreview {
      _id
      position
      type
    }
    isVisible
  }
`;
