import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import bundleComponentQuery from "../graphql/queries/bundleComponent";

const withBundleContainer = (Comp) => {
  /**
   * @name WithBundleContainer
   * @param {Object} props Component props
   * @returns {React.Component} returns a React component
   */
  function WithBundleContainer(props) {
    const { match: { params } } = props;
    const [currentShopId] = useCurrentShopId();

    const { loading, data, error, refetch } = useQuery(bundleComponentQuery, {
      onError(fetchError) {
        console.error(fetchError);
      },
      variables: {
        id: params._id,
        shopId: currentShopId
      },
      errorPolicy: "all"
    });

    if (loading) return null;

    if (!data || !data.bundleComponent) {
      return <div>Bundle not found</div>;
    }

    return (
      <Comp
        {...props}
        refetchBundleComponent={refetch}
        bundleComponent={data && data.bundleComponent}
      />
    );
  }

  WithBundleContainer.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        _id: PropTypes.string
      })
    }),
    shopId: PropTypes.string
  };

  return WithBundleContainer;
};

export default withBundleContainer;