import gql from "graphql-tag";
import { productClassFragment } from "../fragments/productClass";

export default gql`
  mutation updateProductClassMutation($input: UpdateProductClassInput!) {
    updateProductClass(input: $input) {
      productClass {
        ...ProductClass
      }
    }
  }
  ${productClassFragment}
`;
