import React from "react";
import { handleMjmlProps } from "./utils";

/*
import ReactDOMServer from "react-dom/server";
import mjml2html from "mjml-browser";
*/

type ComponentProps = {
  children?: React.ReactNode;
  [key: string]: any;
};

const getComponent = (componentName: string) => {
  const MjmlComponent = ({ children, ...rest }: ComponentProps) => {
    if (componentName === "mj-style") {
      return React.createElement(componentName, {
        ...handleMjmlProps(rest),
        dangerouslySetInnerHTML: { __html: children },
      });
    }

    return React.createElement(componentName, handleMjmlProps(rest), children);

    /*
    const CurrentItem = () => React.createElement(componentName, handleMjmlProps(rest), children);


    const staticMjml = ReactDOMServer.renderToStaticMarkup(<CurrentItem />);
    const result = mjml2html(staticMjml);

    const staticPlaceholder = `<div>test</div>`;

    return (
      <React.Fragment dangerouslySetInnerHTML={result}>
        {children}
      </React.Fragment>
    )
    */
  };

  MjmlComponent.displayName = componentName;

  return MjmlComponent;
};

const Mjml = getComponent("mjml");
const MjmlAccordion = getComponent("mj-accordion");
const MjmlAccordionElement = getComponent("mj-accordion-element");
const MjmlAccordionText = getComponent("mj-accordion-text");
const MjmlAccordionTitle = getComponent("mj-accordion-title");
const MjmlAll = getComponent("mj-all");
const MjmlAttributes = getComponent("mj-attributes");
const MjmlBody = getComponent("mj-body");
const MjmlBreakpoint = getComponent("mj-breakpoint");
const MjmlButton = getComponent("mj-button");
const MjmlCarousel = getComponent("mj-carousel");
const MjmlCarouselImage = getComponent("mj-carousel-image");
const MjmlClass = getComponent("mj-class");
const MjmlColumn = getComponent("mj-column");
const MjmlDivider = getComponent("mj-divider");
const MjmlFont = getComponent("mj-font");
const MjmlGroup = getComponent("mj-group");
const MjmlHead = getComponent("mj-head");
const MjmlHero = getComponent("mj-hero");
const MjmlImage = getComponent("mj-image");
const MjmlNavbar = getComponent("mj-navbar");
const MjmlNavbarLink = getComponent("mj-navbar-link");
const MjmlPreview = getComponent("mj-preview");
const MjmlRaw = getComponent("mj-raw");
const MjmlSection = getComponent("mj-section");
const MjmlSocial = getComponent("mj-social");
const MjmlSocialElement = getComponent("mj-social-element");
const MjmlSpacer = getComponent("mj-spacer");
const MjmlStyle = getComponent("mj-style");
const MjmlTable = getComponent("mj-table");
const MjmlText = getComponent("mj-text");
const MjmlTitle = getComponent("mj-title");
const MjmlWrapper = getComponent("mj-wrapper");

export {
  Mjml,
  MjmlAccordion,
  MjmlAccordionElement,
  MjmlAccordionText,
  MjmlAccordionTitle,
  MjmlAll,
  MjmlAttributes,
  MjmlBody,
  MjmlBreakpoint,
  MjmlButton,
  MjmlCarousel,
  MjmlCarouselImage,
  MjmlClass,
  MjmlColumn,
  MjmlDivider,
  MjmlFont,
  MjmlGroup,
  MjmlHead,
  MjmlHero,
  MjmlImage,
  MjmlNavbar,
  MjmlNavbarLink,
  MjmlPreview,
  MjmlRaw,
  MjmlSection,
  MjmlSocial,
  MjmlSocialElement,
  MjmlSpacer,
  MjmlStyle,
  MjmlTable,
  MjmlText,
  MjmlTitle,
  MjmlWrapper,
};
