import React from "react";
import KibanaIcon from "./components/KibanaIcon";
import { registerOperatorRoute } from "../../../routes";
import ContentViewFullLayout from "../../../layouts/ContentViewFullLayout";
import Iframe from "./components/Iframe";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  layoutComponent: ContentViewFullLayout,
  mainComponent: Iframe,
  path: "/kibana",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <KibanaIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.kibanaIframeLabel"
});
