import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import useDebouncedCallback from "./useDebouncedCallback";
import productSpecsQuery from "../../product-specifications/graphql/queries/productSpecs";

export const useQuerySuggestions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  const { loading, data } = useQuery(productSpecsQuery, {
    // skip: !shopId,
    skip: !searchTerm,
    variables: {
      filters: {
        searchField: searchTerm
      }
      // shopId
    },
    // fetchPolicy: "network-only"
  });

  useEffect(() => {
    const productSpecs = data && data.productSpecs;
    if (productSpecs) {
      const { nodes } = productSpecs;
      setOptions(nodes);
    }
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  const [setDebouncedSearchTerm] = useDebouncedCallback((value) => {
    setSearchTerm(value);
  }, 500);

  return [
    searchTerm,
    setSearchTerm,
    setDebouncedSearchTerm,
    options,
    loading,
    isOpen,
    setIsOpen
  ];
};
