import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/link-ws";
import { onError } from "@apollo/link-error";
import { getOperationAST } from "graphql";
import config from "../../config";

const { REACT_APP_PUBLIC_GRAPHQL_API_URL_HTTP, REACT_APP_PUBLIC_GRAPHQL_API_URL_WS } = config;

let sharedClient;
let token;

/**
 * @summary Set the access token that GraphQL requests will use in the Authorization header
 * @param {String} value New token value
 * @return {undefined}
 */
export async function setAccessToken(value) {
  const previousToken = token;
  token = value;

  // "Resets your entire store by clearing out your cache and then re-executing all of your active queries.
  // This makes it so that you may guarantee that there is no data left in your store from a time
  // before you called this method."
  //
  // We do this because we have effectively switched users here. We don't want data from the previous user
  // (or the previous non-authenticated queries) to be kept.
  if (previousToken !== token) {
    if (sharedClient) {
        // await sharedClient.stop();
        // sharedClient.resetStore();
        // await sharedClient.queryManager.fetchQueryRejectFns;
        await sharedClient.cache.reset();
        await sharedClient.reFetchObservableQueries();
        // sharedClient.clearStore();
    }

    /*
    // If we are logged in with OAuth, log in as the same use with Meteor for the DDP connection
    Accounts.callLoginMethod({
      methodArguments: [{
        accessToken: token
      }]
    });
    */
  }
}

/**
 * @summary Sets the Authorization header for all GraphQL requests done
 *   through simpleClient.
 * @param {Object} client graphql.js client instance
 * @returns {undefined}
 */
export function setSimpleClientTokenHeader(client) {
  if (token) {
    client.headers({ Authorization: token });
  } else {
    client.headers({});
  }
}

const authenticationLink = new ApolloLink((operation, forward) => {
  if (typeof token === "string") {
    operation.setContext(() => ({
      headers: {
        Authorization: token
      }
    }));
  }

  return forward(operation);
});

const httpLink = new HttpLink({ uri: REACT_APP_PUBLIC_GRAPHQL_API_URL_HTTP });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const standardLink = ApolloLink.from([
  authenticationLink,
  httpLink,
  errorLink
]);

let linkWithSubscriptions;

if (REACT_APP_PUBLIC_GRAPHQL_API_URL_WS && REACT_APP_PUBLIC_GRAPHQL_API_URL_WS.length) {
  linkWithSubscriptions = ApolloLink.split(
    (operation) => {
      const operationAST = getOperationAST(operation.query, operation.operationName);
      return !!operationAST && operationAST.operation === "subscription";
    },
    new WebSocketLink({
      uri: REACT_APP_PUBLIC_GRAPHQL_API_URL_WS,
      options: {
        reconnect: true, // auto-reconnect
        connectionParams: {
          authToken: localStorage.getItem("Meteor.loginToken")
        }
      }
    }),
    standardLink
  );
}

/**
 * @name initApollo
 * @summary Initializes Apollo Client
 * @returns {Object} New ApolloClient
 */
export default function initApollo() {
  if (sharedClient) return sharedClient;

  sharedClient = new ApolloClient({
    link: linkWithSubscriptions || standardLink,
    cache: new InMemoryCache()
  });
  
  return sharedClient;
}
