import React, { Fragment } from "react";

import {
  MjmlColumn,
  MjmlImage,
  MjmlSection,
  MjmlText,
  MjmlWrapper,
  MjmlDivider,
  MjmlGroup,
} from "../../mjml-react";

import Translation from "../../components/Translation";

export default function Header() {
  return (
    <Fragment>
      <MjmlSection padding="0" paddingTop="20px" fullWidth="full-width">
        <MjmlColumn>
          <MjmlImage
            width="600px"
            src="https://dr72jt4i3ro9g.cloudfront.net/topshadow_white_alpha.png"
            padding="0px"
          ></MjmlImage>
        </MjmlColumn>
      </MjmlSection>

      <MjmlWrapper padding="0px" backgroundColor="#FFFFFF">
        <MjmlSection padding="0">
          <MjmlColumn width="75%" verticalAlign="top">
            <MjmlText
              align="left"
              color="#B8B8B8"
              fontSize="16px"
              padding="40px"
              paddingTop="20px"
              paddingBottom="0px"
              lineHeight="14px"
            >
              Powered by <strong>CDS</strong>
            </MjmlText>
            <MjmlImage
              padding="40px"
              paddingBottom="20px"
              paddingTop="15px"
              src="https://dr72jt4i3ro9g.cloudfront.net/remarket-now/REMARKET_RGB_Logo.png"
              href=""
              alt="remarket-now.com"
              width="340px"
              align="left"
            ></MjmlImage>
          </MjmlColumn>

          <MjmlColumn width="25%" verticalAlign="top">
            {/*
            <MjmlText
              align="right"
              color="#B8B8B8"
              fontSize="12px"
              padding="20px"
              paddingTop="10px"
              paddingRight="20px"
              lineHeight="14px"
            >
              {`{$$TodayDate$$}`}
            </MjmlText>
              */}
          </MjmlColumn>
        </MjmlSection>

        <MjmlSection padding="0" paddingLeft="20px" paddingRight="20px">
          <MjmlGroup>
            <MjmlColumn verticalAlign="middle">
              <MjmlDivider
                borderWidth="1px"
                border-color="#A3A3A3"
                padding="0"
              />
            </MjmlColumn>
            <MjmlColumn verticalAlign="middle">
              <MjmlText
                align="center"
                color="#A3A3A3"
                fontSize="12px"
                padding="5px"
                textTransform="uppercase"
              >
                <Translation tKey="formerlyB4BMall" />
              </MjmlText>
            </MjmlColumn>
            <MjmlColumn verticalAlign="middle">
              <MjmlDivider
                borderWidth="1px"
                border-color="#A3A3A3"
                padding="0"
              />
            </MjmlColumn>
          </MjmlGroup>
        </MjmlSection>
      </MjmlWrapper>
    </Fragment>
  );
}
