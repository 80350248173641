import React from "react";
import { registerOperatorRoute } from "../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import ProductSpecGroupTable from "./components/ProductSpecGroupTable";
import ProductSpecGroupsForm from "./pages/ProductSpecGroupsForm";

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  isProductMetadata: true,
  path: "/product-spec-groups/create",
  mainComponent: ProductSpecGroupsForm
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  isProductMetadata: true,
  path: "/product-spec-groups/:productSpecGroupId",
  mainComponent: ProductSpecGroupsForm
});

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  isProductMetadata: true,
  mainComponent: ProductSpecGroupTable,
  path: "/product-spec-groups",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <FontAwesomeIcon icon={faListAlt} {...props} />,
  sidebarI18nLabel: "admin.dashboard.productSpecGroupsLabel"
});