import gql from "graphql-tag";
import { bundleComponentWithSpecificationsFragment } from "../fragments/bundleComponentWithSpecifications";

export default gql`
query bundleComponentsQuery($shopIds: [ID], $filters: BundleComponentsFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [BundleComponentsSortBy]) {
  bundleComponents(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
    nodes {
      ...BundleComponentWithSpecifications
    }
    totalCount
    __typename
  }
}
${bundleComponentWithSpecificationsFragment}
`;
