import React, { useMemo } from "react";

import { TranslationProvider } from "../../context/TranslationContext";
import translations from "./translations";

import Layout from "./Layout";

import ManualNewsletter from "./Templates/ManualNewsletter";

import useSelection from "../../hooks/useSelection";
import { B4BTemplate } from "../../context/SelectionContext";

function RemarketNowNewsletter() {
  const { template } = useSelection();

  return (
    <Layout title={template}>
      {template &&
        [
          B4BTemplate.Invitation,
          B4BTemplate.Reminder1,
          B4BTemplate.Reminder2,
          B4BTemplate.Reminder3,
          B4BTemplate.SaleOverview,
        ].includes(template) && <ManualNewsletter />}
    </Layout>
  );
}

export default function RemarketNowNewsletterWrapper() {
  const { template } = useSelection();

  // Templates in DE
  const isGerman = useMemo<Boolean>(
    () =>
      template
        ? [
            B4BTemplate.Invitation,
            B4BTemplate.Reminder1,
            B4BTemplate.Reminder2,
            B4BTemplate.Reminder3,
            B4BTemplate.SaleOverview,
            B4BTemplate.SaleOverview,
          ].includes(template)
        : false,
    [template]
  );

  return (
    <TranslationProvider
      locale={isGerman ? "de" : "en"}
      translations={translations}
    >
      <RemarketNowNewsletter />
    </TranslationProvider>
  );
}
