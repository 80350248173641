import gql from "graphql-tag";

export default gql`
query stockItemsQuery($shopIds: [ID], $filters: StockItemFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [StockItemSortBy]) {
  stockItems(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
    nodes {
      _id
      attributeLabel
      barcode
      createdAt
      height
      index
      isDeleted
      isVisible
      length
      minOrderQuantity
      optionTitle
      originCountry
      sku
      title
      updatedAt
      weight
      width
      limesId
      cdsType
      isMakeAnOffer
      identifier
      displayPrice
      listPrice
      serialNumber
      storageBin
      taxCode
      type
      vendor
      isTaxable
      compareAtPrice
      price
      inventory {
        inventoryInStock
        inventoryReserved
      }
      product {
        _id
        publishedProductHash
        currentProductHash
        productNumber
        title
        vendor
      }
    }
    totalCount
    __typename
  }
}
`;
