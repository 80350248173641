import React, { createContext, useState } from "react";

export enum B4BTemplate {
  Invitation = "Invitation",
  Reminder1 = "Reminder1",
  Reminder2 = "Reminder2",
  Reminder3 = "Reminder3",
  SaleOverview = "SaleOverview",
}

export enum TemplateType {
  LosPage = "LosPage",
  RemarketNowNewsletter = "RemarketNowNewsletter",
}

export type LotSelection = {
  index: number;
  isAccessory: Boolean;
};

interface IContext {
  selectedLot?: LotSelection;
  setSelectedLot?: React.Dispatch<React.SetStateAction<LotSelection>>;
  templateType?: TemplateType;
  setTemplateType?: React.Dispatch<React.SetStateAction<TemplateType>>;
  template?: B4BTemplate | undefined;
  setTemplate?: React.Dispatch<React.SetStateAction<B4BTemplate | undefined>>;
}

export const SelectionContext = createContext<IContext>({});

export const SelectionProvider = ({
  value,
  children,
}: {
  value?: IContext;
  children: React.ReactNode;
}) => {
  const [templateType, setTemplateType] = useState<TemplateType>(
    value?.templateType ?? TemplateType.RemarketNowNewsletter
  );

  const [selectedLot, setSelectedLot] = useState<LotSelection>(
    value?.selectedLot ?? {
      index: 0,
      isAccessory: false,
    }
  );

  const [template, setTemplate] = useState<B4BTemplate | undefined>(
    value?.template ?? B4BTemplate.Invitation
  );

  return (
    <SelectionContext.Provider
      value={{
        selectedLot,
        setSelectedLot,
        templateType,
        setTemplateType,
        template,
        setTemplate,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
