import gql from "graphql-tag";

export default gql `
    mutation removeAccountAddressBookEntryMutation($input: RemoveAccountAddressBookEntryInput!) {
      removeAccountAddressBookEntry(input: $input) {
        address {
          _id
          address1
          address2
          city
          company
          country
          fullName
          isBillingDefault
          isCommercial
          isShippingDefault
          phone
          postal
          region
        }
      }
    }
`;
