import React from "react";

import { MjmlColumn, MjmlSection, MjmlDivider } from "../../mjml-react";

export default function Divider() {
  return (
    <MjmlSection padding="10px 0" backgroundColor="#FFFFFF">
      <MjmlColumn>
        <MjmlDivider
          paddingLeft="20px"
          paddingRight="20px"
          width="100%"
          borderWidth="1px"
          borderColor="#A3A3A3"
        ></MjmlDivider>
      </MjmlColumn>
    </MjmlSection>
  );
}
