import gql from "graphql-tag";
import { productSpecGroupFragment } from "../fragments/productSpecGroup";

export default gql`
  query productSpecGroupQuery($productSpecGroupId: ID!) {
    productSpecGroup(_id: $productSpecGroupId, shouldIncludeInvisible: true) {
      ...ProductSpecGroup
    }
  }
  ${productSpecGroupFragment}
`;
