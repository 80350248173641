import gql from "graphql-tag";
import { productSpecFragment } from "../fragments/productSpec";

export default gql`
  mutation addProductSpecMutation($input: AddProductSpecInput!) {
    addProductSpec(input: $input) {
      productSpec {
        ...ProductSpec
      }
    }
  }
  ${productSpecFragment}
`;
