import gql from "graphql-tag";

export const productSpecGroupWithSpecificationsFragment = gql`
    fragment ProductSpecGroupWithSpecifications on ProductSpecGroupWithSpecifications {
        _id
        name
        specifications {
            ... on FullSpecification {
            _id
            name
            unit
            type
            position
            }
            ... on SpecificationText {
            _id
            content
            type
            position
            }
        }
        isVisible
    }
`;
