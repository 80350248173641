import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

/**
 * @name SerialNumberCell
 * @param {Object} serialNumber A serialNumber string
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
function SerialNumberCell({ serialNumber, row }) {
  const classes = useStyles();

  const { productConfiguration = {} } = row.original;

  return (
    <Link
      to={`/products/${productConfiguration.productId}/${productConfiguration.productVariantId}`}
      className={classes.link}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {serialNumber}
    </Link>
  );
}

SerialNumberCell.propTypes = {
  serialNumber: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export default SerialNumberCell;
