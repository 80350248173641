import { createContext } from "react";

export const UIContext = createContext({
  isDetailDrawerOpen: false,
  isMobile: false,
  isPrimarySidebarOpen: true,
  detailDrawerWidth: 400,
  onCloseDetailDrawer: () => {},
  onClosePrimarySidebar: () => {},
  onToggleDetailDrawer: () => {},
  onTogglePrimarySidebar: () => {},
  onChangeDetailDrawerWidth: () => {},
});
