import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { parseISO } from "date-fns";
import { useMutation } from "@apollo/react-hooks";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import makeAnOfferEntryQuery from "../graphql/queries/makeAnOfferEntry";
import declineOfferMutation from "../graphql/mutations/declineOffer";
import acceptOfferMutation from "../graphql/mutations/acceptOffer";
import sendCounterOfferMutation from "../graphql/mutations/sendCounterOffer";

const withMakeAnOfferEntry = (Comp) => {
  /**
   * @name WithMakeAnOfferEntry
   * @param {Object} props Component props
   * @returns {React.Component} returns a React component
   */
  function WithMakeAnOfferEntry(props) {
    const {
      match: { params },
    } = props;
    const [counterPrice, setCounterPrice] = useState();
    const [currentShopId] = useCurrentShopId();

    const [declineOffer, { loading: declineOfferLoading }] = useMutation(
      declineOfferMutation
    );
    const [acceptOffer, { loading: acceptOfferLoading }] = useMutation(
      acceptOfferMutation
    );
    const [
      sendCounterOffer,
      { loading: sendCounterOfferLoading },
    ] = useMutation(sendCounterOfferMutation);

    const { loading, data, error, refetch } = useQuery(makeAnOfferEntryQuery, {
      onError(fetchError) {
        console.error(fetchError);
      },
      variables: {
        id: params._id,
        shopId: currentShopId,
      },
      skip: !params._id,
      errorPolicy: "all",
    });

    if (loading) return null;

    if (!data || !data.makeAnOfferEntry) {
      return <div>Offer not found</div>;
    }

    const {
      status,
      workflow = [],
      quantity,
      productConfiguration,
      catalogProduct,
      chosenVariant,
      currentPrice,
      _id: makeOfferId,
    } = data && data.makeAnOfferEntry;

    if (!catalogProduct) {
      return <div>Offer Product not found</div>;
    }

    const { title, productNumber } = catalogProduct;
    const { serialNumber } = chosenVariant;
    const { productId, productVariantId } = productConfiguration;

    const makeAnOfferEntryMessages = [];

    const isAdminView = true;

    const submitAnswer = async ({
      selectedAcceptOffer,
      selectedDeclineOffer,
      selectedCounterOffer,
      counterPrice: newCounterPrice,
    }) => {
      if (selectedAcceptOffer) {
        await acceptOffer({
          variables: {
            input: {
              makeOfferId,
            },
          },
        });
      }
      if (selectedDeclineOffer) {
        await declineOffer({
          variables: {
            input: {
              makeOfferId,
            },
          },
        });
      }
      if (selectedCounterOffer) {
        await sendCounterOffer({
          variables: {
            input: {
              makeOfferId,
              price: newCounterPrice,
            },
          },
        });
      }

      refetch();
    };

    if (workflow) {
      workflow.forEach((workflowElement) => {
        const { status: workflowStatus, price, createdAt } = workflowElement;

        if (workflowStatus === "offer") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "left" : "right",
            type: "text",
            title: isAdminView ? "Kundenangebot" : "Ihr Angebot",
            text: `${quantity}x ${title} (PN: ${productNumber} | SN: ${serialNumber}) für je ${price} €`,
            linkedItem: `/products/${productId}/${productVariantId}`,
            date: parseISO(createdAt),
          });
        }

        if (workflowStatus === "offerDeclined") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "left" : "right",
            type: "text",
            title: isAdminView ? "Abgelehnt" : "Es tut uns leid",
            text: isAdminView
              ? "Dieses Angebot wurde abgelehnt"
              : "Wir haben Ihr Angebot sorgfältig geprüft und sind zu dem Ergebnis gekommen, dass es nicht unseren preislichen Rahmenbedingungen entspricht.",
            date: parseISO(createdAt),
          });
        }

        if (workflowStatus === "offerAccepted") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "right" : "left",
            type: "text",
            title: "Deal!",
            text: isAdminView
              ? `Angebot wurde akzeptiert. Die Reservierung für ${title} wurde auf weitere 48 Stunden zurückgesetzt.`
              : `Ihr Angebot wurde akzeptiert. Wir haben ${title} Ihrem Warenkorb hinzugefügt`,
            date: parseISO(createdAt),
          });
        }

        if (workflowStatus === "counterOffer") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "right" : "left",
            type: "text",
            title: "Unser Gegenangebot",
            text: `Wir können Ihnen ${quantity}x ${title} für je ${price} € anbieten`,
            date: parseISO(createdAt),
          });
        }

        if (workflowStatus === "counterOfferAccepted") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "left" : "right",
            type: "text",
            title: isAdminView ? "Kundenentscheidung" : "Ihre Entscheidung",
            text: isAdminView
              ? "Kunde hat dem Gegenangebot zugestimmt"
              : "Sie haben dem Gegenangebot zugestimmt",
            date: parseISO(createdAt),
          });
        }

        if (workflowStatus === "counterOfferDeclined") {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "left" : "right",
            type: "text",
            title: isAdminView ? "Kundenentscheidung" : "Ihre Entscheidung",
            text: isAdminView
              ? "Kunde hat das Gegenangebot abgelehnt"
              : "Sie haben das Gegenangebot abgelehnt",
            date: parseISO(createdAt),
          });
        }
      });

      // For the initial offer state, show a System Message
      if (status === "offer") {
        makeAnOfferEntryMessages.push({
          avatar: true,
          position: isAdminView ? "right" : "left",
          // status: "sent",
          type: "acceptDeclineOrCounter",
          animate: true,
          // title: "Unser Gegenangebot",
          text: isAdminView
            ? "Produkt wurde bis zur Auswahl reserviert"
            : "Vielen Dank für Ihr Interesse, Ihr Produkt wurde reserviert! Ein Vertriebsmitarbeiter wird Ihr Angebot zum nächstmöglichen Zeitpunkt prüfen und Sie benachrichtigen.",
          date: isAdminView ? null : new Date(),
          quantity: quantity,
          price: currentPrice,
          submitAnswer,
          counterPrice,
          setPrice: (value) => setCounterPrice(value),
        });
      }

      // For the counterOfferStage, show a Accept or Decline Selection
      if (status === "counterOffer") {
        if (!isAdminView) {
          makeAnOfferEntryMessages.push({
            avatar: true,
            position: isAdminView ? "left" : "right",
            // status: "read",
            type: "acceptOrDecline",
            title: "Ihre Entscheidung",
            text: "Angebot akzeptieren?",
            // date: new Date()
          });
        }
      }

      // For the counterOfferAccepted Stage, show a notice that the item was added
      if (status === "counterOfferAccepted") {
        makeAnOfferEntryMessages.push({
          avatar: true,
          position: isAdminView ? "right" : "left",
          type: "text",
          title: "Deal!",
          text: isAdminView
            ? `Angebot wurde akzeptiert. Die Reservierung für ${title} wurde auf weitere 48 Stunden zurückgesetzt.`
            : `Schön, dass wir eine Übereinkunft finden konnten. Wir haben ${title} Ihrem Warenkorb hinzugefügt`,
          date: new Date(),
        });
      }
    }

    return (
      <Comp
        {...props}
        isAdminView
        refetchMakeAnOfferEntry={refetch}
        makeAnOfferEntry={data.makeAnOfferEntry}
        makeAnOfferEntryMessages={makeAnOfferEntryMessages}
      />
    );
  }

  WithMakeAnOfferEntry.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }),
    shopId: PropTypes.string,
  };

  return WithMakeAnOfferEntry;
};

export default withMakeAnOfferEntry;
