import gql from "graphql-tag";

export default gql`
query notifications($first: ConnectionLimitInt, $offset: Int) {
  notifications(first: $first, offset: $offset, isAdminView: true) {
    nodes {
      _id
      message
      type
      url
      hasDetails
      details
      status
      timeSent
      highlighted
    }
  }
}
`;