import React from "react";
import PuzzleIcon from "mdi-material-ui/Puzzle";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import BundleComponentTable from "./components/BundleComponentTable";
import BundleComponent from "./components/BundleComponent";
import withBundleComponent from "./hocs/withBundleComponent";
import withBundleComponentEdit from "./hocs/withBundleComponentEdit";

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/bundle-components/:_id",
  mainComponent: BundleComponent,
  hocs: [
    withBundleComponent,
    withBundleComponentEdit
  ]
});

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: BundleComponentTable,
  path: "/bundle-components",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <PuzzleIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.bundleComponentsLabel"
});
