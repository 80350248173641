import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import MakeAnOfferTable from "./components/MakeAnOfferTable";
import MakeAnOffer from "./components/MakeAnOffer";
import withMakeAnOfferEntry from "./hocs/withMakeAnOfferEntry";

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  path: "/make-an-offer/:_id",
  mainComponent: MakeAnOffer,
  hocs: [
    withMakeAnOfferEntry
  ]
});

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: MakeAnOfferTable,
  path: "/make-an-offer",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <FontAwesomeIcon icon={faFire} {...props} />,
  sidebarI18nLabel: "admin.dashboard.makeAnOfferLabel"
});
