import React from "react";
import PackageIcon from "mdi-material-ui/Package";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import StockTable from "./components/StockTable";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 20,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: StockTable,
  path: "/stock-items",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <PackageIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.stockItemsLabel"
});
