import React from "react";

import {
  Mjml,
  MjmlAll,
  MjmlAttributes,
  MjmlBody,
  MjmlFont,
  MjmlHead,
  MjmlSection,
  MjmlText,
  MjmlTitle,
} from "../../mjml-react";

import Header from "./Header";
import Footer from "./Footer";
import FooterCaption from "./FooterCaption";
import Divider from "./Divider";
import Outro from "./Outro";

export default function Layout({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <Mjml>
      <MjmlHead>
        <MjmlTitle>{title}</MjmlTitle>
        <MjmlFont
          name="HCo Gotham"
          href="https://dr72jt4i3ro9g.cloudfront.net/fonts/hco_fonts.css"
        />
        <MjmlAttributes>
          <MjmlAll fontFamily="'HCo Gotham', Calibri, Arial" />
          <MjmlText
            fontWeight="400"
            fontSize="16px"
            color="#000000"
            lineHeight="24px"
          ></MjmlText>
          <MjmlSection padding="0px"></MjmlSection>
        </MjmlAttributes>
      </MjmlHead>
      <MjmlBody backgroundColor="#ebebeb">
        <Header />

        {children}

        <Divider />

        <Outro />

        <Footer />

        <FooterCaption />
      </MjmlBody>
    </Mjml>
  );
}
