import gql from "graphql-tag";

export default gql`
mutation setBundleComponentsCompleteMutation($input: SetBundleComponentsCompleteInput!) {
  setBundleComponentsComplete(input: $input) {
    bundleComponents {
      _id
    }
  }
}
`;
