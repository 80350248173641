import gql from "graphql-tag";
import { makeAnOfferEntryFragment } from "../fragments/makeAnOfferEntry";

export default gql`
mutation declineOffer($input: UpdateOfferInput!) {
  declineOffer(input: $input) {
    makeAnOfferEntry {
        ...MakeAnOfferEntry
      }
    }
}
${makeAnOfferEntryFragment}
`;
