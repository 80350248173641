import gql from "graphql-tag";
import { b4bLosListingFragment } from "../fragments/b4bLosListing";

export default gql`
query b4bLosListings($shopIds: [ID], $filters: B4BLosListingFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [B4BLosListingSortBy]) {
  b4bLosListings(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
    nodes {
      ...B4BLosListing
    }
    totalCount
  }
}
${b4bLosListingFragment}
`;
