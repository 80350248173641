/**
 * All config needed for the app should be parsed from environment variables in this file.
 * This goes for config needed in server code as well as config needed in browser code.
 * - If the config is needed in a Node js file, you should import it directly from here.
 * - If the config is needed in browser code, which may sometimes be executed in a browser
 *   and sometimes be executed on the server, you should import the config from here into
 *   next.config.js and add it to either `publicRuntimeConfig` or `serverRuntimeConfig`.
 *   Then use `getConfig()` to get it within React components and other browser code.
 */
const envalid = require("envalid");

/*
// Config is loaded for the `next build` command, too, and we don't want to complain
// about missing environment variables in that phase.
if (process.env.IS_BUILDING_NEXTJS) {
  module.exports = {};
} else {
*/
const { bool, num, port, str, url } = envalid;

/**
 * See https://www.npmjs.com/package/envalid
 *
 * Envalid parses NODE_ENV automatically, and provides the following
 * shortcut (boolean) properties for checking its value:
 *   env.isProduction    // true if NODE_ENV === 'production'
 *   env.isTest          // true if NODE_ENV === 'test'
 *   env.isDevelopment   // true if NODE_ENV === 'development'
 *
 * Be sure to add `{ default: null }` if it should be optional.
 */
module.exports = envalid.cleanEnv(
  process.env,
  {
    REACT_APP_OAUTH2_ADMIN_URL: str({
      desc: "An OAuth2 OpenID Connect compliant URL",
      example: "http://hydra.auth.reaction.localhost:4445",
    }),
    REACT_APP_OAUTH2_CLIENT_ID: str({
      default: "reaction-admin",
      desc: "The OAuth2 client ID to use for authentication flows from the browser",
      example: "reaction-admin",
    }),
    REACT_APP_OAUTH2_PUBLIC_URL: str({
      desc: "An OAuth2 OpenID Connect compliant URL",
      example: "http://localhost:4444",
    }),
    REACT_APP_PUBLIC_GRAPHQL_API_URL_HTTP: str({
      desc: "A URL that is accessible from browsers and accepts GraphQL POST requests over HTTP",
      example: "http://localhost:3000/graphql",
    }),
    REACT_APP_PUBLIC_GRAPHQL_API_URL_WS: str({
      default: "",
      desc: "A URL that is accessible from browsers and accepts GraphQL WebSocket connections",
      example: "ws://localhost:3000/graphql",
    }),
    REACT_APP_PUBLIC_FILES_BASE_URL: str({
      desc: "A URL that has /assets/files and /assets/uploads endpoints for uploading and downloading files",
      example: "http://localhost:3000",
    }),
    REACT_APP_PUBLIC_I18N_BASE_URL: str({
      desc: "A URL that has /locales/namespaces.json and /locales/resources.json endpoints for loading translations",
      example: "http://localhost:3000",
    }),
    REACT_APP_OAUTH2_IDP_PUBLIC_CHANGE_PASSWORD_URL: str({
      desc: "The Reaction Identity server password change URL. Uppercase EMAIL and FROM placeholders will be replaced with the user's email and a redirect URL.",
      example:
        "http://localhost:4100/account/change-password?email=EMAIL&from=FROM",
    }),
    REACT_APP_PUBLIC_STOREFRONT_HOME_URL: str({
      desc: "The URL for your storefront home page. This is only used as fallback if a URL isn't set for the shop.",
      example: "http://localhost:4000",
    }),
    REACT_APP_PUBLIC_PRICELIST_BASE_URL: str({
      desc: "The URL for api that serves the pricelist files",
      example: "http://localhost:4000",
    }),
    REACT_APP_ROOT_URL: str({
      desc: "The canonical root URL for the Reaction Admin server",
      example: "http://localhost:4080",
    }),
    REACT_APP_KIBANA_URL: str({
      desc: "The root url for the Kibana dashboard",
      example: "http://localhost:4080",
    }),
  },
  {
    // disable dotenv processing
    dotEnvPath: null,
    // https://www.npmjs.com/package/envalid#strict-mode
    strict: false,
  }
);

/*
}
*/
