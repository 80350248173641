import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Grow,
  Button,
  Card as MuiCard,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles
} from "@material-ui/core";
import CloseIcon from "mdi-material-ui/Close";
import ImportIcon from "mdi-material-ui/Download";
import Chip from "@reactioncommerce/catalyst/Chip";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    alignItems: "center"
  },
  hidden: {
    display: "none"
  },
  visible: {
    display: "block"
  },
  helpText: {
    marginLeft: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  leftChip: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3, 0)
  }
}));

/**
 * FilterByFileCard component
 * @param {Object} props Component props
 * @returns {React.Component} A React component
 */
export default function FilterByFileCard(props) {
  const {
    files,
    getInputProps,
    getRootProps,
    handleDelete,
    importFiles,
    isFilterByFileVisible,
    setFilterByFileVisible,
    fieldToFilter,
    setFieldToFilter
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("ns1");

  const cardClasses = classNames({
    [classes.hidden]: true,
    [classes.visible]: isFilterByFileVisible
  });

  return (
    <Grid item sm={12} className={cardClasses}>
      <Grow
        in={isFilterByFileVisible}
        mountOnEnter
        style={{ transformOrigin: "center top" }}
        timeout={180}
        unmountOnExit
      >
        <MuiCard>
          <CardHeader
            action={
              <IconButton aria-label="close" onClick={() => setFilterByFileVisible(false)}>
                <CloseIcon />
              </IconButton>
            }
            title={t("admin.importCard.title")}
          />
          <CardContent>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup aria-label="fieldToFilter" name="fieldToFilter" value={fieldToFilter} onChange={(event) => setFieldToFilter(event.target.value)}>
                <FormControlLabel value="internalProductId" control={<Radio />} label="Interne Produkt-ID" />
                <FormHelperText>Systeminterne Produkt-ID, etwa: 12abCd3Ef4gHi56j7</FormHelperText>
                <FormControlLabel value="productId" control={<Radio />} label="Produkt-ID" />
                <FormHelperText>Externe Frontend- und API-ID, etwa: cmVhY3Rpb24vcHJvZHVjdDoxMmFiQ2QzRWY0Z0hpNTZqNw==</FormHelperText>
                <FormControlLabel value="productNumber" control={<Radio />} label="Produktnummer" />
                <FormHelperText>Herstellereigene Produktnummer, etwa: 6XE15EA</FormHelperText>
                <FormControlLabel value="limesId" control={<Radio />} label="Limes-ID" />
                <FormHelperText>Limes-Quellsysteminterne ID, etwa: 12345</FormHelperText>
              </RadioGroup>
            </FormControl>
            {files.length > 0 ? (
              <Grid container spacing={1} className={classes.cardContainer}>
                <Grid item sm={12}>
                  {
                    files.map((file, index) => (
                      <Chip
                        label={file.name}
                        key={index}
                        className={classes.leftChip}
                        onDelete={() => handleDelete(file.name)}
                      />
                    ))
                  }
                </Grid>
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={() => importFiles(files)}
                  >
                    {t("admin.importCard.filterProducts")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} className={classes.cardContainer}>
                <Grid item sm={12}>
                  <Button
                    {...getRootProps({ className: "dropzone" })}
                    variant="contained"
                    color="primary"
                  >
                    <input {...getInputProps()} />
                    <ImportIcon className={classes.leftIcon} />
                    {t("admin.importCard.import")}
                  </Button>
                  <Typography variant="caption" display="inline" className={classes.helpText}>
                    {t("admin.importCard.importHelpText")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </MuiCard>
      </Grow>
    </Grid>
  );
}

FilterByFileCard.propTypes = {
  files: PropTypes.array,
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  handleDelete: PropTypes.func,
  importFiles: PropTypes.func,
  isFilterByFileVisible: PropTypes.bool,
  setFilterByFileVisible: PropTypes.func
};

