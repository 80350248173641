import React, { Fragment, useMemo } from "react";
import LegalNotesDaimler from "../LegalNotesDaimler";
import LegalNotesLPR from "../LegalNotesLPR";
import useVKLData from "../../../hooks/useVKLData";
import useSelection from "../../../hooks/useSelection";
import { B4BTemplate } from "../../../context/SelectionContext";
import { MjmlColumn, MjmlSection, MjmlText } from "../../../mjml-react";
import Intro from "../Intro";
import LosList from "../LosList";
import Divider from "../Divider";
import MainButton, { ButtonVariant } from "../MainButton";

export default function ManualNewsletter() {
  const { excelLinks, vendor } = useVKLData();
  const { template } = useSelection();

  const mainButtonProps = useMemo(() => {
    if (template === B4BTemplate.Invitation) {
      return {
        variant: "outlined" as ButtonVariant,
        label: "Details anschauen",
      };
    }

    if (
      template &&
      [B4BTemplate.Reminder2, B4BTemplate.Reminder3].includes(template)
    ) {
      return {
        variant: "danger" as ButtonVariant,
        label: "Gebot abgeben",
      };
    }

    return {
      label: "Zur Auktion",
    };
  }, [template]);

  return (
    <Fragment>
      <Intro />
      <LosList />
      <MainButton {...mainButtonProps} href="https://mall.remarket-now.com" />
      <Divider />
      <MjmlSection padding="10px" backgroundColor="#FFFFFF">
        <MjmlColumn>
          <MjmlText
            align="left"
            paddingRop="0px"
            fontWeight="500"
            fontSize="16px"
            padding="20px"
            paddingBottom="10px"
            color="#00334C"
          >
            Hinweise zum Auktionsverfahren
          </MjmlText>

          <MjmlText
            align="left"
            paddingTop="0px"
            fontSize="13px"
            padding="20px"
            paddingBottom="0"
            color="#00334C"
          >
            Die einzelnen Lose können jeweils nur&nbsp;
            <strong>komplett</strong> ersteigert werden. <br />
            Details zu den Losen und den angebotenen Produkten entnehmen Sie
            bitte den folgenden Dateien:
            <br />
            {excelLinks?.map((excelLink, index: number) => (
              <a
                key={`${index}-${excelLink.name}`}
                href={`https://mall.remarket-now.com/backoffice2/api/cms/cds/${excelLink.file}`}
                style={{ color: "#00b8f1", textDecoration: "underline" }}
                target="_blank"
              >
                {excelLink.name}
              </a>
            ))}
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>

      <MjmlSection padding="10px 10px 20px 10px" backgroundColor="#FFFFFF">
        <MjmlColumn>
          {vendor === "LPR" && <LegalNotesLPR />}

          {vendor === "daimler" && <LegalNotesDaimler />}
        </MjmlColumn>
      </MjmlSection>
    </Fragment>
  );
}
