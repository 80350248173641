import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation, useApolloClient } from "@apollo/react-hooks";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import makeDataTableColumnFilter from "../../shared/useDataTable-custom/makeDataTableColumnFilter";
import InlineAlert from "@reactioncommerce/components/InlineAlert/v1";
import primaryShopIdQuery from "../../../../graphql/queries/getPrimaryShopId";
import {
  Grid,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import ChevronRightIcon from "mdi-material-ui/ChevronRight";
import ChevronUpIcon from "mdi-material-ui/ChevronUp";
import CloseIcon from "mdi-material-ui/Close";
import reservationsQuery from "../graphql/queries/reservations";
import DateCell from "./TableCells/DateCell";
import AvatarCell from "../../shared/components/TableCells/AvatarCell";
import ProductNumberCell from "../../shared/components/TableCells/ProductNumberCell";
import SerialNumberCell from "../../shared/components/TableCells/SerialNumberCell";
import BarcodeCell from "./TableCells/BarcodeCell";
// import YesNoCell from "../../shared/useDataTable-custom/TableCells/YesNoCell";
// import ExpandedComponent from "./ExpandedRow";
import decodeOpaqueId from "../../../../utils/decodeOpaqueId";
import encodeOpaqueId from "../../../../utils/encodeOpaqueId";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const REMOVE_RESERVATION = gql`
  mutation removeReservationMutation($reservationId: ID!, $accountId: ID) {
    removeReservation(reservationId: $reservationId, accountId: $accountId)
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0,
  },
  buttonAsc: {
    borderTop: "2px solid",
  },
  buttonDesc: {
    borderBottom: "2px solid",
  },
}));

/**
 * @name AccountTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function AccountTable({ history }) {
  const apolloClient = useApolloClient();
  const classes = useStyles();

  const [removeReservation, { error: removeReservationError }] =
    useMutation(REMOVE_RESERVATION);

  const [totalItems, setTotalItems] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [lastFetchVariables, setLastFetchVariables] = useState({});

  const { t } = useTranslation("ns1");

  // Create and memoize the column data
  const columns = useMemo(
    () => [
      /*
    {
      // Make an expander cell
      Header: () => null, // No header
      id: "expander",
      cellProps: {
        isClickDisabled: true,
        padding: "none"
      },
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ?
            <IconButton className={classes.expandButton}>
              <ChevronUpIcon/>
            </IconButton>
            :
            <IconButton className={classes.expandButton}>
              <ChevronRightIcon/>
            </IconButton>
          }
        </span>
      ),
      Expanded: (props) => <ExpandedComponent {...props} />
    },*/
      {
        id: "selection",
        disableSorting: true,
        cellProps: {
          // Disables the cell click if the row is clickable
          // This is important if you have a callback for onRowClick, as the checkbox cell
          // will also trigger the row click.
          // Alternatively you can control the onClick with the following option
          // onClick: (event) => event.stopPropagation(),
          isClickDisabled: true,

          // All other props will be applied to the table cell.
          padding: "none",
        },
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Checkbox {...getToggleAllRowsSelectedProps()} />
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
        Cell: ({ row }) => (
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            title={`Toggle row selection for ${row.values.fullName}`}
          />
        ),
      },
      {
        Header: "Kunde",
        accessor: "account",
        id: "accountName",
        Cell: ({ cell }) => <AvatarCell account={cell.value} />,
      },
      {
        Header: "Produkttitel",
        id: "catalogProductTitle",
        accessor: "catalogProduct.title",
      },
      {
        Header: "Produktnummer",
        id: "catalogProductProductNumber",
        accessor: "catalogProduct.productNumber",
        Cell: ({ cell, row }) => (
          <ProductNumberCell productNumber={cell.value} row={row} />
        ),
        // Cell: ({ cell }) => <BarcodeCell value={cell.value} />
      },
      {
        Header: "Seriennummer",
        id: "catalogProductSerialNumber",
        disableSorting: true,
        accessor: "chosenVariant.serialNumber",
        Cell: ({ cell, row }) => (
          <SerialNumberCell serialNumber={cell.value} row={row} />
        ),
        // Cell: ({ row }) => <BarcodeCell row={row} />
      },
      {
        Header: "Anzahl",
        accessor: "quantity",
      },
      {
        // Header: "Offer ID",
        Header: "Account ID",
        accessor: "accountId",
      },
      {
        Header: "Erstelldatum",
        accessor: "createdAt",
        Cell: ({ cell }) => <DateCell value={cell.value} />,
      },
      {
        Header: "Enddatum",
        accessor: "expiresAt",
        Cell: ({ cell }) => <DateCell value={cell.value} />,
        Filter: makeDataTableColumnFilter({
          options: [
            {
              label: t("admin.reservations.table.isExpired"),
              value: "isExpired",
            },
            {
              label: t("admin.reservations.table.isNotExpired"),
              value: "isNotExpired",
            },
          ],
        }),
      },
      {
        Header: "Typ",
        accessor: "reservationType",
        Cell: ({ cell }) =>
          t(`admin.reservations.reservationType.${cell.value}`, cell.value),
      },
      /*
      {
        Header: "Status",
        accessor: "isDeleted",
        Cell: ({ cell }) => <YesNoCell value={cell.value} />,
      },
      */
    ],
    [classes.expandButton, t]
  );

  const onFetchData = useCallback(
    async ({
      globalFilter,
      filters,
      pageIndex,
      pageSize,
      sortBy,
      skipCache = false,
    }) => {
      setIsLoading(true);
      setLastFetchVariables({
        globalFilter,
        filters,
        pageIndex,
        pageSize,
        sortBy,
      });

      const { data: shopData } = await apolloClient.query({
        query: primaryShopIdQuery,
      });

      let filterObject = {};
      filters.forEach((filter) => {
        filterObject[filter.id] = filter.value;
      });

      // TODO: Add loading and error handling
      const { data } = await apolloClient.query({
        query: reservationsQuery,
        variables: {
          shopIds: [shopData.primaryShopId],
          first: pageSize,
          offset: pageIndex * pageSize,
          filters: {
            searchField: globalFilter,
            ...filterObject,
          },
          sortBy,
        },
        fetchPolicy: skipCache ? "network-only" : "cache-first",
      });

      const totalCount =
        data && data.reservations && data.reservations.totalCount;
      const nodes = data && data.reservations && data.reservations.nodes;

      setTotalItems(totalCount);
      setTableData(nodes);
      setPageCount(Math.ceil(totalCount / pageSize));
      setIsLoading(false);
    },
    [apolloClient, setTableData, setPageCount, setLastFetchVariables]
  );

  // Row click callback
  /*
  const onRowClick = useCallback(async ({ row }) => {
    const { id: decodedId } = decodeOpaqueId(row.values._id);
    if (row.values.type === "bundle") {
      history.push(`/accounts-cto/${decodedId}`);
    } else {
      history.push(`/accounts/${decodedId}`);
    }
  }, [history]);
  */

  const onRowSelect = useCallback(
    async ({ selectedRows: newSelectedRows }) => {
      setSelectedRows(newSelectedRows || []);
    },
    [setSelectedRows, tableData]
  );

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    // onRowClick,
    onRowSelect,
    initialState: {
      hiddenColumns: ["quantity", "accountId"],
      filters: [{ id: "expiresAt", value: "isNotExpired" }],
    },
  });

  const options = useMemo(
    () => [
      {
        label: "Reservierung aufheben",
        isDisabled: selectedRows.length === 0,
        onClick: async () => {
          await Promise.all(
            (selectedRows || []).map(async (rowIndex) => {
              const { _id: reservationId, accountId: opaqueAccountId } =
                tableData[rowIndex];

              const accountId = encodeOpaqueId(
                "reaction/account",
                opaqueAccountId
              );

              try {
                await removeReservation({
                  variables: {
                    accountId,
                    reservationId,
                  },
                });
              } catch (error) {
                console.error("error");
              }
            })
          );

          onFetchData({ ...lastFetchVariables, skipCache: true });
        },
      },
    ],
    [selectedRows, tableData, removeReservation]
  );

  return (
    <Grid container spacing={3}>
      {removeReservationError && (
        <Grid item sm={12}>
          <InlineAlert
            isDismissable
            components={{ iconDismiss: <CloseIcon style={{ fontSize: 14 }} /> }}
            alertType="error"
            message={removeReservationError.message}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <Card className={classes.card}>
          <CardHeader
            title={t("admin.dashboard.reservationTitle")}
            subheader={
              selectedRows.length > 0
                ? t("admin.reservations.table.bulkActions.selectedCount", {
                    count: selectedRows.length,
                  })
                : t("admin.reservations.table.bulkActions.totalCount", {
                    count: totalItems,
                  })
            }
          />
          <CardContent>
            <DataTable
              {...dataTableProps}
              actionMenuProps={{ children: "Aktionen", options }}
              placeholder={"Filter orders"}
              isFilterable
              labels={{
                "filterChipValue.isExpired": t(
                  "admin.reservations.table.isExpired"
                ),
                "filterChipValue.isNotExpired": t(
                  "admin.reservations.table.isNotExpired"
                ),
                allFilters: "Alle Filter",
                allFiltersDrawerTitle: "Alle Filter",
                clearAllFilters: "Alle Filter entfernen",
                clearFilter: "Entfernen",
                globalFilterPlaceholder: "Filter",
                next: "Nächste",
                page: "Seite",
                pageOf: ({ count }) => `von ${count}`,
                pageSizeSelect: ({ count }) => `${count} Zeilen`,
                previous: "Vorherige",
              }}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

AccountTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(AccountTable);
