import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow, TableCell, TextField, InputAdornment, IconButton, CircularProgress, makeStyles } from "@material-ui/core";
import DeleteIcon from "mdi-material-ui/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuerySuggestions } from "../../../shared/useDataTable-custom/useQuerySuggestions";

// import { createSelectable } from "react-selectable-fast";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    ...theme.typography.body2,
    padding: theme.spacing(0.5)
  },
  tableCellSelected: {
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.light
  }
}));

/**
 * @name BundleSpecificationsCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function BundleSpecificationsCell({ specifications: existingSpecifications = [], onSpecificationEdit }) {
  const classes = useStyles();
  let textInputRef = useRef(null);

  const [specifications, setSpecifications] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  const [searchTerm, setSearchTerm, setDebouncedSearchTerm, options, loading, isOpen, setIsOpen] = useQuerySuggestions();

  useEffect(() => {
    if (Array.isArray(existingSpecifications)) {
      setSpecifications(existingSpecifications);
    }
  }, [existingSpecifications]);

  /*
  const SelectableTableCell = useMemo(() =>
    createSelectable(
      ({ children, selectableRef, isSelected, isSelecting, ...props }) => {
        return (
          <TableCell
            ref={selectableRef}
            classes={{ root: isSelected && classes.tableCellSelected }}
            {...props}
          >
            {children}
          </TableCell>
        );
      }
    ), [classes]);
  */

  const handleDeleteClick = (selectedId) => {
    setSpecifications([
      ...specifications.filter((specification) => specification.specificationId !== selectedId)
    ]);
  };

  const handleAddClick = ({ _id, name }) => {
    console.log("called handleAddClick, resetting setCurrentSearchTerm");
    setSearchTerm(null);
    setDebouncedSearchTerm(null);
    setIsOpen(false);
    setSpecifications([
      ...specifications,
      { specificationId: _id, name, value: "" }
    ]);
    setCurrentSearchTerm("");
    setTimeout(() => {
      textInputRef.current.focus();
    }, 100);
  };

  const handleSpecificationEdit = (selectedId) => (event) => {
    const { value } = event.target;

    setSpecifications(specifications.map((spec) => (
      spec.specificationId === selectedId ?
        { ...spec, value }
        :
        spec
    )));

    onSpecificationEdit();
  };

  return (
    <Table size="small">
      <TableBody>
        {specifications.map((specification, index) => {
          const { name, unit, specificationId, value } = specification;

          return (
            <TableRow /* classes={{ root: classes.tableRow }}*/ key={`${index}-${specificationId}`}>
              <TableCell classes={{ root: classes.tableCell }}>{name}</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>
                <TextField
                  id="specificationValue"
                  value={value}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{unit || ""}</InputAdornment>
                  }}
                  inputProps={{
                    tabIndex: 1,
                    autoComplete: "off",
                    style: { textAlign: "center" }
                  }}
                  // inputRef={(ref) => { tentRefs.current.push(ref)}}
                  inputRef={textInputRef}
                  onChange={handleSpecificationEdit(specificationId)}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableCell }}>
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleDeleteClick(specificationId)}
                  tabIndex="-1"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}

        <TableRow>
          <TableCell classes={{ root: classes.tableCell }} colSpan={3}>
            <Autocomplete
              id="bundleSearchComplete"
              noOptionsText="Suchbegriff..."
              open={isOpen}
              onOpen={() => {
                setIsOpen(true);
              }}
              onClose={() => {
                setIsOpen(false);
              }}
              autoHighlight
              clearOnEscape
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newValue) => {
                setCurrentSearchTerm(newValue);
                setDebouncedSearchTerm(newValue);
              }}
              // inputValue={searchTerm}
              inputValue={searchTerm}
              value={null}
              onChange={(event, newValue) => handleAddClick(newValue)}
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Hinzufügen"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  inputProps={{
                    ...params.inputProps,
                    tabIndex: 1
                  }}
                  value={currentSearchTerm}
                />
              )}
            />
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>
  );
}

BundleSpecificationsCell.propTypes = {
  onSpecificationEdit: PropTypes.func,
  specifications: PropTypes.array
};
