import gql from "graphql-tag";

export const reservationFragment = gql`
  fragment Reservation on Reservation {
    _id
    productConfiguration {
      productId
      productVariantId
    }
    catalogProduct {
      title
      slug
      productId
      productNumber
      primaryImage {
        URLs {
          large
          medium
          original
          small
          thumbnail
        }
        priority
        productId
        variantId
      }
    }
    chosenVariant {
      pricing {
        compareAtPrice {
          displayAmount
        }
        price
        currency {
          code
        }
        displayPrice
      }
      primaryImage {
        URLs {
          large
          medium
          original
          small
          thumbnail
        }
        priority
        productId
        variantId
      }
      serialNumber
    }
    accountId
    reservationType
    quantity
    createdAt
    expiresAt
  }
`;
