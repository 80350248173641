import gql from "graphql-tag";
import { bundleComponentFragment } from "../fragments/bundleComponent";

export default gql`
query bundleComponentQuery($shopId: ID!, $id: ID!) {
  bundleComponent(shopId: $shopId, id: $id) {
    ...BundleComponent
  }
}
${bundleComponentFragment}
`;
