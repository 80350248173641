import gql from "graphql-tag";

export default gql `
    mutation addAccountAddressBookEntryMutation($input: AddAccountAddressBookEntryInput!) {
      addAccountAddressBookEntry(input: $input) {
        address {
          _id
          address1
          address2
          city
          country
          fullName
          phone
          postal
          region
          isCommercial
        }
      }
    }
`;
