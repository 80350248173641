import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Button } from "@reactioncommerce/catalyst";
import Zoom from "@material-ui/core/Zoom";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UndoIcon from "mdi-material-ui/Undo";
import RedoIcon from "mdi-material-ui/Redo";
import ResetIcon from "mdi-material-ui/Recycle";

import { UIContext } from "../../../../context/UIContext";
import PrimaryAppBar from "../../../../components/PrimaryAppBar";

/**
 * ProductHeader layout component
 * @returns {Node} React node
 */
function FrontendContentToolbar({
  isSaving,
  locale,
  setLocale,
  undoState,
  hasPendingChanges,
  publishHeaderSlider,
  resetHeaderSlider,
  updateHeaderSlider,
}) {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation("ns1");
  const { isDetailDrawerOpen, onToggleDetailDrawer } = useContext(UIContext);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const { undo, canUndo, redo, canRedo, reset } = undoState || {};

  return (
    <PrimaryAppBar title={t("admin.dashboard.frontendContentTitle")}>
      <Box display="flex" alignItems="center">
        <Zoom
          in={isSaving}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${isSaving ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Button>Speichern...</Button>
        </Zoom>
        <Zoom
          in={!isSaving && !hasPendingChanges}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              !isSaving && !hasPendingChanges ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Button disabled>Gespeichert</Button>
        </Zoom>
        <Zoom
          in={!isSaving && hasPendingChanges}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              !isSaving && hasPendingChanges ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Button onClick={updateHeaderSlider}>Speichern</Button>
        </Zoom>
      </Box>
      <Box display="flex" alignItems="center">
        <Tooltip title="Rückgängig">
          <IconButton key="undo" onClick={undo} disabled={!canUndo}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Wiederholen">
          <IconButton key="redo" onClick={redo} disabled={!canRedo}>
            <RedoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Live-Zustand wiederherstellen">
          <IconButton
            key="reset"
            disabled={isSaving}
            onClick={resetHeaderSlider}
          >
            <ResetIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center">
        <Button color="primary" onClick={onToggleDetailDrawer}>
          {isDetailDrawerOpen ? "Vorschau schließen" : "Vorschau"}
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        <FormControl>
          <Select
            labelId="localeselect-label"
            id="locale-select"
            value={locale}
            onChange={(event) => setLocale(event.target.value)}
          >
            <MenuItem value={"de"}> DE</MenuItem>
            <MenuItem value={"en"}> EN</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          disabled={isSaving}
          onClick={publishHeaderSlider}
        >
          {t("productDetailEdit.publish")}
        </Button>
      </Box>
    </PrimaryAppBar>
  );
}

export default FrontendContentToolbar;
