import gql from "graphql-tag";
import { headerSlideFragment } from "../fragments/headerSlide";

export default gql`
  mutation updateHeaderSlider($input: UpdateHeaderSliderInput!) {
    updateHeaderSlider(input: $input) {
      ...HeaderSlide
    }
  }
  ${headerSlideFragment}
`;
