import React, { useState, useEffect } from "react";

import useGetHTMLResult from "../hooks/useGetHTMLResult";

export default function TemplateSelection() {
  const { getResultHtml } = useGetHTMLResult();

  const [resultHtml, setResultHtml] = useState("");

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      const result = await getResultHtml();
      if (!active) {
        return;
      }
      setResultHtml(result);
    }
  }, [getResultHtml]);

  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
      <iframe
        key={resultHtml}
        frameBorder={0}
        srcDoc={resultHtml}
        width={700}
        height="100%"
      />
    </div>
  );
}
