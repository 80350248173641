import React, { useState, useEffect } from "react";
import { EditorState, Modifier, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@material-ui/core";

import useDebouncedCallback from "../../shared/useDataTable-custom/useDebouncedCallback";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    // alignItems: "flex-start",
    // justifyContent: "center",
  },
  "@global": {
    ".DraftEditor-editorContainer": {
      minHeight: 150,
    },
    ".public-DraftStyleDefault-block": {
      margin: 0,
    },
  },
}));

function CustomOption({ editorState, onChange }) {
  const addStar = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "⭐",
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <div
      // color="primary"
      // variant="outlined"
      onClick={addStar}
      className="rdw-option-wrapper"
    >
      ⭐
    </div>
  );
}

export default function DraftEditor({ value, onChange }) {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      try {
        const jsonEditorState = convertFromRaw(JSON.parse(value || {}));
        setEditorState(EditorState.createWithContent(jsonEditorState));
      } catch (error) {
        console.error("draft-error", error);
      }
    }
  }, [value]);

  const [onChangeDebounced] = useDebouncedCallback((newValue) => {
    onChange(newValue);
  }, 4000);

  return (
    <div className="App">
      <Editor
        //editorStyle={{ lineHeight: "0.95" }}
        // toolbarCustomButtons={[<CustomOption />]}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
          ],
          blockType: {
            inDropdown: true,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              "H4",
              "H5",
              "H6",
              "Blockquote",
              "Code",
              "Test",
            ],
          },
          fontSize: {
            options: [
              8,
              9,
              10,
              11,
              12,
              14,
              16,
              18,
              24,
              30,
              36,
              48,
              60,
              72,
              96,
              110,
            ],
          },
          fontFamily: {
            options: [
              "HCo Gotham",
              "Gotham",
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
          },
          colorPicker: {
            colors: [
              "rgb(0, 184, 241)",
              "rgb(0, 53, 77)",
              "rgb(97,189,109)",
              "rgb(26,188,156)",
              "rgb(84,172,210)",
              "rgb(44,130,201)",
              "rgb(147,101,184)",
              "rgb(71,85,119)",
              "rgb(204,204,204)",
              "rgb(65,168,95)",
              "rgb(0,168,133)",
              "rgb(61,142,185)",
              "rgb(41,105,176)",
              "rgb(85,57,130)",
              "rgb(40,50,78)",
              "rgb(0,0,0)",
              "rgb(247,218,100)",
              "rgb(251,160,38)",
              "rgb(235,107,86)",
              "rgb(226,80,65)",
              "rgb(163,143,132)",
              "rgb(239,239,239)",
              "rgb(255,255,255)",
              "rgb(250,197,28)",
              "rgb(243,121,52)",
              "rgb(209,72,65)",
              "rgb(184,49,47)",
              "rgb(124,112,107)",
              "rgb(209,213,216)",
            ],
          },
        }}
        editorState={editorState}
        onEditorStateChange={(newState) => {
          setEditorState(newState);
          onChangeDebounced(
            JSON.stringify(convertToRaw(editorState.getCurrentContent()))
          );
        }}
      />
    </div>
  );
}
