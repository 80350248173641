import React, { Children, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "mdi-material-ui/Menu";
import ArrowLeftIcon from "mdi-material-ui/ArrowLeft";
import { UIContext } from "../../context/UIContext";

const useStyles = makeStyles((theme) => ({
  action: {
    marginLeft: theme.spacing(),
  },
  primarySidebarOpen: {
    ...theme.mixins.leadingPaddingWhenPrimaryDrawerIsOpen,
  },
  detailDrawerOpen: {
    ...theme.mixins.trailingPaddingWhenDetailDrawerIsOpen,
    paddingRight: ({ detailDrawerWidth }) =>
      detailDrawerWidth + theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

/**
 * An AppBar for the main content area that provides a place for a title,
 * actions to the right, and a menu button for opening and closing the sidebar drawer menu
 * @param {Object} props Component props
 * @returns {React.Component} A react component
 */
function PrimaryAppBar({
  children,
  title,
  onBackButtonClick,
  shouldShowBackButton = true,
}) {
  const {
    isMobile,
    isDetailDrawerOpen,
    isPrimarySidebarOpen,
    onTogglePrimarySidebar,
    detailDrawerWidth,
  } = useContext(UIContext);

  const classes = useStyles({ detailDrawerWidth });

  const toolbarClassName = classNames({
    // Add padding to the left when the primary sidebar is open,
    // only if we're on desktop. On mobile the sidebar floats over
    // the content like a modal that's docked to either the left
    // or right side of the screen.
    [classes.primarySidebarOpen]:
      isPrimarySidebarOpen && !isDetailDrawerOpen && !isMobile,

    // Add padding to the right when the detail sidebar is open.
    // Omit on mobile as the sidebar will float over content.
    [classes.detailDrawerOpen]: isDetailDrawerOpen && !isMobile,
  });

  return (
    <AppBar>
      <Toolbar className={toolbarClassName}>
        {/* <Hidden mdUp> */}
        {!isPrimarySidebarOpen && (
          <IconButton onClick={onTogglePrimarySidebar}>
            <MenuIcon />
          </IconButton>
        )}
        {/* </Hidden> */}
        {shouldShowBackButton && onBackButtonClick && (
          <Box paddingRight={1}>
            <IconButton onClick={onBackButtonClick}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        <Typography className={classes.title} component="h1" variant="h3">
          {title}
        </Typography>
        {Children.map(children, (child) => (
          <div className={classes.action}>{child}</div>
        ))}
      </Toolbar>
    </AppBar>
  );
}

PrimaryAppBar.propTypes = {
  children: PropTypes.node,
  onBackButtonClick: PropTypes.func,
  onToggleDrawerOpen: PropTypes.func,
  shouldShowBackButton: PropTypes.bool,
  title: PropTypes.string,
};

export default PrimaryAppBar;
