import React, {useState} from "react";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import _ from "lodash";
import userAccount from "../graphql/queries/userAccount";
import AccountEditInvoiceForm from "../components/AccountEditInvoiceForm";

import { useQuery } from "@apollo/react-hooks";
import { Typography, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { styled } from "@material-ui/core";
import AccountEditShippingMenu from "../components/AccountEditShippingMenu";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function AccountEditFormContainer(props) {
    const { match } = props;
    const accountId = (match && match.params.accountId) || null;
    const mode = (match && match.params.mode) || null;
    const [ showMsg, setShowMsg ] = useState(false);
    const [ returnToList, setReturnToList ] = useState(false);
    const [ dialogMsg, setDialogMsg ] = useState("");

    const { data } = useQuery(userAccount, {
       variables: {
           accountId
       },
        fetchPolicy: 'no-cache'
    });

    const handleClose = () => {
        props.history.push("/account-manager");
    }

    const handleModalOpen = () => {
        setShowMsg(true)
    }

    const handleModalClose = () => {
        setShowMsg(false);

        if (returnToList) {
            handleClose();
        }
    }

    const getAccountDataForInvoice = (data) => {

        const invoiceAddress = _.omit(data.invoiceAddress, ["address2", "__typename", "fullName"]);

        return {
            "accountId": data._id,
            "primaryEmailAddress": data.primaryEmailAddress,
            "company": data.company,
            "invoiceAddress": {...invoiceAddress}
        }
    }

    const accountData = data && data.account;

    if (accountData) {
        return (
            <div>
                <BootstrapDialog
                    open={showMsg}
                    onClose={handleModalClose}
                    aria-labelledby={"modal-modal-title"}
                    aria-describedby={"modal-modal-description"}
                >
                    <DialogContent>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {dialogMsg}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} variant={"contained"}>
                            Close
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

                {mode === "invoice" &&
                    <AccountEditInvoiceForm
                        onClose={handleClose}
                        afterSubmit={handleModalOpen}
                        setReturnToList={setReturnToList}
                        setDialogMsg={setDialogMsg}
                        accountData={getAccountDataForInvoice(accountData)}
                    />
                }

                {mode === "shipping" &&
                    <AccountEditShippingMenu
                        onClose={handleClose}
                        afterSubmit={handleModalOpen}
                        setReturnToList={setReturnToList}
                        setDialogMsg={setDialogMsg}
                        setShowMsg={setShowMsg}
                        accountData={accountData}
                        history={props.history}
                    />
                }
            </div>
        );
    } else {
        return (<div>loading</div>);
    }
}

export default compose(
    withApollo,
    withRouter
)(AccountEditFormContainer);
