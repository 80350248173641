import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import useCurrentShopId from "../../../../hooks/useCurrentShopId";
import BundleComponentForm from "./BundleComponentForm";
import BundleComponentSpecs from "./BundleComponentSpecs";

const styles = ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  saveButton: {
    alignSelf: "flex-end"
  }
});

/**
 * @name BundleComponent
 * @param {Object} props Component props
 * @returns {React.Component} returns a React component
 */
function BundleComponent(props) {
  const { classes, bundleComponent, setBundleComponent, saveBundleComponent, pendingChanges } = props;
  const { isCompleted = false } = bundleComponent;
  const { t } = useTranslation("ns1");
  const [currentShopId] = useCurrentShopId();

  return (
    <div className={classes.container}>
      <Helmet title={`Bundle Component Details for Bundle Component with id #${bundleComponent._id}`} />
      {/* <BundleComponentAppBar bundleComponent={bundleComponent} /> */}
      <BundleComponentForm shopId={currentShopId} bundleComponent={bundleComponent} />
      <BundleComponentSpecs shopId={currentShopId} bundleComponent={bundleComponent} setBundleComponent={setBundleComponent} />

      <Button variant="contained" color="primary" className={classes.saveButton} disabled={!pendingChanges && isCompleted}
        onClick={saveBundleComponent}
      >
        {t("admin.tags.form.save")}
      </Button>
    </div>
  );
}

BundleComponent.propTypes = {
  bundleComponent: PropTypes.object,
  classes: PropTypes.object,
  order: PropTypes.object,
  pendingChanges: PropTypes.bool,
  saveBundleComponent: PropTypes.func,
  setBundleComponent: PropTypes.func
};

export default withStyles(styles, { name: "BundleComponent" })(BundleComponent);
