import React, { Fragment, useCallback, useState, useEffect } from "react";
import {
  Collapse,
  List,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  ListItem
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import useProduct from "../../../included/product-admin/hooks/useProduct";
import getPDPUrl from "../../../included/product-admin/utils/getPDPUrl";
// import VariantItemAction from "../components/VariantItemAction";
import VariantListItemContainer from "../components/VariantListItemContainer";
import formatMoney from "../../../../utils/formatMoney";

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    "&:hover $listItemAction": {
      display: "block"
    }
  },
  listItem: {
    paddingLeft: theme.spacing(7)
  },
  nested: {
    paddingLeft: theme.spacing(8)
  },
  listItemAction: {
    display: "none"
  }
}));

/**
 * Get the secondary label for the product item
 * @param {Object} item A product, variant or option
 * @returns {String} A text label with status and price
 */
/*
function getItemSecondaryLabel({ isVisible }, t) {
  const visibility = t(isVisible ? "app.visible" : "app.hidden");

  return `${visibility}`;
}
*/
function getItemSecondaryLabel({ price }, t) {
  return formatMoney(price, "EUR");
}

/**
 * Variant and Option list component
 * @summary A list component for variant and option depending on passed in props
 * @param {Object} props Component props
 * @returns {Node} React node
 */
export default function VariantList() {
  const {
    onArchiveProductVariants,
    onCreateVariant,
    onToggleVariantVisibility,
    onCloneProductVariants,
    onRestoreProduct,
    product,
    isLoading,
    variant: currentVariant
  } = useProduct();
  const classes = useStyles();
  const history = useHistory();
  const [expandedIds, setExpandedIds] = useState([]);
  const { t } = useTranslation("ns1");

  useEffect(() => {
    if (currentVariant) {
      setExpandedIds((prevState) => [...prevState, currentVariant._id]);
    }
  }, [
    currentVariant
  ]);

  const renderVariantTree = useCallback((variants, parentVariant) => {
    const toggleExpand = (itemId) => {
      setExpandedIds((prevState) => {
        const isOpen = expandedIds.includes(itemId);

        if (isOpen) {
          return prevState.filter((id) => id !== itemId);
        }

        return [...prevState, itemId];
      });
    };

    if (Array.isArray(variants)) {
      return variants.map((variant) => {
        const isExpanded = expandedIds.includes(variant._id);
        const hasChildren = Array.isArray(variant.options) && variant.options.length > 0;

        return (
          <Fragment key={`listItem-${variant._id}`}>
            <ListItem
              component="nav"
              ContainerProps={{
                className: classes.listItemContainer,
                isExpanded,
                hasChildren,
                onArrowButtonClick: () => toggleExpand(variant._id)
              }}
              ContainerComponent={VariantListItemContainer}
              className={clsx({
                [classes.listItem]: true,
                [classes.nested]: Boolean(parentVariant)
              })}
              button
              onClick={() => {
                const url = getPDPUrl(product._id, variant._id, parentVariant && parentVariant._id);
                history.push(url);

                if (!parentVariant) {
                  toggleExpand(variant._id);
                }
              }}
            >
              <ListItemText
                primary={variant.serialNumber || variant.optionTitle || variant.title || "Untitled"}
                secondary={getItemSecondaryLabel(variant, t)}
              />
              {/*
              <ListItemSecondaryAction className={classes.listItemAction}>
                
                <VariantItemAction
                  product={product}
                  variant={parentVariant || variant}
                  option={parentVariant && variant}
                  onArchiveProductVariants={onArchiveProductVariants}
                  onCreateVariant={onCreateVariant}
                  onToggleVariantVisibility={onToggleVariantVisibility}
                  onCloneProductVariants={onCloneProductVariants}
                  onRestoreProduct={onRestoreProduct}
                />
              </ListItemSecondaryAction>
               */}
            </ListItem>
            {Array.isArray(variant.options) &&
              <Collapse
                in={isExpanded}
              >
                <List
                  component="div"
                  disablePadding
                >
                  {renderVariantTree(variant.options, variant)}
                </List>
              </Collapse>
            }
          </Fragment>
        );
      });
    }

    return null;
  }, [
    expandedIds,
    classes,
    product,
    onArchiveProductVariants,
    onCreateVariant,
    onToggleVariantVisibility,
    onCloneProductVariants,
    onRestoreProduct,
    history
  ]);

  return (
    <List
      subheader={
        <ListSubheader>
          Verfügbare Lagerteile
        </ListSubheader>
      }
    >
      {product && Array.isArray(product.variants) && renderVariantTree(product.variants)}
      { isLoading &&
        Array.apply(null, { length: 5 }).map((element, index) => {
          return (
              <ListItem key={index}>
                  <ListItemText primary={
                      <Skeleton height={20} width="80%" style={{ marginBottom: 6 }} /> 
                  } secondary={<Skeleton height={17} width="40%" /> }
                  />  
              </ListItem>
          );
        })
      }
    </List>
  );
}
