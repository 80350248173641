import React from "react";
import CubeIcon from "mdi-material-ui/Cube";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import ContentViewFullLayout from "../../../layouts/ContentViewFullLayout";
import ProductTable from "./components/ProductTable";

import "./blocks";
import ProductDetailCTOLayout from "./layouts/ProductDetailCTO";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 20,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: ProductTable,
  path: "/products",
  isProduct: true,
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <CubeIcon {...props} />,
  sidebarI18nLabel: "admin.products",
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  layoutComponent: ContentViewFullLayout,
  path: "/products-cto/:handle",
  mainComponent: ProductDetailCTOLayout,
});
