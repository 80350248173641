import React from "react";
import decodeOpaqueId from "../../../../utils/decodeOpaqueId";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function ExpandedComponent(props) {
  const { original: { _id, invoiceAddress, company, datevId, vatId, primaryEmailAddress, isFamilyAndFriends, isActivated, confirmedEmail } } = props;
  const { firstName, lastName, address1, city, country, postal, phone, region } = invoiceAddress || {};

  const { id: decodedId } = decodeOpaqueId(_id);

  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <Typography variant="body1"><strong>Name: </strong>{firstName} {lastName}</Typography>
        <Typography variant="body1"><strong>Firma: </strong>{company}</Typography>
        <Typography variant="body1"><strong>Straße &amp; Hausnr.: </strong>{address1}</Typography>
        <Typography variant="body1"><strong>Stadt: </strong>{city}</Typography>
        <Typography variant="body1"><strong>PLZ: </strong>{postal}</Typography>
        <Typography variant="body1"><strong>Region: </strong>{region}</Typography>
        <Typography variant="body1"><strong>Land: </strong>{country}</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body1"><strong>Firma: </strong>{company}</Typography>
        <Typography variant="body1"><strong>E-Mail: </strong>{primaryEmailAddress}</Typography>
        <Typography variant="body1"><strong>Telefon: </strong>{phone}</Typography>
        <Typography variant="body1"><strong>Datev-ID: </strong>{datevId}</Typography>
        <Typography variant="body1"><strong>VAT-ID: </strong>{vatId}</Typography>
        <Typography variant="body1"><strong>Account-ID: </strong>{decodedId}</Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="body1"><strong>Email bestätigt: </strong>{confirmedEmail ? "Ja" : "Nein"}</Typography>
        <Typography variant="body1"><strong>Freigeschaltet: </strong>{isActivated ? "Ja" : "Nein"}</Typography>
        <Typography variant="body1"><strong>Nutzertyp: </strong>{isFamilyAndFriends ? "Family & Friends" : "Geschäftskunde"}</Typography>
      </Grid>
    </Grid>
  );
}
