import React from "react";
import { withRouter } from "react-router";
import { registerOperatorRoute } from "../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import ProductClassTable from "./components/ProductClassTable";
import ProductClassForm from "./components/table/ProductClassForm";
import withProductClass from "./hocs/withProductClass";
import withProductClassEdit from "./hocs/withProductClassEdit";

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  isProductMetadata: true,
  path: "/product-classes/create",
  mainComponent: ProductClassForm,
  hocs: [
    withRouter,
    withProductClass,
    withProductClassEdit
  ]
});

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  isProductMetadata: true,
  path: "/product-classes/:productClassId",
  mainComponent: ProductClassForm,
  hocs: [
    withRouter,
    withProductClass,
    withProductClassEdit
  ]
});

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  isProductMetadata: true,
  mainComponent: ProductClassTable,
  path: "/product-classes",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <FontAwesomeIcon icon={faObjectGroup} {...props} />,
  sidebarI18nLabel: "admin.dashboard.productClassLabel"
});
