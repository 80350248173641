import React from "react";

import Translation from "../../components/Translation";
import { MjmlColumn, MjmlSection, MjmlText } from "../../mjml-react";
import useSelection from "../../hooks/useSelection";
import { B4BTemplate } from "../../context/SelectionContext";

export default function Outro() {
  const { template } = useSelection();

  const showSuccess = true;

  return (
    <MjmlSection padding="10px 0px 20px 0px" backgroundColor="#FFFFFF">
      <MjmlColumn>
        {showSuccess && (
          <MjmlText
            align="left"
            paddingTop="0px"
            fontSize="25px"
            padding="20px"
            paddingBottom="5px"
            color="#00334C"
          >
            <Translation tKey="outroLine1" />
          </MjmlText>
        )}
        <MjmlText
          align="left"
          color="#82C539"
          paddingTop="0px"
          fontSize="25px"
          padding="20px"
          paddingBottom="40px"
        >
          <Translation tKey="outroLine2" />
        </MjmlText>
      </MjmlColumn>
    </MjmlSection>
  );
}
