import React from "react";
import CalendarIcon from "mdi-material-ui/CalendarToday";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import ReservationTable from "./components/ReservationTable";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 20,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: ReservationTable,
  path: "/reservations",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <CalendarIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.reservationLabel"
});
