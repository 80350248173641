import React from "react";
import PropTypes from "prop-types";
import Button from "@reactioncommerce/catalyst/Button";
import { useTranslation } from "react-i18next";
import PrimaryAppBar from "../../../../components/PrimaryAppBar/PrimaryAppBar";

/**
 * Tag toolbar component
 * @param {Object} props Component props
 * @returns {React.Component} Tag toolbar component
 */
function DetailToolbar(props) {
  const { canBeDeleted, onDelete, onCancel, onSave, title } = props;
  const { t } = useTranslation("ns1");

  return (
    <PrimaryAppBar title={title}>
      {(canBeDeleted) &&
        <Button
          isTextOnly={true}
          onClick={onDelete}
        >
          {t("admin.tags.form.delete")}
        </Button>
      }
      <Button variant="outlined" onClick={onCancel}>
        {t("admin.tags.form.cancel")}
      </Button>
      <Button variant="contained" color="primary" onClick={onSave}>
        {t("admin.tags.form.saveChanges")}
      </Button>
    </PrimaryAppBar>
  );
}

DetailToolbar.propTypes = {
  canBeDeleted: PropTypes.bool,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string
};

DetailToolbar.defaultProps = {
  allowsDeletion: true,
  isNew: true
};

export default DetailToolbar;
