import React from "react";
import FileTableIcon from "mdi-material-ui/FileTable";
import { registerOperatorRoute } from "../../../routes";
import LosDetailView from "./components/LosDetailView";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import B4BLosListingTable from "./components/B4BLosListingTable";

registerOperatorRoute({
  isNavigationLink: false,
  isSetting: false,
  isProductMetadata: true,
  path: "/b4b-listings/create",
  mainComponent: LosDetailView
});

registerOperatorRoute({
    isNavigationLink: false,
    isSetting: false,
    path: "/b4b-listings/:_id",
    mainComponent: LosDetailView
});
  
registerOperatorRoute({
  isNavigationLink: true,
  specialAccessId: "b4b-lose",
  // isNavigationLink: false,
  isSetting: false,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: B4BLosListingTable,
  path: "/b4b-listings",
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <FileTableIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.b4bLosListingsLabel"
});