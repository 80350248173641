import gql from "graphql-tag";

export default gql`
  mutation updateManyProductVariantPrices($input: UpdateManyProductVariantPricesInput!) {
    updateManyProductVariantPrices(input: $input) {
      variants {
        _id
      }
    }
  }
`;
