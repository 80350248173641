import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import CircleIcon from "mdi-material-ui/CheckboxBlankCircle";

const useStyles = makeStyles((theme) => ({
  trueCircle: {
    color: theme.palette.colors.forestGreen300,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  },
  falseCircle: {
    color: theme.palette.colors.black40,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  }
}));

/**
 * @name TrueFalseCell
 * @param {Boolean} value
 * @return {React.Component} A date component
 */
export default function TrueFalseCell({ value }) {
  const classes = useStyles();
  const { t } = useTranslation("ns1");

  return (
    <div style={{ display: "flex" }}>
      <Tooltip title={value ? "Ja" : "Nein"}>
        <CircleIcon className={value ? classes.trueCircle :  classes.falseCircle}/>
      </Tooltip>
    </div>
  );
}

TrueFalseCell.propTypes = {
  value: PropTypes.bool
};
