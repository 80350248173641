import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import updateBundleComponentMutation from "../graphql/mutations/updateBundleComponent";

const withBundleComponentEdit = (Comp) => {
  /**
   * @name BundleContainer
   * @param {Object} props Component props
   * @returns {React.Component} returns a React component
   */
  function WithBundleComponentEdit(props) {
    const { bundleComponent: bundleComponentFromQuery, shopId, refetchBundleComponent } = props;
    const [bundleComponent, setBundleComponent] = useState(bundleComponentFromQuery);
    const [pendingChanges, setPendingChanges] = useState(false);
    const [updateBundleComponent, { loading: updateBundleComponentLoading }] = useMutation(updateBundleComponentMutation);

    useEffect(() => {
      setBundleComponent(bundleComponentFromQuery);
    }, [bundleComponentFromQuery]);

    const editBundleComponent = (newComponent) => {
      setBundleComponent(newComponent);
      setPendingChanges(true);
    };

    const specificationsToInsert = Array.isArray(bundleComponent.specifications) && bundleComponent.specifications.map((spec) => ({ specificationId: spec.specificationId, value: spec.value })) || [];

    const saveBundleComponent = async () => {
      // setBundleComponent(newComponent);
      await updateBundleComponent({
        variables: {
          input: {
            shopId,
            _id: bundleComponent._id,
            specifications: specificationsToInsert
          }
        }
      });
      refetchBundleComponent();
      setPendingChanges(false);
    };

    return (
      <Fragment>
        <Prompt
          when={pendingChanges}
          message="Es gibt ungespeicherte Änderungen. Wirklich verlassen?"
        />
        <Comp
          {...props}
          setBundleComponent={editBundleComponent}
          saveBundleComponent={saveBundleComponent}
          pendingChanges={pendingChanges}
          isSaving={updateBundleComponentLoading}
          bundleComponent={bundleComponent}
        />
      </Fragment>
    );
  }

  WithBundleComponentEdit.propTypes = {
  };

  return WithBundleComponentEdit;
};

export default withBundleComponentEdit;
