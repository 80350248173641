import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import useDataTable from "../../shared/useDataTable-custom/useDataTable";
import DataTable from "../../shared/useDataTable-custom/DataTable";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import primaryShopIdQuery from "../../../../graphql/queries/getPrimaryShopId";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Card, CardHeader, CardContent, Button, makeStyles } from "@material-ui/core";
import VisibilityCell from "./TableCells/VisibilityCell";
import productSpecGroupsQuery from "../graphql/queries/productSpecGroups";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";  

/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible"
  },
  button: {
    color: theme.palette.colors.coolGrey500,
    fontWeight: 600,
    borderRadius: 0
  },
  buttonAsc: {
    borderTop: "2px solid"
  },
  buttonDesc: {
    borderBottom: "2px solid"
  },
  expandButton: {
    marginLeft: theme.spacing(1)
  },
  newButton: {
    margin: theme.spacing(0, 1)
  }
}));

/**
 * @name ProductSpecGroupTable
 * @param {Object} history Browser history API
 * @returns {React.Component} A React component
 */
function ProductSpecGroupTable({ history }) {
  const apolloClient = useApolloClient();
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("ns1");

  // Create and memoize the column data
  const columns = useMemo(() => [
    {
      id: "selection",
      disableSorting: true,
      cellProps: {
        // Disables the cell click if the row is clickable
        // This is important if you have a callback for onRowClick, as the checkbox cell
        // will also trigger the row click.
        // Alternatively you can control the onClick with the following option
        // onClick: (event) => event.stopPropagation(),
        isClickDisabled: true,

        // All other props will be applied to the table cell.
        padding: "none"
      },
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <Checkbox {...getToggleAllRowsSelectedProps()} />
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      // eslint-disable-next-line react/no-multi-comp,react/display-name,react/prop-types
      Cell: ({ row }) => (
        <Checkbox
          {...row.getToggleRowSelectedProps()}
          title={`Toggle row selection for ${row.values.fullName}`}
        />
      )
    },
    {
      Header: "ID",
      accessor: "_id"
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Sichtbarkeit",
      accessor: "isVisible",
      Cell: ({ row }) => <VisibilityCell row={row} />
    }
  ]);

  const onFetchData = useCallback(async ({ globalFilter, filters, pageIndex, pageSize, sortBy }) => {
    setIsLoading(true);

    const { data: shopData } = await apolloClient.query({
      query: primaryShopIdQuery
    });

    let filterObject = {};
    filters.forEach((filter) => {
      filterObject[filter.id] = filter.value;
    });

    // TODO: Add loading and error handling
    const { data } = await apolloClient.query({
      query: productSpecGroupsQuery,
      variables: {
        shopIds: [shopData.primaryShopId],
        first: pageSize,
        offset: pageIndex * pageSize,
        filters: {
          searchField: globalFilter,
          ...filterObject
        },
        sortBy
      }
    });

    const totalCount = data && data.productSpecGroups && data.productSpecGroups.totalCount;
    const nodes = data && data.productSpecGroups && data.productSpecGroups.nodes;

    setTableData(nodes);
    setPageCount(Math.ceil(totalCount / pageSize));
    setIsLoading(false);
  }, [apolloClient, setTableData, setPageCount]);

  // Row click callback
  const onRowClick = useCallback(async ({ row }) => {
    history.push(`/product-spec-groups/${row.values._id}`);
  }, [history]);

  const onRowSelect = useCallback(async ({ selectedRows: newSelectedRows }) => {
    setSelectedRows(newSelectedRows || []);
  }, [setSelectedRows, tableData]);

  const dataTableProps = useDataTable({
    data: tableData,
    columns,
    onFetchData,
    pageCount,
    onRowClick,
    onRowSelect,
    initialState: {hiddenColumns: ["_id"] }
  });

  /*
  const options = useMemo(() => [{
    label: t("admin.productSpecGroups.form.createNew"),
    onClick: (data) => {
      history.push("/product-spec-groups/create");
    }
  }], [selectedRows]);
  */

  return (
    <Card className={classes.card}>
      <CardHeader title={t("admin.dashboard.productSpecGroupsTitle")} />
      <CardContent>
        <Link to="/product-spec-groups/create">
          <Button variant="contained" color="primary" className={classes.newButton}>
            Neu Produktspezifikationsgruppe
          </Button>
        </Link>
        <DataTable
          {...dataTableProps}
          // actionMenuProps={{ children: "Aktionen", options }}
          placeholder={"Filter orders"}
          isFilterable
          labels={{
            "filterChipValue.completed": t("admin.table.completedStatus.completed"),
            "filterChipValue.uncompleted": t("admin.table.completedStatus.uncompleted"),
            "allFilters": "Alle Filter",
            "allFiltersDrawerTitle": "Alle Filter",
            "clearAllFilters": "Alle Filter entfernen",
            "clearFilter": "Entfernen",
            "globalFilterPlaceholder": "Filter",
            "next": "Nächste",
            "page": "Seite",
            "pageOf": ({ count }) => `von ${count}`,
            "pageSizeSelect": ({ count }) => `${count} Zeilen`,
            "previous": "Vorherige"
          }}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
}

ProductSpecGroupTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(ProductSpecGroupTable);
