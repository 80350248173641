import React from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PreviewDrawer from "./PreviewDrawer";

import ContentViewPrimaryDetailLayout from "../../../../layouts/ContentViewPrimaryDetailLayout";
import FrontendContentToolbar from "./FrontendContentToolbar";

import config from "../../../../config";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  content: {
    color: theme.palette.text.secondary,
  },
  iframeContainer: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

const {
  REACT_APP_PUBLIC_FILES_BASE_URL,
  REACT_APP_PUBLIC_STOREFRONT_HOME_URL,
} = config;

function VendorGridCard({ vendor, locale, setLocale, PrimaryComponent }) {
  const classes = useStyles();
  const { t } = useTranslation("ns1");

  const previewUrl = `${REACT_APP_PUBLIC_STOREFRONT_HOME_URL}/api/preview?secret=Tj4GJGxo9BiJvf8VWjgQVp&route=/${locale}/landing/${vendor}`;

  return (
    <ContentViewPrimaryDetailLayout
      AppBarComponent={
        <FrontendContentToolbar
          locale={locale}
          setLocale={setLocale}
          //undoState={undoState}
        />
      }
      PrimaryComponent={PrimaryComponent}
      DetailComponent={
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardHeader title={t("admin.frontEndContent.mainSliderTitle")} />
            <CardContent>VendorGridCard {vendor}</CardContent>
          </Card>
          <PreviewDrawer previewUrl={previewUrl}>
            <iframe
              // key={headerSlider && headerSlider.updatedAt}
              src={previewUrl}
              className={classes.iframeContainer}
            />
          </PreviewDrawer>
        </Container>
      }
    />
  );
}

export default VendorGridCard;
