import React, {Fragment, useState} from "react";
import { Form, muiOptions, useReactoForm } from "reacto-form";
import updateAccountMutation from "../graphql/mutations/updateAccount";
import { useMutation } from "@apollo/react-hooks";
import SimpleSchema from "simpl-schema";
import countryOptions from "../../../../utils/CountryOptions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactPhoneInput from "react-phone-input-material-ui";
import { makeStyles } from "@material-ui/core";

import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    submitButton: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.colors.white
    },
}));

function AccountEditInvoiceForm(props) {
    const { accountData, onClose, afterSubmit, setReturnToList, setDialogMsg } = props;
    const [ updateAccount ] = useMutation(updateAccountMutation);
    const { invoiceAddress } = accountData;
    const [ phoneCode, setPhoneCode ] = useState(null);
    const classes = useStyles();

    const formSchema = new SimpleSchema({
        "accountId": {
            type: String,
            optional: true
        },
        "invoiceAddress": {
            type: Object,
            optional: true
        },
        "invoiceAddress.street": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.houseNumber": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.address1": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.city": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.postal": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.country": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.region": {
            type: String,
            optional: true
        },
        "invoiceAddress.phone": {
            type: String,
            min: 8,
            optional: true
        },
        "invoiceAddress.firstName": {
            type: String,
            min: 1,
            optional: true
        },
        "invoiceAddress.lastName": {
            type: String,
            min: 1,
            optional: true
        },
        "primaryEmailAddress": {
            type: String,
            min: 1,
            optional: true,
            regEx: SimpleSchema.RegEx.Email
        },
        "company": {
            type: String,
            min: 1,
            optional: true
        }
    });

    const validator = formSchema.getFormValidator();

    const { getErrors, hasErrors, getInputProps, submitForm, formData, isDirty } = useReactoForm({
        async onSubmit(formData) {
            let is_ok = false;
            try {
                let updateData = {...formData};

                const invoiceAddress = accountData.invoiceAddress;

                const street = formData.invoiceAddress?.street || invoiceAddress.street;
                const houseNumber = formData.invoiceAddress?.houseNumber || invoiceAddress.houseNumber;
                const address1 = street + " " + houseNumber;

                const firstName = formData.invoiceAddress?.firstName || invoiceAddress.firstName;
                const lastName = formData.invoiceAddress?.lastName || invoiceAddress.lastName;
                const fullName = firstName + " " + lastName;

                if (
                    (address1 !== invoiceAddress.address1 || fullName !== invoiceAddress.fullName) &&
                    (updateData.invoiceAddress === undefined || updateData.invoiceAddress === null)
                ) {
                    updateData['invoiceAddress'] = {};
                }

                if (address1 !== invoiceAddress.address1) {
                    updateData.invoiceAddress['address1'] = address1;
                }

                if (fullName !== invoiceAddress.fullName) {
                    updateData.invoiceAddress['fullName'] = fullName;
                }

                await updateAccount({
                    variables: {
                        input: updateData
                    }
                });

                setDialogMsg("Success: Invoice Address updated");
                setReturnToList(true);
                is_ok = true;
            } catch (error) {
                const errorMsg = error.message.split(": ")[1];
                setDialogMsg("Error: " + errorMsg);
                setReturnToList(false);
            }

            afterSubmit();
            return { ok: is_ok };
        },
        validator,
        value: accountData
    });

    if (Object.keys(accountData).length === 0) {
        return (<div>loading...</div>);
    }

    // TODO: translation
    return (
        <Card>
            <CardHeader
                title={"Edit Invoice Address/E-mail Address"}
                titleTypographyProps={{variant:'h1'}}
            />
            <CardContent>

                <Form>
                    <Grid container spacing={2} style={({padding: '25px'})}>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                {...getInputProps("invoiceAddress.firstName", muiOptions)}
                                error={hasErrors(["invoiceAddress.firstName"])}
                                helperText={
                                    getErrors(["invoiceAddress.firstName"]).length
                                        ? getErrors(['invoiceAddress.firstName']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                id={'firstName'}
                                label={'First Name'}
                                value={(
                                        (formData.invoiceAddress && formData.invoiceAddress?.firstName) ||
                                        (formData.invoiceAddress?.firstName === "")
                                    ) ? formData.invoiceAddress.firstName : invoiceAddress.firstName
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                {...getInputProps("invoiceAddress.lastName", muiOptions)}
                                error={hasErrors(["invoiceAddress.lastName"])}
                                helperText={
                                    getErrors(["invoiceAddress.lastName"]).length
                                        ? getErrors(['invoiceAddress.lastName']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                id={'lastName'}
                                label={'Last Name'}
                                value={(
                                        (formData.invoiceAddress && formData.invoiceAddress?.lastName) ||
                                        (formData.invoiceAddress?.lastName === "")
                                    ) ? formData.invoiceAddress.lastName : invoiceAddress.lastName
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("company", muiOptions)}
                                error={hasErrors(["company"])}
                                helperText={
                                    getErrors(["company"]).length
                                        ? getErrors(['company']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'Company'}
                                value={(formData.company || (formData.company === ""))
                                    ? formData.company : accountData.company}
                                id={'company'}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("invoiceAddress.street", muiOptions)}
                                error={hasErrors(["invoiceAddress.street"])}
                                helperText={
                                    getErrors(["invoiceAddress.street"]).length
                                        ? getErrors(['invoiceAddress.street']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                id={'street'}
                                label={'Street'}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.street) || (formData.invoiceAddress?.street === "")) ?
                                    formData.invoiceAddress.street : invoiceAddress.street}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                {...getInputProps("invoiceAddress.houseNumber", muiOptions)}
                                error={hasErrors(["invoiceAddress.houseNumber"])}
                                helperText={
                                    getErrors(["invoiceAddress.houseNumber"]).length
                                        ? getErrors(['invoiceAddress.houseNumber']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'House No.'}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.houseNumber) || (formData.invoiceAddress?.houseNumber === "")) ?
                                    formData.invoiceAddress.houseNumber : invoiceAddress.houseNumber}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("invoiceAddress.city", muiOptions)}
                                error={hasErrors(["invoiceAddress.city"])}
                                helperText={
                                    getErrors(["invoiceAddress.city"]).length
                                        ? getErrors(['invoiceAddress.city']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'City'}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.city) || (formData.invoiceAddress?.city === "")) ?
                                    formData.invoiceAddress.city : invoiceAddress.city}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("invoiceAddress.postal", muiOptions)}
                                error={hasErrors(["invoiceAddress.postal"])}
                                helperText={
                                    getErrors(["invoiceAddress.postal"]).length
                                        ? getErrors(['invoiceAddress.postal']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'Zip Code'}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.postal) || (formData.invoiceAddress?.postal === "")) ?
                                    formData.invoiceAddress.postal : invoiceAddress.postal}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("invoiceAddress.region", muiOptions)}
                                error={hasErrors(["invoiceAddress.region"])}
                                helperText={
                                    getErrors(["invoiceAddress.region"]).length
                                        ? getErrors(['invoiceAddress.region']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'Region'}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.region) || (formData.invoiceAddress?.region === "")) ?
                                    formData.invoiceAddress.region : invoiceAddress.region}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                {...getInputProps("invoiceAddress.country", muiOptions)}
                                id={"invoice-country"}
                                options={countryOptions}
                                getOptionLabel={(option) => {
                                    if (option && option.value) return option.value;
                                    return option || "";
                                }}
                                renderOption={(option) => option.label}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={hasErrors(["invoiceAddress.country"])}
                                        helperText={
                                            getErrors(["invoiceAddress.country"]).length
                                                ? getErrors(['invoiceAddress.country']).map((error, index) => (
                                                    <React.Fragment key={index}>{error.message}</React.Fragment>
                                                )) : ""
                                        }
                                        label={'Country'}
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                getOptionSelected={(option, value) => {
                                    return option.value === value;
                                }}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.country) || (formData.invoiceAddress?.country === "")) ?
                                    formData.invoiceAddress.country : invoiceAddress.country}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setPhoneCode(value.value.toLowerCase());
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReactPhoneInput
                                {...getInputProps("invoiceAddress.phone", muiOptions)}
                                value={((formData.invoiceAddress && formData.invoiceAddress?.phone) || (formData.invoiceAddress?.phone === "")) ?
                                    formData.invoiceAddress?.phone : invoiceAddress.phone}
                                onChange={(phoneNum) => {
    
                                }}
                                component={TextField}
                                country={phoneCode || "de"}
                                isValid={!hasErrors(["invoiceAddress.phone"])}
                                placeholder={" "}
                                id={`phone`}
                                autoComplete="phone"
                                label={'Phone'}
                                fullWidth
                            />
                            {hasErrors(["invoiceAddress.phone"]) &&
                                (getErrors(["invoiceAddress.phone"]).length
                                    ? getErrors(["invoiceAddress.phone"]).map((error, index) => (
                                        <Fragment key={index}>
                                            <div style={{ color: "red" }}>{error.message}</div>
                                        </Fragment>
                                    ))
                                    : "")
                            }
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...getInputProps("primaryEmailAddress", muiOptions)}
                                error={hasErrors(["primaryEmailAddress"])}
                                helperText={
                                    getErrors(["primaryEmailAddress"]).length
                                        ? getErrors(['primaryEmailAddress']).map((error, index) => (
                                            <React.Fragment key={index}>{error.message}</React.Fragment>
                                        )) : ""
                                }
                                label={'E-mail'}
                                value={(formData.primaryEmailAddress || (formData.primaryEmailAddress === ""))
                                    ? formData.primaryEmailAddress : accountData.primaryEmailAddress}
                                id={'email'}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Form>

                <Grid
                    container
                    justify={"flex-end"}
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            id={'cancelBtn'}
                            onClick={onClose}
                            variant={"outlined"}
                            display={"flex"}
                            m={1}
                        >
                            Cancel
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            id={'submitBtn'}
                            onClick={submitForm}
                            variant={"contained"}
                            className={classes.submitButton}
                            disabled={!isDirty}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default (AccountEditInvoiceForm);
