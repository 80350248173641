import gql from "graphql-tag";
import { productLineFragment } from "../fragments/productLine";

export default gql`
  query productLinesQuery($shopIds: [ID], $filters: ProductLinesFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ProductLineSortBy]) {
    productLines(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...ProductLine
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${productLineFragment}
`;
