import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import ContentViewPrimaryDetailLayout from "../../../../layouts/ContentViewPrimaryDetailLayout";
import FrontendContentToolbar from "./FrontendContentToolbar";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "mdi-material-ui/ChevronDown";
import ChevronRightIcon from "mdi-material-ui/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import MainSliderCard from "./MainSliderCard";
import VendorGridCard from "./VendorGridCard";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  content: {
    color: theme.palette.text.secondary,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
}));

const data = {
  id: "root",
  name: "Weblounge",
  children: [
    {
      id: "start",
      name: "Startseite",
      children: [
        {
          id: "slider",
          name: "Slider",
        },
      ],
    },
    {
      id: "vendorLanding",
      name: "Hersteller Landingpage",
      children: [
        {
          id: "hp",
          name: "HP",
        },
        {
          id: "sharp",
          name: "Sharp",
        },
        {
          id: "epson",
          name: "Epson",
        },
      ],
    },
  ],
};

function traverse(object, id, path, callbackFunc) {
  const ownPath = path.slice();
  ownPath.push(object.id);

  if (object.id === id) {
    return callbackFunc(ownPath);
  }
  if (object.children) {
    object.children.forEach((child) =>
      traverse(child, id, ownPath, callbackFunc)
    );
  }
}

function ContentTable({ history, match }) {
  const { params } = match;
  const _id = params && params._id && `${params._id}`;

  const classes = useStyles();
  const { t } = useTranslation("ns1");
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(_id);

  // Toolbar
  const [locale, setLocale] = useState("de");

  useEffect(() => {
    const callbackFunc = (path) => {
      setExpanded(path);
    };
    traverse(data, _id, [], callbackFunc);
  }, [data]);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      classes={{
        content: classes.content,
        label: classes.label,
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (nodeIds === "start") {
      setSelected("slider");
      return history.push("/frontend-content/slider");
    }

    setSelected(nodeIds);
    history.push(`/frontend-content/${nodeIds}`);
  };

  const PrimaryComponent = (
    <Box padding={2}>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {renderTree(data)}
      </TreeView>
    </Box>
  );

  if (selected === "slider") {
    return (
      <MainSliderCard
        locale={locale}
        setLocale={setLocale}
        PrimaryComponent={PrimaryComponent}
      />
    );
  }

  if (["hp", "lg", "kyocera", "epson", "samsung", "sharp"].includes(selected)) {
    return (
      <VendorGridCard
        vendor={selected}
        locale={locale}
        setLocale={setLocale}
        PrimaryComponent={PrimaryComponent}
      />
    );
  }

  return <ContentViewPrimaryDetailLayout PrimaryComponent={PrimaryComponent} />;
}

export default withRouter(ContentTable);
