import React from "react";
import { makeStyles } from "@material-ui/core";
import config from "../../../../config";

const { REACT_APP_KIBANA_URL } = config;

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none"
  }
}));

export default function Iframe() {
  const classes = useStyles();

  return (
    <iframe src={REACT_APP_KIBANA_URL} className={classes.iframeContainer} />
  );
}
