import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";
import useReactoForm from "reacto-form/cjs/useReactoForm";
import SimpleSchema from "simpl-schema";
import muiOptions from "reacto-form/cjs/muiOptions";
import { Button, TextField } from "@reactioncommerce/catalyst";
import useProduct from "../hooks/useProduct";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginBottom: theme.spacing(4)
  }
}));

const formSchema = new SimpleSchema({
  price: {
    type: Number,
    optional: true
  },
  compareAtPrice: {
    type: Number,
    optional: true
  }
});

const validator = formSchema.getFormValidator();

/**
 * @name VariantPricesForm
 * @param {Object} props Component props
 * @param {Object} ref Forwarded ref
 * @returns {React.Component} Variant form React component
 */
const VariantPricesForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("ns1");

  const {
    currentVariant,
    onUpdateProductVariantPrices,
    product
  } = useProduct();

  const {
    getFirstErrorMessage,
    getInputProps,
    hasErrors,
    isDirty,
    submitForm
  } = useReactoForm({
    async onSubmit(formData) {
      setIsSubmitting(true);

      await onUpdateProductVariantPrices({
        variantId: currentVariant._id,
        variantPrices: formSchema.clean(formData)
      });

      setIsSubmitting(false);
    },
    validator(formData) {
      return validator(formSchema.clean(formData));
    },
    value: currentVariant
  });

  const isSaveDisabled = !product || !isDirty || isSubmitting;

  return (
    <Card className={classes.card} ref={ref}>
      <CardHeader title={t("productVariant.prices")} />
      <CardContent>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["price"])}
                fullWidth
                helperText={getFirstErrorMessage(["price"]) || t("admin.helpText.price")}
                label={t("productVariant.price")}
                placeholder="0.00"
                {...getInputProps("price", muiOptions)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                type="numeric"
                className={classes.textField}
                error={hasErrors(["compareAtPrice"])}
                fullWidth
                helperText={getFirstErrorMessage(["compareAtPrice"]) || t("admin.helpText.compareAtPrice")}
                label={t("productVariant.compareAtPrice")}
                placeholder="0.00"
                {...getInputProps("compareAtPrice", muiOptions)}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {!isSaveDisabled &&
              <Box paddingRight={2}>
                <Typography>
                  {t("productVariant.pricePublishWarning")}
                </Typography>
              </Box>
            }
            <Button
              color="primary"
              disabled={isSaveDisabled}
              isWaiting={isSubmitting}
              type="submit"
              variant="contained"
            >
              {t("app.saveChanges")}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
});

export default VariantPricesForm;