import React from "react";
import PenIcon from "mdi-material-ui/Pen";
import { registerOperatorRoute } from "../../../routes";
import ContentViewFullLayout from "../../../layouts/ContentViewFullLayout";
import ContentTable from "./components/ContentTable";

registerOperatorRoute({
    isNavigationLink: false,
    mainComponent: ContentTable,
    layoutComponent: ContentViewFullLayout,
    path: "/frontend-content/:_id"
  });

registerOperatorRoute({
    isNavigationLink: true,
    // isNavigationLink: false,
    isSetting: false,
    layoutComponent: ContentViewFullLayout,
    mainComponent: ContentTable,
    path: "/frontend-content",
    // eslint-disable-next-line react/display-name
    SidebarIconComponent: (props) => <PenIcon {...props} />,
    sidebarI18nLabel: "admin.dashboard.frontendContentLabel"
  });