import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Link } from "@material-ui/core";
import decodeOpaqueId from "../../../../../utils/decodeOpaqueId.js";

/**
 * @name OrderIdCell
 * @param {Object} row A react-table row object
 * @param {Object} history Router history API
 * @return {React.Component} A date component
 */
function OrderIdCell({ row, history }) {
  const { id: decodedId } = decodeOpaqueId(row.values._id);

  const handleClick = () => {
    history.push(`/orders/${decodedId}`);
  };

  return (
    <Fragment>
      <Link onClick={handleClick}>
        {decodedId}
      </Link>
    </Fragment>
  );
}

OrderIdCell.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  row: PropTypes.object.isRequired
};

export default withRouter(OrderIdCell);
