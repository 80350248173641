import React, { Fragment } from "react";

import { MjmlColumn, MjmlImage, MjmlSection, MjmlText } from "../../mjml-react";

export default function Footer() {
  return (
    <Fragment>
      <MjmlSection backgroundColor="#FFFFFF" padding="0">
        <MjmlColumn>
          <MjmlText
            paddingBottom="10px"
            paddingTop="30px"
            paddingLeft="20px"
            paddingRight="20px"
            fontSize="14px"
            lineHeight="18px"
            color="#00354c"
          >
            <strong>CDS Business Services GmbH</strong> <br />
            Edisonstr. 19
            <br />
            D-32791 Lage / Lippe <br />
            <br />
            <span style={{ color: "#00b8f1" }}>
              <strong>T</strong>
            </span>
            &nbsp;+49 (0) 52 32 / 95 54 – 525 <br />
            <span style={{ color: "#00b8f1" }}>
              <strong>E</strong>
            </span>
            <a
              href="mailto:sales@remarket-now.com"
              style={{ color: "#00b8f1", textDecoration: "underline" }}
            >
              &nbsp;sales@remarket-now.com
            </a>
            <br />
            {/*
            <br /> Geschäftsführer: Sven Bent, Frank Fischer, Paul Koch <br />
            Handelsregister: HR B 7519 | Amtsgericht Lemgo | UST-ID: DE277772364
            <br />
            */}
          </MjmlText>

          <MjmlText
            paddingBottom="32px"
            paddingTop="12px"
            paddingLeft="20px"
            paddingRight="20px"
            fontSize="30px"
            lineHeight="30px"
            color="#00354c"
          >
            <a
              href="https://www.remarket-now.com"
              style={{ color: "#00354c", textDecoration: "none" }}
              target="_blank"
            >
              www.remarket-now.com
            </a>
          </MjmlText>
        </MjmlColumn>
      </MjmlSection>

      <MjmlSection padding="0" fullWidth="full-width">
        <MjmlColumn>
          <MjmlImage
            width="600px"
            src="https://dr72jt4i3ro9g.cloudfront.net/bottomshadow_white_alpha.png"
            padding="0px"
          ></MjmlImage>
        </MjmlColumn>
      </MjmlSection>
    </Fragment>
  );
}
