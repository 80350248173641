import React, { useMemo } from "react";

import { MjmlButton, MjmlColumn, MjmlSection } from "../../mjml-react";

export type ButtonVariant = "contained" | "outlined" | "danger" | undefined;

export type Props = {
  label: string;
  href: string;
  variant?: ButtonVariant;
};

export default function MainButton({
  label,
  href,
  variant = "contained",
}: Props) {
  const buttonStyles = useMemo(() => {
    if (variant === "outlined") {
      return {
        border: "1px solid #0FBEFF",
        backgroundColor: "#FFFFFF",
        color: "#0FBEFF",
      };
    }

    if (variant === "danger") {
      return {
        border: "1px solid #FF0000",
        backgroundColor: "#FF0000",
        color: "#FFFFFF",
      };
    }

    return {
      border: "1px solid #0FBEFF",
      backgroundColor: "#0FBEFF",
      color: "#FFFFFF",
    };
  }, [variant]);

  return (
    <MjmlSection padding="0px" backgroundColor="#FFFFFF">
      <MjmlColumn>
        <MjmlButton
          borderRadius="50px"
          {...buttonStyles}
          innerPadding="9px 60px"
          href={href}
          padding="0px"
          paddingTop="40px"
          paddingBottom="40px"
          fontSize="16px"
          fontWeight="bold"
        >
          {label}
        </MjmlButton>
      </MjmlColumn>
    </MjmlSection>
  );
}
