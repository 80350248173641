import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import formatMoney from "../../../../../utils/formatMoney";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

/**
 * @name PricingCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function PricingCell({ price: existingPrice, id ,onBulkUpdate, bulkEnabled }) {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    if (existingPrice) {
      setPrice(existingPrice);
    }
  }, []);

  const updatePrice = (value) => {
    onBulkUpdate(value, id);
    setPrice(value);
  }

  if (!bulkEnabled) {
    return price && formatMoney(price, "EUR") || null;
  }

  return (
    <Box textAlign="center">
      <TextField
        autoFocus
        value={price}
        type="number"
        inputProps={{
          tabIndex: 1
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>
        }}
        onChange={(event) => {
          updatePrice(Number(event.target.value));
        }}
      />
    </Box>
  );
}

PricingCell.propTypes = {
  bulkEnabled: PropTypes.bool,
  priceRange: PropTypes.number
};
