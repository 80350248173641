import { useQuery } from "@apollo/react-hooks";
import notificationsQuery from "./notifications";
import useDebouncedCallback from "./useDebouncedCallback";

export default function useNotifications({ skip }) {
  // const { authStore } = useStores();

  const { loading, data, fetchMore } = useQuery(notificationsQuery, {
    skip,
    variables: {
      limit: 10,
      offset: 0
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000
  });

  const [loadMore] = useDebouncedCallback(() => {
    fetchMore({
      variables: {
        offset: data.notifications.nodes.length
      },
      updateQuery: (prevState, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevState;

        return {
          ...fetchMoreResult,
          notifications: {
            ...fetchMoreResult.notifications,
            nodes: [
              ...(prevState && prevState.notifications.nodes),
              ...(fetchMoreResult && fetchMoreResult.notifications.nodes)
            ]
          }
        };
      }
    })
  }, 1000);

  const notificationsWithCache = data && data.notifications && data.notifications.nodes;
  const notifications = [...new Set(notificationsWithCache)];

  return [
    notifications,
    loading,
    loadMore
  ];
}
