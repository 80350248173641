import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CircleIcon from "mdi-material-ui/CheckboxBlankCircle";

const useStyles = makeStyles((theme) => ({
  isVisible: {
    color: theme.palette.colors.forestGreen300,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  },
  isHidden: {
    color: theme.palette.colors.black40,
    fontSize: theme.typography.fontSize * 1.25,
    marginRight: theme.spacing(1)
  }
}));

/**
 * @name YesNoCell
 * @param {Object} row A react-table row object
 * @return {React.Component} A date component
 */
export default function YesNoCell({ value }) {
  const classes = useStyles();

  if (value) {
    return (
      <div style={{ display: "flex" }}>
        <CircleIcon className={classes.isVisible}/>
        <span>Ja</span>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <CircleIcon className={classes.isHidden}/>
      <span>Nein</span>
    </div>
  );
}

YesNoCell.propTypes = {
  value: PropTypes.bool
};
