import gql from "graphql-tag";

export const accountLabelFragment = gql`
  fragment AccountLabel on Account {
    _id
    name
    firstName
    lastName
    primaryEmailAddress
    confirmedEmail
    isActivated
    isFamilyAndFriends
    invoicePaymentAllowed
    invoiceAddress {
      fullName
      firstName
      lastName
    }
    updatedAt
    vatId
    datevId
  }
`;
