import React from "react";
import { useQuery } from "@apollo/react-hooks";
import productSpecsQuery from "../../product-specifications/graphql/queries/productSpecs";
import productSpecGroupsWithSpecificationsQuery from "../../product-specification-groups/graphql/queries/productSpecGroupsWithSpecifications";

function matchSpecifications(productSpecs, productSpecGroups, displaySpecifications, previewSpecifications) {
    const mapSpecs = (displaySpecifications) => displaySpecifications.map((relatedSpecification) => {
      const { type, position } = relatedSpecification;
      if (type === "specification") {
        const foundSpec = productSpecs && productSpecs.find((productSpec) => productSpec._id === relatedSpecification._id);
        if (!foundSpec) {
          return null;
        }
        return { ...foundSpec, type, position };
      }
      if (type === "specificationGroup") {
        const foundSpec = productSpecGroups.find((productSpec) => productSpec._id === relatedSpecification._id);
        if (!foundSpec) {
          return null;
        }
        return { ...foundSpec, type, position };
      }
    });
  
    return {
      mappedRelatedSpecifications: displaySpecifications ? mapSpecs(displaySpecifications).filter(Boolean) : [],
      mappedRelatedSpecificationsPreview: previewSpecifications ? mapSpecs(previewSpecifications).filter(Boolean) : []
    };
}

export default function useProductSpecsAndSpecGroups({ shopId, product, productClass }) {
    let displaySpecifications;
    let previewSpecifications;

    if (productClass) {
        const { relatedSpecifications, relatedSpecificationsPreview } = productClass;
        displaySpecifications = relatedSpecifications;
        previewSpecifications = relatedSpecificationsPreview;
    }

    if (product) {
        const { displaySpecifications: productDisplaySpecifications, previewSpecifications: productPreviewSpecifications } = product;
        displaySpecifications = productDisplaySpecifications;
        previewSpecifications = productPreviewSpecifications;
    }

    const mixedSpecifications = [...new Set(displaySpecifications && displaySpecifications.concat(previewSpecifications))];

    let productSpecs = [];
    let productSpecGroups = [];

    const mappedSpecificationIds = mixedSpecifications && mixedSpecifications.reduce((ids, specification) => {
      if (specification && specification.type === "specification") {
        ids.push(specification._id);
      }
      return ids;
    }, []);

    const mappedSpecificationGroupIds = mixedSpecifications && mixedSpecifications.reduce((ids, specification) => {
      if (specification && specification.type === "specificationGroup") {
        ids.push(specification._id);
      }
      return ids;
    }, []);

    // Spezifikationen
    const { loading: loadingSpecifications, data: dataSpecifications, error: errorSpecifications } = useQuery(productSpecsQuery, {
        onError(fetchError) {
          console.error(fetchError);
        },
        variables: {
          shopId: [shopId],
          filters: {
            specificationIds: mappedSpecificationIds
          }
        },
        skip: !mappedSpecificationIds || !mappedSpecificationIds.length,
        fetchPolicy: "network-only"
    });

    if (dataSpecifications && dataSpecifications.productSpecs) {
        productSpecs = dataSpecifications.productSpecs && dataSpecifications.productSpecs.nodes || [];
    }

    // Spezifikationsgruppen
    const { loading: loadingSpecificationGroups, data: dataSpecificationGroups, error: errorSpecificationGroups } = useQuery(productSpecGroupsWithSpecificationsQuery, {
        onError(fetchError) {
          console.error(fetchError);
        },
        variables: {
          shopId: [shopId],
          filters: {
            specificationIds: mappedSpecificationGroupIds
          }
        },
        skip: !mappedSpecificationGroupIds || !mappedSpecificationGroupIds.length,
        fetchPolicy: "network-only"
    });
  
    // productSpecGroupsWithSpecifications
    if (dataSpecificationGroups && dataSpecificationGroups.productSpecGroupsWithSpecifications) {
        productSpecGroups = dataSpecificationGroups.productSpecGroupsWithSpecifications.nodes || [];
    }

    const { mappedRelatedSpecifications, mappedRelatedSpecificationsPreview } = matchSpecifications(productSpecs, productSpecGroups, displaySpecifications, previewSpecifications);

    return [
        mappedRelatedSpecifications || [],
        mappedRelatedSpecificationsPreview || []
    ];
}