import gql from "graphql-tag";
import { productSpecGroupFragment } from "../fragments/productSpecGroup";

export default gql`
  query productSpecGroupsQuery($shopIds: [ID], $filters: ProductSpecGroupFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: [ProductSpecGroupSortBy]) {
    productSpecGroups(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy) {
      nodes {
        ...ProductSpecGroup
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${productSpecGroupFragment}
`;
