import React, { Fragment, useState, useMemo, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ImportIcon from "mdi-material-ui/Upload";
import MoreIcon from "mdi-material-ui/DotsVertical";
import PlusIcon from "mdi-material-ui/Plus";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import LinearProgress from "@material-ui/core/LinearProgress";

import config from "../../../../config";

import DraftEditor from "./draftEditor";
import PickerDialog from "./PickerDialog";

import FileRecord from "../../../../FileRecord";

import { convert, converters } from "./transformers";

import useMainSlider from "../hooks/useMainSlider.js";

import PreviewDrawer from "./PreviewDrawer";

import ContentViewPrimaryDetailLayout from "../../../../layouts/ContentViewPrimaryDetailLayout";
import FrontendContentToolbar from "./FrontendContentToolbar";

const {
  REACT_APP_PUBLIC_FILES_BASE_URL,
  REACT_APP_PUBLIC_STOREFRONT_HOME_URL,
} = config;

const createMediaRecordMutation = gql`
  mutation CreateMediaRecord($input: CreateMediaRecordInput!) {
    createMediaRecord(input: $input) {
      mediaRecord {
        _id
        original {
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
    margin: theme.spacing(3, 0),
  },
  content: {
    color: theme.palette.text.secondary,
  },
  iframeContainer: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none",
  },
  addFab: {
    display: "flex",
    margin: `${theme.spacing(2)}px auto`,
  },
}));

function ensureUrl(path) {
  // Check if the path is already absolute
  const pathStart = path.slice(0, 8).toLowerCase();
  if (
    pathStart.startsWith("https://") ||
    pathStart.startsWith("http://") ||
    pathStart.startsWith("//")
  ) {
    return path;
  }

  let pathNoSlash = path;
  if (path.startsWith("/")) {
    pathNoSlash = path.slice(1);
  }

  return `${REACT_APP_PUBLIC_FILES_BASE_URL}/${pathNoSlash}`;
}

function isDarkBackground(hexcolor) {
  return parseInt(hexcolor.substr(1), 16) > 0xffffff / 2 ? false : true;
}

function ColorTextField({ field, value: outerValue, setField }) {
  const { t } = useTranslation("ns1");
  const [showPicker, setShowPicker] = useState(false);
  const [value, setValue] = useState(outerValue);

  return (
    <Fragment>
      <TextField
        fullWidth
        InputProps={{
          style: {
            color: value || "",
            backgroundColor:
              value && isDarkBackground(value) ? null : "#808080",
          },
        }}
        onClick={(event) => {
          setShowPicker(true);
        }}
        InputLabelProps={{ shrink: true }}
        value={value}
        label={t(`admin.frontEndContent.mainSlider.${field}`)}
        onChange={(event) => {
          setField(field, event.target.value);
        }}
      />
      {showPicker && (
        <PickerDialog
          value={value}
          onClick={() => {
            setShowPicker(false);
          }}
          onChange={(c) => {
            const newValue = converters[convert](c);
            setValue(newValue);
          }}
        />
      )}
    </Fragment>
  );
}

function ImageField({ field, value, setField }) {
  const classes = useStyles();
  const { t } = useTranslation("ns1");
  const [uploadProgress, setUploadProgress] = useState();
  const [uploadPreview, setUploadPreview] = useState();

  const ensuredImage = useMemo(() => value && ensureUrl(value), [value]);

  const [createMediaRecord] = useMutation(createMediaRecordMutation, {
    ignoreResults: true,
  });

  const onDrop = async (accepted) => {
    if (accepted.length === 0) return;
    const file = accepted[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      setUploadPreview(base64data);
    };

    const fileRecord = FileRecord.fromFile(file);

    fileRecord.metadata = {
      type: "slider-image",
    };

    fileRecord.on("uploadProgress", (progress) => {
      setUploadProgress(progress);
    });

    try {
      await fileRecord.upload();

      const { data } = await createMediaRecord({
        variables: {
          input: {
            mediaRecord: fileRecord.document,
            shopId: "cmVhY3Rpb24vc2hvcDpKOEJocTN1VHRkZ3daeDNyeg==",
          },
        },
      });

      const {
        createMediaRecord: { mediaRecord },
      } = data;

      const recordId = mediaRecord && mediaRecord._id;
      const recordFileName = mediaRecord && mediaRecord.original.name;

      const newFileUrl = encodeURI(
        `/assets/files/Media/${recordId}/image/${recordFileName}`
      ).replace(/[!'()*]/g, (c) => {
        return "%" + c.charCodeAt(0).toString(16);
      });

      // TODO: Artifical delay, find alternative
      setTimeout(() => {
        setField(field, newFileUrl);
        setUploadPreview(null);
        setUploadProgress(null);
      }, 5000);
    } catch (error) {
      setUploadPreview(null);
      setUploadProgress(null);
      console.error(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/png, image/jpeg",
    disableClick: true,
    disablePreview: true,
    multiple: false,
    onDrop,
  });

  return (
    <Fragment>
      {ensuredImage || uploadPreview ? (
        <img src={uploadPreview || ensuredImage} width="100%" alt={field} />
      ) : null}
      {uploadProgress ? (
        <LinearProgress variant="determinate" value={uploadProgress} />
      ) : null}
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button size="small" {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <ImportIcon className={classes.leftIcon} />
                {t("admin.frontEndContent.mainSlider.upload")}
              </Button>
            </InputAdornment>
          ),
        }}
        label={t(`admin.frontEndContent.mainSlider.${field}`)}
        onChange={(event) => {
          setField(field, event.target.value);
        }}
      />
    </Fragment>
  );
}

function SchemaTextField({ field, value, setField }) {
  const { t } = useTranslation("ns1");

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      fullWidth
      value={value}
      label={t(`admin.frontEndContent.mainSlider.${field}`)}
      onChange={(event) => {
        setField(field, event.target.value);
      }}
    />
  );
}

const MainSliderCard = ({ locale, setLocale, PrimaryComponent }) => {
  const classes = useStyles();
  const { t } = useTranslation("ns1");
  const [anchorEl, setAnchorEl] = useState([]);
  const [iframeKeyCount, setIframeKeyCount] = useState(0);

  const setAnchorElIndex = (arrayIndex, value) => {
    setAnchorEl(
      Object.assign([], anchorEl, {
        [arrayIndex]: value,
      })
    );
  };

  const {
    isHeaderSliderLoading,
    headerSlider,
    refetchHeaderSlider,
    setHeaderField,
    removeHeaderSlide,
    addHeaderSlide,
    undoState,
    isSaving,
    hasPendingChanges,
    publishHeaderSlider,
    resetHeaderSlider,
    updateHeaderSlider,
  } = useMainSlider(locale);

  // Each time the saving finished, refresh iframe
  useEffect(() => {
    if (!isSaving) {
      setIframeKeyCount(iframeKeyCount + 1);
    }
  }, [isSaving]);

  const { slides = [] } = headerSlider || {};

  const setField = (index) => (field, value) => {
    setHeaderField(field, index, value);
  };

  const previewUrl = `${REACT_APP_PUBLIC_STOREFRONT_HOME_URL}/api/preview?secret=Tj4GJGxo9BiJvf8VWjgQVp&route=/${locale}`;

  return (
    <ContentViewPrimaryDetailLayout
      AppBarComponent={
        <FrontendContentToolbar
          locale={locale}
          setLocale={setLocale}
          undoState={undoState}
          isSaving={isSaving}
          hasPendingChanges={hasPendingChanges}
          publishHeaderSlider={publishHeaderSlider}
          resetHeaderSlider={resetHeaderSlider}
          updateHeaderSlider={updateHeaderSlider}
        />
      }
      PrimaryComponent={PrimaryComponent}
      DetailComponent={
        <Container maxWidth="md">
          {slides.map((slide, index) => {
            const {
              imageUrl,
              buttonMdStyles,
              backgroundColor,
              backgroundImage,
              buttonText,
              buttonLink,
              buttonLinkAs,
              containerColor,
              draftContent,
            } = slide;

            return (
              <Card key={`mainSlide-${index}`} className={classes.card}>
                <CardHeader
                  title={t("admin.frontEndContent.mainSliderTitle")}
                  subheader={t("admin.contentType.slider")}
                  action={
                    <Fragment>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          setAnchorElIndex(index, event.currentTarget)
                        }
                      >
                        <MoreIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl[index]}
                        keepMounted
                        open={Boolean(anchorEl[index])}
                        onClose={() => setAnchorElIndex(index, null)}
                      >
                        <MenuItem
                          onClick={() => {
                            setAnchorElIndex(index, null);
                            removeHeaderSlide(index);
                          }}
                        >
                          Entfernen
                        </MenuItem>
                        {/*
                        <MenuItem onClick={() => setAnchorElIndex(index, null)}>
                          Verschieben
                        </MenuItem>
                        */}
                      </Menu>
                    </Fragment>
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item sm={6}>
                      <ImageField
                        value={backgroundImage}
                        field="backgroundImage"
                        setField={setField(index)}
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <ImageField
                        value={imageUrl}
                        field="imageUrl"
                        setField={setField(index)}
                      />
                    </Grid>

                    <Grid item md={6}>
                      <SchemaTextField
                        value={buttonText}
                        field="buttonText"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <ColorTextField
                        value={backgroundColor}
                        field="backgroundColor"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <ColorTextField
                        value={containerColor}
                        field="containerColor"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SchemaTextField
                        value={buttonLink}
                        field="buttonLink"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SchemaTextField
                        value={buttonLinkAs}
                        field="buttonLinkAs"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <SchemaTextField
                        value={buttonMdStyles}
                        field="buttonMdStyles"
                        setField={setField(index)}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <FormControl
                        variant="outlined"
                        style={{
                          backgroundImage: backgroundImage
                            ? `url('${backgroundImage}')`
                            : null,
                        }}
                      >
                        <FormLabel component="legend">
                          <Typography gutterBottom>Text</Typography>
                        </FormLabel>
                        <FormGroup>
                          <DraftEditor
                            value={draftContent}
                            onChange={(newDraftContent) =>
                              setHeaderField(
                                "draftContent",
                                index,
                                newDraftContent
                              )
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
          <Fab
            color="primary"
            className={classes.addFab}
            onClick={addHeaderSlide}
          >
            <PlusIcon />
          </Fab>
          <PreviewDrawer previewUrl={previewUrl}>
            <iframe
              title="preview"
              key={`preview-${iframeKeyCount}`}
              src={previewUrl}
              className={classes.iframeContainer}
            />
          </PreviewDrawer>
        </Container>
      }
    />
  );
};

export default MainSliderCard;
