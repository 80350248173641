import gql from "graphql-tag";

export default gql`
  query productsWithoutImage(
    $shopIds: [ID]
    $filters: ProductFilterInput
    $first: ConnectionLimitInt
    $offset: Int
    $sortBy: [ProductsSortBy]
  ) {
    productsCustom(
      shopIds: $shopIds
      filters: $filters
      first: $first
      offset: $offset
      sortBy: $sortBy
    ) {
      nodes {
        _id
        productType
        type
        description
        salesDescription
        productDescription
        localization
        productType
        createdAt
        isVisible
        productNumber
        pageTitle
        title
        primaryImage {
          URLs {
            thumbnail
          }
        }
        updatedAt
        publishedAt
        publishedProductHash
        currentProductHash
        limesId
        vendor
        cdsType
      }
      totalCount
      __typename
    }
  }
`;
