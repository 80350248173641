import React, { Fragment, Component } from "react";

import { withStyles, makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

import { Link } from "react-router-dom";

import { Check, CheckAll, Forward } from "mdi-material-ui";
import { Clock } from "react-feather";

import { formatDistanceToNow } from "date-fns";
import { de as deLocale, enUS as enLocale } from "date-fns/locale";

import classNames from "classnames";

const styles = (theme) => ({
  rceContainerMbox: {
    flexDirection: "column",
    display: "block",
    overflow: "hidden",
    minWidth: "200px",
  },
  rceMboxForward: {
    width: "30px",
    height: "30px",
    borderRadius: "20px",
    background: "#fff",
    position: "absolute",
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0 5px 0 rgba(164, 164, 164, 1)",
    cursor: "pointer",
    transition: "all 0.3s ease",
    top: "0",
    bottom: "0",
    margin: "auto",
  },
  rceMboxForwardLeft: {
    display: "flex",
    opacity: "0",
    visibility: "hidden",
    left: "-50px",
  },
  rceMboxForwardRight: {
    display: "flex",
    opacity: "0",
    visibility: "hidden",
    right: "-50px",
  },
  rceMbox: {
    position: "relative",
    background: "white",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    borderTopLeftRadius: "0px",
    marginLeft: "20px",
    marginRight: "5px",
    marginTop: "3px",
    flexDirection: "column",
    marginBottom: "3px",
    padding: "6px 9px 8px 9px",
    float: "left",
    minWidth: "140px",
  },
  message_focus: {
    animationIterationCount: "2",
    WebkitAnimationIterationCount: "2",
    WebkitAnimationDuration: "1s",
    animationName: "message-box-default-focus",
    animationDuration: "1s",
  },
  rceMboxBody: {
    margin: "0",
    paddingBottom: "8px",
    position: "relative",
  },
  rceMboxRight: {
    float: "right",
    marginLeft: "5px",
    marginRight: "20px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "5px",
  },
  rceMboxText: {
    fontSize: "14px",
    wordBreak: "break-word",
    "&:after": {
      content:
        '"\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0"',
    },
  },
  initialOfferProduct: {
    padding: theme.spacing(0, 1),
    fontSize: "14px",
    wordBreak: "break-word",
    "&:after": {
      content:
        '"\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0"',
    },
  },
  rceMboxTime: {
    textAlign: "right",
    color: "rgba(0, 0, 0, 0.45)",
    fontSize: "12px",
    position: "absolute",
    right: "-4px",
    bottom: "-8px",
    display: "flex",
  },
  nonCopiable: {
    "&:before": {
      content: "attr(data-text)",
    },
  },
  rceMboxTimeBlock: {
    right: "0",
    bottom: "0",
    left: "0",
    marginRight: "-6px",
    marginLeft: "-6px",
    paddingTop: "5px",
    paddingRight: "3px",
    paddingBottom: "2px",
    background: "linear-gradient(to top, rgba(0, 0, 0, 0.33), transparent)",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    color: "#fff",
  },
  clearPadding: {
    paddingBottom: "3px",
  },
  clearNotch: {
    borderRadius: "5px 5px 5px 5px !important",
  },
  rceMboxRightNotch: {
    position: "absolute",
    right: "-14px",
    top: "0px",
    width: "15px",
    height: "15px",
    fill: "white",
    filter: "drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2))",
  },
  rceMboxLeftNotch: {
    position: "absolute",
    left: "-14px",
    top: "0px",
    width: "15px",
    height: "15px",
    fill: "white",
  },
  rceMboxTitle: {
    margin: "0",
    marginBottom: "8px",
    fontWeight: "500",
    fontSize: "13px",
    color: "#00B8F1",
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rceMboxTitleClear: {
    marginBottom: "5px",
  },
  rceMboxStatus: {
    marginLeft: "3px",
    fontSize: "15px",
  },
  actionButtonGroup: {
    margin: "8px 0",
    textTransform: "none",
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: "none",
  },
  acceptButtonSelected: {
    textTransform: "none",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  acceptButton: {
    textTransform: "none",
    color: theme.palette.primary.light,
    borderRadius: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  declineButton: {
    textTransform: "none",
    color: theme.palette.primary.light,
    borderRadius: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#FFFFFF",
    },
  },
  declineButtonSelected: {
    textTransform: "none",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: "#FFFFFF",
    },
  },
  counterButton: {
    textTransform: "none",
    color: theme.palette.cdsGreen.light,
    borderRadius: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.cdsGreen.main,
      color: "#FFFFFF",
    },
  },
  counterButtonSelected: {
    textTransform: "none",
    color: "#FFFFFF",
    borderRadius: 0,
    backgroundColor: theme.palette.cdsGreen.main,
    "&:hover": {
      backgroundColor: theme.palette.cdsGreen.light,
    },
  },
  icon: {
    fontSize: "1rem",
    width: 16,
    height: 16,
  },
  iconRead: {
    color: "#00B8F1",
    fontSize: "1rem",
    width: 16,
    height: 16,
  },
  link: {
    color: theme.palette.primary.light,
    fontWeight: 600,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textFieldQuantity: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: "50%",
    width: 65,
  },
  textFieldPrice: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: "50%",
    width: 125,
  },
  sendOfferButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  sendOfferButton: {
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: "#FFFFFF",
  },
});

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: 15,
    background: theme.palette.primary.light,
    marginRight: 8,
  },
  avatarDark: {
    fontSize: 15,
    background: theme.palette.primary.main,
    marginRight: 8,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={2}
      isNumericString={true}
    />
  );
}

function AvatarWithFallback({ position, isAdminView, viewerInitials }) {
  const classes = useStyles();

  if (
    (position === "right" && !isAdminView) ||
    (position === "left" && isAdminView)
  ) {
    return <Avatar className={classes.avatar}>{viewerInitials}</Avatar>;
  }

  if (
    (position === "left" && !isAdminView) ||
    (position === "right" && isAdminView)
  ) {
    return <Avatar className={classes.avatarDark}>CDS</Avatar>;
  }

  return null;
}

class MessageBox extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.focus !== this.props.focus && nextProps.focus === true) {
      if (this.refs.message) {
        this.refs.message.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });

        this.props.onMessageFocused(nextProps);
      }
    }
  }

  state = {
    selectedAcceptOffer: true,
    selectedCounterOffer: false,
    selectedDeclineOffer: false,
  };

  get viewerInitials() {
    const { authStore } = this.props;
    // const { account: { firstName, lastName, primaryEmailAddress } } = authStore;

    const firstName = "Placeholder";
    const lastName = "Placeholder";
    const primaryEmailAddress = "place@hold.er";

    const firstInitial =
      (firstName && firstName.charAt()) ||
      primaryEmailAddress.charAt().toUpperCase();
    const lastInitial = (lastName && lastName.charAt()) || "";
    return `${firstInitial}${lastInitial}`;
  }

  handleChange = (name) => (event) => {
    const { setQuantity, setPrice } = this.props;
    if (name === "quantity") {
      setQuantity(event.target.value);
    }
    if (name === "price") {
      setPrice(Number(event.target.value));
    }
  };

  handleBlur = (name) => (event) => {
    const { setQuantity, quantityMin, quantityMax } = this.props;

    if (name === "quantity") {
      if (event.target.value < quantityMin) {
        setQuantity(quantityMin);
      }
      if (event.target.value > quantityMax) {
        setQuantity(quantityMax);
      }
    }
  };

  render() {
    const {
      classes,
      clickSend,
      submitAnswer,
      quantity,
      price,
      counterPrice,
      quantityMin,
      quantityMax,
      linkedItem,
    } = this.props;
    const {
      selectedAcceptOffer,
      selectedCounterOffer,
      selectedDeclineOffer,
    } = this.state;

    const positionCls = classNames(
      classes.rceMbox,
      this.props.position === "right" && classes.rceMboxRight
    );

    /*
    const thatAbsoluteTime =
      this.props.type !== "text" &&
      this.props.type !== "file" &&
      !(this.props.type === "location" && this.props.text);
    */

    const thatAbsoluteTime = false;

    const dateText =
      this.props.date &&
      formatDistanceToNow(this.props.date, { locale: deLocale });

    return (
      <div>
        {this.props.renderAddCmp instanceof Function &&
          this.props.renderAddCmp()}
        <div
          className={classNames(
            positionCls,
            thatAbsoluteTime && classes.clearPadding,
            !this.props.notch && classes.clearNotch,
            this.props.focus && classes.message_focus
          )}
        >
          <div
            className={classes.rceMboxBody}
            onContextMenu={this.props.onContextMenu}
          >
            {this.props.forwarded === true && (
              <div
                className={classNames(
                  classes.rceMboxForward,
                  this.props.position === "left" && classes.rceMboxForwardRight,
                  this.props.position === "right" && classes.rceMboxForwardLeft
                )}
                onClick={this.props.onForwardClick}
              >
                <Forward className={classes.icon} />
              </div>
            )}

            {(this.props.title || this.props.avatar) && (
              <div
                style={
                  this.props.titleColor && { color: this.props.titleColor }
                }
                onClick={this.props.onTitleClick}
                className={classNames(
                  classes.rceMboxTitle,
                  this.props.type === "text" && classes.rceMboxTitleClear
                )}
              >
                {this.props.avatar && (
                  <AvatarWithFallback
                    position={this.props.position}
                    isAdminView={this.props.isAdminView}
                    viewerInitials={this.viewerInitials}
                  />
                )}
                {this.props.title && (
                  <Typography>{this.props.title}</Typography>
                )}
              </div>
            )}

            {linkedItem ? (
              <Link to={linkedItem} className={classes.link}>
                <Typography className={classes.rceMboxText}>
                  {this.props.text}
                </Typography>
              </Link>
            ) : (
              <Typography className={classes.rceMboxText}>
                {this.props.text}
              </Typography>
            )}

            {this.props.type === "acceptOrDecline" && (
              <ButtonGroup
                className={classes.actionButtonGroup}
                variant="contained"
                size="small"
                aria-label="Angebot akzeptieren"
              >
                <Button className={classes.acceptButton}>Akzeptieren</Button>
                <Button className={classes.declineButton}>Ablehnen</Button>
              </ButtonGroup>
            )}

            {this.props.type === "acceptDeclineOrCounter" && (
              <Fragment>
                <ButtonGroup
                  className={classes.actionButtonGroup}
                  variant="contained"
                  size="small"
                  aria-label="Angebot akzeptieren"
                >
                  <Button
                    className={
                      selectedAcceptOffer
                        ? classes.acceptButtonSelected
                        : classes.acceptButton
                    }
                    onClick={() =>
                      this.setState({
                        selectedAcceptOffer: true,
                        selectedCounterOffer: false,
                        selectedDeclineOffer: false,
                      })
                    }
                  >
                    Akzeptieren
                  </Button>
                  <Button
                    className={
                      selectedDeclineOffer
                        ? classes.declineButtonSelected
                        : classes.declineButton
                    }
                    onClick={() =>
                      this.setState({
                        selectedAcceptOffer: false,
                        selectedCounterOffer: false,
                        selectedDeclineOffer: true,
                      })
                    }
                  >
                    Ablehnen
                  </Button>
                  <Button
                    className={
                      selectedCounterOffer
                        ? classes.counterButtonSelected
                        : classes.counterButton
                    }
                    onClick={() =>
                      this.setState({
                        selectedAcceptOffer: false,
                        selectedCounterOffer: true,
                        selectedDeclineOffer: false,
                      })
                    }
                  >
                    Gegenangebot
                  </Button>
                </ButtonGroup>
                {selectedCounterOffer && (
                  <div>
                    <TextField
                      id="quantity"
                      label="Menge"
                      className={classes.textFieldQuantity}
                      defaultValue={quantity}
                      type="number"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">x</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: quantityMin,
                        max: quantityMax,
                        step: 1,
                      }}
                      margin="normal"
                    />
                    <TextField
                      id="price"
                      label="Preis"
                      className={classes.textFieldPrice}
                      value={counterPrice}
                      defaultValue={price}
                      onChange={this.handleChange("price")}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />
                  </div>
                )}
                <div className={classes.sendOfferButtonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      submitAnswer({
                        selectedAcceptOffer,
                        selectedDeclineOffer,
                        selectedCounterOffer,
                        counterPrice: counterPrice || price,
                      })
                    }
                    className={classes.sendOfferButton}
                  >
                    Absenden
                  </Button>
                </div>
              </Fragment>
            )}

            {this.props.type === "initialOffer" && (
              <Fragment>
                {this.props.productName && (
                  <Typography className={classes.initialOfferProduct}>
                    Produkt: <strong>{this.props.productName}</strong>
                  </Typography>
                )}
                <TextField
                  id="quantity"
                  label="Menge"
                  className={classes.textFieldQuantity}
                  value={quantity}
                  type="number"
                  onChange={this.handleChange("quantity")}
                  onBlur={this.handleBlur("quantity")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">x</InputAdornment>
                    ),
                  }}
                  inputProps={{ min: quantityMin, max: quantityMax, step: 1 }}
                  margin="normal"
                />
                <TextField
                  id="price"
                  label="Preis"
                  className={classes.textFieldPrice}
                  value={price}
                  onChange={this.handleChange("price")}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                />
                <div className={classes.sendOfferButtonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={clickSend}
                    className={classes.sendOfferButton}
                  >
                    Absenden
                  </Button>
                </div>
              </Fragment>
            )}

            <Typography
              className={classNames(
                classes.rceMboxTime,
                thatAbsoluteTime && classes.rceMboxTimeBlock,
                !this.props.copiableDate && classes.nonCopiable
              )}
              data-text={this.props.copiableDate ? undefined : dateText}
            >
              {this.props.copiableDate &&
                this.props.date &&
                !isNaN(this.props.date) &&
                (this.props.dateString || dateText)}
              {this.props.status && (
                <span className={classes.rceMboxStatus}>
                  {this.props.status === "waiting" && (
                    <Clock className={classes.icon} />
                  )}

                  {this.props.status === "sent" && (
                    <Check className={classes.icon} />
                  )}

                  {this.props.status === "received" && (
                    <CheckAll className={classes.icon} />
                  )}

                  {this.props.status === "read" && (
                    <CheckAll className={classes.iconRead} color="#4FC3F7" />
                  )}
                </span>
              )}
            </Typography>
          </div>

          {this.props.notch &&
            (this.props.position === "right" ? (
              <svg
                className={classNames(
                  classes.rceMboxRightNotch,
                  this.props.focus && classes.message_focus
                )}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 0v20L20 0" />
              </svg>
            ) : (
              <div>
                <svg
                  className={classNames(
                    classes.rceMboxLeftNotch,
                    this.props.focus && classes.message_focus
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <defs>
                    <filter id="filter1" x="0" y="0">
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dx="-2"
                        dy="-5"
                      />
                      <feGaussianBlur
                        result="blurOut"
                        in="offOut"
                        stdDeviation="3"
                      />
                      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                  </defs>
                  <path d="M20 0v20L0 0" filter="url(#filter1)" />
                </svg>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

MessageBox.defaultProps = {
  position: "left",
  type: "text",
  text: "",
  title: null,
  titleColor: null,
  onTitleClick: null,
  onForwardClick: null,
  date: null,
  data: {},
  onClick: null,
  onOpen: null,
  onDownload: null,
  onLoad: null,
  onPhotoError: null,
  forwarded: false,
  status: null,
  dateString: null,
  notch: true,
  avatar: null,
  renderAddCmp: null,
  copiableDate: false,
  onContextMenu: null,
  focus: false,
  onMessageFocused: null,
};

export default withStyles(styles)(MessageBox);
