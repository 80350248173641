import gql from "graphql-tag";
import { productSpecGroupWithSpecificationsFragment } from "../fragments/productSpecGroupWithSpecifications";

export default gql`
  query productSpecGroupsWithSpecificationsQuery($shopIds: [ID], $filters: ProductSpecGroupFilterInput, $first: ConnectionLimitInt, $offset: Int, $sortBy: ProductSpecGroupSortByField, $sortOrder: SortOrder) {
    productSpecGroupsWithSpecifications(shopIds: $shopIds, filters: $filters, first: $first, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder) {
      nodes {
        ...ProductSpecGroupWithSpecifications
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${productSpecGroupWithSpecificationsFragment}
`;
