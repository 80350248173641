import React from "react";
import ImageIcon from "mdi-material-ui/Image";
import { registerOperatorRoute } from "../../../routes";
import ContentViewExtraWideLayout from "../../../layouts/ContentViewExtraWideLayout";
import ProductImageTable from "./components/ProductImageTable";

registerOperatorRoute({
  isNavigationLink: true,
  isSetting: false,
  priority: 30,
  layoutComponent: ContentViewExtraWideLayout,
  mainComponent: ProductImageTable,
  path: "/product-images",
  isProduct: true,
  // eslint-disable-next-line react/display-name
  SidebarIconComponent: (props) => <ImageIcon {...props} />,
  sidebarI18nLabel: "admin.dashboard.productImagesLabel",
});
