import Handlebars from "handlebars";

export default function getNewsletterUploadfile(context) {
  const newsletterTemplate = `//Listing Type	Shop Code	Warehouse Code	Listing Title	Listing Description	Listing Long Description	Product Name	Product Description	Product Long Description	Catalog	Available Qty	Minimum Purchase Qty	Opening Bid/Price	Bid Increment	Reserve Price	Listing Start Date	Listing Start Time	Listing End Date	Listing End Time	Item No./SKU	Currency Code	User Data1	User Data2	User Data3	User Data4	User Data6	User Data7	User Data8	User Data9	User Data10	User Data11	User Data12	User Data13	User Data14	User Data15	Is Promotion	is_clearance 	is_touchable
{{#each lose}}{{type_short_category}}	20800	CDS_WH	KW{{../kw}}_{{../year}}_{{number}}-LGI-Los {{number}} VKL{{../vkl}} - {{amount}} {{manufactor}} {{type}}	KW{{../kw}}_{{../year}}_{{number}}-LGI-Los {{number}} VKL{{../vkl}} - {{amount}} {{manufactor}} {{type}}	"<!DOCTYPE HTML PUBLIC ""-//W3C//DTD HTML 4.0 Transitional//EN"">"				|cgyDaimler|	1	1	{{minimumAmount}}	1		{{../startDateValue}}	09:00	{{../endDateValue}}	{{endTime}}		EUR	|150|													TRUE	FALSE	FALSE	FALSE
{{/each}}
{{#each zubehoer}}AFRZ	20800	CDS_WH	KW{{../kw}}_{{../year}}_{{number}}-LGI-Zubehör Los {{number}} VKL{{../vkl}} - {{amount}} {{manufactor}} {{type}}	KW{{../kw}}_{{../year}}_{{number}}-LGI-Zubehör Los {{number}} VKL{{../vkl}} - {{amount}} {{manufactor}} {{type}}	"<!DOCTYPE HTML PUBLIC ""-//W3C//DTD HTML 4.0 Transitional//EN"">"				|cgyDaimler|	1	1	{{minimumAmount}}	1		{{../startDateValue}}	09:00	{{../endDateValue}}	{{endTime}}		EUR	|150|													TRUE	FALSE	FALSE	FALSE
{{/each}}`;

  const template = Handlebars.compile(newsletterTemplate);
  const parsedTemplate = template(context);

  return parsedTemplate;
}